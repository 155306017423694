import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_EVENT_USER_COMMENTS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateEventsUserCommentsCache = (eventsUsersId) => {
  const client = useApolloClient();
  const updateCache = (eventsUser) => {
    try {
      const data = client.readQuery({
        query: FETCH_EVENT_USER_COMMENTS,
        variables: {
          eventsUsersId: parseInt(eventsUsersId, 10),
        },
      });
      client.writeQuery({
        query: FETCH_EVENT_USER_COMMENTS,
        data: {
          fetchEventUserComments: [
            { ...eventsUser },
            ...data.fetchEventUserComments,
          ],
        },
        variables: {
          eventsUsersId: parseInt(eventsUsersId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateEventsUserCommentsCache;
