/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { get, last, isEmpty } from 'lodash/fp';
import {
  TitleC, Hr, RoundImg, GrayText, ImgLink,
} from '../Auth/Layout';
import SINK_ICON from '../../Images/sink.png';

const UserDetail = (props) => {
  const {
    data,
    imageAttached,
    onClick,
    schoolUser,
  } = props;

  const getFileName = (url) => url.split('/').pop();

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-12 col-3 px-4">
          {get('profileImageUrl', data.fetchUser)
            ? <RoundImg><img src={data.fetchUser.profileImageUrl} alt="User-Icon" className="centerImg" /></RoundImg>
            : (
              <>
                {imageAttached
                  ? <RoundImg><img src={imageAttached} alt="User-Icon" className="centerImg" /></RoundImg>
                  : null}
              </>
            )}
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 mt-4 px-4">
          <TitleC> First Name </TitleC>
          <Hr />
          <GrayText>
            {get('firstname', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> Last Name </TitleC>
          <Hr />
          <GrayText>
            {get('lastname', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> Date of Birth </TitleC>
          <Hr />
          <GrayText>
            {get('dateOfBirth', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> Gender </TitleC>
          <Hr />
          <GrayText>
            {get('gender', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> Phone </TitleC>
          <Hr />
          <GrayText>
            {get('phone', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> Email </TitleC>
          <Hr />
          <GrayText>
            {get('email', data.fetchUser)}
          </GrayText>
          <div className="mt-4" />
          <TitleC> School </TitleC>
          <Hr />
          <GrayText>
            {get('name', get('fetchSchool', last(schoolUser)))}
          </GrayText>
          <div className="mt-4" />
          <TitleC> School Verification</TitleC>
          <Hr />
          <div className="mt-4" />
          <GrayText>
            {!isEmpty(get('fetchUserAttachment', last(schoolUser)))
              ? (get('fetchUserAttachment', last(schoolUser))).map((file) => (
                <div className="d-flex" key={file.id}>
                  {file.verificationFileUrl
                    ? (
                      <ImgLink onClick={() => onClick(file.verificationFileUrl)}>
                        <img className="img-fluid" src={SINK_ICON} alt="arrow" />
                        {'  '}
                        {getFileName(file.verificationFileUrl)}
                      </ImgLink>
                    )
                    : <GrayText>(None)</GrayText>}
                </div>
              ))
              : 'N/A'}
          </GrayText>
        </div>
      </div>
    </>
  );
};

UserDetail.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  imageAttached: PropTypes.string,
  onClick: PropTypes.func,
  schoolUser: PropTypes.instanceOf(Object).isRequired,
};

UserDetail.defaultProps = {
  imageAttached: null,
  onClick: null,
};

export default UserDetail;
