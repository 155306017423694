import { getOr, first } from 'lodash/fp';

const useUserPermissionChecker = (user) => {
  const checkPermission = (permissionName) => {
    const permissions = getOr([], 'fetchRole.permissions', user);
    return first(permissions.filter((x) => x.title === permissionName));
  };

  return checkPermission;
};

export default useUserPermissionChecker;
