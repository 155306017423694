import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import {
  DELETE_RESOURCE,
} from '../../../GraphQL/Mutations';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';
import REMOVE_ICON from '../../../Images/icons/remove.png';

const IconButton = styled.button`
background: transparent;
height: 20px;
border: none;
`;

const ExistingResourceRow = ({ resource, onRemoveComplete, onError }) => {
  const extractError = useGraphQLErrorExtractor();
  const [deleteResource, { loading }] = useMutation(
    DELETE_RESOURCE,
    {
      onCompleted: () => {
        onRemoveComplete(resource);
      },
      onError: (err) => {
        onError(extractError(err));
      },
    },
  );

  const onRemove = () => {
    deleteResource({
      variables: {
        id: resource.id,
      },
    });
  };

  return (
    <div className="col-10 mx-2 mt-2" style={{ border: '1px solid #F4AB37' }}>
      <div className="row my-2 justify-content-center align-items-center">
        <div className="col m-4">
          {resource.title}
        </div>
        {resource.body && (
          <div className="col-8 m-4">
            <input style={{ width: '100%' }} disabled value={resource.body} />
          </div>
        )}
        {resource.attachmentUrl && (
        <div className="col-8 m-4">
          <a href={resource.attachmentUrl} target="_blank" rel="noopener noreferrer">{resource.attachmentName}</a>
        </div>
        )}
        <div className="col-1 px-2">
          <div className="row justify-content-center align-items-center">
            {loading ? (
              <i className="fa fa-spinner" aria-label="loading" />
            ) : (
              <IconButton onMouseDown={onRemove} onTouchEnd={onRemove} type="button">
                <img src={REMOVE_ICON} alt="remove resource" />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExistingResourceRow.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    attachmentName: PropTypes.string,
    attachmentUrl: PropTypes.string,
  }).isRequired,
  onRemoveComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ExistingResourceRow;
