import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const ReassignCaptainPopUp = (props) => {
  const {
    toggle,
    modal,
    onClick,
    onSelect,
    bodyText,
  } = props;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Remove Captain</ModalHeader>
      <ModalBody>
        <div className="h6">
          {bodyText}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClick}>Reassign Captain</Button>
        { onSelect
          ? (
            <Button color="secondary" onClick={onSelect}>Continue</Button>
          ) : null
        }
      </ModalFooter>
    </Modal>
  );
};

ReassignCaptainPopUp.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  bodyText: PropTypes.string.isRequired,
};


export default ReassignCaptainPopUp;
