export default {
  colors: {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
      orange: {
        500: '#F4AB37',
      },
    },
  },
};
