import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../Components/Loader';
import { EVENT_TITLE } from '../../../Constants/AppConstants';
import { FETCH_EVENT_APP_INFO } from '../../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import ExistingAnnouncementRow from './ExistingAnnouncementRow';
import AddAnnouncementRow from './AddAnnouncementRow';
import GenericAlert from '../../../Components/GenericAlert';

const Announcements = () => {
  const { id } = useParams();
  const eventId = parseInt(id, 10);
  const [announcements, setAnnouncements] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const queryResult = useQuery(FETCH_EVENT_APP_INFO, {
    variables: {
      id: eventId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const existingAnnouncements = data.fetchEventAppDetail?.announcements;
      // sort by createdAt, newest announcements first
      existingAnnouncements.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      setAnnouncements(existingAnnouncements);
    },
  });
  const eventLoading = queryResult.loading;
  const event = queryResult.data?.fetchEventAppDetail;
  const backLink = `/event-manage/${id}`;
  useHeaderComponentsMutation({
    title: 'Announcements',
    backLink,
    components: [{ key: EVENT_TITLE, value: event?.title }],
  });

  if (eventLoading) {
    return <Loader />;
  }

  // insert new announcement at the beginning of the list
  const onAnnouncementCreate = (newAnnouncement) => {
    const newListOfAnnouncements = [newAnnouncement, ...announcements];
    setAnnouncements(newListOfAnnouncements);
  };

  const onAnnouncementRemoved = (deletedAnnouncementId) => {
    const newListOfAnnouncements = announcements.filter((a) => a.id !== deletedAnnouncementId);
    setAnnouncements(newListOfAnnouncements);
  };

  const setError = (msg) => {
    setErrorMessage(msg);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="row my-2 justify-content-center">
        <AddAnnouncementRow
          eventId={eventId}
          event={event}
          onCreate={onAnnouncementCreate}
          onError={setError}
        />
      </div>
      <div className="row my-4 justify-content-center">
        <h4>Past Announcements:</h4>
        {announcements.map((a) => (
          <ExistingAnnouncementRow
            key={a.id}
            announcement={a}
            onError={setError}
            onRemoveComplete={onAnnouncementRemoved}
          />
        ))}
      </div>
    </>
  );
};

export default Announcements;
