import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { MANAGE_MY_COMPETITIONS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateManageMyCompetitionsCache = (eventId, userId) => {
  const client = useApolloClient();
  const updateCache = (member, action) => {
    try {
      const data = client.readQuery({
        query: MANAGE_MY_COMPETITIONS,
        variables: {
          eventId: parseInt(eventId, 10),
          userId: parseInt(userId, 10),
        },
      });
      const { manageMyCompetitions } = data;
      let response = [];
      if (action === 'add') {
        response = manageMyCompetitions;
        response.push(member);
      } else if(action === 'remove') {
        response = remove((e) => e.id === member.id)(manageMyCompetitions);
      }
      client.writeQuery({
        query: MANAGE_MY_COMPETITIONS,
        data: {
          manageMyCompetitions: response,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          userId: parseInt(userId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateManageMyCompetitionsCache;
