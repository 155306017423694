import React from 'react';
import styled from 'styled-components';

const NotFound = styled.div`
height:377px;

`;
const NoMatch = () => (
  <NotFound>Page not found</NotFound>
);

export default NoMatch;
