import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { get, isEmpty, getOr } from 'lodash/fp';
import {
  FETCH_EVENT_HEADER,
  FETCH_REGISTRATION_OPTION_DETAIL,
  FETCH_ORDER_DETAIL,
  FETCH_REGION_DETAIL,
  FETCH_TENANT_DETAIL,
} from '../../GraphQL/Queries';
import {
  CHECK_COUPON_VALIDITY,
  UPDATE_ORDER,
} from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { Input, Button } from '../../Components/Form';
import {
  LinkText,
  PaymentDescription,
  CircleIcon,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import CIRCLE_ARROW from '../../Images/icons/circle-arrow.png';
import BackLink from '../../Components/BackLink';
import Loader from '../../Components/Loader';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import JoinEventSlider from './JoinEventSlider';

const LineItemPropType = PropTypes.shape({
  lineItemAbleId: PropTypes.number.isRequired,
  lineItemAbleType: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
});

const CouponLineItem = ({ lineItem }) => (
  <>
    <p>
      Coupon Discount
      <span style={{ color: '#F4AB37', float: 'right' }}>
          $
        {parseFloat(lineItem.amount / 100).toFixed(2)}
      </span>
    </p>
    <hr />
  </>
);
CouponLineItem.propTypes = {
  lineItem: LineItemPropType.isRequired,
};

const MerchandiseLineItem = ({ lineItem }) => (
  <>
    <p>
      Add-on:
      {' '}
      {lineItem.description}
      <span style={{ color: '#F4AB37', float: 'right' }}>
          $
        {parseFloat(lineItem.amount / 100).toFixed(2)}
      </span>
    </p>
    <hr />
  </>
);
MerchandiseLineItem.propTypes = {
  lineItem: LineItemPropType.isRequired,
};

const RegistrationOptionLineItem = ({ lineItem, event }) => {
  const option = event.fetchRegistrationOptions
    .find((ro) => ro.id === lineItem.lineItemAbleId.toString());
  return (
    <>
      <p>
        Registration type:
        {' '}
        {get('title', option)}
        <span style={{ color: '#F4AB37', float: 'right' }}>
          $
          {parseFloat(lineItem.amount / 100).toFixed(2)}
        </span>
      </p>
      <hr />
    </>
  );
};
RegistrationOptionLineItem.propTypes = {
  lineItem: LineItemPropType.isRequired,
  event: PropTypes.shape({
    fetchRegistrationOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export const LineItem = ({ lineItem, event }) => {
  switch (lineItem.lineItemAbleType) {
    case 'Coupon': return <CouponLineItem lineItem={lineItem} />;
    case 'Merchandise': return <MerchandiseLineItem lineItem={lineItem} />;
    case 'RegistrationOption': return <RegistrationOptionLineItem lineItem={lineItem} event={event} />;
    default: return null;
  }
};
LineItem.propTypes = {
  lineItem: LineItemPropType.isRequired,
  event: PropTypes.shape({
    fetchRegistrationOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

const JoinEventCart = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [stripeFee, setStripeFee] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0.0);
  const extractError = useGraphQLErrorExtractor();

  const history = useHistory();
  const { params, onChange, setParams } = useFormParams({
    couponCode: '',
  });

  const getLineItemsTotal = (orderObj) => orderObj.fetchLineItems.filter(
    ({ lineItemAbleType }) => lineItemAbleType === 'RegistrationOption',
  ).map(
    (item) => item.amount,
  ).reduce((prev, next) => prev + next);

  const [fetchTenantDetail] = useLazyQuery(
    FETCH_TENANT_DETAIL, {
      onCompleted: (dataTenantDetail) => {
        const tempMissFee = (dataTenantDetail.fetchTenantDetail.percentageShare / 100);
        let intermediateSum = totalAmount;
        intermediateSum += (order.fetchLineItems.filter(
          ({ lineItemAbleType }) => lineItemAbleType === 'RegistrationOption',
        )[0].amount * tempMissFee);
        setStripeFee(0);
        setTotalAmount(intermediateSum);
      },
    },
  );

  const [fetchRegionDetail] = useLazyQuery(
    FETCH_REGION_DETAIL, {
      onCompleted: (dataRegionDetail) => {
        fetchTenantDetail({
          variables: {
            id: parseInt(dataRegionDetail.fetchRegionDetail.tenantId, 10),
          },
        });
      },
    },
  );

  const [fetchEventHeader, {
    data: dataFetchEventHeader,
    loading: eventLoading,
  }] = useLazyQuery(FETCH_EVENT_HEADER);

  const event = getOr({}, 'fetchEventDetail', dataFetchEventHeader);

  useEffect(() => {
    if (!isEmpty(event)) {
      fetchRegionDetail({
        variables: {
          id: parseInt(event.regionId, 10),
        },
      });
    }
  }, [fetchRegionDetail, event]);

  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));


  const [fetchRegistrationOptionDetail, { data: dataRegistrationOption }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const option = getOr({}, 'fetchRegistrationOptionDetail', dataRegistrationOption);

  const [fetchOrderDetail, {
    data: dataFetchOrderDetail,
    loading: orderLoading,
  }] = useLazyQuery(FETCH_ORDER_DETAIL);

  useEffect(() => {
    if (dataFetchOrderDetail?.fetchOrderDetail && order !== {}) {
      const orderData = dataFetchOrderDetail.fetchOrderDetail;
      setOrder(orderData);
      let totalStart = getLineItemsTotal(orderData);
      const lineItemCouponObj = orderData.fetchLineItems.filter(
        ({ lineItemAbleType }) => lineItemAbleType === 'Coupon',
      )[0];
      totalStart += lineItemCouponObj ? lineItemCouponObj.amount : 0;
      setCouponDiscount(lineItemCouponObj ? lineItemCouponObj.amount : 0);
      setTotalAmount(totalStart);
      fetchEventHeader({
        variables: {
          id: parseInt(orderData.eventId, 10),
        },
      });
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(orderData.fetchLineItems[0].lineItemAbleId, 10),
        },
      });
    }
  }, [dataFetchOrderDetail, fetchEventHeader, fetchRegistrationOptionDetail, order]);

  const [checkCouponValidity, { error, loading }] = useMutation(
    CHECK_COUPON_VALIDITY, {
      onCompleted: (data) => {
        if (data.checkCouponValidity.message) setErrorMessage(data.checkCouponValidity.message);
        else {
          setErrorMessage('');
          setCouponDiscount(data.checkCouponValidity.lineItem.amount);
          setOrder(data.checkCouponValidity.order);
          setTotalAmount(totalAmount + data.checkCouponValidity.lineItem.amount);
          setParams({
            couponCode: '',
          });
        }
      },
    },
  );

  const [updateOrder, { error: updateError, loading: updateLoading }] = useMutation(
    UPDATE_ORDER, {
      onCompleted: (data) => {
        updateOrdersCache(data.updateOrder.order, 'update');
        history.push(`/payment-confirmation/${orderId}`);
      },
    },
  );

  useHeaderComponentsMutation({
    title: 'CHECKOUT',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });


  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);


  const couponValidityCheck = () => {
    if (!isEmpty(params.couponCode)) {
      checkCouponValidity({
        variables: {
          orderId: parseInt(order.id, 10),
          registrationOptionId: params.registrationOptionId
            ? parseInt(params.registrationOptionId, 10)
            : order.fetchLineItems[0].lineItemAbleId,
          couponCode: params.couponCode,
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isEmpty(params.couponCode)) {
      checkCouponValidity({
        variables: {
          orderId: parseInt(order.id, 10),
          registrationOptionId: params.registrationOptionId
            ? parseInt(params.registrationOptionId, 10)
            : order.fetchLineItems[0].lineItemAbleId,
          couponCode: params.couponCode,
        },
      });
    } else if (get('amount', order) === 0) {
      updateOrder({
        variables: {
          id: parseInt(order.id, 10),
          status: 2,
          transactionId: 'FREEREGISTRATION',
        },
      });
    } else {
      history.push(`/order/${orderId}/billing`);
    }
  };

  if (error || updateError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  if (loading || eventLoading || orderLoading || updateLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <p style={{ display: 'none' }}>{stripeFee}</p>
          <p style={{ display: 'none' }}>{couponDiscount}</p>
          {error
            ? <GenericAlert>{extractError(error)}</GenericAlert>
            : (
              <>
                {errorMessage
                  ? <GenericAlert>{errorMessage}</GenericAlert>
                  : null}
              </>
            )}
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={option}
          />
          <p style={{ fontWeight: '600' }}>Your Cart</p>
          <Form onSubmit={onSubmit}>
            <div style={{ margin: 'auto', textAlign: 'center' }} />
            <hr />
            <LinkText color="#000" style={{ display: 'block' }}>
              <p>
                {get('firstname', currentUser)}
                {' '}
                {get('lastname', currentUser)}
              </p>
              <p>{event.title}</p>
              <hr />
            </LinkText>
            <PaymentDescription className="pt-3">
              {order && order.fetchLineItems
                && order.fetchLineItems.sort((li1, li2) => li1.id - li2.id).map((lineItem) => (
                  <LineItem key={lineItem.id} lineItem={lineItem} event={event} />
                ))}
            </PaymentDescription>
            {order && order.fetchLineItems && order.fetchLineItems.filter(
              ({ lineItemAbleType }) => lineItemAbleType === 'Coupon',
            )[0]
              ? null
              : (
                <>
                  <Input
                    title="Coupon Code"
                    params={params}
                    withFormGroup
                    id="couponCode"
                    name="couponCode"
                    placeholder="Coupon Code"
                    onChange={onChange}
                    setParams={setParams}
                  />
                  <CircleIcon onClick={() => couponValidityCheck()}>
                    <span><img src={CIRCLE_ARROW} alt="circle" /></span>
                  </CircleIcon>
                </>
              )}
            <PaymentDescription>
              <hr />
              <p style={{ textAlign: 'center' }}>
                Total
                <span style={{ textAlign: 'center', float: 'inherit' }}>
                  {' '}
                    $
                  {parseFloat(get('amount', order) / 100, 10).toFixed(2)}
                </span>
                {` (${event.currency})`}
              </p>
            </PaymentDescription>
            <hr />
            <Button
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="2px solid #FFF"
              marginbottom="-2rem"
              addNewText="PAY NOW"
              permissionName="View Event"
              currentUser={currentUser}
              htmltype="submit"
            >
            AGREE AND SUBMIT
            </Button>
            <div className="pt-3" />
          </Form>
          <BackLink to={`/event-manage/${order.eventId}`} imageNotRequired>
                Skip For Now
          </BackLink>
        </div>
      </div>
    </>
  );
};

export default JoinEventCart;
