import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PASSWORD } from '../../GraphQL/Mutations';
import Form from '../../Components/Form';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';

const UpdatePassword = () => {
  const history = useHistory();
  const [errorMessage, seterrorMessage] = useState('');
  const { params, onChange } = useFormParams({
    password: '',
  });
  useHeaderComponentsMutation({ title: 'Update PASSPHRASE' });
  const [updatePassword, { loading }] = useMutation(
    UPDATE_PASSWORD,
    {
      onCompleted: () => history.push('/me'),
    },
  );
  const onSubmit = (e) => {
    e.preventDefault();
    if (params.password !== params.passwordConfirmation) {
      seterrorMessage('Passphrase and Confirm Passphrase mismatch.');
    } else {
      updatePassword({
        variables: {
          ...params,
        },
      });
    }
  };

  return (
    <Form onSubmit={onSubmit} className="my-5">
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <Input
        title="Passphrase"
        type="password"
        id="password"
        placeholder="New Passphrase"
        name="password"
        required
        minLength="8"
        onChange={onChange}
        withFormGroup
        label="Passphrase"
        labelClass="sr-only"
        params={params}
      />
      <Input
        type="password"
        title="Confirm Passphrase"
        id="confirm_pwd"
        name="passwordConfirmation"
        label="Confirm Passphrase"
        placeholder="Confirm Passphrase"
        minLength="8"
        required
        onChange={onChange}
        withFormGroup
        labelClass="sr-only"
        params={params}
      />
      <div className="d-block mb-5">
        <InputButton disabled={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Update</InputButton>
      </div>
    </Form>
  );
};
export default UpdatePassword;
