import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegionIndex from '../Containers/Region/Index';
import RegionNew from '../Containers/Region/New';
import RegionEdit from '../Containers/Region/Edit';
import AddAdmin from '../Containers/Region/AddAdmin';
import RoleNew from '../Containers/Roles/New';
import RoleEdit from '../Containers/Roles/Edit';
import RoleIndex from '../Containers/Roles/Index';
import RolePermissionIndex from '../Containers/Roles/RolePermission';
import SchoolIndex from '../Containers/Schools/Index';
import SchoolNew from '../Containers/Schools/New';
import SchoolEdit from '../Containers/Schools/Edit';
import SchoolStudentIndex from '../Containers/Schools/SchoolStudentIndex';
import CategoryIndex from '../Containers/Categories/Index';
import CategoryNew from '../Containers/Categories/New';
import CategoryEdit from '../Containers/Categories/Edit';
import CompetitionIndex from '../Containers/Competitions/Index';
import CompetitionNew from '../Containers/Competitions/New';
import CompetitionEdit from '../Containers/Competitions/Edit';
import UserIndex from '../Containers/Users/Index';
import UserView from '../Containers/Users/View';
import InviteUser from '../Containers/Users/InviteUser';
import InviteExistingUsers from '../Containers/Users/InviteExistingUsers';
import CreateInvitedUser from '../Containers/Users/CreateInvitedUser';
import SignIn from '../Containers/Auth/SignIn';
import SignUp from '../Containers/Auth/SignUp';
import NewPassword from '../Containers/Auth/NewPassword';
import EditPassword from '../Containers/Auth/EditPassword';
import ResetPasswordConfirmation from '../Containers/Auth/ResetPasswordConfirmation';
import NoMatch from '../Containers/NoMatch';
import DashboardIndex from '../Containers/Dashboard/Index';
import CouponIndex from '../Containers/Coupons/Index';
import CouponNew from '../Containers/Coupons/New';
import CouponEdit from '../Containers/Coupons/Edit';
import EventIndex from '../Containers/Events/Index';
import EventDetail from '../Containers/Events/Detail';
import EventNew from '../Containers/Events/New';
import EventEdit from '../Containers/Events/Edit';
import RegistrationOptionsNew from '../Containers/Events/RegistrationOptionsNew';
import RegistrationOptionsIndex from '../Containers/Events/RegistrationOptionsIndex';
import RegistrationOptionsEdit from '../Containers/Events/RegistrationOptionsEdit';
import Settings from '../Containers/Events/Settings';
import UpdateEventCompetitions from '../Containers/Events/UpdateEventCompetitions';
import EventManage from '../Containers/Events/EventManage';
import SchoolVerification from '../Containers/Schools/SchoolVerification';
import BrowseEvents from '../Containers/Events/BrowseEvents';
import VolunteerEvent from '../Containers/Events/VolunteerEvent';
import JoinEvent from '../Containers/Events/JoinEvent';
import JoinEventTermsAndConditions from '../Containers/Events/JoinEventTermsAndConditions';
import EventTermsAndConditions from '../Containers/Events/EventTermsAndConditions';
import JoinEventCompetitions from '../Containers/Events/JoinEventCompetitions';
import JoinEventCart from '../Containers/Events/JoinEventCart';
import JoinEventBilling from '../Containers/Events/JoinEventBilling';
import PaymentConfirmation from '../Containers/Events/PaymentConfirmation';
import PaymentStatus from '../Containers/Events/PaymentStatus';
import CoalitionIndex from '../Containers/Coalitions/Index';
import CoalitionNew from '../Containers/Coalitions/New';
import CoalitionEdit from '../Containers/Coalitions/Edit';
import UserEdit from '../Containers/Users/Edit';
import UserProfile from '../Containers/Users/Profile';
import EditProfile from '../Containers/Users/EditProfile';
import ViewMember from '../Containers/Events/ViewMember';
import EditViewMembers from '../Containers/Events/EditViewMembers';
import UpdatePassword from '../Containers/Users/UpdatePassword';
import Unverified from '../Containers/Users/Unverified';
import ViewCoach from '../Containers/Events/ViewCoaches';
import CaptainRequest from '../Containers/Events/CaptainRequest';
import CaptainRequestDetail from '../Containers/Events/CaptainRequestDetail';
import TeamMemberRequest from '../Containers/Events/TeamMemberRequest';
import TeamMemberRequestDetail from '../Containers/Events/TeamMemberRequestDetail';
import UnapproveSchoolsByEvent from '../Containers/Events/UnapproveSchoolsByEvent';
import ActiveSchoolsByEvent from '../Containers/Events/ActiveSchoolsByEvent';
import CompetitionRoster from '../Containers/Events/CompetitionRoster';
import EditCompetitionRoster from '../Containers/Events/EditCompetitionRoster';
import MyCompetitions from '../Containers/Events/ManageMyCompetitions';
import CompetitionSubmissions from '../Containers/Events/CompetitionSubmissions';
import EditMyCompetitions from '../Containers/Events/EditMyCompetitions';
import TeamApproval from '../Containers/Events/TeamApproval';
import EventUserConversation from '../Containers/Events/EventUserConversation';
import TeamMemberConversation from '../Containers/Events/TeamMemberConversation';
import ReassignCaptain from '../Containers/Events/ReassignCaptain';
import InviteExistingUser from '../Containers/Events/InviteExistingUser';
import InviteNewUser from '../Containers/Events/InviteNewUser';
import ViewTermAndConditions from '../Containers/Events/ViewTermsAndConditions';
import Teams from '../Containers/Events/Teams';
import Guests from '../Containers/Events/Guests';
import Help from '../Containers/Help/Index';
import Coaches from '../Containers/Events/Coaches';
import Students from '../Containers/Events/Students';
import Admins from '../Containers/Events/Admins';
import Judging from '../Containers/Events/Judging';
import ParentGuardianApproval from '../Containers/Events/ParentGuardianApproval';
import ThankYou from '../Containers/Events/Thankyou';
import ReviewInvite from '../Containers/Events/ReviewInvite';
import UnpaidUsers from '../Containers/Events/UnpaidUsers';
import SchoolTeam from '../Containers/Events/SchoolTeam';
import TeamStudents from '../Containers/Events/TeamStudents';
import TeamCoaches from '../Containers/Events/TeamCoaches';
import SchoolTeamEdit from '../Containers/Events/SchoolTeamEdit';
import Judges from '../Containers/Events/Judges';
import Volunteers from '../Containers/Events/Volunteers';
import ReassignCaptainByAdmin from '../Containers/Events/ReassignCaptainByAdmin';
import PendingTermAndConditionUsers from '../Containers/Events/PendingTermAndConditionUsers';
import EventUserDetail from '../Containers/Events/EventUserDetail';
import PaymentsReport from '../Containers/Reports/PaymentReport';
import PaymentDetails from '../Containers/Reports/PaymentDetailReport';
import CoachAnotherTeam from '../Containers/Events/CoachAnotherTeam';
import EventUserProfile from '../Containers/Events/EventUserProfile';
import OverallScores from '../Containers/Scores/OverallScores';
import ParticipantDetails from '../Containers/Scores/ParticipantDetails';
import NewSchoolVerification from '../Containers/Schools/NewSchoolVerification';
import CompetitionScores from '../Containers/Scores/CompetitionScores';
import ScoreProgress from '../Containers/Scores/ScoreProgress';
import CompleteCompetition from '../Containers/Scores/CompleteCompetition';
import InCompleteCompetition from '../Containers/Scores/InCompleteCompetition';
import EditCompetitionScores from '../Containers/Scores/EditCompetitionScores';
import SpecialNeedReports from '../Containers/Reports/SpecialNeedReports';
import CompetitionReport from '../Containers/Reports/CompetitionReport';
import CompetitionDetailReport from '../Containers/Reports/CompetitionDetailReport';
import CouponsByEvent from '../Containers/Reports/CouponsReport';
import CouponsDetails from '../Containers/Reports/CouponsDetails';
import RegistrationReport from '../Containers/Reports/RegistrationReport';
import BallotIndex from '../Containers/Ballots/Index';
import BallotNew from '../Containers/Ballots/New';
import BallotEdit from '../Containers/Ballots/Edit';
import ApproveRequest from '../Containers/Events/ApproveRequest';
import ApproveRequestDetail from '../Containers/Events/ApproveRequestDetail';
import RemoveUsers from '../Containers/Events/RemoveUsers';
import EventLocations from '../Containers/Events/EventLocations/EventLocationsPage';
import EventSchedule from '../Containers/Events/EventSchedules/EventSchedulePage';
import EventAnnouncements from '../Containers/Events/EventAnnouncements/EventAnnouncementsPage';
import LiveEventPage from '../Containers/Events/LiveEvent/LiveEventPage';
import EventResources from '../Containers/Events/EventResources/EventResourcesPage';
import JoinEventMerch from '../Containers/Events/JoinEventMerch';
import JoinEventPersonalDetails from '../Containers/Events/JoinEventPersonalDetails';
import SpiritPointsReport from '../Containers/Reports/SpiritPointsReport';
import AttendanceReport from '../Containers/Reports/AttendanceReport';

const Routes = () => (
  <Switch>
    <Route exact path="/login">
      <SignIn />
    </Route>
    <Route exact path="/sign_up">
      <SignUp />
    </Route>
    <Route exact path="/password/reset">
      <NewPassword />
    </Route>
    <Route path="/users/password/edit">
      <EditPassword />
    </Route>
    <Route exact path="/reset_password_confirmation">
      <ResetPasswordConfirmation />
    </Route>
    <Route exact path="/regions">
      <RegionIndex />
    </Route>
    <Route exact path="/regions/new">
      <RegionNew />
    </Route>
    <Route exact path="/region/:id/admins">
      <AddAdmin />
    </Route>
    <Route exact path="/roles/new">
      <RoleNew />
    </Route>
    <Route exact path="/roles">
      <RoleIndex />
    </Route>
    <Route exact path="/role-permissions/:id">
      <RolePermissionIndex />
    </Route>
    <Route exact path="/roles/:id/edit">
      <RoleEdit />
    </Route>
    <Route exact path="/regions/:id/edit">
      <RegionEdit />
    </Route>
    <Route exact path="/schools">
      <SchoolIndex />
    </Route>
    <Route exact path="/dashboard">
      <DashboardIndex />
    </Route>
    <Route exact path="/coupons">
      <CouponIndex />
    </Route>
    <Route exact path="/coupons/new">
      <CouponNew />
    </Route>
    <Route exact path="/schools/new">
      <SchoolNew />
    </Route>
    <Route exact path="/schools/:id/edit">
      <SchoolEdit />
    </Route>
    <Route exact path="/events/:eventId/schools/:id/edit">
      <SchoolEdit />
    </Route>
    <Route exact path="/schools/:id/students">
      <SchoolStudentIndex />
    </Route>
    <Route exact path="/school-verification">
      <SchoolVerification />
    </Route>
    <Route exact path="/categories">
      <CategoryIndex />
    </Route>
    <Route exact path="/competitions/:id">
      <CompetitionIndex />
    </Route>
    <Route path="/competitions/:id/new">
      <CompetitionNew />
    </Route>
    <Route path="/competitions/:id/edit">
      <CompetitionEdit />
    </Route>
    <Route exact path="/categories/new">
      <CategoryNew />
    </Route>
    <Route exact path="/categories/:id/edit">
      <CategoryEdit />
    </Route>
    <Route exact path="/users">
      <UserIndex />
    </Route>
    <Route exact path="/users/:id/view">
      <UserView />
    </Route>
    <Route exact path="/invite-user">
      <InviteUser />
    </Route>
    <Route exact path="/invite-existing-user">
      <InviteExistingUsers />
    </Route>
    <Route exact path="/create-user/:token">
      <CreateInvitedUser />
    </Route>
    <Route exact path="/coupons/:id/edit">
      <CouponEdit />
    </Route>
    <Route exact path="/events">
      <EventIndex />
    </Route>
    <Route exact path="/event-detail">
      <EventDetail />
    </Route>
    <Route exact path="/event-detail/:id">
      <EventDetail />
    </Route>
    <Route exact path="/event-manage/:id">
      <EventManage />
    </Route>
    <Route exact path="/events/new">
      <EventNew />
    </Route>
    <Route exact path="/events/:id/edit">
      <EventEdit />
    </Route>
    <Route exact path="/events/:id/settings">
      <Settings />
    </Route>
    <Route exact path="/events/:id/registration-options/new">
      <RegistrationOptionsNew />
    </Route>
    <Route exact path="/events/:id/registration-options">
      <RegistrationOptionsIndex />
    </Route>
    <Route exact path="/events/:id/registration-options">
      <RegistrationOptionsIndex />
    </Route>
    <Route exact path="/registration-options/:id/edit">
      <RegistrationOptionsEdit />
    </Route>
    <Route exact path="/events/:id/event-competitions">
      <UpdateEventCompetitions />
    </Route>
    <Route exact path="/events/:id/event-locations">
      <EventLocations />
    </Route>
    <Route exact path="/events/:id/event-schedule">
      <EventSchedule />
    </Route>
    <Route exact path="/events/:id/event-announcements">
      <EventAnnouncements />
    </Route>
    <Route exact path="/events/:id/event-resources">
      <EventResources />
    </Route>
    <Route exact path="/">
      <BrowseEvents />
    </Route>
    <Route exact path="/volunteer-event/:id">
      <VolunteerEvent />
    </Route>
    <Route exact path="/join-event/:id">
      <JoinEvent />
    </Route>
    <Route exact path="/order/:orderId/terms-and-conditions">
      <JoinEventTermsAndConditions />
    </Route>
    <Route exact path="/order/:orderId/confirm-personal-details">
      <JoinEventPersonalDetails />
    </Route>
    <Route exact path="/order/:orderId/pending-terms-and-conditions">
      <EventTermsAndConditions />
    </Route>
    <Route exact path="/order/:orderId/competitions">
      <JoinEventCompetitions />
    </Route>
    <Route exact path="/order/:orderId/merch">
      <JoinEventMerch />
    </Route>
    <Route exact path="/order/:orderId/cart">
      <JoinEventCart />
    </Route>
    <Route exact path="/order/:orderId/billing">
      <JoinEventBilling />
    </Route>
    <Route exact path="/payment-confirmation/:id">
      <PaymentConfirmation />
    </Route>
    <Route exact path="/payment-status/:id">
      <PaymentStatus />
    </Route>
    <Route exact path="/payment-status">
      <PaymentStatus />
    </Route>
    <Route exact path="/event-manage/:id/coalitions">
      <CoalitionIndex />
    </Route>
    <Route exact path="/event-manage/:id/coalitions/new">
      <CoalitionNew />
    </Route>
    <Route exact path="/event-manage/:id/coalitions/:coalitionId/edit">
      <CoalitionEdit />
    </Route>
    <Route exact path="/users/:id/edit">
      <UserEdit />
    </Route>
    <Route exact path="/me">
      <UserProfile />
    </Route>
    <Route exact path="/events/:id/team/:schoolId">
      <ViewMember />
    </Route>
    <Route exact path="/events/:id/team/:schoolId/edit">
      <EditViewMembers />
    </Route>
    <Route exact path="/events/:id/competition-roster/:schoolId">
      <CompetitionRoster />
    </Route>
    <Route exact path="/events/:id/competition-roster/:schoolId/edit">
      <EditCompetitionRoster />
    </Route>
    <Route exact path="/events/:id/my-competitions">
      <MyCompetitions />
    </Route>
    <Route exact path="/events/:id/competition-submissions">
      <CompetitionSubmissions />
    </Route>
    <Route exact path="/events/:id/my-competitions/edit">
      <EditMyCompetitions />
    </Route>
    <Route exact path="/events/:id/coach/:schoolId">
      <ViewCoach />
    </Route>
    <Route exact path="/me/edit-profile">
      <EditProfile />
    </Route>
    <Route exact path="/me/update-password">
      <UpdatePassword />
    </Route>
    <Route exact path="/users/unverified">
      <Unverified />
    </Route>
    <Route exact path="/event-manage/:eventId/team-captains-by-school/:id">
      <CaptainRequest />
    </Route>
    <Route exact path="/event-manage/:eventId/team-captains/:id">
      <CaptainRequestDetail />
    </Route>
    <Route exact path="/event-manage/:id/team-member-requests/:schoolId/requests">
      <TeamMemberRequest />
    </Route>
    <Route exact path="/event-manage/:eventId/team-member-requests/:schoolId/requests/:id">
      <TeamMemberRequestDetail />
    </Route>
    <Route exact path="/event-manage/:id/unapprove-schools">
      <UnapproveSchoolsByEvent />
    </Route>
    <Route exact path="/event-manage/:id/unapprove-teams">
      <ActiveSchoolsByEvent />
    </Route>
    <Route exact path="/event-manage/:eventId/team-approval/:id">
      <TeamApproval />
    </Route>
    <Route exact path="/event-manage/:eventId/team-conversations/:id">
      <EventUserConversation />
    </Route>
    <Route exact path="/event-manage/:eventId/team-member-requests/:schoolId/requests/team-member-conversations/:id">
      <TeamMemberConversation />
    </Route>
    <Route exact path="/reassign-captain/:id">
      <ReassignCaptain />
    </Route>
    <Route exact path="/event-manage/:eventId/invite-existing-user/:userType">
      <InviteExistingUser />
    </Route>
    <Route exact path="/event-manage/:eventId/invite-new-user/:userType">
      <InviteNewUser />
    </Route>
    <Route exact path="/events/:id/terms-and-conditions">
      <ViewTermAndConditions />
    </Route>
    <Route exact path="/event/:id/teams">
      <Teams />
    </Route>
    <Route exact path="/event/:id/guests">
      <Guests />
    </Route>
    <Route exact path="/help">
      <Help />
    </Route>
    <Route exact path="/event/:id/coaches">
      <Coaches />
    </Route>
    <Route exact path="/event/:id/students">
      <Students />
    </Route>
    <Route exact path="/events/:id/admins">
      <Admins />
    </Route>
    <Route exact path="/events/:id/judging/:eventCompetitionId/rounds/:roundId">
      <Judging />
    </Route>
    <Route exact path="/events/:id/judging/:eventCompetitionId/rounds/:roundId/competitors/:judgableId">
      <Judging />
    </Route>
    <Route exact path="/event-user/:id/parent-guardian-approval">
      <ParentGuardianApproval />
    </Route>
    <Route exact path="/thank-you">
      <ThankYou />
    </Route>
    <Route exact path="/event-manage/:eventId/review-invites">
      <ReviewInvite />
    </Route>
    <Route exact path="/event-manage/:eventId/unpaid-users">
      <UnpaidUsers />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId">
      <SchoolTeam />
    </Route>
    <Route exact path="/event/:eventId/team-students/:schoolId">
      <TeamStudents />
    </Route>
    <Route exact path="/event/:eventId/team-coaches/:schoolId">
      <TeamCoaches />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId/edit">
      <SchoolTeamEdit />
    </Route>
    <Route exact path="/event/:id/judges">
      <Judges />
    </Route>
    <Route exact path="/event/:id/volunteers">
      <Volunteers />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId/reassign-captain">
      <ReassignCaptainByAdmin />
    </Route>
    <Route exact path="/event-manage/:eventId/pending-term-condition-users">
      <PendingTermAndConditionUsers />
    </Route>
    <Route exact path="/event-manage/:eventId/event-user-detail/:userId">
      <EventUserDetail />
    </Route>
    <Route exact path="/events/:id/payment-report">
      <PaymentsReport />
    </Route>
    <Route exact path="/payments/:id/option/:optionId">
      <PaymentDetails />
    </Route>
    <Route exact path="/event-manage/:eventId/coach-another-team">
      <CoachAnotherTeam />
    </Route>
    <Route exact path="/event-manage/:eventId/event-user-profile/:userId">
      <EventUserProfile />
    </Route>
    <Route exact path="/events/:id/overall-scores">
      <OverallScores />
    </Route>
    <Route exact path="/event/:id/participant/:userId">
      <ParticipantDetails />
    </Route>
    <Route exact path="/new-school-verification">
      <NewSchoolVerification />
    </Route>
    <Route exact path="/events/:id/scoring/:eventCompetitionId">
      <CompetitionScores />
    </Route>
    <Route exact path="/events/:id/scoring/:eventCompetitionId/rounds/:roundId/edit">
      <EditCompetitionScores />
    </Route>
    <Route exact path="/events/:id/score-progress">
      <ScoreProgress />
    </Route>
    <Route exact path="/events/:id/complete-competition">
      <CompleteCompetition />
    </Route>
    <Route exact path="/events/:id/incomplete-competition">
      <InCompleteCompetition />
    </Route>
    <Route exact path="/events/:id/special-need-reports">
      <SpecialNeedReports />
    </Route>
    <Route exact path="/events/:id/competitions-report">
      <CompetitionReport />
    </Route>
    <Route exact path="/events/:id/competitions/:competitionId/">
      <CompetitionDetailReport />
    </Route>
    <Route exact path="/events/:id/coupons">
      <CouponsByEvent />
    </Route>
    <Route exact path="/events/:id/coupon/:couponId">
      <CouponsDetails />
    </Route>
    <Route exact path="/events/:id/registration-report">
      <RegistrationReport />
    </Route>
    <Route exact path="/events/:id/spirit-points-report">
      <SpiritPointsReport />
    </Route>
    <Route exact path="/events/:id/attendance-report">
      <AttendanceReport />
    </Route>
    <Route exact path="/ballots">
      <BallotIndex />
    </Route>
    <Route exact path="/ballots/new">
      <BallotNew />
    </Route>
    <Route exact path="/ballots/:id/edit">
      <BallotEdit />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId/approve-requests">
      <ApproveRequest />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId/approve-requests/:id">
      <ApproveRequestDetail />
    </Route>
    <Route exact path="/event/:eventId/teams/:schoolId/remove-users">
      <RemoveUsers />
    </Route>
    <Route exact path="/events/:id/live">
      <LiveEventPage />
    </Route>
    <Route path="*">
      <NoMatch />
    </Route>
  </Switch>
);

export default Routes;
