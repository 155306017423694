import ApolloClient from 'apollo-client';
import Cookies from 'universal-cookie';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { USER_TOKEN_KEY } from '../Constants/AppConstants';

if (!process.env.REACT_APP_API_URL) {
  throw new Error("process.env.REACT_APP_API_URL not found! please do 'export REACT_APP_API_URL=\"https://api2-staging.getmistified.com\"' in your terminal for testing" );;
}
const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const addAuthHeader = (operation) => {
  const cookies = new Cookies();
  const token = cookies.get(USER_TOKEN_KEY);
  operation.setContext({
    headers: {
      token,
    },
  });
};

// courtesy of https://www.apollographql.com/docs/react/v2/migrating/boost-migration/
// and https://www.apollographql.com/docs/react/v2/networking/authentication/
const requestLink = new ApolloLink((operation, forward) => {
  addAuthHeader(operation);
  return forward(operation);
});

const Client = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Judgable: {
        keyFields: false,
      },
    },
  }),
  resolvers: {},
});

export default Client;
