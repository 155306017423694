import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash/fp';
import { useLazyQuery } from '@apollo/react-hooks';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import BackLink from '../../Components/BackLink';
import {
  FETCH_ORDERS,
  FETCH_ORDERS_BY_EVENT,
  FETCH_EVENT_HEADER,
} from '../../GraphQL/Queries';
import OrderDetail from './OrderDetail';
import EXCLAMATION from '../../Images/icons/exclamation-mark.png';
import { RedirectButton } from '../../Components/Form';
import {
  WrapperButton,
} from '../../Components/Auth/Layout';


const PaymentStatus = () => {
  const { id } = useParams();
  const {
    currentUser,
  } = useCurrentUser(true);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [fetchOrdersByEvent] = useLazyQuery(
    FETCH_ORDERS_BY_EVENT, {
      onCompleted: (data) => {
        setPendingOrders(data.fetchOrdersByEvent.filter(({ status }) => status === 'pending'));
        setCompleteOrders(data.fetchOrdersByEvent.filter(({ status }) => status === 'complete'));
      },
    },
  );

  const [fetchOrders] = useLazyQuery(
    FETCH_ORDERS, {
      onCompleted: (data) => {
        setPendingOrders(data.fetchOrders.filter(({ status }) => status === 'pending'));
        setCompleteOrders(data.fetchOrders.filter(({ status }) => status === 'complete'));
      },
    },
  );

  const [fetchEvent, { data: eventData }] = useLazyQuery(FETCH_EVENT_HEADER);
  const event = eventData?.fetchEventDetail;


  useEffect(() => {
    if (currentUser && !isEmpty(id)) {
      fetchOrdersByEvent({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
        },
      });
      fetchEvent({
        variables: {
          id: parseInt(id, 10),
        },
      });
    } else if (currentUser) {
      fetchOrders({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchOrders, fetchOrdersByEvent, fetchEvent, id]);

  useHeaderComponentsMutation({
    title: 'PAYMENT STATUS',
  });

  const selectOrder = (order) => {
    setSelectedOrder(order);
  };

  const getColor = (orderIndex) => {
    if (orderIndex % 2 === 0) return '#F7F7F7';
    return '#FFF';
  };

  const orderId = get('id', selectedOrder);
  const paymentLink = event?.merchandise.length > 0 ? `/order/${orderId}/merch` : `/order/${orderId}/cart`;

  return (
    <>
      {pendingOrders && pendingOrders.length > 0
        ? (
          <>
            <div className="pt-3" />
            <span>Pending </span>
            <img src={EXCLAMATION} alt="ex" />
            {pendingOrders.map((order, Index) => (
              <WrapperButton onClick={() => selectOrder(order)}>
                <OrderDetail
                  key={order.id}
                  index={Index}
                  order={order}
                  backgroundColor={get('id', selectedOrder) === order.id
                    ? '#3E6796'
                    : (getColor(Index)
                    )}
                  color={get('id', selectedOrder) === order.id
                    ? '#FFF'
                    : '#000'}
                  currentUser={currentUser}
                />
              </WrapperButton>
            ))}
            {selectedOrder
              ? (
                <RedirectButton
                  backgroundcolor="#F4AB37"
                  fontColor="#FFF"
                  border="none"
                  addNewText="MAKE PAYMENT"
                  addNewLink={paymentLink}
                />
              )
              : null}
          </>
        )
        : null}
      {completeOrders && completeOrders.length > 0
        ? (
          <>
            <div className="pt-3" />
            <span>Payment History </span>
            {completeOrders.map((order, Index) => (
              // <WrapperButton>
              <OrderDetail
                key={order.id}
                index={Index}
                order={order}
                backgroundColor={Index % 2 === 0 ? '#F7F7F7' : '#FFF'}
                currentUser={currentUser}
              />
              // </WrapperButton>
            ))}
          </>
        )
        : null}
      {id
        ? (
          <BackLink to={`/event-manage/${id}`}>
          Manage Event
          </BackLink>
        )
        : (
          <BackLink to="/dashboard">
          Dashboard
          </BackLink>
        )}
    </>
  );
};
export default PaymentStatus;
