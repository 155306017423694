import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';

import { EventCompetitionPropType, LocationPropType, SchedulePropType } from './propTypes';
import { SCHEDULE_EVENT_TYPES } from './utils';

const FORM_DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_TZ = format(new Date(), 'z');

export const ScheduleEventForm = ({
  schedule,
  setSchedule,
  eventCompetitions,
  locations,
}) => {
  const setParam = (name, value) => {
    setSchedule({
      ...schedule,
      [name]: value,
    });
  };
  const setType = (value) => {
    setSchedule({
      ...schedule,
      type: value,
      associatedEventCompetitionId: undefined,
    });
  };

  const startTime = schedule.startTime ? utcToZonedTime(schedule.startTime, USER_LONG_TZ) : '';
  const endTime = schedule.endTime ? utcToZonedTime(schedule.endTime, USER_LONG_TZ) : '';
  const setStartTime = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_LONG_TZ);
    setParam('startTime', utcDate.toISOString());
  };
  const setEndTime = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_LONG_TZ);
    setParam('endTime', utcDate.toISOString());
  };


  return (
    <VStack width="full">
      <FormControl isRequired>
        <FormLabel htmlFor="title">Title</FormLabel>
        <Input backgroundColor="white" id="title" value={schedule.title} onChange={(e) => setParam('title', e.target.value)} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="startTime">
          {`Start Time (in ${USER_TZ})`}
        </FormLabel>
        <DatePicker
          selected={startTime}
          onChange={(date) => setStartTime(date)}
          customInput={<Input backgroundColor="white" id="startTime" />}
          dateFormat={FORM_DISPLAY_DATE_FORMAT}
          placeholderText="Enter Start Time"
          timeIntervals={15}
          showTimeSelect
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="endTime">
          {`End Time (in ${USER_TZ})`}
        </FormLabel>
        <DatePicker
          selected={endTime}
          onChange={(date) => setEndTime(date)}
          customInput={<Input backgroundColor="white" id="endTime" />}
          dateFormat={FORM_DISPLAY_DATE_FORMAT}
          placeholderText="Enter End Time"
          timeIntervals={15}
          showTimeSelect
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="location">Location</FormLabel>
        <Select backgroundColor="white" id="location" placeholder="Select a location" value={schedule.locationId} onChange={(e) => setParam('locationId', e.target.value)}>
          {locations.map((location) => (
            <option key={location.id} value={parseInt(location.id, 10)}>{location.name}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="room">Room</FormLabel>
        <Input backgroundColor="white" id="room" value={schedule.room} onChange={(e) => setParam('room', e.target.value)} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel htmlFor="type">Type</FormLabel>
        <Select backgroundColor="white" id="type" placeholder="Select event type" value={schedule.type} onChange={(e) => setType(e.target.value)}>
          {SCHEDULE_EVENT_TYPES.map((t) => (
            <option key={t} value={t.toLowerCase()}>{t}</option>
          ))}
        </Select>
      </FormControl>
      {schedule.type === 'competition' && (
        <FormControl isRequired>
          <FormLabel htmlFor="competition">Competition</FormLabel>
          <Select backgroundColor="white" id="competition" placeholder="Select a competition" value={schedule.associatedEventCompetitionId} onChange={(e) => setParam('associatedEventCompetitionId', e.target.value)}>
            {eventCompetitions.map((comp) => (
              <option key={comp.id} value={parseInt(comp.id, 10)}>{comp.title}</option>
            ))}
          </Select>
        </FormControl>
      )}
    </VStack>
  );
};
ScheduleEventForm.propTypes = {
  schedule: SchedulePropType.isRequired,
  setSchedule: PropTypes.func.isRequired,
  eventCompetitions: PropTypes.arrayOf(EventCompetitionPropType).isRequired,
  locations: PropTypes.arrayOf(LocationPropType).isRequired,
};
