import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  get, getOr, last, split,
} from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import edit from '../../Images/icons/edit.png';
import { ButtonLink, Input } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import ImageUpload from '../ImageUpload/Index';
import {
  UPLOAD_PROFILE_IMAGE, UPDATE_SCHOOL_VERIFICATION, REMOVE_SCHOOL_VERIFICATION, REMOVE_SCHOOL_USER,
  UPDATE_DEFAULT_SCHOOL,
} from '../../GraphQL/Mutations';
import {
  ProfileImgCircular, TextAlignCenter, UploadBtn, ImgLink,
} from '../../Components/Auth/Layout';
import uploadImage from '../../Images/dashboard/upload.png';
import SINK_ICON from '../../Images/sink.png';
import REMOVE_ICON from '../../Images/cross.png';
import useUpdateUserDetailCache from '../../Hooks/useUpdateUserDetailCache';
import userGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import Permissions from '../../Constants/PermissionConstrants';
import { FETCH_USER_DETAIL } from '../../GraphQL/Queries';
import useUpdateCoachSchoolCache from '../../Hooks/useUpdateCoachSchoolCache';
import { raceChoices } from '../../Constants/RaceConstants';

const NewUserSignUpButton = styled(Button)`
  width: 100%;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  font-size: 14px !important;
  box-shadow: none !important;
  min-height: 45px !important;
  text-shadow: none !important;
  font-weight: bold !important;
  font-family: 'nunito_bold' !important;
  border: 2px solid #f3AA44 !important;
  color: rgba(244, 171, 55, 1) !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 10px !important;
`;
const DividerLine = styled.div`
  line-height: 0.5;
  text-align: center;
  overflow: hidden;
  border-bottom: 2px solid #C6C5C5;
  span {
    display: inline-block;
    position: relative;
    border: 1px solid rgba(151, 151, 151, .9);
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    font-family: 'nunito_regular';
    ::before {
    right: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
  }
  ::after {
    left: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
    }
  }
`;
const MistID = styled.div`
  text-align:center;
  color:black;
  font-size:15px;
  padding-top:12px;
  font-weight:bold;
  font-family:Arial;
`;
const IdContainer = styled.div`
  text-align:center;
  color:black;
  font-size:15px;
  padding-top:12px;
  font-family:Arial;
  padding-bottom:24px;
`;
const Text = styled.label`
  color:#C6C5C5;
  font-size:15px;
  font-weight:bold;
  display:inline;
  width: 100%;
`;
const InputField = styled.input`
  border:none;
  display:block;
  width: 100%;
`;
const TextField = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:15px;
  margin-bottom:20px;
  margin-left:30px;
  width: 100%;
`;
const EmailField = styled.input`
  width=100%;
  background-color:#E7EBEE;
  border:none;
  border-radius:9px;
`;
const EditLink = styled.button`
border:white;
background-color:white;
font-size:17px;
font-weight:bold;
color: rgba(244, 171, 55, 1);
`;

const UserProfile = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const updateSchoolsCache = useUpdateCoachSchoolCache(get('fetchEventsUsers', currentUser), get('id', currentUser));
  const { params, onChange } = useFormParams({
    profileImageUrl: '',
    schoolId: currentUser.fetchSchoolsUsers.length > 1
      ? last(currentUser.fetchSchoolsUsers.filter((schoolsUser) => schoolsUser.isDefault)).schoolId
      : '',
  });
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateUserDetailCache(get('id', currentUser));
  useHeaderComponentsMutation({
    backLink: '/',
    title: 'MY PROFILE',
  });
  const extractError = userGraphQLErrorExtractor();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [currentSchoolId, setCurrentSchoolId] = useState('');
  const [deletedSchoolUserId, setDeletedSchoolUserId] = useState('');
  const [uploadProfileImage] = useMutation(
    UPLOAD_PROFILE_IMAGE,
    {
      onCompleted: () => {
        history.push('/me');
      },
    },
  );
  const onImageUpload = (d) => {
    params.profileImage = d.signed_id;
    uploadProfileImage({
      variables: { ...params },
    });
  };

  const { loading } = useQuery(
    FETCH_USER_DETAIL,
    {
      variables: { id: parseInt(currentUser.id, 10) },
    },
  );

  const [schoolUserAttachment] = useMutation(
    UPDATE_SCHOOL_VERIFICATION,
    {
      onCompleted: (data1) => {
        updateCache(data1.schoolUserAttachment.schoolsUsers);
      },
    },
  );
  const onFileUpload = (d) => {
    params.verificationFile = d.signed_id;
    schoolUserAttachment({
      variables: {
        ...params,
        schoolId: parseInt(currentSchoolId, 10),
        userId: parseInt(currentUser.id, 10),
      },
    });
  };

  const onImagePreview = (url) => {
    setModal(true);
    setImagePreviewUrl(url);
  };

  const selectCurrentSchoolId = (id) => {
    setCurrentSchoolId(id);
  };

  const [deleteSchoolUserAttachment] = useMutation(
    REMOVE_SCHOOL_VERIFICATION,
    {
      onCompleted: (data) => {
        updateCache(data.deleteSchoolUserAttachment.schoolsUsers, 'remove');
      },
    },
  );

  const removeSchoolVerification = (fileId) => {
    deleteSchoolUserAttachment({
      variables: {
        id: parseInt(fileId, 10),
      },
    });
  };

  const getFileName = (url) => last(split('/', url));

  const toggle = () => setModal(!modal);

  const [deleteSchoolUser] = useMutation(
    REMOVE_SCHOOL_USER, {
      onCompleted: () => {
        setTimeout((() => {
          updateSchoolsCache(deletedSchoolUserId, 'remove');
        }).bind(), 500);
      },
    },
  );

  const removeSchoolsUser = (schoolUserId) => {
    setDeletedSchoolUserId(schoolUserId);
    deleteSchoolUser({
      variables: {
        id: parseInt(schoolUserId, 10),
      },
      refetchQueries: ['fetchUserDetail', 'fetchSchoolsByCoach'],
    });
  };

  const [updateDefaultSchool] = useMutation(
    UPDATE_DEFAULT_SCHOOL,
  );

  useEffect(() => {
    if (currentUser && currentUser.fetchSchoolsUsers.length > 1 && !params.schoolId) {
      updateDefaultSchool({
        variables: {
          ...params,
          schoolId: parseInt(currentUser.fetchSchoolsUsers[0].schoolId, 10),
        },
        refetchQueries: ['fetchUserDetail'],
      });
    }
  }, [currentUser, params, params.schoolId, updateDefaultSchool]);


  if (loading || userLoading) {
    return <Loader />;
  }

  if (userError) {
    return (
      <GenericAlert>
        {extractError(userError)}
      </GenericAlert>
    );
  }

  return (
    <>
      { currentUser.dateOfBirth
        ? (
          <>
            <MistID>
              MIST ID:
              {' '}
              {get('code', currentUser)}
            </MistID>
          </>
        )
        : <IdContainer>Please complete your profile to view your MIST ID</IdContainer> }
      <div className="container-fluid">
        <div className="row">
          <div className="text-right col-md-2 offset-md-3 col-6 ">
            <ProfileImgCircular>
              <ImageUpload
                onImageUpload={onImageUpload}
                image={getOr(uploadImage, 'profileImageUrl', currentUser)}
                type="image"
                visibleText="Upload Picture"
              />
            </ProfileImgCircular>
          </div>
          <div className="col-md-5 offset-md-2  col-6 text-left">
            <img src={get('qrCodeUrl', currentUser)} alt="QR-Code" />
            <p>your QR Code</p>
          </div>
        </div>
      </div>
      <DividerLine />
      <TextField>
        <div className="row">
          <div className="col-md-10 col-7 ">
            <Text> First Name </Text>
            <InputField type="text" name="firstName" id="firstName" value={currentUser.firstname} readonly />
          </div>
          <div className="col-md-2 col-5 text-right">
            <Link to="/me/edit-profile">
              <img src={edit} alt="User-Icon" />
              <EditLink>edit profile</EditLink>
            </Link>
          </div>
        </div>
      </TextField>
      <TextField>
        <Text> Last Name </Text>
        <InputField type="text" name="lastName" id="lastname" value={currentUser.lastname} readonly />
      </TextField>
      <TextField>
        <Text> Date of Birth </Text>
        <InputField type="text" name="dateofbirth" id="dateofbirth" value={currentUser.dateOfBirth} readonly />
      </TextField>
      <TextField>
        <Text> Gender </Text>
        <InputField type="text" name="gender" id="gender" value={currentUser.gender} readonly />
      </TextField>
      <TextField>
        <Text> phone </Text>
        <InputField type="text" name="phone" id="phone" value={currentUser.phone} readonly />
      </TextField>
      <TextField>
        <Text> Email: </Text>
        <EmailField type="text" name="email" id="email" value={currentUser.email} readonly />
      </TextField>
      <TextField>
        <Text>Emergency Contact</Text>
        <InputField type="text" name="emergencContactName" id="emergencyContactName" value={currentUser.emergencyContactName} readonly />
        <InputField type="text" name="emergencContactNumber" id="emergencyContactNumber" value={currentUser.emergencyContactNumber} readonly />
      </TextField>
      {get('fetchRole.title', currentUser)
        ? (
          <TextField>
            <Text>Default Role </Text>
            <InputField type="text" name="default-role" id="default-role" value={currentUser.fetchRole.title} readonly />
          </TextField>
        )
        : null}
      {get('defaultRegion.name', currentUser)
        ? (
          <TextField>
            <Text>Default Region </Text>
            <InputField type="text" name="default-region" id="default-region" value={currentUser.defaultRegion.name} readonly />
          </TextField>
        )
        : null}

      <TextField>
        <Text>T-Shirt Size</Text>
        <InputField type="text" name="shirtSize" id="shirtSize" value={currentUser.shirtSize} readonly />
      </TextField>

      <TextField>
        <Text>Race/Ethnicity</Text>
        <InputField type="text" name="race" id="race" value={raceChoices[currentUser.race]} readonly />
      </TextField>

      <TextField>
        <Text>Special Accomodations </Text>
        <InputField type="text" name="accommodationNote" id="accommodationNote" value={currentUser.accommodationNote} readonly />
      </TextField>
      {checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
        ? (
          <>
            <DividerLine />
            <TextField>
              <div className="row">
                <div className="col-md-12 col-12">
                  <Text>
                    {' '}
                    Default School
                    <Input type="select" params={params} onChange={onChange} name="schoolId" id="schoolId">
                      { get('fetchSchoolsUsers', currentUser).map((obj) => (
                        <option value={obj.fetchSchool.id}>{obj.fetchSchool.name}</option>
                      ))}
                    </Input>
                  </Text>
                </div>
              </div>
            </TextField>
          </>
        ) : null}
      <DividerLine />
      {getOr([], 'fetchSchoolsUsers', currentUser).map((userSchool, index) => (
        <div key={userSchool.id}>
          <TextField>
            <div className="row">
              <div className="col-md-11 col-10">
                <Text>
                  {' '}
                    School
                  {index + 1}
                  <InputField type="text" name="defaultschool" id="defaultschool" defaultValue={userSchool.fetchSchool.name} readonly />
                </Text>
              </div>
              <div className="col-md-1 col-2">
                {!checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
                  ? (
                    <>
                      <div className="col-md-1 col-2 text-right">
                        <Link to="/school-verification">
                          <img src={edit} alt="User-Icon" />
                        </Link>
                      </div>
                    </>
                  )
                  : (
                    <>
                      {!userSchool.isDefault
                        ? (
                          <>
                            <Button className="ml-auto" color="link" onClick={() => removeSchoolsUser(userSchool.id)}>
                              <p>Remove</p>
                            </Button>
                          </>
                        )
                        : null}
                    </>
                  )}
              </div>
            </div>
          </TextField>
          <TextField>
            <Text> School Verification </Text>
            {userSchool.fetchUserAttachment.map((file) => (
              <div className="d-flex" key={file.id}>
                {file.verificationFileUrl
                  ? (
                    <ImgLink onClick={() => onImagePreview(file.verificationFileUrl)}>
                      <img className="img-fluid" src={SINK_ICON} alt="arrow" />
                      {'  '}
                      {getFileName(file.verificationFileUrl)}
                    </ImgLink>
                  )
                  : null}
                <Button className="ml-auto" color="link" onClick={() => removeSchoolVerification(file.id)}>
                  <img src={REMOVE_ICON} alt="arrow" className="img-fluid" />
                </Button>
              </div>
            ))}
            <UploadBtn onClick={() => selectCurrentSchoolId(get('schoolId', userSchool))}>
              <ImageUpload
                onImageUpload={onFileUpload}
                type="image"
                visibleText="Add more verification"
              />
            </UploadBtn>
          </TextField>
          <DividerLine />
        </div>
      ))}
      <TextField>
        <ButtonLink
          htmltype="submit"
          backgroundcolor="#F4AB37"
          fontColor="#FFF"
          border="none"
          addNewText="Add School"
          addNewLink="/new-school-verification"
          imageAttached={ADD_SMALL_ICON}
          checkPermission={
            checkPermission(Permissions.CAN_COACH_ANOTHER_TEAM)
          }
        />
      </TextField>
      <div className="py-5">
        <Link to="/me/update-password">
          <NewUserSignUpButton className="btn btn__transparent--secondary">RESET PASSPHRASE</NewUserSignUpButton>
        </Link>
      </div>
      <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
        <ModalHeader toggle={toggle}>File Preview </ModalHeader>
        <ModalBody>
          <TextAlignCenter>
            <img src={imagePreviewUrl} alt="arrow" className="img-fluid" />
          </TextAlignCenter>
        </ModalBody>
      </Modal>
    </>
  );
};
export default UserProfile;
