import React, { useState, useEffect } from 'react';
import {
  useParams,
  // useHistory,
} from 'react-router-dom';
import {
  useLazyQuery,
  // useMutation,
} from '@apollo/react-hooks';
import { isEmpty, getOr } from 'lodash/fp';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  MANAGE_MY_COMPETITIONS,
  FETCH_ORDERS_BY_EVENT,
  FETCH_EVENT_DETAIL,
} from '../../GraphQL/Queries';
// import {
//   MEMBER_SWAP,
// } from '../../GraphQL/Mutations';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter,
  // LinkButton,
} from '../../Components/Auth/Layout';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import BasicEventCompetitions from './BasicEventCompetitions';
// import EventCompetitions from './EventCompetitions';
// import EventCompetitionsSearch from './EventCompetitionsSearch';
// import useUpdateSwappedCompetitionsCache from '../../Hooks/useUpdateSwappedCompetitionsCache';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import BackLink from '../../Components/BackLink';

const EditMyCompetitions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  // const history = useHistory();
  const [swap/* , setSwap */] = useState(false);
  const [myCompetitions, setMyCompetitions] = useState([]);
  const [error/* , setError */] = useState(null);
  const [addedCompetition/* , setAddedCompetition */] = useState('');
  const extractError = useGraphQLErrorExtractor();
  const [manageMyCompetitions, { data }] = useLazyQuery(
    MANAGE_MY_COMPETITIONS, {
      onCompleted: () => {
        setMyCompetitions(data.manageMyCompetitions);
      },
    },
  );

  const [fetchOrdersByEvent, { data: dataOrder }] = useLazyQuery(
    FETCH_ORDERS_BY_EVENT, {
    },
  );

  const order = getOr({}, 'fetchOrdersByEvent[0]', dataOrder);

  const [fetchEventDetail, { data: dataEvent }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', dataEvent);

  // const updateCache = useUpdateSwappedCompetitionsCache(
  //   getOr(0, 'id', event), getOr(0, 'id', currentUser),
  // );


  useHeaderComponentsMutation({
    title: 'MANAGE EVENT',
    backLink: `/events/${id}/my-competitions`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (!isEmpty(currentUser.fetchSchools)) {
      manageMyCompetitions({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
        },
      });
      fetchOrdersByEvent({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, manageMyCompetitions, id, fetchOrdersByEvent]);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  // const appendEventCompetition = (member) => {
  //   setError(null);
  //   setAddedCompetition(member.id);
  // };

  // const setCompetitions = (competitions) => {
  //   setSwap(true);
  //   setMyCompetitions(competitions);
  // };

  // const [swapMember, { loading }] = useMutation(
  //   MEMBER_SWAP,
  //   {
  //     onCompleted: (dataMember) => {
  //       updateCache(dataMember.memberSwap.members);
  //       history.push(`/events/${id}/my-competitions`);
  //     },
  //   },
  // );

  // const memberSwap = () => {
  //   const idArray = myCompetitions.map((obj) => (obj.id));
  //   const priorityArray = myCompetitions.map((obj) => (obj.memberPriority));
  //   const idPrioityArray = [];
  //   for (let i = 0; i < idArray.length; i += 1) {
  //     const obj = {};
  //     obj.memberId = parseInt(idArray[i], 10);
  //     obj.memberPriority = priorityArray[i];
  //     idPrioityArray.push(obj);
  //   }
  //   if (swap === true) {
  //     swapMember({
  //       variables: {
  //         members: idPrioityArray,
  //       },
  //     });
  //   } else {
  //     history.push(`/events/${id}/my-competitions`);
  //   }
  // };

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <div className="d-none">{addedCompetition}</div>
      {myCompetitions.length === 0
        ? (
          null
        )
        : (
          <div className="container px-0 pt-3 ml-0">
            <div className="row">
              <div className="col-md-12 col-12 font-weight-bold">
                Your  Competitions
              </div>
            </div>
            {/* <p>
                Drag and Drop competitions in order of priority,
                from highest to lowest
            </p> */}
          </div>
        )}
      {/* <EventCompetitions
        eventCompetitions={myCompetitions}
        onArrange={setCompetitions}
      />
      <div className="pt-3" />
      <EventCompetitionsSearch
        currentUser={currentUser}
        order={order}
        onClick={appendEventCompetition}
        onError={setError}
      /> */}
      <BasicEventCompetitions
        order={order}
        addNewText="SAVE"
        addNewLink={`/events/${id}/my-competitions`}
        eventAllowsMultipleSports={event?.allowsMultipleSports}
      />
      <AlignCenter>
        {/* <LinkButton
          onClick={memberSwap}
          backgroundcolor="#F4AB37"
          fontcolor="#FFF"
          border="none"
          loading={loading}
        >
        SAVE
        </LinkButton> */}
        {swap === true
          ? (
            <BackLink to={`/events/${id}/my-competitions`}>
              Discard
            </BackLink>
          )
          : null}
      </AlignCenter>
    </>
  );
};

export default EditMyCompetitions;
