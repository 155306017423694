const useUpdateBottomMembersPriority = () => {
  const swapBottomPriority = (array, bottomElement, intitalIndex, finalIndex, sortAttribute) => {
    const swappedArray = array;
    if(sortAttribute === 'memberPriority'){
      swappedArray[intitalIndex][sortAttribute] = swappedArray[finalIndex][sortAttribute];
      for (let i = finalIndex; i > intitalIndex; i=i-1 ){
        swappedArray[i][sortAttribute] = swappedArray[i][sortAttribute] - 1;
      }
  }
  else{
    swappedArray[intitalIndex][sortAttribute] = finalIndex + 1;
      for (let i = finalIndex; i > intitalIndex; i -= 1) {
        swappedArray[i][sortAttribute] = i;
      }
      for(let j = swappedArray.length - 1; j > finalIndex; j -= 1){
        swappedArray[j][sortAttribute] = j + 1;
      }
  }
    return swappedArray;
  };
  return swapBottomPriority;
};

export default useUpdateBottomMembersPriority;
