/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash/fp';
import {
  Hr, ImgSquareViewMember,
} from '../../Components/Auth/Layout';
import userDefaultImage from '../../Images/team-dum.png';
import Permissions from '../../Constants/PermissionConstrants';
import BasicEventCompetitions from './BasicEventCompetitions';

const EditTeamMember = (props) => {
  const {
    member,
    eventId,
    currentEventsUser,
    schoolId,
    eventAllowsMultipleSports,
  } = props;

  if (isEmpty(member)) {
    return null;
  }

  return (
    <>
      <div className="pt-3 pb-3 mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1 col-4 ">
              <ImgSquareViewMember>
                { get('profileImageUrl', member.fetchUser)
                  ? (<img src={get('fetchUser.profileImageUrl', member)} alt="Member" />)
                  : (<img src={userDefaultImage} alt="User-Icon" />)}
              </ImgSquareViewMember>
            </div>
            <div className="col-md-11 col-8">
              <div className="row">
                <div className=" font-weight-bold col-md-6 col-6 small">
                  {get('fetchUser.firstname', member)}
                  {' '}
                  &nbsp;
                  {get('fetchUser.lastname', member)}
                </div>
              </div>
              <div className="small pl-0">
                {get('fetchUser.email', member)}
              </div>
            </div>
            <div className="col-md-12 col-12">
              <Hr className="mr-1 mt-1 mb-2 pl-n1 text-left" />
              <BasicEventCompetitions
                order={(eventId && currentEventsUser) ? {
                  eventId,
                  fetchEventsUser: currentEventsUser,
                } : {}}
                addNewText="SAVE"
                addNewLink={`/events/${eventId}/team/${schoolId}`}
                permissionName={Permissions.CAN_MANAGE_TEAM_MEMBERS}
                eventAllowsMultipleSports={eventAllowsMultipleSports}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditTeamMember.propTypes = {
  member: PropTypes.instanceOf(Object),
  eventId: PropTypes.string.isRequired,
  currentEventsUser: PropTypes.instanceOf(Object).isRequired,
  schoolId: PropTypes.string.isRequired,
};
EditTeamMember.defaultProps = {
  member: null,
};

export default EditTeamMember;
