export const raceChoices = {
  decline_to_answer: 'Prefer not to answer',
  american_indian_or_alaska_native: 'American Indian or Alaska Native',
  black_or_african_american: 'Black or African American',
  east_asian: 'East Asian',
  hispanic_or_latino: 'Hispanic or Latino',
  middle_eastern_or_north_african: 'Middle Eastern or North African',
  native_hawaiian_or_pacific_islander: 'Native Hawaiian or Pacific Islander',
  south_asian: 'South Asian',
  white: 'White or Caucasian',
  other: 'Other',
};
