import React, { useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';

import { useParams } from 'react-router-dom';
import { FETCH_UNAPPROVE_SCHOOLS_BY_EVENT, FETCH_EVENT } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useFilterArray from '../../Hooks/useFilterArray';

const UnapproveSchoolsByEvent = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(null);

  const extractError = useGraphQLErrorExtractor();
  const { id } = useParams();
  const filterArray = useFilterArray();
  const [fetchUnapproveSchool, { loading, error, data: dataSchools }] = useLazyQuery(
    FETCH_UNAPPROVE_SCHOOLS_BY_EVENT,
  );

  const filteredUnapproveSchools = useMemo(() => filterArray((getOr([], 'fetchUnapproveSchool', dataSchools)), 'status', 'pending'), [dataSchools, filterArray]);

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useHeaderComponentsMutation({
    title: 'UNAPPROVED SCHOOLS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser) {
      fetchUnapproveSchool({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchUnapproveSchool, id]);

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <p style={{ display: 'none' }}>{get('firstname', currentUser)}</p>
        {filteredUnapproveSchools.length === 0
          ? <p>No Schools</p>
          : null}
        <List data={filteredUnapproveSchools} displayAttribute="name" redirectLink={`events/${id}/schools`} redirectOperation="edit" dataType="schools" />
      </div>
    </div>
  );
};

export default UnapproveSchoolsByEvent;
