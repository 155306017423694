import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import NumberFormat from 'react-number-format';
import {
  FontBold, DividerLine,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL,
  PAYMENT_USER_AGAINST_REGISTRATION_OPTION,
  UNPAID_USER_AGAINST_REGISTRATION_OPTION,
  PAID_USER_AGAINST_REGISTRATION_OPTION,
  FETCH_REGISTRATION_OPTION_DETAIL,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const PaymentsByOption = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { optionId } = useParams();
  const extractError = useGraphQLErrorExtractor();

  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  const [paymentUserAgainstRegistrationOption,
    { data: dataPayment, loading: paymentLoading, error: paymentError }] = useLazyQuery(
    PAYMENT_USER_AGAINST_REGISTRATION_OPTION,
  );
  const paymentInformation = getOr({}, 'paymentUserAgainstRegistrationOption', dataPayment);
  const [unpaidUserAgainstRegistrationOption,
    { data: dataUnpaid, loading: upaidLoading, error: unpaidError }] = useLazyQuery(
    UNPAID_USER_AGAINST_REGISTRATION_OPTION,
  );
  const unpaidUsers = getOr([], 'unpaidUserAgainstRegistrationOption', dataUnpaid);

  const [paidUserAgainstRegistrationOption,
    { data: dataPaid, loading: paidLoading, error: paidError }] = useLazyQuery(
    PAID_USER_AGAINST_REGISTRATION_OPTION,
  );
  const paidUsers = getOr([], 'paidUserAgainstRegistrationOption', dataPaid);

  const [fetchRegistrationOptionDetail, { data: dataFetchRegistrationOptionDetail }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const registrationOption = getOr({}, 'fetchRegistrationOptionDetail', dataFetchRegistrationOptionDetail);

  useHeaderComponentsMutation({
    title: registrationOption.title,
    backLink: `/events/${id}/payment-report`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (currentUser && id) {
      paymentUserAgainstRegistrationOption({
        variables: {
          eventId: parseInt(id, 10),
          registrationOptionId: parseInt(optionId, 10),
        },
      });
    }
  }, [currentUser, paymentUserAgainstRegistrationOption, id, optionId]);

  useEffect(() => {
    if (currentUser && id) {
      unpaidUserAgainstRegistrationOption({
        variables: {
          eventId: parseInt(id, 10),
          registrationOptionId: parseInt(optionId, 10),
        },
      });
    }
  }, [currentUser, unpaidUserAgainstRegistrationOption, id, optionId]);

  useEffect(() => {
    if (currentUser && id) {
      paidUserAgainstRegistrationOption({
        variables: {
          eventId: parseInt(id, 10),
          registrationOptionId: parseInt(optionId, 10),
        },
      });
    }
  }, [currentUser, paidUserAgainstRegistrationOption, id, optionId]);

  useEffect(() => {
    if (currentUser) {
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(optionId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptionDetail, optionId]);


  if (loading || userLoading || paymentLoading || upaidLoading || paidLoading) {
    return <Loader />;
  }
  if (error || userError || paymentError || unpaidError || paidError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Unpaid
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('unpaid_user_count', paymentInformation)}
              </h5>
            </div>
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Paid
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('paid_user_count', paymentInformation)}
              </h5>
            </div>
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Total
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('total_user', paymentInformation)}
              </h5>
            </div>
          </div>
          <div className="pt-3" />
          {!isEmpty(unpaidUsers)
            ? (
              <>
                <FontBold>
                  <p>
Unpaid (
                    {unpaidUsers.length}
)
                  </p>
                </FontBold>

                {unpaidUsers.map((item) => (
                  <>
                    <div className="row pl-2 my-2">
                      <div className="col-md-10 col-9 ">
                        {get('firstname', item)}
                        {' '}
                        {get('lastname', item)}
                        {' '}
(
                        {get('code', item)}
)
                      </div>
                      <div className="col-md-2 col-3 pl-5 float-right">
                        <NumberFormat value={get('amount', item) / 100} displayType="text" thousandSeparator decimalScale={2} />
                        {' '}
                        {get('defaultCurrency', item)}
                      </div>
                    </div>
                    <DividerLine className="my-3" />
                  </>
                ))}
              </>
            )
            : null}
          <div className="pt-3" />
          {!isEmpty(paidUsers)
            ? (
              <>
                <FontBold>
                  <p>
Paid (
                    {paidUsers.length}
)
                  </p>
                </FontBold>
                {paidUsers.map((item) => (
                  <>
                    <div className="row pl-2 my-2">
                      <div className="col-md-10 col-9 ">
                        {get('firstname', item)}
                        {' '}
                        {get('lastname', item)}
                        {' '}
(
                        {get('code', item)}
)
                      </div>
                      <div className="col-md-2 col-3 pl-5  float-right">
                        <NumberFormat value={get('amount', item) / 100} displayType="text" thousandSeparator decimalScale={2} />
                        {' '}
                        {get('defaultCurrency', item)}
                      </div>
                    </div>
                    <DividerLine className="my-3" />
                  </>
                ))}
              </>
            )
            : null}
        </div>
      </div>
    </>
  );
};
export default PaymentsByOption;
