import React, {
  useEffect,
  // useState
} from 'react';
import {
  useParams,
  // useHistory,
} from 'react-router-dom';
import {
  useLazyQuery,
  // useMutation,
} from '@apollo/react-hooks';
import {
  getOr,
  get,
  isEmpty,
} from 'lodash/fp';
import {
  FETCH_EVENT_DETAIL,
  FETCH_ORDER_DETAIL,
  // MANAGE_MY_COMPETITIONS,
} from '../../GraphQL/Queries';
// import {
//   MEMBER_SWAP,
// } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
// import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
// import useSortArray from '../../Hooks/useSortArray';
import BackLink from '../../Components/BackLink';
// import GenericAlert from '../../Components/GenericAlert';

import BasicEventCompetitions from './BasicEventCompetitions';
// import EventCompetitions from './EventCompetitions';
// import EventCompetitionsSearch from './EventCompetitionsSearch';
// import useUpdateSwappedCompetitionsCache from '../../Hooks/useUpdateSwappedCompetitionsCache';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import JoinEventSlider from './JoinEventSlider';

const JoinEventCompetitions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const { orderId } = useParams();
  // const history = useHistory();

  // const [alreadySelectedEventCompetitions, setAlreadySelectedEventCompetitions] = useState([]);
  // const extractError = useGraphQLErrorExtractor();
  // const [addedCompetition, setAddedCompetition] = useState('');
  // const [error, setError] = useState(null);
  // const [swap, setSwap] = useState(false);
  // const sortedArray = useSortArray();

  // const [manageMyCompetitions] = useLazyQuery(
  //   MANAGE_MY_COMPETITIONS,
  //   {
  //     onCompleted(data) {
  //       setAlreadySelectedEventCompetitions(
  //         sortedArray(data.manageMyCompetitions, 'priority'),
  //       );
  //     },
  //   },
  // );

  const [fetchEventDetail, { data: dataFetchEventDetail }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );

  const event = getOr({}, 'fetchEventDetail', dataFetchEventDetail);

  // const updateCache = useUpdateSwappedCompetitionsCache(
  //   getOr(0, 'id', event), getOr(0, 'id', currentUser),
  // );


  // useEffect(() => {
  //   if (!isEmpty(event)) {
  //     manageMyCompetitions({
  //       variables: {
  //         eventId: parseInt(get('id', event), 10),
  //         userId: parseInt(currentUser.id, 10),
  //       },
  //     });
  //   }
  // }, [currentUser,
  //   event,
  //   manageMyCompetitions,
  // ]);


  const [fetchOrderDetail, { data: dataOrder }] = useLazyQuery(
    FETCH_ORDER_DETAIL,
  );

  const order = getOr({}, 'fetchOrderDetail', dataOrder);

  useEffect(() => {
    if (!isEmpty(order)) {
      fetchEventDetail({
        variables: {
          id: parseInt(order.eventId, 10),
        },
      });
    }
  }, [order, fetchEventDetail,
  ]);


  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);

  // const appendEventCompetition = (member) => {
  //   setError(null);
  //   setAddedCompetition(member.id);
  // };

  // const setCompetitions = (competitions) => {
  //   setSwap(true);
  //   setAlreadySelectedEventCompetitions(competitions);
  // };

  // const [swapMember, { loading }] = useMutation(
  //   MEMBER_SWAP,
  //   {
  //     onCompleted: (data) => {
  //       updateCache(data.memberSwap.members);
  //       history.push(`/order/${orderId}/cart`);
  //     },
  //   },
  // );

  // const memberSwap = () => {
  //   const idArray = alreadySelectedEventCompetitions.map((obj) => (obj.id));
  //   const priorityArray = alreadySelectedEventCompetitions.map((obj) => (obj.memberPriority));
  //   const idPrioityArray = [];
  //   for (let i = 0; i < idArray.length; i += 1) {
  //     const obj = {};
  //     obj.memberId = parseInt(idArray[i], 10);
  //     obj.memberPriority = priorityArray[i];
  //     idPrioityArray.push(obj);
  //   }
  //   if (swap === true) {
  //     swapMember({
  //       variables: {
  //         members: idPrioityArray,
  //       },
  //     });
  //   } else {
  //     history.push(`/order/${orderId}/cart`);
  //   }
  // };

  useHeaderComponentsMutation({
    title: 'SELECT COMPETITIONS',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });
  const nextLink = (event?.merchandise?.length > 0) ? `/order/${orderId}/merch` : `/order/${orderId}/cart`;

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          {/* <div className="d-none">{addedCompetition}</div> */}
          {/* {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null} */}
          <JoinEventSlider
            event={event}
            order={order}
          />
          {/* <p>
            Drag and Drop competitions in order of priority,
            from highest to lowest
          </p>
          <EventCompetitions
            eventCompetitions={alreadySelectedEventCompetitions}
            onArrange={setCompetitions}
          />

          <p className="pt-3" style={{ fontWeight: '600' }}>Join Competition</p>
          <EventCompetitionsSearch
            currentUser={currentUser}
            order={order}
            onClick={appendEventCompetition}
            onError={setError}
          /> */}
          <BasicEventCompetitions
            order={order}
            addNewText="CONTINUE"
            addNewLink={nextLink}
            permissionName="Join_Event"
            eventAllowsMultipleSports={event?.allowsMultipleSports}
          />
        </div>
      </div>
      {/* <LinkButton
        onClick={memberSwap}
        backgroundcolor="#F4AB37"
        fontcolor="#FFF"
        border="none"
        loading={loading}
      >
        CONTINUE
      </LinkButton> */}
      <BackLink to={nextLink} imageNotRequired>
              Skip For Now
      </BackLink>
      <hr />
      <p style={{ fontWeight: '600' }}>Waitlist Rules</p>
      <p>
            You may compete in a max of one competition per category.
            If you choose basketball, you must sign up for another competition in any category.
            You must qualify by attending previous rounds
      </p>
    </>
  );
};

export default JoinEventCompetitions;
