import React from 'react';
import { compareAsc } from 'date-fns';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useParams, Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { EVENT_TITLE } from '../../../Constants/AppConstants';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import { ExportButton } from './ExportButton';
import { AttendanceTable } from './AttendanceTable';

const ATTENDANCE_REPORT = gql`
query fetchEventDetail($id: Int!) {
  fetchEventDetail(id: $id) {
    id
    title
    schedule {
      scheduleEvents {
        id
        title
        startTime
        endTime
        attendees {
          id
          user {
            id
            code
            firstname
            lastname
          }
          eventUsers {
            fetchRole {
              id
              title
            }
            fetchSchool {
              id
              name
            }
            fetchTeam {
              id
              code
            }
          }
          checkedInAt
        }
      }
    }
  }
}
`;


const scheduleEventsComparator = (e1, e2) => {
  if (e1.startTime !== e2.startTime) {
    return compareAsc(new Date(e1.startTime), new Date(e2.startTime));
  }
  if (e1.endTime !== e2.endTime) {
    return compareAsc(new Date(e1.endTime), new Date(e2.endTime));
  }
  return e1.title.localeCompare(e2.title);
};
const sorted = (items) => [...items].sort(scheduleEventsComparator);

export const AttendanceReport = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(ATTENDANCE_REPORT, {
    variables: {
      id: parseInt(id, 10),
    },
  });

  useHeaderComponentsMutation({
    title: 'ATTENDANCE REPORT',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: data?.fetchEventDetail?.title }],
  });

  if (loading) {
    return (
      <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const { title: eventTitle, schedule } = data.fetchEventDetail;

  const sortedScheduleEvents = sorted(schedule.scheduleEvents);

  return (
    <Box w="100%" h="100%" marginBlock={8}>
      {sortedScheduleEvents.length === 0 ? (
        <Text>
          No events scheduled yet! Add to your
          {' '}
          <Link as={ReactRouterLink} to={`/events/${id}/event-schedule`} textDecoration="underline">Schedule</Link>
        </Text>
      ) : (
        <>
          <Flex justifyContent="end" w="100%">
            <ExportButton eventName={eventTitle} scheduleEvents={sortedScheduleEvents} />
          </Flex>
          <AttendanceTable scheduleEvents={sortedScheduleEvents} />
        </>
      )}
    </Box>
  );
};
