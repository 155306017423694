import React from 'react';
import { Provider } from '../../../Chakra';
import { AttendanceReport } from './AttendanceReport';


export default () => (
  <Provider>
    <AttendanceReport />
  </Provider>
);
