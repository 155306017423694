import React from 'react';
import PropTypes from 'prop-types';
import Form, { Input, PermissionButton } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const CategoriesForm = ({
  params, onSubmit, onChange, loading, currentUser,
}) => {
  const checkPermission = useUserPermissionChecker(currentUser);
  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="Title"
        label="Title"
        name="title"
        id="title"
        placeholder="Title"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <PermissionButton loading={loading} htmltype="submit" marginBottom="10px" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" checkPermission={(checkPermission('Add Category'))}>SUBMIT</PermissionButton>
    </Form>
  );
};

CategoriesForm.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

CategoriesForm.defaultProps = {
  loading: false,
};

export default CategoriesForm;
