const useUpdateTopMembersPriority = () => {
  const swapTopPriority = (array, bottomElement, intitalIndex, finalIndex, sortAttribute) => {
    const swappedArray = array;
    if (sortAttribute === 'memberPriority') {
      swappedArray[intitalIndex][sortAttribute] = swappedArray[finalIndex][sortAttribute];
      for (let i = finalIndex; i < intitalIndex; i += 1) {
        swappedArray[i][sortAttribute] += 1;
      }
    } else {
      swappedArray[intitalIndex][sortAttribute] = finalIndex + 1;
      for (let i = finalIndex; i < intitalIndex; i += 1) {
        swappedArray[i][sortAttribute] = i + 2;
      }
      for (let j = 0; j < finalIndex; j += 1) {
        swappedArray[j][sortAttribute] = j + 1;
      }
    }
    return swappedArray;
  };
  return swapTopPriority;
};

export default useUpdateTopMembersPriority;
