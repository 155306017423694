import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useParams, Link } from 'react-router-dom';
import { get } from 'lodash/fp';

import { FETCH_COALITIONS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
// import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import { EVENT_TITLE } from '../../Constants/AppConstants';


import {
  TitleC, RIGHTARROW, Hr,
} from '../../Components/Auth/Layout';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';


const FETCH_EVENT_HEADER = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
    }
  }
`;

const List = (props) => {
  const {
    data,
    itemToLink,
  } = props;

  return (
    <>
      {data && data.map((item) => (
        <div key={item.id}>
          <Link to={itemToLink(item)}>
            <div className="row align-items-center">
              <div className="col-11 mt-4 mx-auto px-4">
                <TitleC>
                  <p>
                    Coalition
                    {' '}
                    {item.code}
                  </p>
                </TitleC>
                <p>
                  <b>
                    {item.fetchEventCompetition.title}
                  </b>
                  {': '}
                  <span>
                    {item.fetchCoalitionsSchools.length > 0
                      ? item.fetchCoalitionsSchools.map((cs) => cs.fetchSchool.name).reduce((s, cur) => (s ? `${s}, ${cur}` : cur), '')
                      : '(no schools yet)'}
                  </span>
                </p>
              </div>
              <div className="col-1 mt-4 mx-auto px-2">
                {
                  get('status', item) === 'pending'
                    ? <span>Pending</span>
                    : null
                }
                <RIGHTARROW>
                  <img src={RIGHT_ARROW} alt="arrow" className="img-fluid mt-3" />
                </RIGHTARROW>
              </div>
            </div>
          </Link>
          <Hr />
        </div>
      ))}
    </>
  );
};

List.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  itemToLink: PropTypes.func.isRequired,
};

const CoalitionIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const [filteredCoalitions, setFilteredCoalitions] = useState([]);

  const [fetchCoalitions, { loading, error }] = useLazyQuery(
    FETCH_COALITIONS, {
      onCompleted: (data) => {
        setFilteredCoalitions(data.fetchCoalitions.sort((a, b) => {
          if (a.fetchEventCompetition !== b.fetchEventCompetition) {
            return a.fetchEventCompetition.title.localeCompare(b.fetchEventCompetition.title);
          }
          return a.code.localeCompare(b.code);
        }));
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();
  const checkPermission = useUserPermissionChecker(currentUser);

  const { data: eventData, loading: eventLoading } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      id,
    },
  });
  const event = eventData?.fetchEventDetail ?? {};
  useHeaderComponentsMutation({
    title: 'COALITIONS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser) {
      fetchCoalitions({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchCoalitions, id]);


  if (loading || userLoading || eventLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4" />
      </div>
      <List
        data={filteredCoalitions}
        itemToLink={(coalition) => `/event-manage/${id}/coalitions/${coalition.id}/edit`}
      />
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Coalition" addNewLink={`/event-manage/${id}/coalitions/new`} imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('View Event'))} />
    </>
  );
};
export default CoalitionIndex;
