import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  getOr, isEmpty, get, first,
} from 'lodash/fp';
import { CREATE_SCHOOL, UPDATE_SCHOOL_VERIFICATION, CHANGE_SCHOOL } from '../../GraphQL/Mutations';
import { FETCH_REGION, FETCH_REGION_BY_TENANT } from '../../GraphQL/Queries';
import useFormParams from '../../Hooks/useFormParams';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUpdateSchoolsCache from '../../Hooks/useUpdateSchoolsCache';
import Form from './Form';
import Loader from '../../Components/Loader';
import Permissions from '../../Constants/PermissionConstrants';

const SchoolNew = () => {
  const history = useHistory();
  const {
    currentUser,
  } = useCurrentUser(true);
  const updateCache = useUpdateSchoolsCache(getOr(0, 'id', currentUser));
  const checkPermission = useUserPermissionChecker(currentUser);

  const [changeUserSchool, { loading: changeSchoolLoading }] = useMutation(
    CHANGE_SCHOOL,
    {
      onCompleted: () => {
        history.goBack();
      },
    },
  );

  const [schoolUserAttachment, { loading: addSchoolLoading }] = useMutation(
    UPDATE_SCHOOL_VERIFICATION,
    {
      onCompleted: () => {
        history.goBack();
      },
    },
  );

  const [createSchool, { loading, error }] = useMutation(
    CREATE_SCHOOL,
    {
      onCompleted: (data) => {
        updateCache(data.createSchool.school);
        if (checkPermission(Permissions.CAN_JOIN_SCHOOL)
            || checkPermission(Permissions.CAN_JOIN_SCHOOL_OPTIONAL)
        ) {
          if (!isEmpty(get('fetchSchoolsUsers', currentUser))) {
            changeUserSchool({
              variables: {
                currentSchoolId: parseInt(get('fetchSchool', first(currentUser.fetchSchoolsUsers)).id, 10),
                userId: parseInt(currentUser.id, 10),
                newSchoolId: parseInt(data.createSchool.school.id, 10),
              },
            });
          } else {
            schoolUserAttachment({
              variables: {
                schoolId: parseInt(data.createSchool.school.id, 10),
                userId: parseInt(currentUser.id, 10),
              },
            });
          }
        } else {
          history.goBack();
        }
      },
    },
  );

  const { data: regionsData } = useQuery(FETCH_REGION, {
    skip: !currentUser,
    variables: {
      userId: parseInt(currentUser.id, 10),
    },
  });
  let regions = regionsData?.fetchRegions ?? [];

  const { data: regionsTenantData } = useQuery(FETCH_REGION_BY_TENANT, {
    skip: !currentUser || regions.length > 0,
    variables: {
      tenantId: 1,
    },
  });
  regions = regions.length > 0 ? regions : (regionsTenantData?.fetchRegionsByTenant ?? []);

  useHeaderComponentsMutation({
    backLink: getOr('', 'fetchRole.title', currentUser).includes('Admin') ? '/schools' : '/school-verification',
    title: 'ADD NEW SCHOOL',
  });
  const { params, onChange, setParams } = useFormParams({
    name: '',
    address1: '',
    address2: '',
    website: '',
    country: '',
    stateCode: '',
    postalCode: '',
    phone: '',
    city: '',
    denyComment: '',
  });
  const extractError = useGraphQLErrorExtractor();

  const onSubmit = (e) => {
    const regionsArray = [];
    e.preventDefault();
    Object.keys(params).map((param, index) => (param.includes('regionUniqueId_')
      ? regionsArray.push(parseInt(Object.values(params)[index], 10))
      : null
    ));
    createSchool({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
        status: checkPermission('Approve School') ? 2 : 1,
        regionIds: regionsArray.filter((item, pos) => regionsArray.indexOf(item) === pos),
      },
    });
  };

  if (addSchoolLoading || changeSchoolLoading) {
    return <Loader />;
  }


  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            className="pt-3"
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            data={regions}
            regionLength={1}
            formType="new"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default SchoolNew;
