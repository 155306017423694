import React from 'react';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';


const ThankYou = () => {
  useHeaderComponentsMutation({
    title: 'SIGNATURE CONFIRMATION',
  });
  return (
    <>
      <div className="my-4 text-center">
        <h1>Thank you !</h1>
        <h5>Your response have been received.</h5>

      </div>
    </>
  );
};

export default ThankYou;
