import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import { useLazyQuery } from '@apollo/react-hooks';
import { FETCH_USER_DETAIL } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import ListItem from '../../Components/ListItem/Index';
import userDefaultImage from '../../Images/team-dum.png';
import {
  FontBold, ImgSqaure,
} from '../../Components/Auth/Layout';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const UserView = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();

  const [fetchUserDetail, { data: userData }] = useLazyQuery(
    FETCH_USER_DETAIL,
  );

  const user = getOr({}, 'fetchUserDetail', userData);

  useHeaderComponentsMutation({
    title: 'VIEW/EDIT USER',
    backLink: '/users',
  });

  useEffect(() => {
    if (currentUser) {
      fetchUserDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchUserDetail, id]);


  if (userLoading) {
    return <Loader />;
  }
  if (userError) {
    return <GenericAlert>{extractError(userError)}</GenericAlert>;
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <div className="pt-3" />
          <div className="row">
            <div className="col-xs-6 px-4">
              <ImgSqaure>
                { get('profileImageUrl', user)
                  ? <img src={getOr(userDefaultImage, 'profileImageUrl', user)} alt="User-Icon" />
                  : <img src={userDefaultImage} alt="User-Icon" />}
              </ImgSqaure>
            </div>
            <div className="col-xs-6 px-4">
              <p>{`${get('firstname', user)} ${get('lastname', user)}`}</p>
              <p>{`MIST ID ${get('code', user)}`}</p>
            </div>
          </div>
          <div className="pt-3" />
          <FontBold>
            <p>Contact</p>
          </FontBold>
          <p>{user.email}</p>
          <p>{user.phone}</p>
          <div className="pt-3" />
          <FontBold className="pt-3">
            <p>Requests</p>
          </FontBold>
          <ListItem title="Schools" redirectLink="/schools" checkPermission={(checkPermission('View User'))} />
          <div className="d-none">
            <ListItem title="Join Team" redirectLink="/" checkPermission={(checkPermission('View Event'))} />
            <ListItem title="Financial Aid" redirectLink="/" checkPermission={(checkPermission('View User'))} />
            <ListItem title="Coalitions" redirectLink="/" checkPermission={(checkPermission('View User'))} />
            <ListItem title="Refunds" redirectLink="/" checkPermission={(checkPermission('View User'))} />
          </div>
          <FontBold className="pt-3">
            <p>Events</p>
          </FontBold>
          {getOr([], 'fetchEventsUsers', user).map((eventUser) => (
            <>
              {get('fetchEvent', eventUser)
                ? <ListItem title={get('fetchEvent.title', eventUser)} redirectLink={`/event-manage/${get('fetchEvent.id', eventUser)}`} checkPermission={(checkPermission('View User'))} />
                : null}
            </>
          ))}
          {getOr({}, 'defaultRegion', user)
            ? (
              <>
                <FontBold className="pt-3">
                  <p>Regions</p>
                </FontBold>
                <ListItem title={getOr('', 'defaultRegion.name', user)} redirectLink="/" checkPermission={(checkPermission('View User'))} />
              </>
            )
            : null}

          <FontBold className="pt-3">
            <p>Role</p>
          </FontBold>
          <ListItem title={getOr('', 'fetchRole.title', user)} redirectLink="/" checkPermission={(checkPermission('View User'))} />
          <div className="d-none">
            <FontBold className="pt-3">
              <p>Admin Controls</p>
            </FontBold>
            <ListItem title="Transaction History" redirectLink="/" checkPermission={(checkPermission('View User'))} />
            <ListItem title="User Logs" redirectLink="/" checkPermission={(checkPermission('View User'))} />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserView;
