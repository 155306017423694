import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { SIGN_UP } from '../../GraphQL/Mutations';
import { TitleC } from '../../Components/Auth/Layout';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useUserSession from '../../Hooks/useUserSession';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useInvitationCode from '../../Hooks/useInvitationCode';
import BackLink from '../../Components/BackLink';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert'; 
import Form from '../../Components/Form';
import { CURRENT_USER, FETCH_INVITATION_DETAIL_BY_CODE } from '../../GraphQL/Queries';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import { Row, Col } from 'reactstrap';


const SignUp = () => {
  const history = useHistory();
  const { saveUserSession } = useUserSession();
  const { currentUser } = useCurrentUser();
  const { getInvitationCode } = useInvitationCode();
  const [errorMessage, seterrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({ title: 'Log in / Sign Up' });
  const [signUp, { loading, client }] = useMutation(SIGN_UP, {
    onCompleted: (data) => {
      if (data.signUp.active) {
        if (data.signUp.user.confirmedAt) {
          saveUserSession(data.signUp.token);
          client.writeQuery({
            query: CURRENT_USER,
            data: { currentUser: data.signUp.user },
          });
          history.replace('/me/edit-profile');
        } else if (!data.signUp.user.confirmedAt) {
          history.replace('/users/unverified');
        }
      } else {
        seterrorMessage('')
        setSuccessMessage("Your account was created successfully. Please verify your email to login.");
      }
    },
    onError: (error) => {
      setSuccessMessage('')
      seterrorMessage(extractError(error));
    },
  });
  const { params, setParams, onChange } = useFormParams({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  });

  const [fetchInvitationDetail] = useLazyQuery(
    FETCH_INVITATION_DETAIL_BY_CODE, {
      onCompleted: (data) => {
        setParams(data.fetchInvitationDetail);
      },
    },
  );

  useEffect(() => {
    if (getInvitationCode) {
      fetchInvitationDetail({
        variables: {
          invitationCode: getInvitationCode,
        },
      });
    }
  }, [getInvitationCode, fetchInvitationDetail]);

  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  const checkRole = () => {
    if (params.registrationOptionId) {
      params.roleId = params.fetchRegistrationOption.roleId;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (params.password !== params.passwordConfirmation) {
      setSuccessMessage('')
      seterrorMessage('Password and confirm password fields do not match.');
      return
    }
    checkRole();
    signUp({
      variables: {
        ...params,
        tenantId: 1,
      },
    });
  };

  return (
    <>
      <Row className="mt-3"> 
        <Col lg={{size: 6, offset: 3}}> 
          { errorMessage ? <GenericAlert color='danger'>{errorMessage}</GenericAlert> : null}
          { successMessage ? <GenericAlert color='success'>{successMessage}</GenericAlert> : null}
        { !successMessage ? 
          <Form onSubmit={onSubmit}>
              <TitleC>
                <p className="mt-4">Create Account</p>
              </TitleC>
              <Input
                title="First Name"
                type="text"
                id="firstname"
                name="firstname"
                placeholder="First Name"
                required
                onChange={onChange}
                withFormGroup
                label="First Name"
                labelClass="sr-only"
                params={params}
              />
              <Input
                type="text"
                title="Last Name"
                id="lastname"
                name="lastname"
                placeholder="Last Name"
                required
                onChange={onChange}
                withFormGroup
                label="Last name"
                labelClass="sr-only"
                params={params}
              />
              <Input
                title="Email"
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                onChange={onChange}
                withFormGroup
                label="email"
                labelClass="sr-only"
                params={params}
              />
              <Input
                type="password"
                title="Passphrase"
                id="pwd"
                name="password"
                label="password"
                placeholder="Passphrase"
                minLength="8"
                required
                onChange={onChange}
                withFormGroup
                labelClass="sr-only"
                params={params}
              />
              <Input
                type="password"
                title="Confirm Passphrase"
                id="confirm_pwd"
                name="passwordConfirmation"
                label="Confirm Password"
                placeholder="Confirm Passphrase"
                minLength="8"
                required
                onChange={onChange}
                withFormGroup
                labelClass="sr-only"
                params={params}
              />
              <p className="small text-muted caption">*8 character minimum</p>
              <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">
                Create Account
              </InputButton>
            </Form> 
          : null }
          <BackLink to="/login">
            Log In
          </BackLink>
          </Col>
      </Row>
    </>
  );
};
export default SignUp;
