import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import fb from '../../Images/footer/Facebook.svg';
import instagram from '../../Images/footer/Instagram.svg';
import twitter from '../../Images/footer/Twitter.svg';
import email from '../../Images/footer/Email.svg';
import help from '../../Images/footer/Help.svg';


const Wrapper = styled.div`
  background-color: #292929;
  padding-top: 1%;
  background-image: url(../../Images/footer/Footer.png);
  
`;

const SocialLayout = styled.ul`
    padding: 0;
    margin-bottom: 0;
    margin-top:20px;
    
    text-align: center;
  li {
    list-style-type: none;
    display: inline-block;
  a {
    display: inline-block;
    position: relative;
  }
  :not(:last-child){
    padding-right: 30px;
  }
  }
`;

const InnerFooterCopyRight = styled.div`
  text-align: center;
`;

const MistifiedDiv = styled.div`
    text-align:center;
`;
const GetMistifiedCom = styled.a`
  {
    text-decoration:none;
    color:#F4AB37;
    font-family: 'nunito_bold';
    font-size: 13pt;
    font-weight: bold;
    line-height: 27px;
    
    
  }
`;

// const InnerFooterCopyright = styled.a`
//   :hover{
//     color: rgba(52, 52, 52, 1);
//     text-decoration: none;
//   }
//   :active{
//     color: rgba(52, 52, 52, 1);
//     text-decoration: none;
//   }
//   :focus{
//     color: rgba(52, 52, 52, 1);
//     text-decoration: none;
//   }
// `;
const SupportDisplay = styled.div`
display:flex;
justify-content:space-between;
`;
const Support = styled.div`
  
  p{
    color: rgba(255, 255, 255, 1);
    font-size: 10pt;
    line-height: 0px;
    margin-bottom: 0;
    padding-top: 0px;
    padding-bottom: 20px;
    font-family: 'nunito_extrabold';
    padding-left:20px;
    padding-right:20px;
  }
`;
const White = styled.b`

color:white;
`;


const Footer = () => (
  <Wrapper>
    <footer data-role="footer">
      <MistifiedDiv>
        <GetMistifiedCom href="https://www.getmistified.com/" target="_blank" rel="noopener noreferrer">

              get
          <White>mist</White>
ified.com


        </GetMistifiedCom>
      </MistifiedDiv>
      <MistifiedDiv>
        <White>Nationally sponsored by </White>
        <GetMistifiedCom href="http://irusa.org/" target="_blank" rel="noopener noreferrer">

              Islamic Relief USA


        </GetMistifiedCom>
      </MistifiedDiv>
      <SocialLayout>
        <li><a href="https://www.facebook.com/getmistified/" target="_blank" rel="noopener noreferrer"><img src={fb} alt="Facebook" /></a></li>
        <li><a href="http://instagram.com/getmistified" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram" /></a></li>
        <li><a href="https://twitter.com/getmistified" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" /></a></li>
        <li><a href="mailto:info@getmistified.com" target="_blank" rel="noopener noreferrer"><img src={email} alt="Email" /></a></li>
      </SocialLayout>
      <InnerFooterCopyRight>
        <div className="py-4" />
      </InnerFooterCopyRight>
      <SupportDisplay>
        <Support>
          <Link to="/help">
            <img src={help} alt="Help" />
            {' '}
            <span className="text-white font-weight-bold">
            Help
            </span>
          </Link>
        </Support>
        <Support className="pt-3">
          <p>
            MIST &copy;
            {' '}
            {new Date().getFullYear()}
          </p>
        </Support>
      </SupportDisplay>
    </footer>
  </Wrapper>
);


export default Footer;
