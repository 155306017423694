import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_COUPONS, SEARCH_COUPONS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const CouponIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [fetchCoupons, { loading, error }] = useLazyQuery(
    FETCH_COUPONS, {
      onCompleted: (data) => {
        setFilteredCoupons(data.fetchCoupons);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'COUPONS',
    backLink: '/dashboard',
  });
  useEffect(() => {
    if (currentUser) {
      fetchCoupons({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchCoupons]);

  const [couponsSearch] = useLazyQuery(
    SEARCH_COUPONS,
    {
      onCompleted: (data) => {
        setFilteredCoupons(data.searchCoupons);
      },
    },
  );

  const searchCoupons = (e) => {
    couponsSearch({
      variables: {
        query: e.target.value,
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">Active Coupons</p>
          </FontBold>
        </div>
      </div>
      <Search
        placeholder="Search"
        onChange={(e) => searchCoupons(e)}
      />
      {filteredCoupons.length === 0
        ? <p>No Coupons</p>
        : null}
      <List data={filteredCoupons} redirectLink="coupons" addNewText="Add New Coupon" addNewLink="coupons/new" redirectOperation="edit" dataType="coupons" />
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Coupon" addNewLink="coupons/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Coupon'))} />
    </>
  );
};
export default CouponIndex;
