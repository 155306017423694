import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  FontBold, DividerLine,
} from '../../Components/Auth/Layout';
import Loader from '../../Components/Loader';
import {
  FETCH_EVENT_DETAIL,
  PARTICIPANTS_BY_COMPETITIONS,
  FETCH_EVENT_COMPETITION_ATTACHMENTS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_SHORT_TZ = format(new Date(), 'z');
const stringifyDate = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const splitMistCode = (code) => {
  if (code.indexOf('-') >= 0) {
    return code.split('-')[1];
  }
  return code;
};



const Attachments = ({ attachments }) => (
  <div className="col-md-4 font-weight-bold">
    {attachments?.length > 0 && (
      <>
        <div className="row font-weight-bold">Submissions:</div>
        {attachments.map((attachment) => <AttachmentBlock key={attachment.id} attachment={attachment} />)}
      </>
    )}
  </div>
);

const AttachmentBlock = ({attachment}) => (
  <>
    <div className="row font-weight-bold">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={attachment.attachmentUrl}
      >
        {attachment.attachmentName}
      </a>
    </div>
    <div className="row text-muted mt-n1">
      Uploaded at: {stringifyDate(attachment.updatedAt)} {USER_SHORT_TZ}
    </div>
  </>
);

const attachmentProp = {
  id: PropTypes.string.isRequired,
  attachmentName: PropTypes.string.isRequired,
  attachmentUrl: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(attachmentProp).isRequired,
};

AttachmentBlock.propTypes = {
  attachment: PropTypes.shape(attachmentProp).isRequired,
};

const PaymentsByOption = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id, competitionId } = useParams();

  const [fetchEventDetail, { data }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const { data: participants, loading: participantsLoading } = useQuery(
    PARTICIPANTS_BY_COMPETITIONS,
    {
      variables: { eventCompetitionId: parseInt(competitionId, 10) },
    },
  );

  const { data: attachments, loading: attachmentsLoading } = useQuery(
    FETCH_EVENT_COMPETITION_ATTACHMENTS,
    {
      variables: {
        id: parseInt(competitionId, 10),
      },
    },
  );

  useHeaderComponentsMutation({
    title: get('participantsByCompetition.event_competition_title', participants),
    backLink: `/events/${id}/competitions-report`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  if (attachmentsLoading || participantsLoading) {
    return <Loader />;
  }

  const attachmentsByCode = {};
  attachments.fetchEventCompetitionDetail.allJudgables.forEach((j) => {
    if (j.members?.length > 0) {
      j.members.forEach((member) => {
        attachmentsByCode[splitMistCode(member.code)] = member.attachments;
      });
    } else {
      attachmentsByCode[splitMistCode(j.code)] = j.attachments;
    }
  });

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('participantsByCompetition.joined_count', participants)}
              </p>
              <FontBold>
                <p className="mt-4 text-center text-muted">
                  Joined
                </p>
              </FontBold>
            </div>
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('participantsByCompetition.waitlisted_count', participants)}
              </p>
              <FontBold>
                <p className="mt-4 text-center">
                  Waitlisted
                </p>
              </FontBold>
            </div>
          </div>
          <div className="pt-3" />
          {!isEmpty(get('participantsByCompetition.joined_user', participants))
            ? (
              <>
                <FontBold>
                  <p>
Joined(
                    {get('participantsByCompetition.joined_count', participants)}
)
                  </p>
                </FontBold>
                {get('participantsByCompetition.joined_user', participants).map((item) => (
                  <>
                    <div className="row ml-2 my-2">
                      <div className="col-md-8 font-weight-bold">
                        <div className="row font-weight-bold">
                          {get('user.full_name', item)}
                          {' '}
(
                          {get('user.code', item)}
)
                        </div>
                        <div className="row text-muted mt-n1">
                          {get('school.name', item)}
                        </div>
                        <div className="row text-muted mt-n1">
                          {get('member.reason', item)}
                        </div>
                      </div>
                      <Attachments attachments={attachmentsByCode[item.user.code]} />
                    </div>
                    <DividerLine className="my-3" />
                  </>
                ))}
              </>
            )

            : null}

          {!isEmpty(get('participantsByCompetition.waitlist_user', participants))
            ? (
              <>
                <FontBold className="ml-0">
                  <p>
 Waitlist (
                    {get('participantsByCompetition.waitlisted_count', participants)}
)
                  </p>
                </FontBold>
                {get('participantsByCompetition.waitlist_user', participants).map((item) => (
                  <>
                    <div className="row ml-2 my-2">
                      <div className="col-md-8 font-weight-bold">
                        <div className="row font-weight-bold">
                          {get('user.full_name', item)}
                          {' '}
(
                          {get('user.code', item)}
)
                        </div>
                        <div className="row text-muted mt-n1">
                          {get('school.name', item)}
                        </div>
                        <div className="row text-muted mt-n1">
                          {get('member.reason', item)}
                        </div>
                      </div>
                      <Attachments attachments={attachmentsByCode[item.user.code]} />
                    </div>
                    <DividerLine className="my-3" />
                  </>
                ))}
              </>
            )

            : null}
        </div>
      </div>
    </>
  );
};
export default PaymentsByOption;
