import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const CancelRegistrationOption = (props) => {
  const {
    toggle,
    modal,
    onClick,
    OnSelect,
  } = props;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Cancel Registration Option</ModalHeader>
      <ModalBody>
        <div className="h6">
          Do you want to cancel Event Registration?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClick}>Yes</Button>
        <Button color="secondary" onClick={OnSelect} type="button" className="btn btn-primary" data-dismiss="modal">No</Button>
      </ModalFooter>
    </Modal>
  );
};

CancelRegistrationOption.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  OnSelect: PropTypes.func.isRequired,
};


export default CancelRegistrationOption;
