import React from 'react';
import { Provider } from '../../../Chakra';
import { SpiritPointsReport } from './SpiritPointsReport';


export default () => (
  <Provider>
    <SpiritPointsReport />
  </Provider>
);
