import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_COALITION } from '../../GraphQL/Mutations';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useUpdateCoalitionsCache from '../../Hooks/useUpdateCoalitionsCache';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import { Input } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const FETCH_COMPETITIONS = gql`
query fetchCompetitionsByEvent($eventId: Int!){
  fetchCompetitionsByEvent(eventId: $eventId) {
    id
    title
    fetchCompetition {
      group
      fetchCategory {
        id
        title
      }
    }
  }
}
`;

const FETCH_EVENT_HEADER = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
    }
  }
`;

const eventCompetitionSort = (ec1, ec2) => {
  const cat1Id = ec1.fetchCompetition.fetchCategory.id;
  const cat2Id = ec2.fetchCompetition.fetchCategory.id;
  if (cat1Id === cat2Id) {
    return ec1.title.localeCompare(ec2.title);
  }
  return cat1Id - cat2Id;
};

const CoalitionNew = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const updateCache = useUpdateCoalitionsCache(parseInt(id, 10));
  const checkPermission = useUserPermissionChecker(currentUser);
  const history = useHistory();
  const extractError = useGraphQLErrorExtractor();
  const [schoolIds, setSchoolIds] = useState([]);

  const { params, onChange } = useFormParams({
    eventCompetitionId: '',
  });

  const { data: eventData, loading: eventLoading } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      id,
    },
  });
  const event = eventData?.fetchEventDetail ?? {};
  useHeaderComponentsMutation({
    title: 'ADD COALITION',
    backLink: `/event-manage/${id}/coalitions`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  const [createCoalition, { loading, error: createCoalitionError }] = useMutation(
    CREATE_COALITION,
    {
      onCompleted: (data) => {
        updateCache(data.createCoalition.coalition);
        history.push(`/event-manage/${id}/coalitions/${data.createCoalition.coalition.id}/edit`);
      },
      onError: () => {
        setSchoolIds([]);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Coalition') === undefined) {
        history.push(`/event-manage/${id}/coalitions`);
      }
    }
  }, [currentUser, checkPermission, history, id]);

  const { data, loading: eventCompetitionsLoading } = useQuery(FETCH_COMPETITIONS, {
    variables: {
      eventId: id,
    },
  });

  const onSubmit = () => {
    createCoalition({
      variables: {
        eventCompetitionId: parseInt(params.eventCompetitionId, 10),
        schoolIds,
      },
    });
  };


  const updateSchools = (schoolId, actionType) => {
    if (actionType === 'add') {
      setSchoolIds(schoolIds.concat(schoolId));
    } else {
      setSchoolIds(schoolIds.filter((Id) => Id !== schoolId));
    }
  };

  if (loading || eventCompetitionsLoading || userLoading || eventLoading) {
    return <Loader />;
  }
  if (userError) {
    return <GenericAlert>{extractError(userError)}</GenericAlert>;
  }

  const eventCompetitions = data.fetchCompetitionsByEvent
    .filter((ec) => ec.fetchCompetition.group)
    .sort(eventCompetitionSort);

  return (
    <>
      {createCoalitionError && <GenericAlert>{extractError(createCoalitionError)}</GenericAlert>}
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <Input title="Competition" name="eventCompetitionId" type="select" params={params} id="eventCompetitionId" onChange={onChange}>
            <option value="" disabled>Competitions</option>
            {eventCompetitions && eventCompetitions.map((obj) => (
              <option key={obj.id} value={obj.id}>{obj.title}</option>
            ))}
          </Input>
          <p style={{ fontWeight: '600' }}>Add School to make Coalition</p>
          <Form
            className="pt-3"
            onSubmit={onSubmit}
            formType="new"
            currentUser={currentUser}
            updateSchools={updateSchools}
            eventCompetitionId={params.eventCompetitionId}
          />
        </div>
      </div>
    </>
  );
};
export default CoalitionNew;
