import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import { FETCH_MY_SCHOOLS, SEARCH_SCHOOLS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const SchoolIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);

  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [fetchSchools, { loading, error }] = useLazyQuery(
    FETCH_MY_SCHOOLS, {
      onCompleted: (data) => {
        setFilteredSchools(data.mySchools.filter(({ status }) => status !== 'inactive').sort((a, b) => a.name.localeCompare(b.name)));
      },
    },
  );
  useHeaderComponentsMutation({
    title: 'SCHOOLS',
    backLink: '/dashboard',
  });

  useEffect(() => {
    if (currentUser) {
      fetchSchools({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchSchools]);

  const [schoolsSearch] = useLazyQuery(
    SEARCH_SCHOOLS,
    {
      onCompleted: (data) => {
        setFilteredSchools(data.searchSchools.filter(({ status }) => status !== 'inactive').sort((a, b) => a.name.localeCompare(b.name)));
      },
    },
  );

  const searchSchools = (e) => {
    schoolsSearch({
      variables: {
        query: e.target.value,
        userId: parseInt(currentUser.id, 10),
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <p style={{ display: 'none' }}>{get('firstname', currentUser)}</p>
        <Search
          placeholder="Search"
          onChange={(e) => searchSchools(e)}
        />
        {filteredSchools.length === 0
          ? <p>No Schools</p>
          : null}
        <List data={filteredSchools} displayAttribute="name" redirectLink="schools" redirectOperation="edit" dataType="schools" />
        <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New School" addNewLink="schools/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Approve School'))} />
      </div>
    </div>
  );
};

export default SchoolIndex;
