import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import LeaderBoard from './LeaderBoard';
import LeaderBoardText from './LeaderBoard/Text';
import LEFT_ARROW from '../Images/icons/left-arrow.png';
import { LEFTARROW, EventTitle } from './Auth/Layout';
import { EVENT_TITLE } from '../Constants/AppConstants';

const PageTitle = ({ data }) => {
  const title = getOr(null, 'title', data);
  const backLink = getOr(null, 'backLink', data);
  const eventComponent = getOr([], 'components', data).find((d) => d.key === EVENT_TITLE);
  return (
    <section role="main">
      {
      eventComponent
        ? (
          <EventTitle>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 mx-auto px-4">
                  {eventComponent.value}
                </div>
              </div>
            </div>
          </EventTitle>
        ) : null
    }
      <LeaderBoard>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mx-auto px-4">
              {
              backLink
                ? (
                  <Link to={backLink} replace>
                    <LEFTARROW>
                      <img src={LEFT_ARROW} alt="arrow" className="img-fluid" />
                    </LEFTARROW>
                  </Link>
                ) : null
            }
              {title ? <LeaderBoardText>{title}</LeaderBoardText> : null}
            </div>
          </div>
        </div>
      </LeaderBoard>
    </section>
  );
};

PageTitle.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    backLink: PropTypes.string,
    components: PropTypes.arrayOf(
      PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
    ),
  }),
};

PageTitle.defaultProps = {
  data: null,
};

export default PageTitle;
