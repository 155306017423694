import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_REGION } from '../../GraphQL/Mutations';
import { FETCH_REGION_DETAIL } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Loader from '../../Components/Loader';
import Form from './Form';


const RegionEdit = () => {
  const { currentUser } = useCurrentUser(true);
  const history = useHistory();
  const { id } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [region, setRegion] = useState({});
  const [regionsUsers, setRegionsUsers] = useState([]);
  const { params, onChange, setParams } = useFormParams({
    name: '',
    tier: '',
    stripeAccount: '',
    currency: 'USD',
    timezone: '',
    websiteUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    email: '',
  });
  useHeaderComponentsMutation({ title: 'EDIT REGION' });
  useHeaderComponentsMutation({
    backLink: '/regions',
    title: 'Edit REGION',
  });
  const extractError = useGraphQLErrorExtractor();
  const [updateRegion, { loading: updating, error: updateError }] = useMutation(
    UPDATE_REGION,
    {
      onCompleted: () => {
        history.push('/regions');
      },
    },
  );
  const { loading, error } = useQuery(
    FETCH_REGION_DETAIL,
    {
      onCompleted: (data) => {
        setParams({
          ...data.fetchRegionDetail,
        });
        setRegionsUsers(data.fetchRegionDetail.regionsUsers);
        setRegion(data.fetchRegionDetail);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update Region') === undefined) {
        history.push('/regions');
      }
    }
  }, [currentUser, checkPermission, history]);

  const onSubmit = (e) => {
    e.preventDefault();
    params.id = parseInt(id, 10);
    params.tier = parseInt(params.tierValue, 10);
    updateRegion({
      variables: {
        ...params,
      },
    });
  };

  const onRemove = (returnedUserRegions) => {
    setRegionsUsers(returnedUserRegions);
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {updateError ? <GenericAlert>{extractError(updateError)}</GenericAlert> : null}
          <Form
            setParams={setParams}
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={updating}
            selectedAdmins={regionsUsers}
            onRemove={onRemove}
            formType="edit"
            currentUser={currentUser}
            region={region}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default RegionEdit;
