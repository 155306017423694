import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import {
  isEmpty, getOr, first, get,
} from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter,
} from '../../Components/Auth/Layout';
import Search from '../../Components/Search/Index';
import { FETCH_TEAM_MEMBERS, FETCH_EVENTS_USERS_BY_USERS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import CardList from '../../Components/CardList/index';
import useEventsUserPermissionChecker from '../../Hooks/useEventsUserPermissionChecker';
import BackLink from '../../Components/BackLink';
import useNotEqualFilterArray from '../../Hooks/useNotEqualFilterArray';
import useFilterArray from '../../Hooks/useFilterArray';
import Permissions from '../../Constants/PermissionConstrants';

const ViewMembers = () => {
  useHeaderComponentsMutation({ title: 'VIEW / EDIT MEMBERS' });
  const {
    currentUser,
  } = useCurrentUser(true);
  const notEqualFilterArray = useNotEqualFilterArray();
  const { id } = useParams();
  const { schoolId } = useParams();
  const filterArray = useFilterArray();
  const [searchMembers, setSearchMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);
  const [fetchTeamMembers] = useLazyQuery(
    FETCH_TEAM_MEMBERS, {
      onCompleted: (data) => {
        const filteredMembers = notEqualFilterArray(data.viewMyTeam, 'fetchRole.title', 'Captain');
        setTotalMembers(filteredMembers);
        setSearchMembers(filteredMembers);
      },
      fetchPolicy: 'network-only',
    },
  );

  const [fetchEventsUsersByUsers, { data: dataEventsUsers }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUsers);

  const eventsUser = useMemo(() => first(filterArray(eventsUsers, 'fetchRole.title', 'Captain')), [eventsUsers, filterArray]);

  const checkPermissionofEventsUser = useEventsUserPermissionChecker(eventsUser);

  const hasSchools = !isEmpty(get('fetchSchools', currentUser));
  useEffect(() => {
    if (hasSchools) {
      fetchTeamMembers({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [hasSchools, fetchTeamMembers, id, schoolId]);

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id, schoolId]);

  const searchTeamMembers = (e) => {
    if (e.target.value !== '') {
      setSearchMembers(
        totalMembers.filter(
          (x) => (`${x.fetchUser.firstname} ${x.fetchUser.lastname}`).toLowerCase().includes(
            e.target.value,
          ),
        ),
      );
    } else {
      setSearchMembers(totalMembers);
    }
  };
  return (
    <>
      <div className="mt-4" />
      {!get('teamFinalize', eventsUser) && !isEmpty(totalMembers)
        ? (
          <Search
            placeholder="Search by Name"
            onChange={(e) => searchTeamMembers(e)}
          />
        )
        : null}
      {isEmpty(searchMembers)
        ? (
          <div className="alert alert-warning mt-3" role="alert">
            No Members
          </div>
        )
        : (
          <div className="row">
            <div className="col-md-6 col-6 font-weight-bold">
              Team
            </div>
          </div>
        )}
      <div className="pt-3" />
      {searchMembers.map((member) => (
        <CardList
          member={member}
          checkPermission={!get('teamFinalize', eventsUser) && checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS)}
          editUrl={`/events/${id}/team/${schoolId}/edit?userId=${member.fetchUser.id}`}
        />
      ))}
      <AlignCenter className="m-5">
        <BackLink to={`/event-manage/${id}`}>
       Manage Event
        </BackLink>
      </AlignCenter>
    </>
  );
};

export default ViewMembers;
