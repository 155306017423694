/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {
  TitleC, RIGHTARROW, Hr, RoundImg, YellowText,
} from '../Auth/Layout';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';


const ListItemDetail = (props) => {
  const {
    data,
    redirectLink,
    roleTitle,
    imageAttached,
    userCode,
    subLink,
    roleType,
  } = props;

  return (
    <>
      {data.map((obj) => (
        <div key={obj.id}>
          {obj.fetchComments.length > 0 && subLink
            ? (
              <Link to={`/event-manage/${obj.eventId}/${subLink}/${obj.id}`}>
                <div className="row align-items-center">
                  <div className="col-md-1 col-3 mt-4 px-4">
                    {obj.fetchUser.profileImageUrl
                      ? <RoundImg><img src={obj.fetchUser.profileImageUrl} alt="User-Icon" /></RoundImg>
                      : (
                        <>
                          {imageAttached
                            ? <RoundImg><img src={imageAttached} alt="User-Icon" /></RoundImg>
                            : null}
                        </>
                      )}
                  </div>
                  <div className="col-md-9 col-7 mt-4 px-4">
                    <TitleC>
                      <p>
                        {obj.fetchUser.firstname}
                        {' '}
                        {obj.fetchUser.lastname}
                        {' '}
                        { userCode
                          ? (
                            <span>
                                  (
                              {' '}
                              {' '}
                              {obj.fetchUser.code}
                              {' '}
                                  )
                            </span>
                          )
                          : (
                            <>
                              {roleTitle
                                ? (
                                  <p>
                                    {obj.fetchRole.title}
                                  </p>
                                )
                                : null}
                            </>
                          )}
                        { roleType
                          ? (
                            <p>
                              {obj.fetchRole.title}
                            </p>
                          )
                          : null}
                      </p>
                    </TitleC>
                  </div>
                  <div className="col-md-1 col-2 mt-4">
                    {roleTitle
                      ? (
                        <YellowText>
                          <p>Review</p>
                        </YellowText>
                      )
                      : null}
                  </div>
                  {redirectLink
                    ? (
                      <div className="col-md-1 col-2 mt-4 mx-auto px-4">
                        <RIGHTARROW>
                          <img src={RIGHT_ARROW} alt="arrow" className="img-fluid" />
                        </RIGHTARROW>
                      </div>
                    )
                    : null}
                </div>
              </Link>
            )
            : (
              <Link to={`${redirectLink}/${obj.id}`}>
                <div className="row align-items-center">
                  <div className="col-md-1 col-3 mt-4 px-4">
                    {obj.fetchUser.profileImageUrl
                      ? <RoundImg><img src={obj.fetchUser.profileImageUrl} alt="User-Icon" /></RoundImg>
                      : (
                        <>
                          {imageAttached
                            ? <RoundImg><img src={imageAttached} alt="User-Icon" /></RoundImg>
                            : null}
                        </>
                      )}
                  </div>
                  <div className="col-md-9 col-7 mt-4 px-4">
                    <TitleC>
                      <p>
                        {obj.fetchUser.firstname}
                        {' '}
                        {obj.fetchUser.lastname}
                        {' '}
                        { userCode
                          ? (
                            <span>
                                  (
                              {' '}
                              {' '}
                              {obj.fetchUser.code}
                              {' '}
                                  )
                            </span>
                          )
                          : (
                            <>
                              {roleTitle
                                ? (
                                  <p>
                                    {obj.fetchRole.title}
                                  </p>
                                )
                                : null}
                            </>
                          )}
                        { roleType
                          ? (
                            <p>
                              {obj.fetchRole.title}
                            </p>
                          )
                          : null}
                      </p>
                    </TitleC>
                  </div>
                  <div className="col-md-1 col-2 mt-4">
                    {roleTitle
                      ? (
                        <YellowText>
                          <p>Review</p>
                        </YellowText>
                      )
                      : null}
                  </div>
                  {redirectLink
                    ? (
                      <div className="col-md-1 col-2 mt-4 mx-auto px-4">
                        <RIGHTARROW>
                          <img src={RIGHT_ARROW} alt="arrow" className="img-fluid" />
                        </RIGHTARROW>
                      </div>
                    )
                    : null}
                </div>
              </Link>
            )}
          <br />
          <Hr />
        </div>
      ))}
    </>
  );
};

ListItemDetail.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  redirectLink: PropTypes.string.isRequired,
  imageAttached: PropTypes.string,
  roleTitle: PropTypes.bool,
  userCode: PropTypes.bool,
  subLink: PropTypes.string,
  roleType: PropTypes.bool,
};

ListItemDetail.defaultProps = {
  imageAttached: null,
  roleTitle: null,
  userCode: null,
  subLink: null,
  roleType: null,
};

export default ListItemDetail;
