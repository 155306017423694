import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import Footer from '../../Components/Layouts/Footer';
import Header from '../../Components/Layouts/Header';
import Sidebar from '../../Components/Layouts/Sidebar';
import { HEADER_COMPONENTS } from '../../GraphQL/Queries';
import { DODGER_BLUE_HEADER } from '../../Constants/AppConstants';
import DodgerBlueInfo from '../../Components/Auth/DodgerBlueInfo';
import LeaderBoard from '../../Components/LeaderBoard';
import LeaderBoardText from '../../Components/LeaderBoard/Text';

const UnAuthenticated = ({ children }) => {
  const { data } = useQuery(HEADER_COMPONENTS);
  const showDodger = getOr([], 'components', data).find((d) => d.key === DODGER_BLUE_HEADER);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const changeSideBarVisibility = () => {
    setSideBarVisible(!sideBarVisible);
  }

  return (
    <>
      <main className="d-flex align-items-stretch flex-grow-1">
        <Sidebar visible={sideBarVisible} changeSideBarVisibility={changeSideBarVisibility} />
        <div className="flex-grow-1">
          <Header toggleSidebar={changeSideBarVisibility} />
          <section data-role="layout" role="main" className="text-center">
            {showDodger ? <DodgerBlueInfo /> : null}
            <LeaderBoard>
              <LeaderBoardText>{getOr('', 'title', data)}</LeaderBoardText>
            </LeaderBoard>
          </section>
          <div className="container visible-xs" data-role="content">
            <div className="row">
              <div className="col-12 mx-auto px-4">
                {children}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};


UnAuthenticated.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default UnAuthenticated;
