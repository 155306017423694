const Permissions = {
  CAN_JOIN_EVENT: 'Join_Event',
  CAN_JOIN_COMPETITION: 'Join Competition',
  CAN_MANAGE_TEAM_MEMBERS: 'Manage Team Members',
  CAN_VIEW_EVENT: 'View Event',
  CAN_VIEW_COALITION: 'View Coalition',
  CAN_VIEW_COUPON: 'View Coupon',
  CAN_ADD_EVENT: 'Add Event',
  CAN_ADD_REQUEST_COALITION: 'Add Request Coalition',
  CAN_JOIN_SCHOOL: 'Join School Required',
  CAN_MANAGE_REGION: 'Region_Admin',
  CAN_ADD_ROLE: 'Add Role',
  CAN_JOIN_SCHOOL_OPTIONAL: 'Join School Optional',
  CAN_JOIN_JUDGE_COMPETITION: 'Judge Competition',
  CAN_VIEW_JUDGE: 'View Judge',
  CAN_ADD_JUDGE: 'Add Judge',
  CAN_INVITE_TEAM_MEMBER: 'Invitation',
  CAN_JOIN_MULTIPLE_SCHOOL: 'Join_Multiple_School',
  CAN_APPROVE_SCHOOL: 'Approve School',
  CAN_VIEW_REPORT_PAYMENT: 'Reports_payment',
  CAN_VIEW_REPORT_COUPON: 'Reports_coupon',
  CAN_VIEW_REPORT_COMPETITION: 'Reports_competition',
  CAN_VIEW_COMPETITION_ROSTER: 'Competition - Roster',
  CAN_MANAGE_COMPETITION: 'Competition - Admin',
  CAN_VIEW_SCORE: 'Score - View',
  CAN_EXPORT_SCORE: 'Score - Export',
  CAN_VIEW_REPORT_SPECIAL_NEED: 'Reports_special_need',
  CAN_COACH_ANOTHER_TEAM: 'Team - Chaperon',
  CAN_VIEW_COACH: 'Coach - View',
};

export default Permissions;
