import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import { FETCH_EVENT_DETAIL } from '../../GraphQL/Queries';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import JoinEventRegistration from './JoinEventRegistration';
import { EVENT_TITLE } from '../../Constants/AppConstants';


const JoinEvent = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();

  const [selectedRegistrationOption, setSelectedRegistrationOption] = useState({});
  const extractError = useGraphQLErrorExtractor();
  const [fetchEventDetail, { data: dataFetchEventDetail, loading, error }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );
  const event = getOr({}, 'fetchEventDetail', dataFetchEventDetail);

  useHeaderComponentsMutation({
    title: 'REGISTRATION',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);


  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <>
      <div className="row py-5">
        <div className="col-12 mx-auto px-4">
          <JoinEventRegistration
            event={event}
            selectedRegistrationOption={selectedRegistrationOption}
            onSelect={setSelectedRegistrationOption}
          />
        </div>
      </div>
    </>
  );
};
export default JoinEvent;
