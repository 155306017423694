import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { PrimaryButton } from '../../../Components/Auth/Layout';
import {
  CREATE_ANNOUNCEMENT,
} from '../../../GraphQL/Mutations';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';
import { FETCH_EVENT_TEAMS, FETCH_ROLES } from '../../../GraphQL/Queries';
import Loader from '../../../Components/Loader';

const competitionComponentPropTypes = {
  competitionList: PropTypes.arrayOf(Object).isRequired,
  setTargetIds: PropTypes.func.isRequired,
};

const roleComponentPropTypes = {
  roleList: PropTypes.arrayOf(Object).isRequired,
  setTargetIds: PropTypes.func.isRequired,
};

const schoolComponentPropTypes = {
  schoolList: PropTypes.arrayOf(Object).isRequired,
  setTargetIds: PropTypes.func.isRequired,
};

const AddAllSelectDropdown = React.forwardRef(() => (
  <>
    {' '}
  </>
));

const AddCompetitionSelectDropdown = React.forwardRef(({ competitionList, setTargetIds }) => (
  <select
    style={{ width: '100%' }}
    onChange={(e) => {
      setTargetIds([e.target.value]);
    }}
  >
    <option disabled selected value> -- select a competition --</option>
    {competitionList.map((competition) => (
      <option key={competition.id} value={competition.id}>{competition.title}</option>
    ))}
    {' '}
  </select>
));
AddCompetitionSelectDropdown.propTypes = competitionComponentPropTypes;

const AddRoleSelectDropdown = React.forwardRef(({ roleList, setTargetIds }) => (
  <select
    style={{ width: '100%' }}
    onChange={(e) => {
      setTargetIds([e.target.value]);
    }}
  >
    <option disabled selected value> -- select a role --</option>
    {roleList.map((role) => (
      <option key={role.id} value={role.id}>{role.title}</option>
    ))}
    {' '}
  </select>
));
AddRoleSelectDropdown.propTypes = roleComponentPropTypes;

const AddSchoolSelectDropdown = React.forwardRef(({ schoolList, setTargetIds }) => (
  <select
    style={{ width: '100%' }}
    onChange={(e) => {
      setTargetIds([e.target.value]);
    }}
  >
    <option disabled selected value> -- select a school --</option>
    {schoolList.map((school) => (
      <option key={school.id} value={school.id}>{school.name}</option>
    ))}
    {' '}
  </select>
));
AddSchoolSelectDropdown.propTypes = schoolComponentPropTypes;

const TARGET_TYPE_COMPONENTS = {
  all: AddAllSelectDropdown,
  school: AddSchoolSelectDropdown,
  competition: AddCompetitionSelectDropdown,
  role: AddRoleSelectDropdown,
};

const validateLink = (link) => {
  if (link === '') return true;
  return link.startsWith('https://');
};

const validateParams = (title, body, targetType) => !(title === '' || body === '' || targetType === '');

const extractError = useGraphQLErrorExtractor();
const AddAnnouncementRow = ({
  eventId, event, onError, onCreate,
}) => {
  const isImportant = false;
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [link, setLink] = useState('');
  const [targetType, setTargetType] = useState('');
  const [targetIds, setTargetIds] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const { data } = useQuery(FETCH_ROLES, {
    variables: {
      tenantId: 1,
    },
  });
  const roleList = data?.fetchRoles
    .sort((a, b) => (a.id - b.id < 0 ? -1 : 1)) // sort so it shows up nicely
    .filter(({ id }) => id > 1) // no one can send notifs to role with id 1 (Super Admin role)
    ?? [];

  const competitionList = event?.fetchEventCompetitions?.map((comp) => (
    { title: comp.title, id: comp.id })) ?? [];
  const schoolQuery = useQuery(FETCH_EVENT_TEAMS, {
    variables: {
      eventId,
    },
    onCompleted: (data) => {
      setSchoolList(data?.eventTeams);
    },
    onError: (err) => {
      onError(extractError(err));
    },
  });
  const schoolLoading = schoolQuery.loading;
  const [createAnnouncement, { loading }] = useMutation(
    CREATE_ANNOUNCEMENT,
    {
      onCompleted: () => {
        setTitle('');
        setBody('');
        setLink('');
      },
      onError: (err) => {
        onError(extractError(err));
      },
    },
  );

  const titleInvalid = title?.trim().length === 0;
  const paramsInvalid = !validateParams(title, body, targetType);
  const linkInvalid = !validateLink(link);

  if (schoolLoading) {
    return <Loader />;
  }
  const onSave = () => {
    createAnnouncement({
      variables: {
        input: {
          title,
          body,
          link,
          eventId,
          targetType,
          targetIds,
          isImportant,
        },
      },
    }).then((data) => {
      onCreate(data.data.createAnnouncement.announcement);
    });
  };

  const AddAnnouncementComponent = TARGET_TYPE_COMPONENTS[targetType];

  return (
    <div className="col-10 mx-2">
      <h4>Add new:</h4>
      <div className="row my-2 justify-content-center align-items-center">
        <div className="col-2 mx-2">
          Title:
        </div>
        <div className="col-8 mx-2">
          <input style={{ width: '100%' }} value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="col-2 mx-2 py-4">
            Body:
        </div>
        <div className="col-8 mx-2">
          <textarea style={{ width: '100%' }} value={body} onChange={(e) => setBody(e.target.value)} />
        </div>
        <div className="col-2 mx-2">
          Link (Optional, must start with https://):
        </div>
        <div className="col-8 mx-2">
          <input
            style={{ width: '100%' }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
        <div className="row col-8 my-2 justify-content-center align-items-center">
          <select
            style={{ width: '100%' }}
            onChange={(e) => {
              setTargetType(e.target.value);
            }}
          >
            <option disabled selected> -- select a target --</option>
            {Object.keys(TARGET_TYPE_COMPONENTS).map((resourceType) => (
              <option key={resourceType} value={resourceType}>{resourceType}</option>
            ))}
          </select>
          <div className="my-2" style={{ width: '100%' }}>
            {targetType && (
            <AddAnnouncementComponent
              schoolList={schoolList}
              roleList={roleList}
              competitionList={competitionList}
              setTargetIds={setTargetIds}
            />
            )}
          </div>
        </div>
      </div>
      <div className="row m-2 justify-content-center">
        <div className="col-4 mx-2 my-2">
          <PrimaryButton
            backgroundcolor="#F4AB37"
            fontcolor="#FFF"
            border="none"
            onClick={onSave}
            disabled={loading || titleInvalid || paramsInvalid || linkInvalid}
          >
            {loading ? 'Submitting...' : 'Save'}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

AddAnnouncementRow.propTypes = {
  eventId: PropTypes.number.isRequired,
  event: PropTypes.shape({
    fetchEventCompetitions: PropTypes.object.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AddAnnouncementRow;
