import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { get } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter, CardBorder, ImgSquareViewMember,
} from '../../Components/Auth/Layout';
import { FETCH_COACH } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import BackLink from '../../Components/BackLink';
import userDefaultImage from '../../Images/team-dum.png';

const ViewEditMembers = () => {
  useHeaderComponentsMutation({ title: 'COACHES' });
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { schoolId } = useParams();
  const [coaches, setCoaches] = useState([]);
  const [fetchCoach] = useLazyQuery(
    FETCH_COACH, {
      onCompleted: (data) => {
        setCoaches(data.fetchCoach);
      },
    },
  );
  useEffect(() => {
    if (currentUser) {
      fetchCoach({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchCoach, id, schoolId]);

  return (
    <>
      {coaches.length === 0
        ? (
          <div className="alert alert-warning mt-3" role="alert">
              No Coaches
          </div>
        )
        : null}
      <div className="mt-4" />
      {coaches && coaches.map((member) => (
        <CardBorder>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1 col-3 pl-0 ">
                <ImgSquareViewMember>
                  {get('profileImageUrl', member.fetchUser)
                    ? (<img className="img-fluid" src={get('profileImageUrl', get('fetchUser', member))} alt="Coach" />)
                    : (<img src={userDefaultImage} alt="User-Icon" />)}
                </ImgSquareViewMember>
              </div>
              <div className="col-md-11 col-9 pt-2 pb-2 ">
                <div className="row">
                  <div className=" font-weight-bold col-md-6 col-6 small pl-0">
                    {get('firstname', member.fetchUser)}
                    {get('lastname', member.fetchUser)}
                  </div>
                </div>
                <div className="row small mt-3">
                  {get('email', get('fetchUser', member))}
                </div>
                <div className="row small">
                  {get('phone', get('fetchUser', member))}
                </div>
              </div>
            </div>
          </div>
        </CardBorder>
      ))}
      <AlignCenter className="m-5">
        <BackLink to={`/event-manage/${id}`}>
          Manage Event
        </BackLink>
      </AlignCenter>
    </>
  );
};
export default ViewEditMembers;
