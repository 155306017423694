import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import { get, getOr } from 'lodash/fp';
import Input from '../../Components/Form/Input';
import Form from '../../Components/Form';
import useFormParams from '../../Hooks/useFormParams';
import {
  FontBold, Hr, PrimaryButton, LinkButton,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT,
  TEAM_CAPTAIN,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import {
  REASSIGN_CAPTAIN,
} from '../../GraphQL/Mutations';
import useUpdateTeamCaptianCache from '../../Hooks/useUpdateTeamCaptianCache';

const ReassignCaptainByAdmin = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const { schoolId } = useParams();
  const updateCache = useUpdateTeamCaptianCache(eventId, schoolId);
  const checkPermission = useUserPermissionChecker(currentUser);

  const [fetchTeamCaptian, { data: dataTeamCaptian }] = useLazyQuery(
    TEAM_CAPTAIN,
  );
  const teamCaptian = getOr([], 'fetchTeamCaptian', dataTeamCaptian);

  const [fetchEventSchoolStudents, { data: studentsData }] = useLazyQuery(
    gql`query fetchEventSchoolStudents($eventId: Int!, $schoolId: Int!){
      fetchEventSchoolStudents(eventId: $eventId, schoolId: $schoolId){
        id
        fetchUser { firstname lastname }
      }
    }`,
  );
  const students = getOr([], 'fetchEventSchoolStudents', studentsData);

  const [fetchEventSchoolCoaches, { data: coachesData }] = useLazyQuery(
    gql`query fetchEventSchoolCoaches($eventId: Int!, $schoolId: Int!){
      fetchEventSchoolCoaches(eventId: $eventId, schoolId: $schoolId){
        id
        fetchUser { firstname lastname }
      }
    }`,
  );
  const coaches = getOr([], 'fetchEventSchoolCoaches', coachesData);
  const participants = [...students, ...coaches];

  const { params, onChange } = useFormParams({
    eventUserId: '',
  });

  const { data1 } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data1);

  useEffect(() => {
    if (currentUser) {
      fetchTeamCaptian({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchTeamCaptian, schoolId, eventId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventSchoolStudents({
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
      fetchEventSchoolCoaches({
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [fetchEventSchoolStudents, fetchEventSchoolCoaches, currentUser, eventId, schoolId]);

  useHeaderComponentsMutation({
    title: 'REASSIGN CAPTAIN',
    backLink: `/event/${eventId}/teams/${schoolId}`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  const onCancel = () => {
    history.push(`/event/${eventId}/teams/${schoolId}`);
  };

  const [reassignCaptain, { loading }] = useMutation(
    REASSIGN_CAPTAIN,
    {
      onCompleted: (data) => {
        updateCache(data.reassignCaptain.eventsUser, 'update');
        history.push(`/event/${eventId}/teams/${schoolId}`);
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    reassignCaptain({
      variables: {
        eventId: parseInt(eventId, 10),
        schoolId: parseInt(schoolId, 10),
        eventsUserId: parseInt(params.eventUserId, 10),
      },
    });
  };

  return (
    <>
      <div className="mt-4" />
      <div>
        {checkPermission(Permissions.CAN_MANAGE_REGION)
          ? (
            <>
              <FontBold className="pt-3">
                <p>Current Captain</p>
              </FontBold>
              <p className="font-weight-bold pl-2 h6">
                {get('firstname', teamCaptian.fetchUser)}
                {' '}
                {get('lastname', teamCaptian.fetchUser)}
                {' '}
              </p>
              <hr />
              <Form onSubmit={onSubmit}>
                <Input title="Select Captain" name="eventUserId" type="select" params={params} id="eventUserId" required onChange={onChange}>
                  <option value="" disabled>Select Captain</option>
                  {participants.map((obj) => (
                    <option key={obj.id} value={obj.id}>{`${get('firstname', obj.fetchUser)} ${get('lastname', obj.fetchUser)}`}</option>
                  ))}
                </Input>
                <div className="row align-items-center">
                  <div className="col-6 mx-auto">
                    <div className="pt-3">
                      <LinkButton className onClick={() => onCancel()}>Cancel</LinkButton>
                    </div>
                  </div>
                  <div className="col-6 mx-auto">
                    <PrimaryButton loading={loading} backgroundcolor="#343434" htmltype="submit">Save</PrimaryButton>
                  </div>
                </div>
              </Form>
            </>
          )
          : null}
      </div>
      <Hr />
    </>
  );
};
export default ReassignCaptainByAdmin;
