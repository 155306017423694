import { compareAsc } from 'date-fns';

export const SCHEDULE_EVENT_TYPES = [
  'Competition',
  'Workshop',
  'Other',
];

export const SCHEDULE_BORDER_COLOR = {
  competition: '#008DC5',
  workshop: '#53A748',
  other: '#F49030',
};


export const EMPTY_SCHEDULE = {
  type: undefined,
  title: undefined,
  description: '',
  startTime: undefined,
  endTime: undefined,
  associatedEventCompetitionId: undefined,
  locationId: undefined,
  room: undefined,
};

const isScheduleValid = (schedule) => {
  const allFieldsDefined = (
    schedule.title !== undefined
    && schedule.type !== undefined
    && schedule.startTime !== undefined
    && schedule.endTime !== undefined
    && schedule.locationId !== undefined
    && (schedule.type !== 'competition' || schedule.associatedEventCompetitionId !== undefined));

  return allFieldsDefined && schedule.startTime < schedule.endTime;
};

export const isScheduleInvalid = (schedule) => !isScheduleValid(schedule);

export const scheduleEventsComparator = (e1, e2) => {
  if (e1.startTime !== e2.startTime) {
    return compareAsc(new Date(e1.startTime), new Date(e2.startTime));
  }
  if (e1.endTime !== e2.endTime) {
    return compareAsc(new Date(e1.endTime), new Date(e2.endTime));
  }
  return e1.title.localeCompare(e2.title);
};
