import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';

import { CREATE_EVENT_SETTING } from '../../GraphQL/Mutations';
// import { FETCH_EVENT_DETAIL } from '../../GraphQL/Queries';

import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { Button, Checkbox } from '../../Components/Form';
import {
  FormTitle,
  LinkButton,
} from '../../Components/Auth/Layout';

const FETCH_EVENT_SETTINGS_DETAIL = gql`
  query fetchEventSettingsDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      allowCoalition
      competeAdditionalCompetition
      competitionPerCategory
      enableMinMax
      qualifiedAttendingPreviousEvent
      restrictToRegion
      captainsCanApprove
      penalizeAbsentees
      allowsMultipleSports
    }
  }
`;


const Settings = () => {
  const history = useHistory();
  const { id } = useParams();
  const { currentUser } = useCurrentUser(true);
  const [createEventSetting, { loading, error }] = useMutation(
    CREATE_EVENT_SETTING,
    {
      onCompleted: () => {
        history.goBack();
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: `/event-detail/${id}`,
    title: 'Event Settings',
  });

  const { params, onChange, setParams } = useFormParams({
    enableMinMax: false,
    allowCoalition: false,
    restrictToRegion: false,
    competitionPerCategory: false,
    competeAdditionalCompetition: false,
    qualifiedAttendingPreviousEvent: false,
  });
  const extractError = useGraphQLErrorExtractor();

  const [fetchEventDetail] = useLazyQuery(
    FETCH_EVENT_SETTINGS_DETAIL, {
      onCompleted: (eventData) => {
        setParams(eventData.fetchEventDetail);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const onSubmit = (e) => {
    e.preventDefault();
    createEventSetting({
      variables: {
        ...params,
        id: parseInt(id, 10),
      },
    });
  };

  const onCancel = () => {
    history.push(`/event-detail/${id}`);
  };


  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}

          <Form onSubmit={onSubmit}>
            <div className="column pt-3">
              <div className="pt-3" />
              <FormTitle color="#000">Event Settings</FormTitle>
              <div className="pt-3" />
            </div>
            <Checkbox
              title="Allow Coalition"
              id="allowCoalition"
              name="allowCoalition"
              params={params}
              onChange={onChange}
              text="Allow Coalition"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Restrict to Region"
              id="restrictToRegion"
              name="restrictToRegion"
              params={params}
              onChange={onChange}
              text="Restrict to Region"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Student may select a max of one competition per category"
              id="competitionPerCategory"
              name="competitionPerCategory"
              params={params}
              onChange={onChange}
              text="Student may select a max of one competition per category"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Student must compete in at least one additional competition if Basketball is selected"
              id="competeAdditionalCompetition"
              name="competeAdditionalCompetition"
              params={params}
              onChange={onChange}
              text="Student must compete in at least one additional competition if Basketball is selected"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Students may compete in multiple sports"
              id="allowsMultipleSports"
              name="allowsMultipleSports"
              params={params}
              onChange={onChange}
              text="Students may compete in multiple sports"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Enable min/max settings"
              id="enableMinMax"
              name="enableMinMax"
              params={params}
              onChange={onChange}
              text="Enable min/max settings"
              setParams={setParams}
              checkboxAlignment="left"
            />
            {/* TODO: implement this logic in the backend */}
            {/* <Checkbox
              title="Captains can approve users to join their team"
              id="captainsCanApprove"
              name="captainsCanApprove"
              params={params}
              onChange={onChange}
              text="Captains can approve users to join their team"
              setParams={setParams}
              checkboxAlignment="left"
            /> */}
            <Checkbox
              title="Penalize students who do not show up for their competitions"
              id="penalizeAbsentees"
              name="penalizeAbsentees"
              params={params}
              onChange={onChange}
              text="Penalize students who do not show up for their competitions"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Checkbox
              title="Student must have qaulified by attending previous event"
              id="qualifiedAttendingPreviousEvent"
              name="qualifiedAttendingPreviousEvent"
              params={params}
              onChange={onChange}
              text="Student must have qualified by attending previous event"
              setParams={setParams}
              checkboxAlignment="left"
            />
            <Button backgroundcolor="#F4AB37" loading={loading} htmltype="submit">Save and Continue</Button>
          </Form>
        </div>
      </div>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <LinkButton onClick={() => onCancel()}>Back</LinkButton>
        </div>
      </div>
      <hr />
    </>
  );
};
export default Settings;
