import React,  { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import userDefaultImage from '../../Images/icons/userIcon.png';
import MistLogo from '../../Images/icons/mymist_logo.png';
import menu from '../../Images/icons/menu.png';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  ImgCircular,
} from '../Auth/Layout';


const Wrapper = styled.div`
  background: #FFF;
`;
const Button = styled.button`
  border:white;
  background-color:white;
`;
const Header = ({ toggleSidebar }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
          return () => {
              window.removeEventListener('resize', handleWindowSizeChange);
          }
      }, []);

  let notMobile = (width > 768);

  const {
    currentUser,
  } = useCurrentUser(false);

  return (
    <>
      <Wrapper>
        <header data-role="header">
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              {notMobile ? <div style={{width: "45px"}}></div> : 
              <Button value="open" onClick={toggleSidebar}><img src={menu} alt="cross" /></Button>}
              <div className="navbar-brand m-0">
                {currentUser
                  ? (
                    <Link to="/dashboard">
                      <img src={MistLogo} alt="logo" className="img-fluid" />
                    </Link>
                  )
                  : (
                    <Link to="/">
                      <img src={MistLogo} alt="logo" className="img-fluid" />
                    </Link>
                  )}
              </div>
              <div className="User-Icon">
                {currentUser
                  ? (
                    <Link to="/me">
                      {currentUser.profileImageUrl
                        ? (
                          <ImgCircular>
                            <img src={currentUser.profileImageUrl} alt="User-Icon" />
                          </ImgCircular>
                        )
                        : <img src={userDefaultImage} alt="User-Icon" />}
                    </Link>
                  )
                  : (
                    <Link to="/login">
                      <Button value="open"><img src={userDefaultImage} alt="User-Icon" /></Button>
                    </Link>
                  )}
              </div>
            </div>
          </nav>
        </header>
      </Wrapper>
    </>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default Header;
