/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {
  TitleC, RIGHTARROW, Hr, ListItemStatus,
} from '../Auth/Layout';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';


const NestedList = (props) => {
  const {
    data,
    displayAttribute,
    redirectLink,
    redirectOperation,
    optionalId,
  } = props;

  return (
    <>
      {data.map((obj) => (
        <div key={obj.id}>
          { obj.school
            ? (
              <Link to={`/${redirectLink}/${optionalId}/${redirectOperation}/${obj.school.id}`}>
                <div className="row align-items-center">
                  <div className="col-10 mt-4 mx-auto px-4">
                    <TitleC>
                      <p>
                        {obj.school[displayAttribute]}
                      </p>
                    </TitleC>
                  </div>
                  <div className="col-1 mt-4 mx-auto px-4">
                    <ListItemStatus>
                      <span>{obj.status}</span>
                    </ListItemStatus>
                  </div>
                  <div className="col-1 mt-4 mx-auto px-4">
                    <RIGHTARROW>
                      <img src={RIGHT_ARROW} alt="arrow" className="img-fluid" />
                    </RIGHTARROW>
                  </div>
                </div>
              </Link>
            )
            : (
              <Link to={`/${redirectLink}/${optionalId}/${redirectOperation}/${obj.id}`}>
                <div className="row align-items-center">
                  <div className="col-10 mt-4 mx-auto px-4">
                    <TitleC>
                      <p>
                        {obj[displayAttribute]}
                      </p>
                    </TitleC>
                  </div>
                  <div className="col-2 mt-4 mx-auto px-4">
                    <RIGHTARROW>
                      <img src={RIGHT_ARROW} alt="arrow" className="img-fluid" />
                    </RIGHTARROW>
                  </div>
                </div>
              </Link>
            )}
          <Hr />
        </div>
      ))}
    </>
  );
};

NestedList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  displayAttribute: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  redirectOperation: PropTypes.string.isRequired,
  optionalId: PropTypes.number,
};

NestedList.defaultProps = {
  optionalId: null,
};

export default NestedList;
