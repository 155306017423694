/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import groupBy from 'lodash/groupBy';
import { UPDATE_EVENT_COMPETITION } from '../../GraphQL/Mutations';
import { FETCH_COMPETITION_LIST, FETCH_COMPETITIONS_BY_EVENT } from '../../GraphQL/Queries';
import {
  PrimaryButton, LinkButton, FormTitle, InnerFormTitle,
} from '../../Components/Auth/Layout';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFormParams from '../../Hooks/useFormParams';
import { Checkbox } from '../../Components/Form';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useUpdateEventCompetitionsCache from '../../Hooks/useUpdateEventCompetitionsCache';

const COMPETITION_STATES = {
  ACTIVE: 2,
  INACTIVE: 3,
};

const competitionTitleSort = (titleA, titleB) => {
  if (
    (titleA.startsWith('**') && titleB.startsWith('**')) ||
    (!titleA.startsWith('**') && !titleB.startsWith('**'))
  ) {
    // both pilots or neither pilot
    return titleA.localeCompare(titleB);
  }
  // reverse order so the ** ends up at the end
  return -1 * titleA.localeCompare(titleB);
};

const UpdateEventCompetitions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const [addedCompetitionsArray, setAddedCompetitionsArray] = useState([]);
  const updateCache = useUpdateEventCompetitionsCache(getOr(0, 'tenantId', currentUser));
  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();
  useHeaderComponentsMutation({ title: 'Select Competitions', backLink: `/event-manage/${id}` });
  const { params, onChange, setParams } = useFormParams({

  });


  const [updateEventCompetition, { loading, error }] = useMutation(
    UPDATE_EVENT_COMPETITION,
    {
      onCompleted: (data) => {
        updateCache(data.updateEventCompetition.eventCompetition);
        history.goBack();
      },
    },
  );

  const [fetchCompetitionList, { data: competitionData }] = useLazyQuery(
    FETCH_COMPETITION_LIST,
  );

  const competitions = getOr([], 'listCompetitions', competitionData);

  const [fetchEventCompetitions] = useLazyQuery(
    FETCH_COMPETITIONS_BY_EVENT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setAddedCompetitionsArray(data.fetchCompetitionsByEvent);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchCompetitionList({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchCompetitionList]);

  useEffect(() => {
    if (parseInt(id, 10)) {
      fetchEventCompetitions({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [id, fetchEventCompetitions]);


  const onSubmit = (e) => {
    e.preventDefault();
    const eventCompetitions = Object.entries(params).map(([competitionId, state]) => ({
      competitionId,
      status: state ? COMPETITION_STATES.ACTIVE : COMPETITION_STATES.INACTIVE,
    }));
    updateEventCompetition({
      variables: {
        tenantId: currentUser.tenantId,
        eventId: parseInt(id, 10),
        eventCompetitions,
      },
    });
  };

  const getEventCompetitionObj = (competitionid) => addedCompetitionsArray.filter(
    ({ competitionId }) => competitionId === parseInt(competitionid, 10),
  )[0];

  const checkEventCompetition = (competitionid) => get('status', getEventCompetitionObj(competitionid)) === 'active';

  const onCancel = () => {
    history.push(`/event-detail/${id}`);
  };

  const competitionsByCategory = groupBy(competitions, (counter) => counter.categoryId);

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <Form onSubmit={onSubmit}>
        <div className="column pt-3">
          <div className="pt-3" />
          <FormTitle color="#000">Select Competitions</FormTitle>
        </div>
        <div className="row py-5">
          <div className="col-12 mx-auto px-4">
            {Object.keys(competitionsByCategory).map((categoryId, index) => (
              <InnerFormTitle key={categoryId}>
                <p>
                  {get('title', get('fetchCategory', competitionsByCategory[categoryId][0]))}
                </p>
                {Object.values(competitionsByCategory)[index]
                  .sort((a, b) => competitionTitleSort(a.title, b.title))
                  .map((competition) => (
                    <div key={`${competition.id}`}>
                      <Checkbox
                        id={competition.id}
                        name={competition.id}
                        params={params}
                        onChange={onChange}
                        setParams={setParams}
                        text={competition.title}
                        subtext={`min: ${competition.minTeam}, max: ${competition.maxTeam}, ${competition.group ? 'group comp' : 'individual comp'}${competition.onlineSubmission ? ', requires online submission' : ''}`}
                        checked={checkEventCompetition(competition.id)}
                      />
                    </div>
                  ))}
              </InnerFormTitle>
            ))}
            <div className="w-100">
              <PrimaryButton loading={loading} htmltype="submit">Save and Continue</PrimaryButton>
              <div className="pt-3">
                <LinkButton className onClick={() => onCancel()}>Cancel</LinkButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
export default UpdateEventCompetitions;
