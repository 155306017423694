/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { omit, getOr } from 'lodash/fp';
import { FormGroup, Label } from 'reactstrap';
import Title from './Title';
import { StyledInput } from '../Auth/Layout';

const FormInput = (props) => {
  const {
    withFormGroup,
    title,
    label,
    id,
    labelClass,
    children,
    name,
    required,
    params,
    onChange,
    info,
  } = props;
  const onValueChange = (e) => {
    onChange(e.target.name, e.target.value);
  };
  const inputProps = omit(['withFormGroup', 'label', 'labelClass', 'children', 'params'])(props);
  const field = <StyledInput {...inputProps} onChange={onValueChange} value={getOr('', name, params)}>{children}</StyledInput>;

  const inputField = (
    <>
      {
        label
          ? (
            <>
              <Label htmlFor={id} className={labelClass}>
                {label}
              </Label>
              {field}
            </>
          ) : field
      }
    </>
  );
  return (
    <>
      {
        <Title>
          <p className="mt-4">
            {title}
            {required === true
              ? <span style={{ color: 'red' }}> *</span>
              : null}
          </p>
        </Title>
      }
      {
        info ? (<p>{info}</p>) : null
      }
      {
        withFormGroup
          ? (<FormGroup>{inputField}</FormGroup>)
          : inputField
      }
    </>
  );
};

FormInput.propTypes = {
  withFormGroup: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FormInput.defaultProps = {
  withFormGroup: true,
  title: null,
  label: null,
  labelClass: 'sr-only',
  children: null,
  required: false,
};

export default FormInput;
