import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const PopUpModal = (props) => {
  const {
    toggle,
    isOpen,
    onAccept,
    onCancel,
    headerText,
    bodyText,
    unpaidUserCount,
    pendingRequestCount,
    pendingInvitationRequestCount,
    pendingTermAndConditionCount,
    eventId,
    schoolId,
    finalTeamMember,
    isPaidUser,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
      <ModalBody>
        {unpaidUserCount !== 0
          ? (
            <Link to={`/event-manage/${eventId}/unpaid-users`}>
              <div className="h6">
                {`You have ${unpaidUserCount} unpaid team member(s).`}
              </div>
            </Link>
          )
          : null}
        {pendingRequestCount !== 0
          ? (
            <Link to={`/event-manage/${eventId}/team-member-requests/${schoolId}/requests`}>
              <div className="h6">
                {`You have ${pendingRequestCount} pending request(s).`}
              </div>
            </Link>
          )
          : null}
        {pendingInvitationRequestCount !== 0
          ? (
            <Link to={`/event-manage/${eventId}/review-invites`}>
              <div className="h6">
                {`You have ${pendingInvitationRequestCount} pending invitation(s).`}
              </div>
            </Link>
          )
          : null}
        {pendingTermAndConditionCount !== 0
          ? (
            <Link to={`/event-manage/${eventId}/pending-term-condition-users`}>
              <div className="h6">
                {`You have ${pendingTermAndConditionCount} teammate(s) pending terms and condition.`}
              </div>
            </Link>
          )
          : null}
        {unpaidUserCount === 0 && pendingRequestCount === 0
         && pendingInvitationRequestCount === 0 && pendingTermAndConditionCount === 0
          ? (
            <div className="h6">
              {bodyText}
              { finalTeamMember
                ? (` This is the final member of this team! You cannot remove this user at the moment.`)
                : null
              }
              { isPaidUser && !finalTeamMember
                ? (
                  <h6><em><br></br>Note: This user has already paid their registration fee, and removing them from the event will not refund them.

                  Are you sure you want to remove them?</em></h6>
                ) : null
              }
            </div>
          )
          : (
            <div className="h6">
              you need to review pending requests before you can finalize your team.
            </div>
          )}
      </ModalBody>
      {unpaidUserCount === 0 && pendingRequestCount === 0
       && pendingInvitationRequestCount === 0 && pendingTermAndConditionCount === 0
        ? (
          <ModalFooter>
            {!finalTeamMember && <Button color="secondary" onClick={onAccept}>Yes</Button>}
            <Button color="secondary" onClick={onCancel} type="button" className="btn btn-primary" data-dismiss="modal">No</Button>
          </ModalFooter>
        )
        : null}
    </Modal>
  );
};

PopUpModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  unpaidUserCount: PropTypes.number,
  pendingRequestCount: PropTypes.number,
  pendingInvitationRequestCount: PropTypes.number,
  pendingTermAndConditionCount: PropTypes.number,
  eventId: PropTypes.string,
  schoolId: PropTypes.string,
  finalTeamMember: PropTypes.bool,
  isPaidUser: PropTypes.bool,
};

PopUpModal.defaultProps = {
  unpaidUserCount: 0,
  pendingRequestCount: 0,
  pendingInvitationRequestCount: 0,
  pendingTermAndConditionCount: 0,
  eventId: null,
  schoolId: null,
};


export default PopUpModal;
