import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import {
  FontBold, Hr,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT,
  FETCH_SCHOOL_DETAIL,
  CHECK_TEAM_FINALIZE,
  FETCH_EVENT_SCHOOL_STUDENTS,
  TEAM_CAPTAIN,
  FETCH_EVENT_SCHOOL_COACHES,
  FETCH_EVENT_GUESTS,
  FETCH_EVENT_USERS_BY_SCHOOL,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import ListItem from '../../Components/ListItem/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import Loader from '../../Components/Loader';

const SchoolTeam = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { eventId } = useParams();
  const { schoolId } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [pendingUsers, setPendingUsers] = useState([]);

  const [fetchSchoolDetail, { data: schoolData }] = useLazyQuery(
    FETCH_SCHOOL_DETAIL,
  );
  const school = getOr([], 'fetchSchoolDetail', schoolData);

  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );
  const teamFinalize = getOr([], 'checkTeamFinalize', dataCheckTeamFinalize);

  const [fetchTeamCaptian, { data: dataTeamCaptian }] = useLazyQuery(
    TEAM_CAPTAIN,
  );
  const teamCaptian = getOr([], 'fetchTeamCaptian', dataTeamCaptian);

  const [fetchEventSchoolStudents, { data: dataTeamStudents }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_STUDENTS,
  );
  const teamStudents = getOr([], 'fetchEventSchoolStudents', dataTeamStudents);

  const [fetchEventSchoolCoaches, { data: dataTeamCoaches }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_COACHES,
  );
  const teamCoaches = getOr([], 'fetchEventSchoolCoaches', dataTeamCoaches);

  const [eventGuests, { data: dataTeamGuest }] = useLazyQuery(
    FETCH_EVENT_GUESTS,
  );
  const teamGuests = getOr([], 'eventGuests', dataTeamGuest);

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const [fetchEventUsersBySchool, { loading: loadingPendingUsers }] = useLazyQuery(
    FETCH_EVENT_USERS_BY_SCHOOL, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setPendingUsers(data.fetchEventUsersBySchool);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchSchoolDetail({
        variables: {
          id: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchSchoolDetail, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchTeamCaptian({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchTeamCaptian, schoolId, eventId]);

  useEffect(() => {
    if (currentUser) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, event, checkTeamFinalize, eventId, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventSchoolStudents({
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [fetchEventSchoolStudents, currentUser, eventId, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventSchoolCoaches({
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [fetchEventSchoolCoaches, currentUser, eventId, schoolId]);

  useEffect(() => {
    if (currentUser) {
      eventGuests({
        variables: {
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, eventGuests, eventId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventUsersBySchool({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchEventUsersBySchool, eventId, schoolId]);

  useHeaderComponentsMutation({
    title: `${get('name', school)}`,
    backLink: `/event/${eventId}/teams`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  if (loadingPendingUsers) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-4" />
      <div>
        {checkPermission(Permissions.CAN_MANAGE_REGION)
          ? (
            <>
              <FontBold className="pt-3">
                <p>Team Status</p>
              </FontBold>
              <ListItem title="Open" redirectLink={`/event/${eventId}/teams/${schoolId}/edit`} checkPermission={(!teamFinalize && checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <ListItem title="Closed" redirectLink={`/event/${eventId}/teams/${schoolId}/edit`} checkPermission={(teamFinalize && checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <FontBold className="pt-3">
                <p>Captain</p>
              </FontBold>
              <ListItem title={`${get('firstname', teamCaptian.fetchUser)} ${get('lastname', teamCaptian.fetchUser)}`} redirectLink={`/event/${eventId}/teams/${schoolId}/reassign-captain`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <FontBold className="pt-3">
                <p>Users</p>
              </FontBold>
              <ListItem title="Students" redirectLink={`/event/${eventId}/team-students/${schoolId}`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} status={teamStudents.length} />
              <ListItem title="Coaches" redirectLink={`/event/${eventId}/team-coaches/${schoolId}`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} status={teamCoaches.length} />
              <ListItem title="Guests" redirectLink={`/event/${eventId}/guests`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} status={teamGuests.length} />
              <FontBold className="pt-3">
                <p>Admin Controls</p>
              </FontBold>
              <ListItem title="Competition Roster" redirectLink={`/events/${eventId}/competition-roster/${schoolId}`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <ListItem title="Edit school" redirectLink={`/schools/${schoolId}/edit`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <ListItem title="Approve Requests" redirectLink={`/event/${eventId}/teams/${schoolId}/approve-requests`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} status={pendingUsers.length}/>
              <ListItem title="Remove Users" redirectLink={`/event/${eventId}/teams/${schoolId}/remove-users`} checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))} />
            </>
          )
          : null}
      </div>
      <Hr />
    </>
  );
};
export default SchoolTeam;
