import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

const StyledSpinner = styled(Spinner)`
  `;

const SpinnerContainer = styled.div`
  position: relative;
  margin: 10px auto;
  width: 2rem;
  `;

const Loader = () => (
  <SpinnerContainer>
    <StyledSpinner color="secondary" />
  </SpinnerContainer>
);

export default Loader;
