import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import InputButton from '../../Components/Form/Button';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT_HEADER,
  FETCH_ORDER_DETAIL,
} from '../../GraphQL/Queries';

const ConfirmationText = styled.div`
  h5 {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: rgba(52, 52, 52, 1);
    padding-top: 3rem!important;
    padding-bottom: 3rem!important;
  }
`;

const PaymentConfirmation = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const {
    currentUser,
  } = useCurrentUser(true);

  const [fetchEventDetail, { loading: eventLoading }] = useLazyQuery(
    FETCH_EVENT_HEADER, {
      onCompleted: (dataFetchEventDetail) => {
        setEvent(dataFetchEventDetail.fetchEventDetail);
      },
    },
  );


  const [fetchOrderDetail, { loading: orderLoading }] = useLazyQuery(
    FETCH_ORDER_DETAIL, {
      onCompleted: (data) => {
        fetchEventDetail({
          variables: {
            id: parseInt(data.fetchOrderDetail.eventId, 10),
          },
        });
      },
    },
  );

  useEffect(() => {
    if (currentUser && id) {
      fetchOrderDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, id]);

  useHeaderComponentsMutation({
    title: 'PAYMENT CONFIRMATION',
    components: [{ key: EVENT_TITLE, value: event.title }],
  });
  return (
    <>
      <ConfirmationText>
        <h5>
          We have received your payment!
          <br />
          A receipt and payment confirmation
          <br />
          have been sent to
          {' '}
          {currentUser.email}
        </h5>
      </ConfirmationText>
      <div className="w-100" style={{ paddingBottom: '1em' }}>
        <Link to={`/event-manage/${event.id}`}>
          <InputButton loading={orderLoading || eventLoading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Continue</InputButton>
        </Link>
      </div>
    </>
  );
};
export default PaymentConfirmation;
