import React, { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';
import Slider from 'react-slick';
import {
  FontBold,
  EventBanner,
  DodgerBlue,
  DarkGrey,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import { FETCH_MY_EVENTS, SEARCH_EVENTS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { RedirectButton } from '../../Components/Form';
import EVENT_IMG from '../../Images/atlanta.png';
import ListItem from '../../Components/ListItem/Index';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import Loader from '../../Components/Loader';
import WHITE_CHECK_IMG from '../../Images/White-check.png';
import useAdminUserChecker from '../../Hooks/useAdminUserChecker';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DashboardIndex = () => {
  const {
    currentUser,
    loading,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const adminCheck = useAdminUserChecker(currentUser);
  const history = useHistory();
  const [updateCount, setUpdateCount] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState(0);
  useHeaderComponentsMutation({
    title: 'My Dashboard',
  });
  const moveToNextSlide = () => {
    setUpdateCount(updateCount + 1);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
  };

  useQuery(
    FETCH_MY_EVENTS, {
      onCompleted: (data) => {
        setFilteredEvents(data.myEvents);
      },
    },
  );

  const [eventsSearch] = useLazyQuery(
    SEARCH_EVENTS,
    {
      onCompleted: (data) => {
        setFilteredEvents(data.searchEvents);
      },
    },
  );

  const searchEvents = (e) => {
    eventsSearch({
      variables: { query: e.target.value },
    });
    setSearchStringLength(e.target.value.length);
  };

  const getSelectedEvent = (e) => {
    if (e.target.id !== '') {
      setSearchStringLength(0);
      history.push(`/event-manage/${parseInt(e.target.id, 10)}`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="row">
      <div className="col-12 mx-auto px-4 pt-3">
        <SearchDialog
          placeholder="Search"
          onChange={(e) => searchEvents(e)}
          data={filteredEvents.slice(0, 3)}
          stringlength={searchStringLength}
          onClick={getSelectedEvent}
          displayattribute="title"
          imageattatched={SEARCH}
        />
        <FontBold>
          { filteredEvents.length > 0
            ? (
              <>
                <p className="mt-4">My Events</p>
                <Slider {...settings} onClick={moveToNextSlide}>
                  {filteredEvents.map((event) => (
                    <div key={event.id}>
                      <EventBanner>
                        { event.coverImageUrl
                          ? (
                            <>
                              {adminCheck === true
                                ? (
                                  <Link to={`/event-manage/${event.id}`}>
                                    <img src={event.coverImageUrl} className="w-100" alt="Panel" style={{ objectFit: 'cover' }} />
                                  </Link>
                                )
                                : (
                                  <Link to={`/event-manage/${event.id}`}>
                                    <img src={event.coverImageUrl} className="w-100" alt="Panel" style={{ objectFit: 'cover' }} />
                                  </Link>
                                )}
                            </>
                          )
                          : (
                            <>
                              {adminCheck === true
                                ? (
                                  <Link to={`/event-manage/${event.id}`}>
                                    <img src={EVENT_IMG} className="w-100" alt="Panel" style={{ objectFit: 'cover' }} />
                                  </Link>
                                )
                                : (
                                  <Link to={`/event-manage/${event.id}`}>
                                    <img src={EVENT_IMG} className="w-100" alt="Panel" style={{ objectFit: 'cover' }} />
                                  </Link>
                                )}
                            </>
                          )}
                        <span>{event.title}</span>
                        {event.status === 'active'
                          ? (
                            <DodgerBlue>
                              <div className="d-flex align-items-center justify-content-between px-4 py-2">
                                <div className="dash--status">
                                  <h5 className="text-uppercase">{event.status}</h5>
                                </div>
                                <div>
                                  <img src={WHITE_CHECK_IMG} alt="check" className="img-fluid" style={{ height: 'auto', zoom: '70%' }} />
                                </div>
                              </div>
                            </DodgerBlue>
                          )
                          : (
                            <DarkGrey>
                              <div className="d-flex align-items-center justify-content-between px-4 py-2 ">
                                <div className="dash--status">
                                  <h5 className="text-uppercase">{event.status}</h5>
                                </div>
                              </div>
                            </DarkGrey>
                          )}
                      </EventBanner>
                    </div>
                  ))}
                </Slider>
                <div className="mt-4" />
              </>
            )
            : null}
          <RedirectButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="JOIN NEW EVENT" addNewLink="/" />
          <p className="mt-4">Quick Links</p>
          <ListItem title="Events" redirectLink="/events" checkPermission />
          <ListItem title="My Payments" redirectLink="/payment-status" checkPermission />
          { checkPermission('View Coupon') || checkPermission('View School') || checkPermission('View User')
            ? (
              <>
                <p className="mt-4">Admin Controls</p>
                <ListItem title="Coupons" redirectLink="/coupons" checkPermission={(checkPermission('View Coupon'))} />
                <ListItem title="Schools" redirectLink="/schools" checkPermission={(checkPermission('View School'))} />
                <ListItem title="Users" redirectLink="/users" checkPermission={(checkPermission('View User'))} />
              </>
            )
            : null}

          { checkPermission('View Competition') || checkPermission('View Region') || checkPermission('View Role')
            ? (
              <>
                <p className="mt-4">Super Admin Controls</p>
                <ListItem title="Ballots" redirectLink="/ballots" checkPermission={(checkPermission('View Competition'))} />
                <ListItem title="Competitions" redirectLink="/categories" checkPermission={(checkPermission('View Competition'))} />
                <ListItem title="Regions" redirectLink="/regions" checkPermission={(checkPermission('View Region'))} />
                <ListItem title="Roles" redirectLink="/roles" checkPermission={(checkPermission('View Role'))} />
              </>
            )
            : null}
        </FontBold>
      </div>
    </div>
  );
};
export default DashboardIndex;
