import { useCookies } from 'react-cookie';
import { useApolloClient } from '@apollo/react-hooks';
import { USER_TOKEN_KEY } from '../Constants/AppConstants';

const useUserSession = () => {
  const client = useApolloClient();
  const [cookies, setCookie, removeCookie] = useCookies([USER_TOKEN_KEY]);

  const saveUserSession = (token) => {
    setCookie(USER_TOKEN_KEY, token, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365, // 1 year, in seconds
    });
  };

  const destroyUserSession = () => {
    Object.keys(cookies).forEach((cookieName) => removeCookie(cookieName));
    client.clearStore();
    client.resetStore();
  };

  return {
    saveUserSession,
    destroyUserSession,
  };
};

export default useUserSession;
