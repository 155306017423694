import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { FETCH_COMPETITIONS, SEARCH_COMPETITIONS } from '../../GraphQL/Queries';

import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import EDIT_SMALL_ICON from '../../Images/icons/edit.png';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const CompetitionIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const { id } = useParams();
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);
  const [fetchCompetitions, { loading, error }] = useLazyQuery(
    FETCH_COMPETITIONS, {
      onCompleted: (data) => {
        setFilteredCompetitions(data.fetchCompetitions);
      },
    },
  );

  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'COMPETITIONS',
    backLink: '/categories',
  });

  const [competitionsSearch] = useLazyQuery(
    SEARCH_COMPETITIONS,
    {
      onCompleted: (data) => {
        setFilteredCompetitions(
          data.searchCompetitions.filter(({ categoryId }) => categoryId === parseInt(id, 10)),
        );
      },
    },
  );

  const searchCompetitions = (e) => {
    competitionsSearch({
      variables: {
        query: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (currentUser) {
      fetchCompetitions({
        variables: {
          tenantId: currentUser.tenantId,
          categoryId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchCompetitions, id]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <Search
          placeholder="Search"
          onChange={(e) => searchCompetitions(e)}
        />
        {filteredCompetitions.length === 0
          ? <p>No Competitions</p>
          : null}
        <List data={filteredCompetitions} displayAttribute="title" redirectLink="competitions" redirectOperation="edit" dataType="competitions" />
        <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Competition" addNewLink={`/competitions/${id}/new`} imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Competition'))} />
        <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Edit Category" addNewLink={`/categories/${id}/edit`} imageAttached={EDIT_SMALL_ICON} checkPermission={(checkPermission('Update Category'))} />
      </div>
    </div>
  );
};
export default CompetitionIndex;
