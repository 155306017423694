import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { get, isEmpty, last } from 'lodash/fp';
import moment from 'moment';
import useFormParams from '../../Hooks/useFormParams';
import { FETCH_EVENT_USER_DETAIL, FETCH_EVENT_USER_COMMENTS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { RedirectButton, Input } from '../../Components/Form';
import { PENDING_EVENT_USER } from '../../GraphQL/Mutations';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  InnerFormTitle, TextAlignCenter, Hr, ConversationDiv,
} from '../../Components/Auth/Layout';
import TRIANGLE_ARROW from '../../Images/icons/triangle.png';
import BackLink from '../../Components/BackLink/Index';
import Message from '../../Components/Message/Index';
import useUpdateEventsUserCommentsCache from '../../Hooks/useUpdateEventsUserCommentsCache';

const TeamApproval = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { params, onChange } = useFormParams({
    comment: '',
  });
  const extractError = useGraphQLErrorExtractor();
  const [eventUser, setFetchEventsUserDetail] = useState({});
  const [eventUsersComments, setEventUsersComments] = useState([]);
  const [createdAt, setCreatedAt] = useState('');
  const updateCache = useUpdateEventsUserCommentsCache(parseInt(id, 10));
  const [defaultMessage, setDefaultMessage] = useState('');

  const { loading, error } = useQuery(
    FETCH_EVENT_USER_DETAIL,
    {
      onCompleted: (data) => {
        setFetchEventsUserDetail(data.fetchEventsUserDetail);
        setCreatedAt(data.fetchEventsUserDetail.createdAt);
        if (data.fetchEventsUserDetail && isEmpty(data.fetchEventsUserDetail.fetchComments)) {
          setDefaultMessage('Your request has been sent to Admin/Captain for team Approval. Meanwhile, you can participate in competitions');
        }
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  const [fetchEventUserComments] = useLazyQuery(
    FETCH_EVENT_USER_COMMENTS, {
      onCompleted: (data) => {
        updateCache(data.fetchEventUserComments);
        setEventUsersComments(data.fetchEventUserComments);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchEventUserComments({
        variables: {
          eventsUsersId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventUserComments, id]);

  const [pendingEventUser] = useMutation(
    PENDING_EVENT_USER,
    {
      onCompleted: () => {
        params.comment = '';
        fetchEventUserComments({
          variables: {
            eventsUsersId: parseInt(id, 10),
          },
        });
      },
    },
  );

  const onSubmit = (e) => {
    if (currentUser) {
      e.preventDefault();
      params.id = parseInt(id, 10);
      pendingEventUser({
        variables: {
          ...params,
          status: 1,
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  };

  useHeaderComponentsMutation({
    backLink: `/event-manage/${eventUser.eventId}`,
    title: 'TEAM APPROVAL STATUS',
    components: [{ key: EVENT_TITLE, value: get('title', eventUser.fetchEvent) }],
  });

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <InnerFormTitle>
            <TextAlignCenter>
              <p>
                Request Submitted
                {' '}
                {`${moment(createdAt).format('MM/DD/YYYY')}`}
              </p>
            </TextAlignCenter>
          </InnerFormTitle>
        </div>
      </div>
      <Hr />
      <ConversationDiv>
        <div className="col-12 text-center mx-auto px-4 pt-3">
          <Message data={eventUsersComments} displayAttribute="comment" defaultMessage={defaultMessage} />
        </div>
      </ConversationDiv>
      <Form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 mx-auto px-4">
            <Input
              title="Reply"
              name="comment"
              id="comment"
              onChange={onChange}
              type="textarea"
              params={params}
              rows="6"
              required
            />
            <RedirectButton htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="REPLY" />
          </div>
        </div>
      </Form>
      <div className="row">
        <div className="col-12 px-4">
          <RedirectButton htmltype="submit" backgroundcolor="#FFF" fontColor="rgba(244, 171, 55, 1)" border="2px solid #f3AA44" addNewText="UPDATE PROFILE" addNewLink="/me" />
        </div>
      </div>
      <div className="col-12 text-center my-5">
        <BackLink text="Manage Event" imageAttached={TRIANGLE_ARROW} redirectLink={`/event-manage/${eventUser.eventId}`} />
      </div>
    </>
  );
};
export default TeamApproval;
