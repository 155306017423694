import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { SET_BALLOT } from '../../GraphQL/Mutations';
import { FETCH_BALLOT } from '../../GraphQL/Queries';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Loader from '../../Components/Loader';
import Form from './Form';

const validateInput = (ballotTitle, ballotSections) => {
  const errors = new Set();
  if (ballotTitle === "") {
    errors.add("Ballot Title cannot be empty.");
  }
  ballotSections.forEach((ballotSection) => {
    if (ballotSection.title === "") {
      errors.add("Ballot Section Headers cannot be empty.")
    }
    if (ballotSection.totalPoints < 0) {
      errors.add("Ballot Sections cannot be worth negative points.")
    }
    if (ballotSection.description === "") {
      errors.add("Ballot Section Descriptions cannot be empty.")
    }
  });
  return errors;
}

const BallotEdit = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const { id } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [ballotSections, setBallotSections] = useState([{}]);
  const backLink = '/ballots';
  const [errorMessage, setErrorMessage] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [ballotTitle, setBallotTitle] = useState("");
  const extractError = useGraphQLErrorExtractor();

  useHeaderComponentsMutation({
    backLink: backLink,
    title: 'EDIT BALLOT',
  });

  const [fetchBallot, { loading: ballotsLoading }] = useLazyQuery(FETCH_BALLOT, {
    onCompleted: (response) => {
      const ballot = response.fetchBallot
      setBallotTitle(ballot.title)
      const currentBallotSections = ballot.fetchBallotSections.map((ballotSection) => {
        // Remove additional fields generated by GraphQL
        const cleanedBallotSection = {};
        cleanedBallotSection.title = ballotSection.title;
        cleanedBallotSection.totalPoints = ballotSection.totalPoints;
        cleanedBallotSection.description = ballotSection.description;
        return cleanedBallotSection;
      })
      setBallotSections(currentBallotSections);
    },
  });
  const [setBallot] = useMutation(
    SET_BALLOT,
    {
      onCompleted: (data) => {
        history.goBack();
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Competition') === undefined) {
        history.push('/ballots');
      }
    }
  }, [currentUser, checkPermission, history]);

  useEffect(() => {
    if (currentUser) {
      fetchBallot({
        variables: { ballotId: id },
      });
    }
  }, [currentUser, fetchBallot, id]);

  const onSubmit = (e) => {
    const errors = validateInput(ballotTitle, ballotSections);
    if (errors.size > 0) {
      const message = Array.from(errors).join(' ');
      setErrorMessage(message);
      window.scrollTo(0, 0);
    } else {
      setUnsavedChanges(false);
      setBallot({
        variables: {
          input: {
            ballotId: id,
            title: ballotTitle,
            sections: ballotSections,
          },
        },
      }).then(() => {
        history.push(backLink);
      }).catch((err) => {
        setErrorMessage(extractError(err));
        window.scrollTo(0, 0);
      });
    }
  };

  if (ballotsLoading) {
   return <Loader />;
 }

  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      {unsavedChanges ? <GenericAlert color="warning">You have unsaved changes! Please scroll down and press Save</GenericAlert> : null}
      <hr />
      <Form
        ballotSections={ballotSections}
        setBallotSections={setBallotSections}
        ballotTitle={ballotTitle}
        setBallotTitle={setBallotTitle}
        onSubmit={onSubmit}
        setUnsavedChanges={setUnsavedChanges}
        checkPermission={checkPermission}
      />
    </>
  );
};
export default BallotEdit;
