import React, { useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import {
  FETCH_EVENT_DETAIL,
  FETCH_REGISTRATION_OPTIONS_BY_DEADLINE,
  FETCH_ROLE_BY_PERMISSION,
}
  from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { PermissionButton, Input } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import BackLink from '../../Components/BackLink/Index';
import TRIANGLE_ARROW from '../../Images/icons/triangle.png';
import { INVITE_USER } from '../../GraphQL/Mutations';
import Permissions from '../../Constants/PermissionConstrants';

const InviteNewUser = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const { userType } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);
  const [fetchRegistrationOptions,
    { data: registrationOptions }] = useLazyQuery(FETCH_REGISTRATION_OPTIONS_BY_DEADLINE);
  const registrations = getOr([], 'registrationOptionByDeadline', registrationOptions);
  useHeaderComponentsMutation({
    title: 'INVITE NEW USER',
    backLink: `/event-manage/${eventId}/invite-existing-user/${userType}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  const [fetchRoleFromPermission, { data: dataRole }] = useLazyQuery(
    FETCH_ROLE_BY_PERMISSION,
  );

  const role = getOr({}, 'fetchRoleFromPermission', dataRole);

  const addJudgePermission = useMemo(() => (checkPermission(Permissions.CAN_ADD_JUDGE)),
    [checkPermission]);

  const { params, onChange } = useFormParams({
    firstName: '',
    lastName: '',
    email: '',
    registrationOptionId: '',
  });

  useEffect(() => {
    if (currentUser && eventId) {
      fetchEventDetail({
        variables: {
          id: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, eventId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchRegistrationOptions({
        variables: {
          tenantId: currentUser.tenantId,
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptions, eventId]);

  useEffect(() => {
    if (currentUser && addJudgePermission) {
      fetchRoleFromPermission({
        variables: {
          title: `${Permissions.CAN_JOIN_JUDGE_COMPETITION}`,
        },
      });
    }
  }, [addJudgePermission, currentUser, fetchRoleFromPermission]);

  const [inviteUser, { loading: invitationLoading }] = useMutation(INVITE_USER, {
    onCompleted: () => {
      history.push(`/event-manage/${parseInt(eventId, 10)}`);
    },
  });

  const onSubmit = (e) => {
    params.invitableId = parseInt(eventId, 10);
    params.invitableType = 'Event';
    if (checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER)) {
      params.registrationOptionId = parseInt(params.registrationOptionId, 10);
    } else if (checkPermission(Permissions.CAN_ADD_JUDGE) && role) {
      params.roleId = parseInt(role.id, 10);
      params.registrationOptionId = null;
    }
    e.preventDefault();
    inviteUser({
      variables: {
        ...params,
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <Form onSubmit={onSubmit}>
            <Input
              type="text"
              title="First Name"
              id="firstname"
              name="firstname"
              placeholder="First Name"
              required
              onChange={onChange}
              withFormGroup
              labelClass="sr-only"
              params={params}
            />
            <Input
              type="text"
              title="Last Name"
              id="lastname"
              name="lastname"
              placeholder="Last Name"
              required
              onChange={onChange}
              withFormGroup
              labelClass="sr-only"
              params={params}
            />
            <Input
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              required
              onChange={onChange}
              withFormGroup
              title="Email"
              labelClass="sr-only"
              params={params}
            />
            {(checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER)
             || checkPermission(Permissions.CAN_MANAGE_REGION))
             && (userType === 'student' || userType === 'coach'
             || userType === 'guest')
              ? (
                <>
                  <Input type="select" params={params} onChange={onChange} name="registrationOptionId" id="registrationOptionId">
                    <option value="" disabled>Registration Options</option>
                    {registrations && registrations.map((obj) => (
                      <option key={obj.id} value={obj.id}>{obj.title}</option>
                    ))}
                  </Input>
                </>
              )
              : null}
            <div className="mt-4" />
            <PermissionButton
              loading={invitationLoading}
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="none"
              htmltype="submit"
              checkPermission={(checkPermission(Permissions.CAN_INVITE_TEAM_MEMBER))
              || checkPermission(Permissions.CAN_ADD_JUDGE)}
            >
INVITE
            </PermissionButton>
            <div className="mt-4" />
            <div className="col-12 text-center my-5">
              <BackLink text="Back" imageAttached={TRIANGLE_ARROW} redirectLink={`/event-manage/${eventId}/invite-existing-user/student`} />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default InviteNewUser;
