import React from 'react';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';

import {
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { ScheduleEventAttendeePropType } from './propTypes';

const DISPLAY_DATE_FORMAT = 'yyyy-MM-dd hh:mm aa z';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const AttendanceTable = ({ scheduleEvents }) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Event Name</Th>
        <Th>Event Time</Th>
        <Th>Number of Attendees</Th>
      </Tr>
    </Thead>
    <Tbody>
      {scheduleEvents.map((scheduleEvent) => (
        <Tr key={scheduleEvent.id}>
          <Td>{scheduleEvent.title}</Td>
          <Td>
            {format(utcToZonedTime(scheduleEvent.startTime, USER_LONG_TZ), DISPLAY_DATE_FORMAT)}
            {' - '}
            {format(utcToZonedTime(scheduleEvent.endTime, USER_LONG_TZ), DISPLAY_DATE_FORMAT)}
          </Td>
          <Td>{scheduleEvent.attendees.length}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
AttendanceTable.propTypes = {
  scheduleEvents: PropTypes.arrayOf(ScheduleEventAttendeePropType).isRequired,
};
