import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../Components/Form';
import styled from 'styled-components';
import { Title } from '../../Components/Form';
import { StyledInput } from '../../Components/Auth/Layout';
import REMOVE_ICON from '../../Images/icons/remove.png';
import { ButtonLink } from '../../Components/Form';
import { PrimaryButton } from '../../Components/Auth/Layout';


const IconButton = styled.button`
  background: transparent;
  height: 20px;
  border: none;
`;

const BallotSectionBlock = ({ title, description, totalPoints, onRemove, onChangeTitle, onChangeDescription, onChangePoints }) => {
  return (
    <>
      <div className="col-12">
        <div className="row my-2">
          <div className="col-8 col-md-8 px-2">
            <Title> Section Header <span style={{ color: 'red' }}> *</span> </Title>
            <StyledInput value={title} onChange={(evt) => { onChangeTitle(evt.target.value); }} required />
          </div>
          <div className="col-1 mx-auto px-5">
            <IconButton onMouseDown={onRemove} onTouchEnd={onRemove} type="button">
              <img src={REMOVE_ICON} alt="remove section" />
            </IconButton>
          </div>
          <div className="col-8 col-md-8 px-2">
            <Title> Section Point Value <span style={{ color: 'red' }}> *</span> </Title>
            <StyledInput style={{ width: '25%' }} type="number" value={totalPoints} onChange={(evt) => { onChangePoints(evt.target.value); }} required />
          </div>
          <div className="col-8 col-md-12 mx-auto px-2">
            <Title> Description <span style={{ color: 'red' }}> *</span> </Title>
            <StyledInput type="textarea" value={description} onChange={(evt) => { onChangeDescription(evt.target.value); }} required />
          </div>

        </div>
      </div>
      <hr />
    </>
  );
};

const BallotForm = ({
  ballotSections, setBallotSections, ballotTitle, setBallotTitle, onSubmit, setUnsavedChanges, checkPermission,
}) => {

  const removeSection = (idxToRemove) => {
    const newSections = ballotSections.filter((_, idx) => idx !== idxToRemove);
    setBallotSections(newSections);
    setUnsavedChanges(true);
  };

  const updateSection = (idx, field, newValue) => {
    const newSections = [...ballotSections];
    newSections[idx][field] = newValue;
    setBallotSections(newSections);
    setUnsavedChanges(true);
  };

  const addSection = () => {
    const newSections = [...ballotSections, {
      "title": "",
      "totalPoints": 0,
      "description": "",
    }];
    setBallotSections(newSections);
  }

  const updateTitle = (title) => {
    setBallotTitle(title);
    setUnsavedChanges(true);
  }

  return (
    <Form>
      <div className="col-8 col-md-8 mx-auto px-2">
        Ballot Name <StyledInput title="Title" label="title" value={ballotTitle} style={{ width: '100%' }} type="text" onChange={(evt) => updateTitle(evt.target.value)} required />
      </div>
      <center><ButtonLink backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add Ballot Section" checkPermission={(checkPermission('Add Competition'))} onClick={addSection} /></center>

      {ballotSections.map((section, idx) => (
        <BallotSectionBlock
          key={idx}
          title={section.title}
          description={section.description}
          totalPoints={section.totalPoints}
          onChangeTitle={(newValue) => updateSection(idx, "title", newValue)}
          onChangeDescription={(newValue) => updateSection(idx, "description", newValue)}
          onChangePoints={(newValue) => updateSection(idx, "totalPoints", newValue)}
          onRemove={() => removeSection(idx)}
        />
      ))}

      <div className="col-12 my-4">
        <PrimaryButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" onClick={onSubmit}>
          Save Ballot
        </PrimaryButton>
      </div>
    </Form>
  );
};

BallotForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ballotSections: PropTypes.instanceOf(Object).isRequired,
  setBallotSections: PropTypes.func.isRequired,
  ballotTitle: PropTypes.string.isRequired,
  setBallotTitle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setUnsavedChanges: PropTypes.func.isRequired,
  checkPermission: PropTypes.func.isRequired,
};

export default BallotForm;
