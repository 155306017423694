/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { get, getOr } from 'lodash/fp';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  Hr, CardBorder, ImgSquareViewMember, TeamWaitlist,
} from '../Auth/Layout';
import Edit from '../../Images/icons/edit.png';
import Question from '../../Images/icons/question.png';
import userDefaultImage from '../../Images/team-dum.png';
import useFilterArray from '../../Hooks/useFilterArray';

const CardList = (props) => {
  const {
    member,
    checkPermission,
    editUrl,
  } = props;

  const filterArray = useFilterArray();

  return (
    <CardBorder className="pt-3 pb-3 mb-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1 col-4 ">
            <ImgSquareViewMember>
              { get('profileImageUrl', member.fetchUser)
                ? (<img src={get('profileImageUrl', get('fetchUser', member))} alt="Member" />)
                : (<img src={userDefaultImage} alt="User-Icon" />)}
            </ImgSquareViewMember>
          </div>
          <div className="col-md-11 col-8">
            <div className="row">
              <div className=" font-weight-bold col-md-6 col-6 small pl-0">
                {get('firstname', get('fetchUser', member))}
                {' '}
                  &nbsp;
                {get('lastname', get('fetchUser', member))}
              </div>
              {checkPermission
                ? (
                  <div className="col-md-6 col-6 text-right">
                    <Link to={editUrl}>
                      <img src={Edit} alt="Edit" />
                    </Link>
                  </div>
                )
                : null}
            </div>
            <Hr className="mr-1 mt-1 mb-2 pl-n1 text-left" />
            {(filterArray(getOr([], 'fetchMembers', member), 'status', 'joined')).length > 0
              ? (
                <>
                  <div className="row font-weight-bold small mb-1">
                    Joined Competitions
                  </div>
                  {filterArray(getOr([], 'fetchMembers', member), 'status', 'joined').map((comp, index) => (
                    <div className="row small">
                      {index + 1}
  .
                      {' '}
                      {get('title', get('fetchEventCompetition', comp))}
                    </div>
                  ))}
                </>
              )
              : null}
            {(filterArray(getOr([], 'fetchMembers', member), 'status', 'waitlist')).length > 0
              ? (
                <>
                  <div className="row font-weight-bold mt-3 mb-1 small">
                      Waitlisted
                  </div>
                  {filterArray(getOr([], 'fetchMembers', member), 'status', 'waitlist').map((comp, index) => (
                    <div className="row small">
                      {index + 1}
                        .
                      {get('title', get('fetchEventCompetition', comp))}
                      <span>
                        <TeamWaitlist>
                          <button data-tip data-for={`reason_${comp.id}`} type="button"><img src={Question} alt="question" /></button>
                          <ReactTooltip id={`reason_${comp.id}`}>
                            <span>{comp.reason}</span>
                          </ReactTooltip>
                        </TeamWaitlist>
                      </span>
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        </div>
      </div>
    </CardBorder>
  );
};

CardList.propTypes = {
  member: PropTypes.instanceOf(Object).isRequired,
  checkPermission: PropTypes.bool,
  editUrl: PropTypes.string,
};
CardList.defaultProps = {
  checkPermission: null,
  editUrl: null,
};

export default CardList;
