// the breakdown works like this:
// regional admin specifies total cost
// stripe takes 30c + 2.9% of total cost
// 5% of non-stripe cost goes to nats
// remaining 95% goes to region
//
// note that internally the math is done in cents
//  this is for added accuracy
//  but we return dollar values
export const createCostBreakdown = (totalCost, natsPercentage) => {
  const stripeFee = 30 + Math.round(0.029 * totalCost);
  const mistAmount = totalCost - stripeFee;
  const natsAmount = Math.round((natsPercentage / 100) * mistAmount);
  const regionAmount = mistAmount - natsAmount;
  return {
    regionAmount: (regionAmount / 100).toFixed(2),
    natsAmount: (natsAmount / 100).toFixed(2),
    stripeFee: (stripeFee / 100).toFixed(2),
  };
};

export const getCorrectedRegionalAmount = (totalCost) => {
  const { regionAmount } = createCostBreakdown(totalCost);
  return Math.max(regionAmount, 0);
};
