export const TENANT = `
  __typename
  id
  name
  status
  percentageShare
`;


export const ROLE = `
  __typename
  id
  title
  tenantId
  termsAndConditions
  status
  level
  allowInvitation
`;

export const REGION = `
  __typename
  id
  name
  stripeAccount
  currency
  websiteUrl
  facebookUrl
  twitterUrl
  instagramUrl
  email
  instagramUrl
  tier
  tierValue
  tenantId
  timezone
  regionsUsers{
    id
    regionId
    roleId
    tenantId
    status
    userId
    fetchUser{
      id
      email
      status
      phone
      userType
      gender
      tenantId
      dateOfBirth
      firstname
      lastname
      profileImageUrl
    }
    fetchRole{
      id
      title
    }
  }
`;

export const ROLE_PERMISSION = `
  __typename
  id
  roleId
  permissionId
  status
  permissionTitle
`;


export const SCHOOL = `
  __typename
  id 
  name
  address1
  address2
  tenantId
  status
  website
  country
  stateCode
  postalCode
  phone
  denyComment
  city
  regionIds
  schoolsUsersCount
`;

export const COUPON = `
  __typename
  id 
  tenantId
  eventId
  roleId
  status
  code
  maxCodeUsage
  value
  valuePercentage
  expirationDate
`;

export const USER = `
  __typename
  id
  firstname
  lastname
  tenantId
  status
  gender
  genderValue
  dateOfBirth
  userType
  email
  phone
  code
  perferContactMethod
  accommodationNote
  regionId
  profileImageUrl
  qrCodeUrl
  confirmedAt
  fetchSchools{
    id
    tenantId
    name
  }
  fetchRole{
    id
    title
    permissions {
      id
      title
      displayName
    }
  }
  defaultRegion{
    id
    tenantId
    name
  }
  fetchSchoolsUsers{
    id 
    schoolId
    userId
    tenantId
    status
    isDefault
    fetchUserAttachment{
      id
      verificationFileUrl
    }
    fetchSchool{
      id
      tenantId
      name
    }
  }
  fetchEventsUsers{
    id
    tenantId
    status
    schoolId
    eventId
    userId
    parentGuardianEmail
    parentGuardianSignature
    roleId
    signIn
    teamFinalize
    fetchRole{
      id
      title
    }
    fetchEvent{
      id
      title
    }
    fetchUser{
      id
      email
      status
      phone
      userType
      gender
      tenantId
      dateOfBirth
      firstname
      lastname
    }
  }
`;

export const STUDENT_SCHOOL = `
  __typename
    id 
    email
    status
    phone
    userType
    gender
    tenantId
    dateOfBirth
    firstname
    lastname
    fetchRole{
      id
      title
    }
`;

export const SCHOOL_USER = `
  __typename
  id 
  schoolId
  userId
  tenantId
  status
  isDefault
  fetchUserAttachment{
    id
    verificationFileUrl
  }
  fetchSchool{
    id
    tenantId
    name
  }
  fetchUser{
    id
    fetchSchools{
      id
      tenantId
      name
    }
  }
`;

export const CATEGORY = `
  __typename
  id
  title
  status
  tenantId
`;

export const COMPETITION = `
  __typename
  id
  status
  title
  tenantId
  categoryId
  matchup
  maxTeam
  minTeam
  maxSubmission
  onlineSubmission
  requiresAdditionalCompetition
  group
  genderEnable
  level
  description
  fetchCategory{
    id
    title
    tenantId
  }
`;

export const EVENT = `
  id
  title
  label
  tenantId
  status
  regionId
  startDate
  endDate
  submissionDeadline
  location
  allowCoalition
  restrictToRegion
  volunteerApplicationLink
  judgeApplicationLink
  sponsorApplicationLink
  enableMinMax
  qualifiedAttendingPreviousEvent
  competitionPerCategory
  competeAdditionalCompetition
  termsAndConditions
  coverImageUrl
  fee
  registrationEarlyDeadline
  registrationFinalDeadline
  approveSchoolsCount
  unapproveSchoolsCount
  fetchEventCompetitions{
    id
    eventId
    tenantId
    competitionId
    status
    gender
    level
    title
    fetchCompetition{
      id
      title
      tenantId
      categoryId
      fetchCategory{
        id
        title
        status
        tenantId
      }
    }
  }
  fetchRegistrationOptions{
    id
    title
    tenantId
    roleId
    eventId
    fee
    lateFee
    capacity
    earlyDeadline
    finalDeadline
    status
    minAge
    maxAge
  }
  allEventsUsers{
    id
    tenantId
    status
    schoolId
    userId
    parentGuardianEmail
    parentGuardianSignature
    roleId
    signIn
    teamFinalize
    fetchRole{
      id
      title
    }
    fetchUser{
      id
      email
      status
      phone
      userType
      gender
      tenantId
      dateOfBirth
      firstname
      lastname
    }
  }
`;


export const REGISTRATION_OPTION = `
  id 
  title
  fee
  lateFee
  status
  capacity
  earlyDeadline
  finalDeadline
  tenantId
  eventId
  roleId
  finalFee
  minAge
  maxAge
  waitlistFormUrl
  fetchRole{
    id
    title
    tenantId
    status
    permissions {
      id
      title
      displayName
    }
  }
`;

export const EVENT_COMPETITION = `
  id
  competitionId
  tenantId
  eventId
  status
  title
  level
  fetchCompetition{
    id
    status
    title
    tenantId
    categoryId
    level
    description
    fetchCategory{
      id
      title
      status
      tenantId
    }
  }
`;

export const REGIONSUSER = `
  __typename
  id 
  regionId
  roleId
  tenantId
  status
  userId
`;

export const EVENTSUSER = `
  __typename
  id
  eventId
  tenantId
  userId
  roleId
  parentGuardianEmail
  parentGuardianSignature
  status
  registrationOptionId
  signIn
  fetchOrder{
    id
    eventId
    userId
    amount
    mistFee
    stripeFee
    status
    fetchLineItems{
      id
      orderId
      description
      amount
      currency
      shareable
      lineItemAbleId
      lineItemAbleType
    }
  }
`;
export const MEMBER = `
  __typename
  id
  userId
  eventsUserId
  eventCompetitionId
  status
  competitionPriority
  memberPriority
  reason
  fetchUser{
    id
    firstname
    lastname
    email
    tenantId
    profileImageUrl
    phone
  }
  fetchEventCompetition{
    id
    eventId
    tenantId
    status
    competitionId
    level
    title
    fetchCompetition{
      id 
      status
      title
      description
      tenantId
      onlineSubmission
    }
  }
`;


export const PERMISSION = `
  __typename
  id
  title
  key
  value
  displayName
`;

export const COALITION = `
  __typename
  id 
  eventCompetitionId
  fetchEventCompetition{ id title }
  status
  tenantId
  code
  fetchCoalitionsSchools{
    id
    status
    schoolId
    tenantId
    coalitionId
    fetchSchool{
      id
      name
      tenantId
      address1
    }
  }
`;

export const ORDER = `
  __typename
  id
  eventId
  userId
  amount
  mistFee
  stripeFee
  status
  transactionId
  createdAt
  fetchLineItems{
    id
    orderId
    description
    amount
    currency
    shareable
    lineItemAbleId
    lineItemAbleType
    fetchRegistrationOption{
      id 
      title
      fee
      lateFee
      status
      capacity
      earlyDeadline
      finalDeadline
      tenantId
      eventId
      roleId
      finalFee
      minAge
      maxAge 
    }
    fetchCoupon{
      id 
      tenantId
      eventId
      roleId
      status
      code
      maxCodeUsage
      value
      valuePercentage
      expirationDate
    }
  }
  fetchEventsUser{
    id 
    roleId
    userId
    schoolId
    eventId
    status
    registrationOptionId
    signIn
    teamFinalize
    parentGuardianSignature
    parentGuardianEmail
    fetchUser{
      id
      email
      status
      phone
      userType
      gender
      tenantId
      dateOfBirth
      firstname
      lastname
      profileImageUrl
      code
      fetchSchools{
        id
        tenantId
        name
      }
    }
    fetchEvent{
      id
      title
      tenantId
      status
    }
    fetchRole{
      id
      title
      tenantId
      status
    }
  }
`;

export const LINE_ITEM = `
  __typename
  id
  orderId
  description
  amount
  currency
  shareable
  lineItemAbleId
  lineItemAbleType
  fetchOrder{
    id
    eventId
    userId
    amount
    mistFee
    stripeFee
  }
`;
export const EVENT_USER = ` 
  id 
  roleId
  userId
  schoolId
  eventId
  status
  signIn
  createdAt
  parentGuardianSignature
  parentGuardianEmail
  teamFinalize
  fetchOrder{
    id
    status
  }
  fetchUser{
    id
    email
    status
    phone
    userType
    gender
    tenantId
    dateOfBirth
    firstname
    lastname
    profileImageUrl
    code
    fetchSchools{
      id
      tenantId
      name
    }
    fetchSchoolsUsers{
      id 
      schoolId
      userId
      tenantId
      status
      isDefault
      fetchUserAttachment{
        id
        verificationFileUrl
      }
    }
  }
  fetchEvent{
    id
    title
    tenantId
    status
    termsAndConditions
  }
  fetchRole{
    id
    title
    tenantId
    status
    termsAndConditions
    permissions {
      id
      title
      displayName
    }
  }
  fetchComments{
    id
    commentableType
    commentableId
    reason
    comment
    userId
    createdAt
  }
  fetchRegistrationOption{
    id 
    title
    fee
    lateFee
    status
    capacity
    earlyDeadline
    finalDeadline
    tenantId
    eventId
    roleId
    finalFee
    minAge
    maxAge
    fetchRole{
      id
      title
      tenantId
      status
      termsAndConditions
      permissions {
        id
        title
        displayName
      }
    }
  }
  fetchMembers{
    id
    userId
    eventsUserId
    eventCompetitionId
    status
    competitionPriority
    memberPriority
    reason
    fetchUser{
      id
      firstname
      lastname
      email
      tenantId
      profileImageUrl
      phone
    }
    fetchEventCompetition{
      id
      eventId
      tenantId
      status
      competitionId
      level
      title
      fetchCompetition{
        id
        title
      }
    }
  }
`;

export const SLIM_EVENT_USER = ` 
  id 
  roleId
  userId
  schoolId
  eventId
  status
  signIn
  createdAt
  parentGuardianSignature
  parentGuardianEmail
  teamFinalize
  fetchOrder{
    id
    status
  }
  fetchEvent {
    id
    title
    tenantId
    status
    termsAndConditions
  }
  fetchRole{
    id
    title
    tenantId
    status
    termsAndConditions
    permissions {
      id
      title
      displayName
    }
  }
  fetchComments {
    id
    commentableType
    commentableId
    reason
    comment
    userId
    createdAt
  }
`;
export const COMMENT = `
  __typename
  id
  commentableType
  commentableId
  reason
  comment
  userId
  createdAt
  authorFirstname
  authorLastname
`;

export const INVITATION = `
  __typename
  id 
  email
  firstname
  lastname
  roleId
  registrationOptionId
  expiry
  regionId
  fetchRegistrationOption{
    id
    title
    tenantId
    roleId
    eventId
    fee
    lateFee
    capacity
    earlyDeadline
    finalDeadline
    status
    minAge
    maxAge
  }
`;

export const COUNTRY = `
  __typename
  id 
  title
  code
  currency
  currencyCode
  status
`;

export const STATE = `
  __typename
  id 
  title
  code
  countryId
  status
`;

export const FINALIZE_TEAM = `
  __typename
  eventId
  schoolId
  status
`;
