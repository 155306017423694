import React from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  ScoreButton,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import Loader from '../../Components/Loader';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const FETCH_EVENT_DETAIL = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
    }
  }
`;

const FETCH_COUPONS_REPORT = gql`
  query fetchCouponsReport($eventId: Int!) {
    # overall stats
    couponUserCountAndAmount(eventId: $eventId)

    # data for the list
    listCouponsUserCountAndAmount(eventId: $eventId){
      id
      code
      value
      valuePercentage
      eventId
      status
      couponUser
    }

    # data for excel sheet
    fetchCouponsReport(eventId: $eventId) {
      id
      amount
      status
      fetchEventsUser {
        id
        fetchUser {
          firstname
          lastname
          code
        }
        fetchTeam {
          code
        }
        fetchSchool {
          name
        }
      }
      fetchLineItems {
        lineItemAbleType
        description
        amount
        fetchCoupon {
          code
        }
      }
    }
  }
`;

const getUserCode = (team, user) => {
  if (team) {
    return `${team.code}-${user.code}`;
  }
  return user.code;
};

const CouponsReport = () => {
  // redirect if the person isn't logged in
  useCurrentUser(true);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const { id } = useParams();
  const eventId = parseInt(id, 10);

  // event info
  const { data, loading: eventLoading } = useQuery(FETCH_EVENT_DETAIL, {
    variables: {
      id: eventId,
    },
  });
  const event = getOr({}, 'fetchEventDetail', data);

  // make the query
  const { data: couponReport, loading: couponReportLoading } = useQuery(FETCH_COUPONS_REPORT, {
    variables: {
      eventId,
    },
  });

  // overall stats
  const userCountAmount = couponReport?.couponUserCountAndAmount;

  // data for the list
  const coupons = couponReport?.listCouponsUserCountAndAmount;

  // data for excel sheet
  const dataSet = couponReport?.fetchCouponsReport?.map((order) => {
    const eventUser = order.fetchEventsUser;
    const couponLineItem = order.fetchLineItems.find((li) => li.lineItemAbleType === 'Coupon');
    return {
      school: eventUser.fetchSchool?.name,
      code: getUserCode(eventUser.fetchTeam, eventUser.fetchUser),
      userName: `${eventUser.fetchUser.firstname} ${eventUser.fetchUser.lastname}`,
      couponCode: couponLineItem.fetchCoupon.code,
      couponDiscount: couponLineItem.amount / 100,
      orderAmount: order.amount / 100,
      orderStatus: order.status,
    };
  }) ?? [];

  useHeaderComponentsMutation({
    title: 'COUPONS REPORTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  if (couponReportLoading || eventLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  Total Users
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('coupon_user_count', userCountAmount)}
              </h5>
            </div>
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  Total Amount
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('payment_amount', userCountAmount) / 100}
                {' '}
                {get('default_currency', userCountAmount)}
              </h5>
            </div>
          </div>
          <div className="pt-3" />

          <ExcelFile
            filename={`${get('title', event)}_coupons (${moment().format('YYYY-MM-DD')})`}
            element={<ScoreButton>EXPORT REPORT</ScoreButton>}
          >
            <ExcelSheet data={dataSet} name="Coupons">
              <ExcelColumn label="School" value="school" />
              <ExcelColumn label="MIST ID" value="code" />
              <ExcelColumn label="User Name" value="userName" />
              <ExcelColumn label="Coupon Code" value="couponCode" />
              <ExcelColumn label="Coupon Discount" value="couponDiscount" />
              <ExcelColumn label="Payment Amount (after applying coupon)" value="orderAmount" />
              <ExcelColumn label="Payment Status" value="orderStatus" />
            </ExcelSheet>
          </ExcelFile>
          {!isEmpty(coupons)
            ? (
              <>
                <FontBold>
                  <p>
Coupons (
                    {coupons.length}
)
                  </p>
                </FontBold>
                <List
                  data={coupons}
                  addNewLink=""
                  addNewText=""
                  redirectLink={`events/${id}/coupon`}
                  addOperationRequired={false}
                  couponUser="true"
                  paymentEvent={id}
                  coupon="true"
                />
              </>
            )
            : null}
          <div className="mt-2" />
        </div>
      </div>
    </>
  );
};
export default CouponsReport;
