import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { INVITATION_CODE, INVITED_EVENT_ID } from '../Constants/AppConstants';

const useInvitationCode = () => {
  const [cookies, setCookie] = useCookies([INVITATION_CODE]);
  const { id } = useParams();

  const saveInvitationCode = (invitationCode) => {
    setCookie(INVITATION_CODE, invitationCode, { path: '/' });
    setCookie(INVITED_EVENT_ID, id, { path: '/' });
  };

  const getInvitationCode = cookies.invitationCode;
  const getInvitedEventId = cookies.invitedEventId;

  return {
    saveInvitationCode,
    getInvitationCode,
    getInvitedEventId,
  };
};

export default useInvitationCode;
