import { Form } from 'reactstrap';
import styled from 'styled-components';
import Input from './Input';
import Button from './Button';
import Checkbox from './Checkbox';
import ButtonLink from './ButtonLink';
import RedirectButton from './RedirectButton';
import PermissionButton from './PermissionButton';
import Title from './Title';

const StyledForm = styled(Form)`
`;

export default StyledForm;
export {
  Input,
  Button,
  Checkbox,
  ButtonLink,
  RedirectButton,
  PermissionButton,
  Title,
};
