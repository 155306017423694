/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { omit, get } from 'lodash/fp';
import groupBy from 'lodash/groupBy';
import {
  StyledInput, SearchIcon, SearchSuggestions, InnerFormTitle,
} from '../Auth/Layout';
import { ButtonLink } from '../Form';

const SearchDialogue = (props) => {
  const {
    children,
    data,
    stringlength,
    onClick,
    displayattribute,
    imageattatched,
    groupedsearch,
    emptySearch,
    addSchoolSearch,
    checkPermission,
    displayObject,
  } = props;

  let dataByProperty = null;
  const inputProps = omit([
    'withFormGroup',
    'children',
    'addSchoolSearch',
    'displayObject',
    'emptySearch',
    'checkPermission',
  ])(props);
  const field = <StyledInput {...inputProps}>{children}</StyledInput>;
  if (groupedsearch) {
    dataByProperty = groupBy(data, (counter) => counter.fetchCompetition.categoryId);
  }
  return (
    <>
      <FormGroup>{field}</FormGroup>
      <SearchIcon>
        <span><img src={imageattatched} alt="search" /></span>
      </SearchIcon>
      {stringlength > 0 || emptySearch === true
        ? (
          <>
            {groupedsearch === false
              ? (
                <>
                  {data.map((obj) => (
                    <SearchSuggestions key={obj.id} id={obj.id} onClick={onClick}>
                      {obj[displayattribute]
                        ? <span id={obj.id}>{obj[displayattribute]}</span>

                        : <span id={obj.id}>{`${get('firstname', obj[displayObject])} ${get('lastname', obj[displayObject])}`}</span>}
                    </SearchSuggestions>
                  ))}
                  {addSchoolSearch
                    ? <ButtonLink color="#F4AC49" border="none" addNewText="Add New School +" addNewLink="/schools/new" checkPermission={checkPermission} />
                    : null}
                </>
              )
              : (
                <>
                  {Object.keys(dataByProperty).map((categoryId, index) => (
                    <InnerFormTitle key={categoryId}>
                      <p>
                        {get('fetchCompetition.fetchCategory.title', dataByProperty[categoryId][0])}
                      </p>
                      {Object.values(dataByProperty)[index].map((obj) => (
                        <div key={`${obj.id}`}>
                          <SearchSuggestions key={obj.id} id={obj.id} onClick={onClick}>
                            <span id={obj.id}>{obj.title}</span>
                          </SearchSuggestions>
                        </div>
                      ))}
                    </InnerFormTitle>
                  ))}
                </>
              )}
          </>
        ) : null}

    </>
  );
};

SearchDialogue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.instanceOf(Array).isRequired,
  stringlength: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  displayattribute: PropTypes.string.isRequired,
  imageattatched: PropTypes.string.isRequired,
  groupedsearch: PropTypes.bool,
  emptySearch: PropTypes.bool,
  addSchoolSearch: PropTypes.bool,
  checkPermission: PropTypes.bool,
  displayObject: PropTypes.string,
};

SearchDialogue.defaultProps = {
  children: null,
  groupedsearch: false,
  emptySearch: false,
  addSchoolSearch: null,
  checkPermission: false,
  displayObject: null,
};

export default SearchDialogue;
