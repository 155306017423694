import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import ReactTooltip from 'react-tooltip';
import {
  ImageButton, CollapseBorder, CollapseHeight, WaitListButton,
} from '../../Components/Auth/Layout';
import UP_ICON from '../../Images/icons/up.png';
import DOWN_ICON from '../../Images/icons/down.png';
import Question from '../../Images/icons/question.png';

const EventCompetitionItem = (props) => {
  const {
    competition,
    index,
    onClick,
    backgroundColor,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="pt-2 pb-1 px-0 m-0">
      <CollapseHeight className="container border rounded-top text-white" style={{ background: backgroundColor }}>
        <div className="row ">
          <div className="col-md-6 col-5 font-weight-bold pt-2 ">
            {index + 1}
.
            {get('fetchEventCompetition.title', competition)}
          </div>
          <div className="col-md-6  col-7 pr-0 ">
            <ImageButton
              className="border-0 bg-transparent font-weight-bold float-right"
              onClick={() => toggle()}
            >
              {get('status', competition)}
              {isOpen === true
                ? <img className=" pl-2" src={UP_ICON} alt="up" />
                : <img className=" pl-2" src={DOWN_ICON} alt="down" />}
            </ImageButton>

            <WaitListButton float="right">
              {get('status', competition) === 'waitlist'
                ? (
                  <>
                    <button data-tip data-for={`reason_${competition.id}`} type="button"><img src={Question} alt="question" /></button>
                    <ReactTooltip id={`reason_${competition.id}`}>
                      <span>{competition.reason}</span>
                    </ReactTooltip>
                  </>
                )

                : null}
            </WaitListButton>
          </div>
        </div>
      </CollapseHeight>
      <Collapse isOpen={isOpen}>
        <div className="container ">
          <CollapseBorder className="row  border-top-0 ">
            <div className="col-md-12">
              <div className="px-3 small py-1">
                <p className="pt-3">{get('description', get('fetchCompetition', get('fetchEventCompetition', competition)))}</p>
                {get('status', competition) === 'waitlist'
                  ? (
                    <>
                      <br />
                      <p className="alert alert-warning">
                        <strong>Waitlist Reason: </strong>
                        <br />
                        {competition.reason}
                      </p>
                    </>
                  )

                  : null}
              </div>
              {get('onlineSubmission', competition) === true
                ? (
                  <div className="row text-center pb-2 pt-2 ">
                    <div className="col-md-12 ">
                      <button type="submit" onClick={onClick} className=" text-weight-bold border rounded-lg border-warning btn text-warning ">
                    Submit Files
                      </button>
                    </div>
                  </div>
                )
                : null}
            </div>
          </CollapseBorder>
        </div>
      </Collapse>
    </div>
  );
};

EventCompetitionItem.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};


export default EventCompetitionItem;
