import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_MY_SCHOOLS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateSchoolsCache = (userId) => {
  const client = useApolloClient();
  const updateCache = (school) => {
    try {
      const data = client.readQuery({
        query: FETCH_MY_SCHOOLS,
        variables: {
          userId: parseInt(userId, 10),
        },
      });
      client.writeQuery({
        query: FETCH_MY_SCHOOLS,
        data: {
          mySchools: [
            ...data.mySchools,
            { ...school },
          ],
        },
        variables: {
          userId: parseInt(userId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateSchoolsCache;
