import React from 'react';
import { TextAlignCenter } from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';

const Unverified = () => {
  useHeaderComponentsMutation({ title: 'Verification' });
  return (
    <>
      <TextAlignCenter className="mt-3">
        <h5>Thank you for Signup! </h5>
        <h3 className="mt-3">Please check email to confirm account</h3>
      </TextAlignCenter>

    </>
  );
};
export default Unverified;
