import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import queryString from 'query-string';
import { CURRENT_USER } from '../GraphQL/Queries';
import useInvitationCode from './useInvitationCode';

const useCurrentUser = (redirect = false) => {
  const { data, loading, error } = useQuery(CURRENT_USER, {
    errorPolicy: 'all',
  });
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const currentUser = getOr(null, 'currentUser', data);
  const {
    saveInvitationCode,
  } = useInvitationCode();

  if (redirect && (error || (!loading && !currentUser))) {
    if (params.invitation_code && params.page === 'signup') {
      saveInvitationCode(params.invitation_code);
      history.replace('/sign_up');
    } else if (params.page !== 'signup') {
      if (params.invitation_code) {
        saveInvitationCode(params.invitation_code);
      }
      history.replace('/login');
    }
  }
  return {
    currentUser,
    loading,
    error,
  };
};

export default useCurrentUser;
