import React from 'react';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Box,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

const DISPLAY_TIME_FORMAT = 'MMM dd hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BORDER_COLOR = {
  all: '#53A748',
  competition: '#008DC5',
};

const AnnouncementPropType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  targetType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
});

export const AnnouncementCard = ({ announcement, highlight }) => {
  const createdAtTime = utcToZonedTime(announcement.createdAt, USER_LONG_TZ);
  return (
    <Box
      sx={{
        borderLeftStyle: 'solid',
        borderLeftColor: BORDER_COLOR[announcement.targetType],
        borderLeftWidth: '8px',
        borderRadius: '8',
        background: highlight ? 'orange.100' : '#f2f2f2',
      }}
      width="100%"
      padding="16px"
    >
      <Text size="sm" color="#666666">
        {announcement.targetType === 'competition' ? announcement.fetchCompetitions[0].title : null}
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading size="sm">{announcement.title}</Heading>
        <Text>
          {format(createdAtTime, DISPLAY_TIME_FORMAT)}
        </Text>
      </Flex>
      <Text size="sm" whiteSpace="pre-wrap">{announcement.body}</Text>
    </Box>
  );
};
AnnouncementCard.propTypes = {
  announcement: AnnouncementPropType.isRequired,
  highlight: PropTypes.bool,
};
AnnouncementCard.defaultProps = {
  highlight: false,
};
