/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {
  RIGHTARROW,
  TitleC,
  Hr,
  CounterText,
  ListItemStatus,
} from '../Auth/Layout';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';

const DyanmicLink = ({ external, to, children}) => (
  external ? (
    <a href={to} target="_blank" rel="noopener noreferrer">{children}</a>
  ) : (
    <Link to={to}>{children}</Link>
  )
);

const ListItem = (props) => {
  const {
    title,
    redirectLink,
    checkPermission,
    underLineRequired,
    unapproveSchoolsCount,
    isCount,
    status,
    paymentStatus,
    userCount,
    external,
  } = props;

  return (
    <>
      {checkPermission
        ? (
          <DyanmicLink to={redirectLink} external={external}>
            <div className="row align-items-center">
              <div className="col-md-10 col-8 mt-4 px-4">
                <TitleC>
                  <p>
                    {title}
                  </p>
                </TitleC>
              </div>
              <div className="col-md-1 col-2 mt-2 mx-auto px-4">
                {isCount && unapproveSchoolsCount > 0
                  ? (<CounterText><span>{unapproveSchoolsCount}</span></CounterText>)
                  : (
                    <>
                      {status
                        ? (<ListItemStatus color="#666666"><span>{status}</span></ListItemStatus>)
                        : null}
                    </>
                  )}
                {paymentStatus
                  ? (
                    <ListItemStatus color="#E44E55">
                      <span>{paymentStatus}</span>
                    </ListItemStatus>
                  )
                  : null}
                {isCount && userCount > 0
                    ? (
                        <span className="col-2 mt-9 mx-auto px-4">{userCount}</span>
                    ) : null }
              </div>

              {redirectLink
                ? (
                  <div className="col-md-1 col-2 mt-4 mx-auto px-4">
                    <RIGHTARROW>
                      <img src={RIGHT_ARROW} alt="arrow" className="img-fluid" />
                    </RIGHTARROW>
                  </div>
                )
                : null}
            </div>
          </DyanmicLink>
        )
        : null}
      {checkPermission && underLineRequired === true
        ? <Hr />
        : null}
    </>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  checkPermission: PropTypes.bool,
  underLineRequired: PropTypes.bool,
  unapproveSchoolsCount: PropTypes.string,
  isCount: PropTypes.bool,
  status: PropTypes.string,
  paymentStatus: PropTypes.string,
  external: PropTypes.bool,
};

ListItem.defaultProps = {
  checkPermission: null,
  underLineRequired: true,
  unapproveSchoolsCount: null,
  isCount: false,
  status: null,
  paymentStatus: null,
  external: false,
};

export default ListItem;
