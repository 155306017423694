import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PptxGenJS from 'pptxgenjs';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import { Progress, Input } from 'reactstrap';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { FETCH_EVENT, EVENT_SCORE_PROGRESS_STATS_SUMMARY, EXPORT_SCORE_SUMMARY } from '../../GraphQL/Queries';
import { HelpLabel, LinkContainer } from '../../Components/Auth/Layout';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { RedirectButton } from '../../Components/Form';
import Loader from '../../Components/Loader';

const addMasterSlide = (presentation, templateTitle, textPlaceholders) => {
  const placeholders = textPlaceholders.map((textTitle, idx) => ({
    placeholder: {
      options: {
        name: textTitle,
        type: 'title',
        x: 0.6,
        y: 1.5 + (idx * 1.5),
        w: 12,
        h: 5.25,
      },
      text: `placeholder ${textTitle}`,
    },
  }));

  presentation.defineSlideMaster({
    title: templateTitle,
    background: { color: 'FFFFFF' },
    objects: placeholders,
  });
};

const addWinnerSlide = (presentation, winner, masterName, includeRegion) => {
  if (!winner) {
    return;
  }

  const { judgableName, schoolName, regionName } = winner;
  const placeSlide = presentation.addSlide(masterName);
  placeSlide.addText(judgableName, { placeholder: 'student_name' });
  placeSlide.addText(schoolName, { placeholder: 'school_name' });
  if (includeRegion) {
    placeSlide.addText(regionName, { placeholder: 'region_name' });
  }
};

const buildPowerPointTemplate = (event, content) => {
  const showRegionInfo = event.qualifiedAttendingPreviousEvent;

  const presentation = new PptxGenJS();

  const { competitionWinners, overallWinners } = content;

  addMasterSlide(presentation, 'Name of Event', ['event_title']);
  addMasterSlide(presentation, 'Category', ['category_name']);
  addMasterSlide(presentation, 'Competition', ['competition_name']);
  addMasterSlide(presentation, 'First Place', ['student_name', 'school_name', 'region_name']);
  addMasterSlide(presentation, 'Second Place', ['student_name', 'school_name', 'region_name']);
  addMasterSlide(presentation, 'Third Place', ['student_name', 'school_name', 'region_name']);
  addMasterSlide(presentation, 'First Place School', ['school_name', 'region_name']);
  addMasterSlide(presentation, 'Second Place School', ['school_name', 'region_name']);
  addMasterSlide(presentation, 'Third Place School', ['school_name', 'region_name']);
  addMasterSlide(presentation, 'First Place Region', ['region_name']);
  addMasterSlide(presentation, 'Second Place Region', ['region_name']);
  addMasterSlide(presentation, 'Third Place Region', ['region_name']);

  const titleSlide = presentation.addSlide('Name of Event');
  titleSlide.addText(event.title, { placeholder: 'event_title' });

  for (let i = 0; i < competitionWinners.length; i += 1) {
    const { categoryName, competitions } = competitionWinners[i];
    const categorySlide = presentation.addSlide('Category');
    categorySlide.addText(categoryName, { placeholder: 'category_name' });

    for (let j = 0; j < competitions.length; j += 1) {
      const { competitionName, winners } = competitions[j];
      const competitionSlide = presentation.addSlide('Competition');
      competitionSlide.addText(competitionName, { placeholder: 'competition_name' });

      addWinnerSlide(presentation, winners[2], 'Third Place', showRegionInfo);
      addWinnerSlide(presentation, winners[1], 'Second Place', showRegionInfo);
      addWinnerSlide(presentation, winners[0], 'First Place', showRegionInfo);
    }
  }

  const { topStudents, topSchools, topRegions } = overallWinners;

  const competitionSlide = presentation.addSlide('Competition');
  competitionSlide.addText('Top Overall Student', { placeholder: 'competition_name' });
  addWinnerSlide(presentation, topStudents[2], 'Third Place', showRegionInfo);
  addWinnerSlide(presentation, topStudents[1], 'Second Place', showRegionInfo);
  addWinnerSlide(presentation, topStudents[0], 'First Place', showRegionInfo);

  const schoolSlide = presentation.addSlide('Competition');
  schoolSlide.addText('Top Overall School', { placeholder: 'competition_name' });
  addWinnerSlide(presentation, topSchools[2], 'Third Place', showRegionInfo);
  addWinnerSlide(presentation, topSchools[1], 'Second Place', showRegionInfo);
  addWinnerSlide(presentation, topSchools[0], 'First Place', showRegionInfo);

  if (showRegionInfo) {
    const regionSlide = presentation.addSlide('Competition');
    regionSlide.addText('Top Overall Region', { placeholder: 'competition_name' });
    addWinnerSlide(presentation, topRegions[2], 'Third Place', showRegionInfo);
    addWinnerSlide(presentation, topRegions[1], 'Second Place', showRegionInfo);
    addWinnerSlide(presentation, topRegions[0], 'First Place', showRegionInfo);
  }

  presentation.writeFile(`${event.title}_Awards.pptx`);
};

const ScoreProgress = () => {
  const { id } = useParams();
  const { data: eventDetail } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', eventDetail);

  const { data: summary } = useQuery(
    EVENT_SCORE_PROGRESS_STATS_SUMMARY,
    {
      fetchPolicy: 'network-only',
      variables: { eventId: parseInt(id, 10) },
    },
  );
  const scoreProgressSummary = getOr({}, 'scoredCompetitions', summary);
  const [format, setFormat] = useState('text');

  const [exportScore, { loading: exportScoresLoading }] = useLazyQuery(EXPORT_SCORE_SUMMARY, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.exportScore.format_type === 'text') {
        const extension = format === 'csv' ? 'csv' : 'txt';
        const file = new Blob(response.exportScore.content, { type: 'text/plain' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = `${get('title', event)}_score.${extension}`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      } else if (response.exportScore.format_type === 'power_point_template') {
        buildPowerPointTemplate(event, response.exportScore.content);
      } else {
        const presentation = new PptxGenJS();
        const { content } = response.exportScore;
        content.forEach((lines) => {
          const slide = presentation.addSlide();
          const textboxOpts = {
            x: 1, y: 1, color: '363636', fill: 'ffffff', align: 'left',
          };
          const textboxText = lines.toString();
          slide.addText(textboxText, textboxOpts);
        });
        presentation.writeFile(`${get('title', event)}_Score.pptx`);
      }
    },
  });
  
  useHeaderComponentsMutation({
    title: 'SCORING PROGRESS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  const exportScoreHandler = () => {
    exportScore({ variables: { eventId: parseInt(id, 10), format } });
  };

  return (
    <div className="row">
      <div className="col-12">
        <p className="font-weight-bold pt-3"> Competitions Scored </p>
        <Progress color="warning" value={get('completed_total_competitions', scoreProgressSummary)} max={get('total_competitions', scoreProgressSummary)} />
      </div>
      <div className="col-6">
        <LinkContainer className="float-left">
          <Link to={`/events/${id}/complete-competition`}>
            {get('completed_total_competitions', scoreProgressSummary)}
            {' '}
            Completed
          </Link>
        </LinkContainer>
      </div>

      <div className="col-6">
        <LinkContainer className="float-right">
          <Link to={`/events/${id}/incomplete-competition`}>
            {get('inprogress_total_competitions', scoreProgressSummary)}
            {' '}
            Incompleted
          </Link>
        </LinkContainer>
      </div>

      <div className="col-12 pt-3">
        <p> Export Format </p>
        <Input type="select" name="fileType" id="fileType" value={format} onChange={(e) => setFormat(e.target.value)}>
          <option key="text" value="text">Text</option>
          <option key="script" value="script">Script</option>
          <option key="power_point_template" value="power_point_template">Power Point Template</option>
          <option key="debug" value="debug">Debug</option>
          <option key="csv" value="csv">CSV</option>
        </Input>
        <HelpLabel className="pb-3"> Text/Powerpoint </HelpLabel>

        {exportScoresLoading
          ? <Loader />
          : <RedirectButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="EXPORT" onClick={exportScoreHandler} />}

      </div>
    </div>
  );
};
export default ScoreProgress;
