import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_COUPONS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateCouponsCache = (tenantId) => {
  const client = useApolloClient();
  const updateCache = (coupon, action = 'add') => {
    try {
      const data = client.readQuery({
        query: FETCH_COUPONS,
        variables: {
          tenantId,
        },
      });
      const { fetchCoupons } = data;
      let response = [];
      if (action === 'add') {
        response = fetchCoupons.unshift(coupon);
      } else {
        response = remove((c) => c.id === coupon.id)(fetchCoupons);
      }
      client.writeQuery({
        query: FETCH_COUPONS,
        data: {
          fetchCoupons: response,
        },
        variables: {
          tenantId,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateCouponsCache;
