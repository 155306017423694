import React, { useEffect, useState } from 'react';
import {
  getOr, get, last, isEmpty,
} from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import { add, isFuture } from 'date-fns';
import topScroll from '../../Hooks/useTopScroll';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Form from '../../Components/Form';
import { FETCH_JOIN_EVENT_ROLES, FETCH_REGION_BY_TENANT } from '../../GraphQL/Queries';
import { UPDATE_USER, UPLOAD_PROFILE_IMAGE } from '../../GraphQL/Mutations';
import ImageUpload from '../ImageUpload/Index';
import {
  ProfileImgCircular, ImgCentered, TitleC, DatePickerInput,
} from '../../Components/Auth/Layout';
import uploadImage from '../../Images/dashboard/upload.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import Loader from '../../Components/Loader';
import useInvitationCode from '../../Hooks/useInvitationCode';
import 'react-datepicker/dist/react-datepicker.css';
import { raceChoices } from '../../Constants/RaceConstants';

const getRolesList = (currentUserRole, roles) => {
  if (currentUserRole && !roles.some((r) => r.id === currentUserRole.id)) {
    return roles.concat([currentUserRole]).sort((r1, r2) => r1.id - r2.id);
  }
  return roles.sort((r1, r2) => r1.id - r2.id);
};

const UserProfile = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  // they turn 18 sometime in the future
  const isUnder18 = isFuture(add(selectedDate, { years: 18 }));
  const tempId = 1;
  const extractError = useGraphQLErrorExtractor();
  const checkPermission = useUserPermissionChecker(currentUser);
  const { getInvitationCode, getInvitedEventId } = useInvitationCode();
  const [fetchJoinEventRole, { data: roleData }] = useLazyQuery(FETCH_JOIN_EVENT_ROLES);
  const roles = getRolesList(currentUser?.fetchRole, getOr([], 'fetchJoinEventRole', roleData));

  useEffect(() => {
    if (currentUser) {
      fetchJoinEventRole();
    }
  }, [currentUser, fetchJoinEventRole]);
  const [fetchRegionsByTenant, { data: regionData }] = useLazyQuery(FETCH_REGION_BY_TENANT);
  const regions = getOr([], 'fetchRegionsByTenant', regionData);
  useEffect(() => {
    if (currentUser) {
      fetchRegionsByTenant({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRegionsByTenant]);
  useHeaderComponentsMutation({
    backLink: '/me',
    title: 'EDIT PROFILE',
  });
  const { params, onChange, setParams } = useFormParams({
    firstname: '',
    email: '',
    password: '',
    gender: '',
    phone: '',
    dateOfBirth: '',
    lastname: '',
    perferContactMethod: '',
    accommodationNote: '',
    emergencyContactName: '',
    emergencyContactNumber: '',
    shirtSize: '',
    race: '',
  });
  useEffect(() => {
    if (currentUser) {
      if (currentUser.dateOfBirth) {
        setSelectedDate(new Date(currentUser.dateOfBirth));
      }
      setParams({
        ...currentUser,
        regionId: get('regionId', currentUser),
        roleId: get('id', get('fetchRole', currentUser)),
      });
    }
  }, [currentUser, setParams]);
  const [updateUser, { error, loading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: (data1) => {
        currentUser.fetchRole = data1.updateUser.user.fetchRole;
        if ((checkPermission(Permissions.CAN_JOIN_SCHOOL)
             || checkPermission(Permissions.CAN_JOIN_SCHOOL_OPTIONAL))
             && (isEmpty(get('fetchSchoolsUsers', currentUser))
             || isEmpty(get('fetchUserAttachment', last(get('fetchSchoolsUsers', currentUser)))))
        ) {
          history.push('/school-verification');
        } else if ((getInvitationCode || getInvitedEventId)
          && (checkPermission(Permissions.CAN_JOIN_JUDGE_COMPETITION)
          || checkPermission(Permissions.CAN_MANAGE_REGION)
          // TODO: clean this up
          || currentUser.fetchRole.title === 'Volunteer')) {
          history.push(`/event-manage/${getInvitedEventId}?invitation_code=${getInvitationCode}`);
        } else {
          history.push('/');
        }
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (!get('profileImageUrl', currentUser)) {
      setErrorMessage('Please upload profile picture before continue!');
      topScroll();
    } else {
      params.gender = parseInt(params.genderValue, 10);
      params.status = 'active';
      params.roleId = parseInt(params.roleId, 10);
      params.regionId = parseInt(params.regionId, 10);
      updateUser({
        variables: {
          ...params,
          tenantId: tempId,
        },
      });
    }
  };

  const [uploadProfileImage] = useMutation(
    UPLOAD_PROFILE_IMAGE,
    {
      onCompleted: (data) => {
        if (data.uploadProfileImage.user.profileImageUrl) {
          setErrorMessage('');
          setParams(params);
        }
      },
    },
  );

  const onImageUpload = (d) => {
    params.profileImage = d.signed_id;
    uploadProfileImage({
      variables: {
        ...params,
        id: parseInt(currentUser.id, 10),
      },
    });
  };

  const updateDateOfBirth = (date) => {
    if (currentUser) {
      setSelectedDate(date);
      params.dateOfBirth = moment(date).format('YYYY-MM-DD');
    }
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      { errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="mt-4" />
      <div className="container-fluid ml-n4">
        <div className="row">
          <div className="text-center col-12 ">
            <ProfileImgCircular>
              <ImgCentered className="float-left">
                <ImageUpload
                  onImageUpload={onImageUpload}
                  image={get('profileImageUrl', currentUser) || uploadImage}
                  type="image"
                />
              </ImgCentered>
            </ProfileImgCircular>
          </div>
        </div>
        <div className="row pl-4 mt-n4">
            Upload Picture &nbsp;
          {' '}
          <span className="text-danger">*</span>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <Input
          title="First Name"
          type="text"
          id="firstname"
          name="firstname"
          placeholder="First Name"
          required
          onChange={onChange}
          withFormGroup
          label="First Name"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="text"
          title="Last Name"
          id="lastname"
          name="lastname"
          placeholder="Last Name"
          required
          onChange={onChange}
          withFormGroup
          label="Last name"
          labelClass="sr-only"
          params={params}
        />
        <p>
          <TitleC>
Date of Birth &nbsp;
            {' '}
            <span className="text-danger">*</span>
          </TitleC>
        </p>
        <DatePickerInput>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => updateDateOfBirth(date)}
            dateFormat="MM/dd/yyyy"
            required
            placeholderText="mm/dd/yyyy"
            maxDate={new Date()}
          />
        </DatePickerInput>
        <Input
          title="Gender"
          name="genderValue"
          type="select"
          params={params}
          id="genderValue"
          required
          onChange={onChange}
        >
          <option value="" disabled>Gender</option>
          <option value="0">Male</option>
          <option value="1">Female</option>
        </Input>
        <Input
          title="Phone"
          id="phone"
          name="phone"
          placeholder="Phone Number"
          required
          onChange={onChange}
          withFormGroup
          label="Phone Number"
          labelClass="sr-only"
          params={params}
        />
        <Input
          title="Email"
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          onChange={onChange}
          withFormGroup
          label="email"
          labelClass="sr-only"
          params={params}
          disabled
        />
        <Input
          type="text"
          title="Emergency Contact Name"
          id="emergencyContactName"
          name="emergencyContactName"
          placeholder="Emergency Contact Name"
          required={isUnder18}
          onChange={onChange}
          withFormGroup
          label="Emergency Contact Name"
          labelClass="sr-only"
          params={params}
        />
        <Input
          title="Emergency Contact Phone Number"
          id="emergencyContactNumber"
          name="emergencyContactNumber"
          placeholder="Emergency Contact Phone Number"
          required={isUnder18}
          onChange={onChange}
          withFormGroup
          label="Emergency Contact Phone Number"
          labelClass="sr-only"
          params={params}
        />
        <Input title="Role" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
          <option value="" disabled>Roles</option>
          {roles && roles.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.title}</option>
          ))}
        </Input>
        <Input title="Default Region" name="regionId" type="select" params={params} id="regionId" required onChange={onChange}>
          <option value="" disabled>Regions</option>
          {regions && regions.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.name}</option>
          ))}
        </Input>

        <Input title="T-Shirt Size" name="shirtSize" type="select" params={params} id="shirtSize" onChange={onChange}>
          <option value="" disabled>T-Shirt Size</option>
          <option value="XS" >XS</option>
          <option value="S" >S</option>
          <option value="M" >M</option>
          <option value="L" >L</option>
          <option value="XL" >XL</option>
          <option value="XXL" >XXL</option>
        </Input>


        <Input title="Race/Ethnicity" name="race" type="select" params={params} id="race" required onChange={onChange} info="For demographics research purposes only">
          <option value="" selected disabled>Select Race/Ethnicity</option>
          {Object.keys(raceChoices).map((key) => (
            <option value={key}>{raceChoices[key]}</option>
          ))}
        </Input>



        <Input
          label="accommodationNote"
          name="accommodationNote"
          id="accommodationNote"
          placeholder="Special Accomodations"
          onChange={onChange}
          params={params}
          type="textarea"
          maxLength="1000"
          title="Special Accomodations"
          info="Please list any special accomodations (ex. food allergies, disabilities)"
        />
        <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">
          Continue
        </InputButton>
      </Form>
      <div className="mt-4" />
    </>
  );
};
export default UserProfile;
