import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Input from '../../Components/Form/Input';
import useFormParams from '../../Hooks/useFormParams';
import userDefaultImage from '../../Images/icons/userIcon.png';
import ScoresList from '../../Components/ScoresList/Index';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';
import { FETCH_EVENT, OVERALL_SCORES } from '../../GraphQL/Queries';

const OverallScores = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { params, onChange, setParams } = useFormParams({
    scoreableId: '',
  });
  const { data: event } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );

  const [overallScore, { data: scores }] = useLazyQuery(OVERALL_SCORES);
  useEffect(() => {
    if (currentUser && isEmpty(params.scoreableType)) {
      setParams({ scoreableId: 'User' });
    }
  }, [currentUser, params.scoreableType, setParams]);
  useEffect(() => {
    if (currentUser && id) {
      overallScore({
        variables: {
          eventId: parseInt(id, 10),
          scoreableType: params.scoreableId,
        },
      });
    }
  }, [currentUser, overallScore, id, params]);


  useHeaderComponentsMutation({
    title: 'OVERALL SCORES',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('eventDetail.title', event) }],
  });


  return (
    <>
      <Input title="Filter by:" name="scoreableId" id="scoreableId" type="select" params={params} onChange={onChange} setParams={setParams}>
        <option value="User"> Individual </option>
        <option value="School">School</option>
      </Input>
      { getOr([], 'overallScore.array', scores).map((data, index) => (
        <ScoresList
          key={data.id}
          user={data}
          imageAttached={userDefaultImage}
          index={index}
          resultType={get('overallScore.result_type', scores)}
          arrowIcon={RIGHT_ARROW}
          redirectLink={`/event/${id}/participant/${get('scoreable_type.id', data)}?type=${get('overallScore.result_type', scores)}`}
        />
      ))}
    </>
  );
};
export default OverallScores;
