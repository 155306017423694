import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { CREATE_REGISTRATION_OPTION } from '../../GraphQL/Mutations';
import { FETCH_JOIN_EVENT_ROLES } from '../../GraphQL/Queries';
import {
  FormTitle,
  LinkButton,
} from '../../Components/Auth/Layout';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useUpdateRegistrationOptionsCache from '../../Hooks/useUpdateRegistrationOptionsCache';
import GenericAlert from '../../Components/GenericAlert';
import Form from './RegistrationOptionsForm';

const EVENT_INFO = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      feePercentage
    }
  }
`;

const RegistrationOptionsNew = () => {
  const history = useHistory();
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const updateCache = useUpdateRegistrationOptionsCache(getOr(0, 'tenantId', currentUser), parseInt(id, 10));
  const extractError = useGraphQLErrorExtractor();
  const [fetchJoinEventRole, { data: roleData }] = useLazyQuery(FETCH_JOIN_EVENT_ROLES);
  const roles = getOr([], 'fetchJoinEventRole', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchJoinEventRole({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchJoinEventRole]);

  const { data: eventData, loading: eventLoading } = useQuery(EVENT_INFO, {
    variables: {
      id,
    },
  });

  useHeaderComponentsMutation({ title: 'Registration Options', backLink: `/events/${id}/registration-options` });

  const { params, onChange, setParams } = useFormParams({
    title: '',
    fee: '',
    lateFee: '',
    roleId: '',
    capacity: '',
    earlyDeadline: '',
    finalDeadline: '',
    minAge: '',
    maxAge: '',
    waitlistFormUrl: '',
  });


  const [createRegistrationOption, { loading, error }] = useMutation(CREATE_REGISTRATION_OPTION, {
    onCompleted: (data) => {
      updateCache(data.createRegistrationOption.registrationOption);
      history.push(
        `/events/${data.createRegistrationOption.registrationOption.eventId}/registration-options`,
      );
    },
  });


  const onSubmit = (e) => {
    e.preventDefault();
    params.roleId = parseInt(params.roleId, 10);
    params.lateFee = parseFloat(params.lateFee);
    params.fee = parseFloat(params.fee);
    params.capacity = params.capacity ? parseInt(params.capacity, 10) : 0;
    params.minAge = parseInt(params.minAge, 10);
    params.maxAge = parseInt(params.maxAge, 10);
    createRegistrationOption({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
        eventId: parseInt(id, 10),
      },
    });
  };

  const onCancel = () => {
    history.goBack();
  };


  if (eventLoading) {
    return null;
  }

  const event = eventData?.fetchEventDetail;

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <div className="column pt-3">
        <FormTitle color="#000">Registration Options</FormTitle>
      </div>
      <Form
        className="pt-3"
        onSubmit={onSubmit}
        onChange={onChange}
        params={params}
        loading={loading}
        setParams={setParams}
        roles={roles}
        event={event}
      />
      <div className="row align-items-center pt-3">
        <div className="col-12 mx-auto">
          <LinkButton onClick={() => onCancel()}>Back</LinkButton>
        </div>
      </div>
    </>
  );
};
export default RegistrationOptionsNew;
