import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';

const getDataToShow = (spiritPointRankings, showStudents) => {
  if (showStudents) {
    const { students } = spiritPointRankings;
    return students.map((s) => ({
      id: s.id,
      code: s.fetchUser.code,
      name: `${s.fetchUser.firstname} ${s.fetchUser.lastname}`,
      school: s.fetchSchool?.name,
      totalSpiritPoints: s.totalSpiritPoints,
    }));
  }

  const { teams } = spiritPointRankings;
  return teams.map((t) => ({
    id: t.school.id,
    code: t.code,
    name: t.school.name,
    totalSpiritPoints: t.totalSpiritPoints,
  }));
};

const sorted = (items) => [...items].sort((a, b) => b.totalSpiritPoints - a.totalSpiritPoints);

export const SpiritPointsTable = ({ spiritPointRankings, showStudents }) => {
  const items = sorted(getDataToShow(spiritPointRankings, showStudents));

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Name</Th>
          {showStudents && <Th>School</Th>}
          <Th>Spirit Points</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item, idx) => (
          <Tr key={item.id}>
            <Td>{idx + 1}</Td>
            <Td>{item.name}</Td>
            {showStudents && <Td>{item.school}</Td>}
            <Td>{item.totalSpiritPoints}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
SpiritPointsTable.propTypes = {
  spiritPointRankings: PropTypes.objectOf({
    students: PropTypes.arrayOf({
      code: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      fetchSchool: PropTypes.objectOf({
        name: PropTypes.string.isRequired,
      }),
      totalSpiritPoints: PropTypes.number.isRequired,
    }),
    teams: PropTypes.arrayOf({
      code: PropTypes.string.isRequired,
      fetchSchool: PropTypes.objectOf({
        name: PropTypes.string.isRequired,
      }),
      totalSpiritPoints: PropTypes.number.isRequired,
    }),
  }).isRequired,
  showStudents: PropTypes.bool.isRequired,
};
