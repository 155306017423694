/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {
  Modal, ModalBody, Button, Progress,
} from 'reactstrap';
import { DirectUpload } from '@rails/activestorage';
import styled from 'styled-components';
import Parser from 'html-react-parser';
import styles from '../../Components/Auth/ImageUploadStyles';
import {
  DottedBorder,
} from '../../Components/Auth/Layout';
import GenericAlert from '../../Components/GenericAlert';
import { FILE_SIZE_LIMIT } from '../../Constants/AppConstants';

const CustomMessage = styled.button`
   color: #EF626C;
   font-size: 12px;
   background: transparent;
   border: none;
   text-decoration: underline;
   flex-direction: row !important;
   height: 20px;
`;

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.onAvatarUpload = this.onAvatarUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.setModalOpen = this.setModalOpen.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.directUploadWillStoreFileWithXHR = this.directUploadWillStoreFileWithXHR.bind(this);
    this.directUploadDidProgress = this.directUploadDidProgress.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
  }

  state = {
    modalOpen: false,
    // eslint-disable-next-line react/destructuring-assignment
    imageFile: this.props.image,
    progress: 0,
    // eslint-disable-next-line react/destructuring-assignment
    fileType: this.props.type,
  };

  onAvatarUpload() {
    const { onImageUpload, type } = this.props;
    const { imageFile } = this.state;
    if (imageFile.size < FILE_SIZE_LIMIT
        && (imageFile.type.match('image/png')
        || imageFile.type.match('image/jpeg'))) {
      const upload = new DirectUpload(imageFile, `${process.env.REACT_APP_API_URL}/rails/active_storage/direct_uploads`, this);
      upload.create((error, blob) => {
        if (error) {
          // eslint-disable-next-line no-alert
          alert(error);
        } else {
          this.setState({ progress: 0 });
          const reader = new FileReader();
          // eslint-disable-next-line no-alert
          reader.onabort = () => alert('image preview was aborted');
          // eslint-disable-next-line no-alert
          reader.onerror = () => alert('image preview has failed');
          reader.onload = () => {
            onImageUpload({
              avatar: reader.result,
              signed_id: blob.signed_id,
              type,
            });

            this.setModalOpen(false);
            this.setErrorMessage('');
          };
          reader.readAsDataURL(imageFile);
        }
      });
    } else if (imageFile.size > FILE_SIZE_LIMIT) {
      this.setModalOpen(true);
      this.setErrorMessage('Maximum upload size is 10MB.');
    } else {
      this.setModalOpen(true);
      this.setErrorMessage('You can only upload picture files in png or jpeg format.');
    }
  }

  onDrop([file]) {
    this.setState({ imageFile: file });
  }

  onDelete() {
    this.setState({ imageFile: null });
  }

  setErrorMessage(message) {
    this.setState({ errorMessage: message });
  }

  setModalOpen(modalOpen) {
    this.setState({ modalOpen });
    this.setState({ imageFile: null });
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    this.setState({ progress: (event.loaded / event.total) * 100 });
  }

  render() {
    const {
      modalOpen,
      imageFile,
      progress,
      fileType,
      errorMessage,
    } = this.state;

    const {
      image,
    } = this.props;

    const ALLOWED_FILE_TYPES = ['call_whisphering', 'adviseQueueCallers', 'advisePositionInQueue', 'adviseBeforeConnectingToUser', 'longQueueOptions'];

    let headerText = '';
    if (fileType === 'audio') {
      headerText = 'Upload file';
    } else if (ALLOWED_FILE_TYPES.includes(fileType)) {
      headerText = 'Upload Custom Message';
    } else {
      headerText = 'Upload image';
    }

    const { visibleText } = this.props;
    const { additionalText } = this.props;

    return (
      <>
        <div className="justify-content-center">
          <div className="image-upload-holder" role="button" aria-hidden onClick={() => this.setModalOpen(true)}>
            {(image && fileType === 'image') && (
            <img style={styles.profileImage} src={image} alt="User Avatar" className="circle-default" />)}
            {(!image && fileType === 'image') && (
            <div className="circle-default" />
            )}
            <Modal isOpen={modalOpen} toggle={this.toggleModal} backdrop="static">
              <ModalBody>
              { errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
                <div style={styles.uploadContainer}>
                  <div style={styles.header}>
                    <p className="upload-header">{headerText}</p>
                    <button type="button" style={styles.crossBtn} onClick={() => this.setModalOpen(false)}>x</button>
                  </div>
                  {
                !imageFile
                  ? (
                    <Dropzone onDrop={this.onDrop} acceptedFiles="audio/*">
                      {({ getRootProps, getInputProps }) => (
                        <div className="drop-file" {...getRootProps()}>
                          <input {...getInputProps()} accept="image/x-png,image/jpeg" />
                          <i className="fal fa-file-plus file-plus-icon" />
                          <DottedBorder className=" pl-2 pt-2">
                            <p className="select-file"> Select a File to Upload</p>
                            <p className="font-14">or drag and drop the file here</p>
                          </DottedBorder>
                        </div>
                      )}
                    </Dropzone>
                  ) : (
                    <div style={styles.imageUploadBox}>
                      <div className="image-align d-flex align-items-center justify-content-center" style={styles.dropFileImage}>
                        <div className="image-info d-flex align-items-center justify-content-center">
                          <i style={styles.deleteImageIcon} onMouseDown={this.onDelete} onTouchEnd={this.onDelete} className="fas fa-times-circle delete-image" />
                          <span className="d-flex flex-nowrap">
                            <i style={styles.fileIcon} className="fas fa-file-image" />
                            {imageFile.name}
                          </span>
                        </div>
                        {progress > 0 ? (<Progress value={progress} />) : (
                          <Button onClick={this.onAvatarUpload} color="primary" style={styles.uploadBtn}>
                            <i className="fas fa-file-upload" style={styles.uploadBtnIcon} />
                              Upload
                          </Button>
                        )}
                      </div>
                    </div>
                  )
              }
                </div>
              </ModalBody>
            </Modal>
            { (fileType === 'audio')
            && (
              <div>
                <i className="fal fa-file-audio" />
                <span className="text-white"> Choose media</span>
              </div>
            )}

          </div>
          { (fileType === 'image')
          && (
            <button type="button" style={{width: "100%"}} className="btn avatar-btn mx-auto" aria-hidden onClick={() => this.setModalOpen(true)}>
              <>
                {visibleText}
                <p style={{ color: '#A6A6A6' }}>{Parser(additionalText)}</p>
              </>
            </button>
          )}
        </div>
        { ALLOWED_FILE_TYPES.includes(fileType)
       && (
         <>
           <CustomMessage type="button" aria-hidden onClick={() => this.setModalOpen(true)}>
       Upload Custom Recording

           </CustomMessage>
         </>
       )}
      </>
    );
  }
}

ImageUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      preview: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  type: PropTypes.string.isRequired,
  visibleText: PropTypes.string,
  additionalText: PropTypes.string,
};

ImageUpload.defaultProps = {
  image: '',
  visibleText: '',
  additionalText: '',
};


export default ImageUpload;
