import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import useFormParams from '../../Hooks/useFormParams';
import { FETCH_EVENT_USER_DETAIL, FETCH_SCHOOLS_USER } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import UserDetail from '../../Components/UserDetail/Index';
import Form, { RedirectButton, Input } from '../../Components/Form';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { DENY_EVENT_USER, APPROVED_EVENT_USER_BY_ADMIN } from '../../GraphQL/Mutations';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import TRIANGLE_ARROW from '../../Images/icons/triangle.png';
import BackLink from '../../Components/BackLink/Index';
import useUpdateUnapprovedSchoolsCache from '../../Hooks/useUpdateUnapprovedSchoolsCache';


const CaptainRequestDetail = () => {
  const history = useHistory();
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const [modal, setModal] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const { id } = useParams();
  const { eventId } = useParams();
  const { params, onChange, setParams } = useFormParams({
    comment: '',
    reason: '',
  });
  const [eventUser, setFetchEventsUserDetail] = useState({});
  const updateCache = useUpdateUnapprovedSchoolsCache(eventId);
  const extractError = useGraphQLErrorExtractor();
  const { loading, error } = useQuery(
    FETCH_EVENT_USER_DETAIL,
    {
      onCompleted: (data) => {
        setFetchEventsUserDetail(data.fetchEventsUserDetail);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  const [denyEventUser] = useMutation(
    DENY_EVENT_USER,
    {
      onCompleted: () => {
        history.push(`/event-manage/${eventUser.eventId}/team-captains-by-school/${eventUser.schoolId}`);
      },
    },
  );

  const onSubmit = (e) => {
    if (currentUser) {
      e.preventDefault();
      params.id = parseInt(id, 10);
      denyEventUser({
        variables: {
          ...params,
          status: 3,
        },
      });
    }
  };

  const [approveCaptainByAdmin, { loading: approveLoading }] = useMutation(
    APPROVED_EVENT_USER_BY_ADMIN,
    {
      onCompleted: () => {
        updateCache();
        history.push(`/event-manage/${eventUser.eventId}/unapprove-teams`);
      },
    },
  );

  const approveEventUserRequest = () => {
    approveCaptainByAdmin({
      variables: {
        id,
        status: 2,
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(params.reason)) {
      setParams({
        comment: params.reason,
        reason: params.reason,
      });
    }
  }, [params.reason, setParams]);

  const [fetchSchoolsUser,
    { data: schoolsUser }] = useLazyQuery(FETCH_SCHOOLS_USER);
  const schoolUsers = getOr([], 'fetchSchoolsUser', schoolsUser);

  useEffect(() => {
    if (currentUser && !isEmpty(eventUser)) {
      fetchSchoolsUser({
        variables: {
          userId: parseInt(eventUser.userId, 10),
          schoolId: parseInt(eventUser.schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchSchoolsUser, eventUser]);


  const onImagePreview = (url) => {
    setModal(true);
    setImagePreviewUrl(url);
  };

  const toggle = () => setModal(!modal);

  useHeaderComponentsMutation({
    backLink: `/event-manage/${eventUser.eventId}/team-captains-by-school/${eventUser.schoolId}`,
    title: 'APPROVE / DENY',
    components: [{ key: EVENT_TITLE, value: get('title', eventUser.fetchEvent) }],
  });

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <UserDetail
        data={eventUser}
        imageAttached={userDefaultImage}
        roleTitle="true"
        dataType="events"
        onClick={(url) => onImagePreview(url)}
        schoolUser={schoolUsers}
      />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <RedirectButton loading={approveLoading} backgroundcolor="#F4AB37" addNewText="APPROVE USER" onClick={() => approveEventUserRequest()} />
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 mx-auto px-4">
            <Input type="select" id="reason" name="reason" onChange={onChange} params={params}>
              <option value="" disabled>Select Reason for Denial</option>
              <option value="Your picture is not valid. Please update your profile picture and respond to this message to re-apply to join this team.">Picture not valid</option>
              <option value="Please update your profile information and respond to this message to reapply to join this school’s team.">Information inaccurate</option>
              <option value="Your school verification is invalid. Please go to your profile and upload a valid School ID or government-issued picture ID. Then, respond to this message to re-apply to join this team.">Verification not valid</option>
              <option value="Please update your profile and choose the correct school. Then, please respond to this conversation to reapply to join the correct school.">Wrong school</option>
            </Input>
            <Input
              title="Comment"
              name="comment"
              id="comment"
              placeholder="Comment"
              onChange={onChange}
              type="textarea"
              params={params}
              required
              rows="6"
            />
            <RedirectButton htmltype="submit" backgroundcolor="#FFF" fontColor="rgba(244, 171, 55, 1)" border="2px solid #f3AA44" addNewText="DENY" />
          </div>
        </div>
      </Form>
      <div className="col-12 text-center my-5">
        <BackLink text="Back" imageAttached={TRIANGLE_ARROW} redirectLink={`/event-manage/${eventUser.eventId}/unapprove-schools`} />
      </div>
      <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
        <ModalHeader toggle={toggle}>File Preview </ModalHeader>
        <ModalBody>
          <img src={imagePreviewUrl} alt="arrow" className="img-fluid" />
        </ModalBody>
      </Modal>
    </>
  );
};
export default CaptainRequestDetail;
