import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { INVITE_USER } from '../../GraphQL/Mutations';

import {
  FETCH_ROLES_BY_LEVEL,
  FETCH_MY_EVENTS,
  FETCH_ROLE_DETAIL,
  FETCH_REGISTRATION_OPTIONS,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Form from '../../Components/Form';
import useRolePermissionChecker from '../../Hooks/useRolePermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';

const InviteUser = () => {
  const history = useHistory();
  const {
    currentUser,
  } = useCurrentUser(true);
  const extractError = useGraphQLErrorExtractor();
  const [fetchRolesByLevel, { data: roleData }] = useLazyQuery(FETCH_ROLES_BY_LEVEL);
  const { data: eventData } = useQuery(FETCH_MY_EVENTS);
  const [fetchRegistrationOptions, { data: registrationData }] = useLazyQuery(
    FETCH_REGISTRATION_OPTIONS,
  );
  const roles = getOr([], 'fetchRolesByLevel', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchRolesByLevel({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRolesByLevel]);

  const events = getOr([], 'myEvents', eventData);
  const registrations = getOr([], 'fetchRegistrationOptions', registrationData);

  const [modal, setModal] = useState(false);
  useHeaderComponentsMutation({
    title: 'INVITE NEW USER',
    backLink: '/invite-existing-user',
  });

  const [inviteUser, { loading, error }] = useMutation(INVITE_USER, {
    onCompleted: () => {
      setModal(true);
    },
  });

  const [fetchRoleDetail, { data: roleDetail }] = useLazyQuery(FETCH_ROLE_DETAIL);
  const role = getOr([], 'fetchRoleDetail', roleDetail);

  const checkRolePermission = useRolePermissionChecker(role);

  const { params, onChange } = useFormParams({
    email: '',
    firstname: '',
    lastname: '',
    roleId: '',
    invitableType: 'Event',
    invitableId: '',
    registrationOptionId: '',
  });

  useEffect(() => {
    if (currentUser) {
      fetchRegistrationOptions({
        variables: {
          tenantId: parseInt(currentUser.tenantId, 10),
          eventId: parseInt(params.invitableId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptions, params.invitableId]);

  useEffect(() => {
    fetchRoleDetail({
      variables: {
        id: parseInt(params.roleId, 10),
      },
    });
  }, [params.roleId, fetchRoleDetail]);


  const onSubmit = (e) => {
    e.preventDefault();
    params.roleId = parseInt(params.roleId, 10);
    params.invitableId = parseInt(params.invitableId, 10);
    params.registrationOptionId = parseInt(params.registrationOptionId, 10);
    inviteUser({
      variables: {
        ...params,
      },
    });
  };


  const toggle = () => setModal(!modal);
  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <Form onSubmit={onSubmit}>
        <Input
          title="First Name"
          params={params}
          withFormGroup
          type="text"
          id="firstname"
          name="firstname"
          placeholder="First Name"
          required
          onChange={onChange}
          label="First Name"
          labelClass="sr-only"
        />
        <Input
          title="Last Name"
          params={params}
          withFormGroup
          type="text"
          id="lastname"
          name="lastname"
          placeholder="Last Name"
          required
          onChange={onChange}
          label="Last Name"
          labelClass="sr-only"
        />
        <Input
          title="Email"
          params={params}
          withFormGroup
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          onChange={onChange}
          label="Email"
          labelClass="sr-only"
        />
        <Input title="Events" name="invitableId" type="select" params={params} id="invitableId" required onChange={onChange}>
          <option value="" selected disabled>Select Event</option>
          {events && events.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.title}</option>
          ))}
        </Input>
        <Input title="Roles" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
          <option value="" disabled>Select Roles</option>
          {roles && roles.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.title}</option>
          ))}
        </Input>
        {checkRolePermission(Permissions.CAN_JOIN_EVENT)
          ? (
            <Input title="Registrations" name="registrationOptionId" type="select" params={params} id="registrationOptionId" required onChange={onChange}>
              <option value="" selected disabled>Select Registration</option>
              {registrations && registrations.map((obj) => (
                <option key={obj.id} value={obj.id}>{obj.title}</option>
              ))}
            </Input>
          )
          : null}

        <div className="d-block" style={{ paddingBottom: '1em' }}>
          <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Send</InputButton>
        </div>
      </Form>
      <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
        <ModalHeader toggle={toggle}>Success!</ModalHeader>
        <ModalBody>
          <h5>
            Invitation has been sent to
            <br />
            &lt;
            {params.email}
            &gt;
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => history.push('/users')}>Done</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default InviteUser;
