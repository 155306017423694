import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FETCH_ROLES } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const RoleIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [fetchRoles, { loading, error, data }] = useLazyQuery(FETCH_ROLES);
  const roles = getOr([], 'fetchRoles', data);
  useHeaderComponentsMutation({
    title: 'MANAGE ROLES',
    backLink: '/dashboard',
  });
  useEffect(() => {
    if (currentUser) {
      fetchRoles({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRoles]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <List data={roles} displayAttribute="title" redirectLink="role-permissions" addNewText="Add New Role" addNewLink="roles/new" redirectOperation="" dataType="roles" />
        <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Role" addNewLink="roles/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Role'))} />
      </div>
    </div>
  );
};

export default RoleIndex;
