import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import {
  GoBack, Hr,
} from '../Auth/Layout';


const BackLink = (props) => {
  const {
    text,
    redirectLink,
    imageAttached,
  } = props;

  return (
    <>
      <Link to={`${redirectLink}`}>
        <div className="row align-items-center">
          <div className="col-12">
            <GoBack>
              <img src={imageAttached} alt="arrow" />
&nbsp;
              {text}
            </GoBack>
          </div>
        </div>
      </Link>
      <br />
      <Hr />
    </>
  );
};

BackLink.propTypes = {
  text: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  imageAttached: PropTypes.string,
};

BackLink.defaultProps = {
  imageAttached: null,
};

export default BackLink;
