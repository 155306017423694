import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { NEW_PASSWORD } from '../../GraphQL/Mutations';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useCurrentUser from '../../Hooks/userCurrentUser';
import BackLink from '../../Components/BackLink';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Form from '../../Components/Form';

const FormTitle = styled.h4`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20px;
  color: rgba(244, 171, 55, 1);
`;

const FormInstruction = styled.p`
  padding-top: 10px;
  font-size: 15px;
  line-height: 19px;
  color: rgba(41, 41, 41, 1);
  font-family: 'nunito_semibold';
`;

const NewPassword = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const { currentUser } = useCurrentUser();
  const [reponseMessage, setReponseMessage] = useState('');
  useHeaderComponentsMutation({ title: 'RESET PASSWORD' });
  const extractError = useGraphQLErrorExtractor();
  const [newPassword, { loading, error }] = useMutation(NEW_PASSWORD, {
    onCompleted: (data) => {
      if (data.forgotPassword.active) {
        setModal(true);
      } else {
        setReponseMessage(data.forgotPassword.result);
      }
    },
  });

  const { params, onChange } = useFormParams({
    email: '',
  });

  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  const onSubmit = (e) => {
    e.preventDefault();
    newPassword({ variables: params });
  };
  const toggle = () => setModal(!modal);

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      { reponseMessage ? <GenericAlert>{reponseMessage}</GenericAlert> : null}
      <Form onSubmit={onSubmit}>
        <div className="column">
          <FormTitle style={{ paddingTop: '3rem' }}>Forgot your Passphrase?</FormTitle>
          <FormInstruction>
            Enter your email below to be sent a
            <br />
             password reset link
          </FormInstruction>
        </div>
        <Input
          title="Email"
          params={params}
          withFormGroup
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          onChange={onChange}
          label="Email"
          labelClass="sr-only"
        />
        <div className="d-block">
          <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Send</InputButton>
        </div>
      </Form>
      <BackLink to="/login">
        Log In
      </BackLink>
      <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
        <ModalHeader toggle={toggle}>Success!</ModalHeader>
        <ModalBody>
          <h5>
            Passphrase reset link sent to
            <br />
            &lt;
            {params.email}
            &gt;
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => history.push('/login')}>Done</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default NewPassword;
