import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import ReactExport from 'react-export-excel';
import { compareAsc } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Button } from '@chakra-ui/react';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const DISPLAY_DATE_FORMAT = 'yyyy-MM-dd hh:mm aa z';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;


const SPIRIT_POINT_EXPORT = gql`
query fetchEventDetail($id: Int!) {
  fetchEventDetail(id: $id) {
    id
    title
    spiritPointRankings {
      students {
        id
        fetchUser {
          id
          firstname
          lastname
          code
        }
        fetchSchool {
          id
          name
        }
        totalSpiritPoints
        spiritPoints {
          id
          amount
          description
          createdAt
        }
      }
    }
  }
}
`;

const Download = ({ eventName, spiritPointRankings }) => {
  const sorted = [...spiritPointRankings.students].sort(compareAsc);
  const dataset = sorted.flatMap((s) => s.spiritPoints.map((sp) => ({
    code: s.fetchUser.code,
    name: `${s.fetchUser.firstname} ${s.fetchUser.lastname}`,
    school: s.fetchSchool?.name,
    amount: sp.amount,
    description: sp.description,
    timestamp: format(utcToZonedTime(sp.createdAt, USER_LONG_TZ), DISPLAY_DATE_FORMAT),
  })));

  return (
    <ExcelFile filename={`${eventName}_spirit_points_(${format(new Date(), 'yyyy-MM-dd')})`} hideElement>
      <ExcelSheet data={dataset} name="spirit points">
        <ExcelColumn label="Timestamp" value="timestamp" />
        <ExcelColumn label="MIST ID" value="code" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="School" value="school" />
        <ExcelColumn label="Points" value="amount" />
        <ExcelColumn label="Description" value="description" />
      </ExcelSheet>
    </ExcelFile>
  );
};
Download.propTypes = {
  eventName: PropTypes.string.isRequired,
  spiritPointRankings: PropTypes.objectOf({
    students: PropTypes.arrayOf({
      code: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      fetchSchool: PropTypes.objectOf({
        name: PropTypes.string.isRequired,
      }),
      spiritPoints: PropTypes.arrayOf({
        amount: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export const ExportButton = ({ eventId, eventName }) => {
  const [exportSpiritPoints, {
    data: exportData,
    loading: exportLoading,
  }] = useLazyQuery(SPIRIT_POINT_EXPORT, {
    fetchPolicy: 'network-only',
    variables: {
      id: eventId,
    },
  });

  return (
    <>
      {exportData && (
        <Download
          eventName={eventName}
          spiritPointRankings={exportData.fetchEventDetail.spiritPointRankings}
        />
      )}
      <Button
        background="brand.orange.500"
        color="white"
        isLoading={exportLoading}
        onClick={exportSpiritPoints}
        paddingBlock={4}
        paddingInline={8}
        borderRadius={16}
      >
        Export Spirit Points Report
      </Button>
    </>
  );
};
ExportButton.propTypes = {
  eventId: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
};
