import { useApolloClient } from '@apollo/react-hooks';
import { CHECK_TEAM_FINALIZE } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateFinlizeTeamStatusCache = (eventId, schoolId) => {
  const client = useApolloClient();
  const updateCache = (response) => {
    try {
      client.writeQuery({
        query: CHECK_TEAM_FINALIZE,
        data: {
          checkTeamFinalize: response,
        },
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateFinlizeTeamStatusCache;
