import React, { useEffect, useState } from 'react';
import {
  getOr, get, isEmpty, last,
} from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import Form from '../../Components/Form';
import { FETCH_JOIN_EVENT_ROLES, FETCH_REGION_BY_TENANT } from '../../GraphQL/Queries';
import { UPDATE_USER, UPLOAD_PROFILE_IMAGE } from '../../GraphQL/Mutations';
import ImageUpload from '../ImageUpload/Index';
import {
  ProfileImgCircular, ImgCentered,
} from '../../Components/Auth/Layout';
import uploadImage from '../../Images/dashboard/upload.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import topScroll from '../../Hooks/useTopScroll';
import useInvitationCode from '../../Hooks/useInvitationCode';

const UserEdit = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const tempId = 1;
  const checkPermission = useUserPermissionChecker(currentUser);
  const { getInvitationCode, getInvitedEventId } = useInvitationCode();
  const [fetchJoinEventRole, { data: roleData }] = useLazyQuery(FETCH_JOIN_EVENT_ROLES);
  const roles = getOr([], 'fetchJoinEventRole', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchJoinEventRole({
      });
    }
  }, [currentUser, fetchJoinEventRole]);
  useHeaderComponentsMutation({ title: 'COMPLETE YOUR PROFILE' });
  const { params, onChange, setParams } = useFormParams({
    firstname: '',
    email: '',
    password: '',
    gender: '',
    phone: '',
    dateOfBirth: '',
    lastname: '',
    perferContactMethod: '',
    accommodationNote: '',
  });
  const [fetchRegionsByTenant, { data: regionData }] = useLazyQuery(FETCH_REGION_BY_TENANT);
  const regions = getOr([], 'fetchRegionsByTenant', regionData);
  useEffect(() => {
    if (currentUser) {
      setParams(currentUser);
    }
  }, [currentUser, setParams]);
  useEffect(() => {
    if (currentUser) {
      fetchRegionsByTenant({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRegionsByTenant]);
  const [updateUser] = useMutation(
    UPDATE_USER,
    {
      onCompleted: (data1) => {
        currentUser.fetchRole = data1.updateUser.user.fetchRole;
        if (checkPermission(Permissions.CAN_JOIN_SCHOOL) && isEmpty(get('fetchUserAttachment', last(get('fetchSchools', currentUser))))) {
          history.push('/school-verification');
        } else if ((getInvitationCode || getInvitedEventId)
          && checkPermission(Permissions.CAN_JOIN_JUDGE_COMPETITION)) {
          history.push(`/event-manage/${getInvitedEventId}?invitation_code=${getInvitationCode}`);
        } else {
          history.push('/');
        }
      },
    },
  );

  const [uploadProfileImage] = useMutation(
    UPLOAD_PROFILE_IMAGE,
    {
      onCompleted: (data) => {
        if (data.uploadProfileImage.user.profileImageUrl) {
          setErrorMessage('');
        }
      },
    },
  );

  const onImageUpload = (d) => {
    params.profileImage = d.signed_id;
    uploadProfileImage({
      variables: {
        ...params,
        id: parseInt(currentUser.id, 10),
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!get('profileImageUrl', currentUser)) {
      setErrorMessage('Please upload profile picture before continue!');
      topScroll();
    } else {
      params.gender = parseInt(params.genderValue, 10);
      params.status = 'active';
      params.roleId = parseInt(params.roleId, 10);
      params.regionId = parseInt(params.regionId, 10);
      updateUser({
        variables: {
          ...params,
          tenantId: tempId,
        },
      });
    }
  };

  return (
    <>
      <>
        {errorMessage
          ? <GenericAlert>{errorMessage}</GenericAlert>
          : null}
      </>
      <div className="mt-4" />
      <div className="container-fluid">
        <div className="row">
          <div className="text-center col-12 ">
            <ProfileImgCircular>
              <ImgCentered>
                <ImageUpload
                  onImageUpload={onImageUpload}
                  image={get('profileImageUrl', currentUser) || uploadImage}
                  type="image"
                  visibleText="Upload Picture *"
                />
              </ImgCentered>
            </ProfileImgCircular>
          </div>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <Input
          title="First Name"
          type="text"
          id="firstname"
          name="firstname"
          placeholder="First Name"
          required
          onChange={onChange}
          withFormGroup
          label="First Name"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="text"
          title="Last Name"
          id="lastname"
          name="lastname"
          placeholder="Last Name"
          required
          onChange={onChange}
          withFormGroup
          label="Last name"
          labelClass="sr-only"
          params={params}
        />
        <Input
          title="Date of Birth"
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          placeholder="Date of Birth"
          required
          onChange={onChange}
          withFormGroup
          label="Date of Birth"
          labelClass="sr-only"
          params={params}
          max={moment().format('YYYY-MM-DD')}
        />
        <Input
          title="Gender"
          name="genderValue"
          type="select"
          params={params}
          id="genderValue"
          required
          onChange={onChange}
        >
          <option value="" disabled>Gender</option>
          <option value="0">Male</option>
          <option value="1">Female</option>
        </Input>
        <Input
          title="Phone"
          id="phone"
          name="phone"
          placeholder="Phone Number"
          required
          onChange={onChange}
          withFormGroup
          label="Phone Number"
          labelClass="sr-only"
          params={params}
        />
        <Input
          title="Email"
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
          onChange={onChange}
          withFormGroup
          label="email"
          labelClass="sr-only"
          params={params}
          disabled
        />
        <Input title="Role" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
          <option value="" disabled>Roles</option>
          {roles && roles.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.title}</option>
          ))}
        </Input>
        <Input title="Default Region" name="regionId" type="select" params={params} id="regionId" required onChange={onChange}>
          <option value="" disabled>Regions</option>
          {regions && regions.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.name}</option>
          ))}
        </Input>
        <p>Please list any special accomodations</p>
        <p>(ex. food allergies, disabilities)</p>
        <Input
          label="accommodationNote"
          name="accommodationNote"
          id="accommodationNote"
          placeholder="Special Accomodations"
          onChange={onChange}
          params={params}
          type="textarea"
          maxLength="1000"
        />
        <InputButton htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">
          Continue
        </InputButton>
      </Form>
      <div className="mt-4" />
    </>
  );
};

export default UserEdit;
