import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import {
  FETCH_EVENT_HEADER,
  FETCH_REGISTRATION_OPTION_DETAIL,
  FETCH_ORDER_DETAIL,
} from '../../GraphQL/Queries';
import { UPDATE_ORDER_MERCH } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { Button, Checkbox } from '../../Components/Form';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import BackLink from '../../Components/BackLink';
import Loader from '../../Components/Loader';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import JoinEventSlider from './JoinEventSlider';

const JoinEventMerch = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();

  const { orderId } = useParams();
  const [order, setOrder] = useState({});
  const [selectedMerchandises, setSelectedMerchandises] = useState({}); // ids to merch obj

  const [fetchEventHeader, {
    data: dataFetchEventHeader,
    loading: eventLoading,
  }] = useLazyQuery(FETCH_EVENT_HEADER, {
    onCompleted: (data) => {
      const event = data.fetchEventDetail;
      const existingMerchEntries = order.fetchLineItems
        .filter((li) => li.lineItemAbleType === 'Merchandise')
        .map((li) => [
          li.lineItemAbleId,
          event.merchandise.find((m) => m.id === li.lineItemAbleId.toString()),
        ]);
      setSelectedMerchandises(Object.fromEntries(existingMerchEntries));
    },
  });

  const event = getOr({}, 'fetchEventDetail', dataFetchEventHeader);

  const onChangeMerchandise = (newSelectedId, isSelected) => {
    const newSelectedMerchandise = event.merchandise.find((m) => m.id === newSelectedId);
    let newSelected;
    if (isSelected) {
      newSelected = {
        ...selectedMerchandises,
        [newSelectedId]: newSelectedMerchandise,
      };
    } else {
      newSelected = Object.fromEntries(
        Object.entries(selectedMerchandises).filter(([mId]) => mId !== newSelectedId),
      );
    }
    setSelectedMerchandises(newSelected);
  };

  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));


  const [fetchRegistrationOptionDetail, { data: dataRegistrationOption }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const option = getOr({}, 'fetchRegistrationOptionDetail', dataRegistrationOption);

  const [fetchOrderDetail, {
    data: dataFetchOrderDetail,
    loading: orderLoading,
  }] = useLazyQuery(FETCH_ORDER_DETAIL);

  useEffect(() => {
    if (dataFetchOrderDetail?.fetchOrderDetail && order !== {}) {
      const orderData = dataFetchOrderDetail.fetchOrderDetail;
      setOrder(orderData);
      fetchEventHeader({
        variables: {
          id: parseInt(orderData.eventId, 10),
        },
      });
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(orderData.fetchLineItems[0].lineItemAbleId, 10),
        },
      });
    }
  }, [dataFetchOrderDetail, fetchEventHeader, fetchRegistrationOptionDetail, order]);

  const [addMerchToOrder, { error, loading }] = useMutation(
    UPDATE_ORDER_MERCH, {
      onCompleted: (data) => {
        updateOrdersCache(data.updateOrderMerch.order, 'update');
        history.push(`/order/${orderId}/cart`);
      },
    },
  );

  useHeaderComponentsMutation({
    title: 'ADD-ONS',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });


  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);


  const onSubmit = (e) => {
    e.preventDefault();
    addMerchToOrder({
      variables: {
        id: parseInt(orderId, 10),
        merchandiseIds: Object.keys(selectedMerchandises),
      },
    });
  };

  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  if (loading || eventLoading || orderLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={option}
          />
          <p style={{ fontWeight: '600' }}>Available Add-Ons</p>
          <Form onSubmit={onSubmit}>
            <div style={{ margin: 'auto', textAlign: 'center' }} />
            {event?.merchandise?.map((m) => (
              <Checkbox
                key={m.id}
                text={`${m.title} ($${m.cost / 100})`}
                onChange={onChangeMerchandise}
                id={m.id}
                name={m.id}
                checked={!!selectedMerchandises[m.id]}
              />
            ))}
            <Button
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="2px solid #FFF"
              marginbottom="-2rem"
              addNewText="PAY NOW"
              permissionName="View Event"
              currentUser={currentUser}
              htmltype="submit"
            >
            CONTINUE
            </Button>
            <div className="pt-3" />
          </Form>
          <BackLink to={`/event-manage/${order.eventId}`} imageNotRequired>
                Skip For Now
          </BackLink>
        </div>
      </div>
    </>
  );
};

export default JoinEventMerch;
