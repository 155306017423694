import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import { FETCH_EVENT_USER_DETAIL, FETCH_EVENT_USER_COMMENTS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { RedirectButton, Input } from '../../Components/Form';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { DENY_EVENT_USER, APPROVED_EVENT_USER_BY_ADMIN } from '../../GraphQL/Mutations';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import Message from '../../Components/Message/Index';
import {
  TitleC, RoundImg, GoBack, ConversationDiv,
} from '../../Components/Auth/Layout';


const EventUserConversation = () => {
  const history = useHistory();
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { params, onChange } = useFormParams({
    comment: '',
  });
  const [eventUser, setFetchEventsUserDetail] = useState({});
  const [eventUsersComments, setEventUsersComments] = useState([]);
  const extractError = useGraphQLErrorExtractor();
  const { loading, error } = useQuery(
    FETCH_EVENT_USER_DETAIL,
    {
      onCompleted: (data) => {
        setFetchEventsUserDetail(data.fetchEventsUserDetail);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  const [fetchEventUserComments] = useLazyQuery(
    FETCH_EVENT_USER_COMMENTS, {
      onCompleted: (data) => {
        setEventUsersComments(data.fetchEventUserComments);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchEventUserComments({
        variables: {
          eventsUsersId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventUserComments, id]);

  const [denyEventUser] = useMutation(
    DENY_EVENT_USER,
    {
      onCompleted: () => {
        history.push(`/event-manage/${eventUser.eventId}/team-captains-by-school/${eventUser.schoolId}`);
      },
    },
  );


  const onSubmit = (e) => {
    if (currentUser) {
      e.preventDefault();
      params.id = parseInt(id, 10);
      denyEventUser({
        variables: {
          ...params,
          status: 3,
        },
      });
    }
  };

  const [approveCaptainByAdmin] = useMutation(
    APPROVED_EVENT_USER_BY_ADMIN,
    {
      onCompleted: () => {
        history.push(`/event-manage/${eventUser.eventId}/unapprove-schools`);
      },
    },
  );

  const approveEventUserRequest = () => {
    approveCaptainByAdmin({
      variables: {
        id,
        status: 2,
      },
    });
  };

  useHeaderComponentsMutation({
    backLink: `/event-manage/${eventUser.eventId}/team-captains-by-school/${eventUser.schoolId}`,
    title: 'APPROVE / DENY',
    components: [{ key: EVENT_TITLE, value: get('title', eventUser.fetchEvent) }],
  });

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-md-1 col-3 mt-4 px-4">
          { get('profileImageUrl', eventUser.fetchUser)
            ? <RoundImg><img src={eventUser.fetchUser.profileImageUrl} alt="User-Icon" /></RoundImg>
            : <RoundImg><img src={userDefaultImage} alt="User-Icon" /></RoundImg>}
        </div>
        <div className="col-md-9 col-7 mt-4 px-4">
          <TitleC>
            <p>
              {`${get('firstname', eventUser.fetchUser)} ${get('lastname', eventUser.fetchUser)}`}
            </p>
            <GoBack>
              <p>
                MIST ID
                {' '}
                {`${get('code', eventUser.fetchUser)}`}
              </p>
            </GoBack>
          </TitleC>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mx-auto px-4">
          <RedirectButton backgroundcolor="#FFF" fontColor="black" border="1px solid black" addNewText="VIEW" addNewLink={`/event-manage/${eventUser.eventId}/team-captains/${eventUser.id}`} breakLine={false} />
        </div>
        <div className="col-6 mx-auto px-4">
          <RedirectButton backgroundcolor="#343434" fontColor="#FFF" border="1px solid #FFF" addNewText="APPROVE" onClick={() => approveEventUserRequest()} breakLine={false} />
        </div>
      </div>
      <div className="mt-4" />
      <ConversationDiv>
        <div className="row">
          <div className="col-12 mx-auto px-4 pt-3">
            <p className="center">Start of the Conversation</p>
          </div>
        </div>
        <div className="col-12 text-center mt-4">
          <span><Message data={eventUsersComments} displayAttribute="comment" /></span>
        </div>
      </ConversationDiv>
      <Form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 mx-auto px-4">
            <Input
              title="Message"
              name="comment"
              id="comment"
              placeholder="Comment"
              onChange={onChange}
              type="textarea"
              params={params}
              required
              rows="6"
            />
            <RedirectButton htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="DENY" />
          </div>
        </div>
      </Form>
    </>
  );
};
export default EventUserConversation;
