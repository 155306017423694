import { useApolloClient } from '@apollo/react-hooks';
import { CURRENT_USER } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateUserPermissionCache = () => {
  const client = useApolloClient();
  const updateUserPermissionCache = (user) => {
    try {
      client.writeQuery({
        query: CURRENT_USER,
        data: {
          currentUser: user,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateUserPermissionCache;
};

export default useUpdateUserPermissionCache;
