import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  isEmpty,
  getOr,
  first,
  get,
} from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter,
} from '../../Components/Auth/Layout';
import {
  FETCH_TEAM_MEMBERS,
  FETCH_EVENTS_USERS_BY_USERS,
  FETCH_EVENT_DETAIL,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import BackLink from '../../Components/BackLink';
import useNotEqualFilterArray from '../../Hooks/useNotEqualFilterArray';
import useFilterArray from '../../Hooks/useFilterArray';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import BasicEditTeamMember from './BasicEditTeamMember';

const EditViewMembers = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams(); // event id
  const { schoolId } = useParams();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [searchMembers, setSearchMembers] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);
  const notEqualFilterArray = useNotEqualFilterArray();
  const filterArray = useFilterArray();
  const [fetchTeamMembers] = useLazyQuery(
    FETCH_TEAM_MEMBERS, {
      onCompleted: (data) => {
        const filteredMembers = notEqualFilterArray(data.viewMyTeam, 'fetchRole.title', 'Captain');
        setTotalMembers(filteredMembers);
        setSearchMembers(filteredMembers);
      },
    },
  );

  const [fetchEventsUsersByUsers, { data: dataEventsUsers }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUsers);

  const eventsUser = useMemo(() => first(filterArray(eventsUsers, 'fetchRole.title', 'Captain')), [eventsUsers, filterArray]);

  const currentEventsUser = useMemo(() => first(filterArray(totalMembers, 'fetchUser.id', params.userId)), [totalMembers, filterArray, params.userId]);

  const [fetchEventDetail, { data: dataEvent }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );

  const event = getOr({}, 'fetchEventDetail', dataEvent);

  useHeaderComponentsMutation({
    title: 'VIEW / EDIT MEMBERS',
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (!isEmpty(currentUser.fetchSchools)) {
      fetchTeamMembers({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchTeamMembers, id, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id]);

  useEffect(() => {
    if (currentUser) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  return (
    <>
      <div className="mt-4" />
      {/* {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null} */}
      {searchMembers.length === 0
        ? (
          <div className="alert alert-warning mt-3" role="alert">
            No Members
          </div>
        )
        : null}
      {!get('teamFinalize', eventsUser)
        ?
        (
          <>
            {searchMembers.map((member) => (
              <BasicEditTeamMember
                member={member.fetchUser.id === params.userId ? member : null}
                eventId={id}
                eventAllowsMultipleSports={event?.allowsMultipleSports}
                currentEventsUser={currentEventsUser}
                schoolId={schoolId}
              />
            ))}
          </>
        )
        : null
      }
      <AlignCenter className="m-5">
        <BackLink to={`/events/${id}/team/${schoolId}`}>
          Team
        </BackLink>
      </AlignCenter>
    </>
  );
};

export default EditViewMembers;
