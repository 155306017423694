import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import queryString from 'query-string';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  ImgSqaure,
} from '../../Components/Auth/Layout';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { FETCH_EVENT, INDIVIDUAL_SCORE } from '../../GraphQL/Queries';
import ScoresList from '../../Components/ScoresList/Index';

const ParticipantDetails = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id, userId, scoreableType } = useParams();
  const location = useLocation();
  const scoreableParams = queryString.parse(location.search);
  const { type } = scoreableParams;

  const { data: eventDetail } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );

  const [individualScore, { data: scores }] = useLazyQuery(INDIVIDUAL_SCORE);
  useEffect(() => {
    if (currentUser && id) {
      individualScore({
        variables: {
          eventId: parseInt(id, 10),
          scoreableId: parseInt(userId, 10),
          scoreableType: type,
        },
      });
    }
  }, [currentUser, individualScore, id, userId, scoreableType, type]);
  useHeaderComponentsMutation({
    title: 'OVERALL SCORES',
    backLink: `/events/${id}/overall-scores`,
    components: [{ key: EVENT_TITLE, value: get('fetchEventDetail.title', eventDetail) }],
  });

  return (
    <div className="container">
      {type === 'User'
        ? (
          <div className="row">
            <div className="col-md-1 col-3 my-5 pl-1">
              <ImgSqaure><img className="rounded-circle" src={get('individualScore[0].user.profileImageUrl', scores)} alt="user-icon" /></ImgSqaure>
            </div>
            <div className="col-md-3 col-9 mt-5 pt-2">
              <div className="row font-weight-bold">
                {get('individualScore[0].user.firstname', scores)}
                {' '}
                {get('individualScore[0].user.lastname', scores)}
              </div>
              <div className="row text-muted">
                          MIST ID
                {' '}
                {get('individualScore[0].user.code', scores)}
              </div>

            </div>
          </div>
        )
        : (
          <div className="row">
            <div className="col-md-1 col-3 my-5 pl-1">
              <div className="rounded-circle text-warning border-top bg-light font-weight-bold h1 py-2 px-4">1</div>
            </div>
            <div className="col-md-3 col-9 mt-5 pt-2">
              <div className="row font-weight-bold">
                {get('individualScore[0].school.name', scores)}
              </div>
            </div>
          </div>
        )}
      <div className="font-weight-bold h4">Competitions</div>
      { getOr([], 'individualScore', scores).map((data) => (
        <ScoresList
          key={data.id}
          user={data}
          imageAttached={userDefaultImage}
          competitionName={get('eventCompetition.title', data)}
          redirectLink={`/events/${id}/scoring/${get('eventCompetition.id', data)}`}
        />
      ))}
    </div>
  );
};
export default ParticipantDetails;
