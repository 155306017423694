import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import InputButton from '../../Components/Form/Button';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';

const ConfirmationText = styled.div`
  h5 {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: rgba(52, 52, 52, 1);
    padding-top: 3rem!important;
    padding-bottom: 3rem!important;
  }
`;

const ResetPasswordConfirmation = () => {
  useHeaderComponentsMutation({ title: 'RESET PASSPHRASE' });
  return (
    <>
      <ConfirmationText>
        <h5>
          Your passphrase has been
          <br />
          successfully reset. Please return
          <br />
          to the login page to continue.
        </h5>
      </ConfirmationText>
      <div className="w-100" style={{ paddingBottom: '1em' }}>
        <Link to="/login">
          <InputButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none">log in</InputButton>
        </Link>
      </div>
    </>
  );
};
export default ResetPasswordConfirmation;
