const styles = {
  avatar: {
    background: 'transparent',
    border: 'none',
    float: 'right',
    height: 80,
    width: 80,
    borderRadius: 50,
  },
  header: {
    display: 'flex',
  },
  crossBtn: {
    marginLeft: 'auto',
    padding: 0,
    background: 'none',
    outline: 'none',
    border: 0,
    height: 0,
  },
  modalAvatar: {
    width: 30,
    height: 30,
  },
  dropFileImage: {
    background: '',
    border: '1px dashed #979797',
    borderRadius: '4px',
    opacity: 1,
    color: '#757575',
    // padding: 20,
  },
  deleteImageIcon: {
    cursor: 'pointer',
  },
  fileIcon: {
    color: '#afa8a8',
    fontSize: 19,
    marginRight: 6,
  },
  uploadBtn: {
    display: 'flex',
    backgroundColor: '#5AB6FF',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginTop: 13,
    fontSize: 12,
  },
  uploadBtnIcon: {
    marginRight: 5,
  },
  imageUploadBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileImage: {
    margin: '0 auto'
  }
};

export default styles;
