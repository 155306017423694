import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_EVENT_USERS_BY_EVENT } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateEventsUsersCache = (eventId, schoolId) => {
  const client = useApolloClient();
  const updateCache = (eventsUser) => {
    try {
      const data = client.readQuery({
        query: FETCH_EVENT_USERS_BY_EVENT,
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
      client.writeQuery({
        query: FETCH_EVENT_USERS_BY_EVENT,
        data: {
          fetchEventUsersByEvent: [
            { ...eventsUser },
            ...data.fetchEventUsersByEvent,
          ],
        },
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateEventsUsersCache;
