import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import {
  FontBold, AddStudentButton,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Search from '../../Components/Search/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENT_SCHOOL_COACHES } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserSearch from '../../Hooks/useUserSearch';
import UserList from '../../Components/UserList/Index';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';
import userDefaultImage from '../../Images/icons/userIcon.png';

const TeamCoaches = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const searchList = useUserSearch();
  const { eventId } = useParams();
  const { schoolId } = useParams();
  const [searchCoaches, setSearchCoaches] = useState([]);
  const [fetchEventSchoolCoaches, { data: coachesData }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_COACHES, {
      onCompleted: (data) => {
        setSearchCoaches(data.fetchEventSchoolCoaches);
      },
    },
  );
  const coaches = getOr([], 'fetchEventSchoolCoaches', coachesData);
  useEffect(() => {
    if (currentUser) {
      fetchEventSchoolCoaches({
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    }
  }, [fetchEventSchoolCoaches, currentUser, eventId, schoolId]);

  const searchCoach = (e) => {
    if (e.target.value === '') {
      setSearchCoaches(coaches);
    } else {
      setSearchCoaches(
        searchList(searchCoaches, e.target.value),
      );
    }
  };
  useHeaderComponentsMutation({
    title: 'COACHES',
    backLink: `/event/${eventId}/teams/${schoolId}`,
    components: [{ key: EVENT_TITLE, value: get('[0].fetchEvent.title', searchCoaches) }],
  });

  return (
    <>
      <div className="mt-4" />
      <div className="container d-none">
        <div className="row">
          <AddStudentButton className="col-md-6 col-6">
            <button className="btn text-white text-small" type="button">
              <small className="font-weight-bold">ADD EXISTING USER</small>
            </button>
          </AddStudentButton>
          <AddStudentButton className="col-md-6 col-6">
            <button className="btn text-white text-small" type="button">
              <small className="font-weight-bold">ADD NEW USER</small>
            </button>
          </AddStudentButton>
        </div>
      </div>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={searchCoach}
      />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              Coaches(
              {searchCoaches.length}
)
            </p>
          </FontBold>
        </div>
      </div>
      {searchCoaches.map((eventUser) => (
        <>
          <div key={eventUser.id}>
            <UserList
              user={get('fetchUser', eventUser)}
              imageAttached={userDefaultImage}
              arrowIcon={RIGHT_ARROW}
              redirectLink={`/event-manage/${eventId}/event-user-profile/${get('id', get('fetchUser', eventUser))}`}
            />
          </div>
        </>
      ))}
    </>
  );
};
export default TeamCoaches;
