import { useApolloClient } from '@apollo/react-hooks';
import { subtract } from 'lodash/fp';
import { PENDING_REQUEST_COUNT } from '../GraphQL/Queries';
import logger from '../logger';

const usePendingRequestCountCache = (schoolId, eventId) => {
  const client = useApolloClient();
  const updatePendingRequestCountCache = (user, action) => {
    try {
      const data = client.readQuery({
        query: PENDING_REQUEST_COUNT,
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
      const { fetchPendingRequest } = data;
      let response = 0;
      if (action === 'remove') {
        response = subtract(fetchPendingRequest, 1);
      }
      client.writeQuery({
        query: PENDING_REQUEST_COUNT,
        data: {
          fetchPendingRequest: response,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updatePendingRequestCountCache;
};

export default usePendingRequestCountCache;
