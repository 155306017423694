import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_USER_IN_EVENTS_USER } from '../../GraphQL/Queries';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import CROSS from '../../Images/cross3.png';
import {
  UserEventCompetitionCard,
  ImageButton,
  SelectedSchool,
} from '../../Components/Auth/Layout';

const JoinEventCompetitionsFromRoster = (props) => {
  const {
    eventId,
    schoolId,
    competitionTitle,
    onClick,
    onSelect,
    eventCompetitionId,
  } = props;

  const [filteredEventsUser, setFilteredEventsUser] = useState([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [selectedEventsUserName, setSelectedEventsUserName] = useState('');

  const [searchEventsUsers] = useLazyQuery(
    SEARCH_USER_IN_EVENTS_USER,
    {
      onCompleted: (dataTemp) => {
        setFilteredEventsUser(dataTemp.searchUserInEventsUser);
      },
    },
  );

  const searchEventsUser = (e) => {
    setSearchStringLength(e.target.value.length);
    searchEventsUsers({
      variables: {
        eventId: parseInt(eventId, 10),
        schoolId: parseInt(schoolId, 10),
        query: e.target.value,
        eventCompetitionId: parseInt(eventCompetitionId, 10),
      },
    });
  };

  const getSelectedEventUser = (e) => {
    if (e.target.id !== '') {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedEventsUserName(e.target.innerText);
      onSelect(e.target.id);
    }
  };

  return (
    <UserEventCompetitionCard>
      <ImageButton className="mt-n1" float="right" onClick={onClick}>
        <span><img src={CROSS} alt="cross" /></span>
      </ImageButton>
      <p>
          Add new student to
        {' '}
        {competitionTitle}
      </p>
      <SearchDialog
        placeholder="Search"
        onChange={(e) => searchEventsUser(e)}
        data={filteredEventsUser}
        stringlength={searchStringLength}
        displayattribute="name"
        displayObject="fetchUser"
        imageattatched={SEARCH}
        emptySearch={clickStatus}
        checkPermission
        onClick={getSelectedEventUser}
      />
      {selectedEventsUserName
        ? (
          <SelectedSchool>
            <h5>{selectedEventsUserName}</h5>
          </SelectedSchool>
        )
        : null}
    </UserEventCompetitionCard>
  );
};

JoinEventCompetitionsFromRoster.propTypes = {
  eventId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  competitionTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  eventCompetitionId: PropTypes.string.isRequired,
};


export default JoinEventCompetitionsFromRoster;
