import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { CREATE_USER } from '../../GraphQL/Mutations';
import { TitleC } from '../../Components/Auth/Layout';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Form from '../../Components/Form';

const CreateInvitedUser = () => {
  const history = useHistory();
  const token = useParams();
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({ title: 'Create User' });
  const tempId = 1;
  const [createUser, { loading, error }] = useMutation(CREATE_USER, {
    onCompleted: () => {
      history.push('/');
    },
  });
  const { params, onChange } = useFormParams({
    firstname: '',
    email: '',
    password: '',
    gender: '',
    phone: '',
    dateOfBirth: '',
    userType: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    params.gender = parseInt(params.gender, 10);
    params.userType = parseInt(params.userType, 10);
    createUser({
      variables: {
        ...params,
        tenantId: tempId,
        token: token.token,
      },
    });
  };

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <Form onSubmit={onSubmit}>
        <TitleC>
          <p className="mt-4">Create Account</p>
        </TitleC>
        <Input
          type="text"
          id="firstname"
          name="firstname"
          placeholder="Full Name"
          required
          onChange={onChange}
          withFormGroup
          label="Full Name"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          pattern=".+@clustox.com"
          required
          onChange={onChange}
          withFormGroup
          label="email"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="password"
          id="pwd"
          name="password"
          label="Passphrase"
          placeholder="Passphrase"
          minLength="8"
          required
          onChange={onChange}
          withFormGroup
          labelClass="sr-only"
          params={params}
        />
        <Input name="gender" type="select" params={params} id="gender" required onChange={onChange}>
          <option value="" disabled>Gender</option>
          <option value="1">MALE</option>
          <option value="2">FEMALE</option>
        </Input>
        <Input name="userType" type="select" params={params} id="userType" required onChange={onChange}>
          <option value="" disabled>User Type</option>
          <option value="0">ADMISTRATOR</option>
          <option value="1">REGULAR</option>
        </Input>
        <Input
          type="text"
          id="phone"
          name="phone"
          placeholder="Phone Number"
          required
          onChange={onChange}
          withFormGroup
          label="Phone Number"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          placeholder="Date of Birth"
          required
          onChange={onChange}
          withFormGroup
          label="Date of Birth"
          labelClass="sr-only"
          params={params}
          max={moment().format('YYYY-MM-DD')}
        />
        <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">
          Create Account
        </InputButton>
      </Form>
    </>
  );
};
export default CreateInvitedUser;
