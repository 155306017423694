import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { PrimaryButton } from '../../../Components/Auth/Layout';
import {
  CREATE_RESOURCE,
} from '../../../GraphQL/Mutations';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';

const bodyComponentPropTypes = {
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
};

const AddNumberRow = React.forwardRef(({ body, setBody }, _ref) => (
  <label htmlFor="body">
    enter a valid phone number (numbers and dashes only)
    <input
      style={{ width: '100%' }}
      title="Number"
      label="Number"
      name="body"
      id="body"
      placeholder=""
      onChange={(e) => setBody(e.target.value)}
      value={body}
      type="tel"
      pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
    />
  </label>
));
AddNumberRow.propTypes = bodyComponentPropTypes;

const AddLinkRow = React.forwardRef(({ body, setBody }, _ref) => (
  <label htmlFor="body">
    link must start with https://
    <input
      style={{ width: '100%' }}
      title="Link"
      label="Link"
      name="body"
      id="body"
      placeholder="https://"
      onChange={(e) => setBody(e.target.value)}
      value={body}
      type="url"
    />
  </label>
));
AddLinkRow.propTypes = bodyComponentPropTypes;

const AddPhotoRow = React.forwardRef(({ setAttachment }, ref) => (
  <label htmlFor="attachment">
    select an image
    <input
      style={{ width: '100%' }}
      title="Photo"
      label="Photo"
      name="attachment"
      id="attachment"
      placeholder=""
      type="file"
      accept="image/*"
      ref={ref}
      onChange={(e) => setAttachment(e.target.files[0])}
    />
  </label>
));

const AddPdfRow = React.forwardRef(({ setAttachment }, ref) => (
  <label htmlFor="attachment">
    select a pdf
    <input
      style={{ width: '100%' }}
      title="Photo"
      label="Photo"
      name="attachment"
      id="attachment"
      placeholder=""
      type="file"
      accept=".pdf"
      ref={ref}
      onChange={(e) => setAttachment(e.target.files[0])}
    />
  </label>
));

const RESOURCE_TYPE_COMPONENTS = {
  number: AddNumberRow,
  link: AddLinkRow,
  photo: AddPhotoRow,
  pdf: AddPdfRow,
};

const REQUIRES_BODY = ['number', 'link'];
const REQUIRES_ATTACHMENT = ['photo', 'pdf'];

const cleanPhoneNum = (phoneNumStr) => phoneNumStr.replace(' ', '').replace('-', '');

const validateParams = (type, body, attachment) => {
  if (REQUIRES_BODY.includes(type)) {
    if (body == null || attachment) {
      return false;
    }
  } else if (REQUIRES_ATTACHMENT.includes(type)) {
    if (attachment == null || body) {
      return false;
    }
  }
  if (type === 'link') {
    return body.startsWith('https://');
  }
  if (type === 'number') {
    return !Number.isNaN(parseInt(cleanPhoneNum(body), 10));
  }
  return true;
};

const AddResourceRow = ({
  eventId, existingResourceTitles, onError, onCreate,
}) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [body, setBody] = useState();
  const [attachment, setAttachment] = useState();
  const fileRef = useRef();

  const extractError = useGraphQLErrorExtractor();
  const [createResource, { loading }] = useMutation(
    CREATE_RESOURCE,
    {
      onCompleted: (data) => {
        setTitle('');
        setBody('');
        setAttachment(undefined);
        if (fileRef.current) fileRef.current.value = '';

        const newResource = data.createResource.resource;
        onCreate(newResource);
      },
      onError: (err) => {
        onError(extractError(err));
      },
    },
  );

  const titleInvalid = existingResourceTitles.includes(title);
  const paramsInvalid = !validateParams(type, body, attachment);

  const onSave = () => {
    createResource({
      variables: {
        eventId,
        title,
        type,
        body: REQUIRES_BODY.includes(type) ? body : null,
        attachment: REQUIRES_ATTACHMENT.includes(type) ? attachment : null,
      },
    });
  };

  const AddResourceComponent = RESOURCE_TYPE_COMPONENTS[type];

  return (
    <div className="col-10 mx-2">
      <h4>Add new:</h4>
      <div className="row my-2 justify-content-center align-items-center">
        <div className="col-2 mx-2">
                  Title:
        </div>
        <div className="col-8 mx-2">
          <input style={{ width: '100%' }} value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>

        <div className="row col-5 my-2 justify-content-center align-items-center">
          <select
            style={{ width: '100%' }}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option disabled selected> -- select a resource type --</option>
            {Object.keys(RESOURCE_TYPE_COMPONENTS).map((resourceType) => (
              <option key={resourceType} value={resourceType}>{resourceType}</option>
            ))}
          </select>
          <div className="my-2">
            {type && (
              <AddResourceComponent
                ref={fileRef}
                setAttachment={setAttachment}
                body={body}
                setBody={setBody}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row m-2 justify-content-center">
        <div className="col-2 mx-2 my-2">
          <PrimaryButton
            backgroundcolor="#F4AB37"
            fontcolor="#FFF"
            border="none"
            onClick={onSave}
            disabled={loading || titleInvalid || paramsInvalid}
          >
            {loading ? 'Submitting...' : 'Save'}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

AddResourceRow.propTypes = {
  eventId: PropTypes.string.isRequired,
  existingResourceTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onError: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AddResourceRow;
