import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { FETCH_SCHOOL_USERS, SEARCH_SCHOOLS, FETCH_MY_SCHOOLS } from '../../GraphQL/Queries';
import { REASSIGN_STUDENT } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import SearchDialog from '../../Components/SearchDialog/Index';
import { LinkButton, SelectedSchool } from '../../Components/Auth/Layout';
import SEARCH from '../../Images/search.png';

export const InactiveLink = styled.div`
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: none;
`;

const SchoolStudentIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { id } = useParams();
  const history = useHistory();
  const extractError = useGraphQLErrorExtractor();
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');
  const [selectedSchoolName, setSelectedSchoolName] = useState('');
  const [fetchSchoolUsers, { data: schoolUsersData, loading, error }] = useLazyQuery(
    FETCH_SCHOOL_USERS,
  );
  const users = getOr([], 'fetchSchoolUsers', schoolUsersData);

  const [fetchSchools] = useLazyQuery(
    FETCH_MY_SCHOOLS, {
      onCompleted: (data) => {
        setFilteredSchools(data.mySchools.filter(({ status }) => status !== 'inactive').sort((a, b) => a.name.localeCompare(b.name)));
      },
    },
  );

  useHeaderComponentsMutation({
    title: 'REASSIGN MEMBERS',
    backLink: '/dashboard',
  });

  useEffect(() => {
    if (currentUser) {
      fetchSchoolUsers({
        variables: {
          schoolId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchSchoolUsers, id]);

  useEffect(() => {
    if (currentUser) {
      fetchSchools({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchSchools]);

  const [searchSearch] = useLazyQuery(
    SEARCH_SCHOOLS,
    {
      onCompleted: (data) => {
        setFilteredSchools(data.searchSchools.filter((obj) => obj.id !== id));
      },
    },
  );


  const getSelectedSchool = (e) => {
    setSelectedSchoolId(parseInt(e.target.id, 10));
    setSelectedSchoolName(e.target.innerText);
    setSearchStringLength(0);
  };

  const searchSchools = (e) => {
    searchSearch({
      variables: {
        query: e.target.value,
        userId: parseInt(currentUser.id, 10),
      },
    });
    setSearchStringLength(e.target.value.length);
  };


  const [reassignStudent] = useMutation(
    REASSIGN_STUDENT,
    {
      onCompleted: () => {
        history.push('/schools');
      },
    },
  );

  const assignStudents = () => {
    reassignStudent({
      variables: {
        schoolId: parseInt(selectedSchoolId, 10),
        userIds: users.map((a) => parseInt(a.id, 10)),
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }


  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <p>Please select school to reassign all students listed below</p>
        <SearchDialog
          placeholder="Search"
          onChange={(e) => searchSchools(e)}
          data={filteredSchools.slice(0, 3)}
          stringlength={searchStringLength}
          onClick={(e) => getSelectedSchool(e)}
          displayattribute="name"
          imageattatched={SEARCH}
        />
        <SelectedSchool>
          <h5>{selectedSchoolName}</h5>
        </SelectedSchool>
        <InactiveLink>
          <List data={users} displayAttribute="firstname" />
        </InactiveLink>
        <LinkButton onClick={() => assignStudents()} backgroundcolor="#F4AB37" fontcolor="#FFF" border="none">SAVE</LinkButton>
      </div>
    </div>
  );
};

export default SchoolStudentIndex;
