import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_PASSWORD } from '../../GraphQL/Mutations';
import Form from '../../Components/Form';
import Input from '../../Components/Form/Input';
import InputButton from '../../Components/Form/Button';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { DODGER_BLUE_HEADER } from '../../Constants/AppConstants';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';

const EditPassword = () => {
  const history = useHistory();
  const [errorMessage, seterrorMessage] = useState('');
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({ title: 'Reset Password', components: [{ key: DODGER_BLUE_HEADER }] });
  const linkToken = window.location.href.split('=')[1];
  const { params, onChange } = useFormParams({
    password: '',
  });
  useHeaderComponentsMutation({ title: 'RESET PASSPHRASE' });
  const [editPassword, { loading, error }] = useMutation(
    EDIT_PASSWORD,
    {
      onCompleted() {
        history.push('/login');
      },
    },
  );
  const onSubmit = (e) => {
    e.preventDefault();
    if (params.password !== params.passwordConfirmation) {
      seterrorMessage('Passphrase and Confirm Passphrase mismatch.');
    }
    editPassword({
      variables: {
        ...params,
        token: linkToken,
      },
    });
  };

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <Form onSubmit={onSubmit} className="my-5">
        {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
        <Input
          title="Passphrase"
          type="password"
          id="password"
          placeholder="New Passphrase"
          name="password"
          required
          minLength="8"
          onChange={onChange}
          withFormGroup
          label="Passphrase"
          labelClass="sr-only"
          params={params}
        />
        <Input
          type="password"
          title="Confirm Passphrase"
          id="confirm_pwd"
          name="passwordConfirmation"
          label="Confirm Passphrase"
          placeholder="Confirm Passphrase"
          minLength="8"
          required
          onChange={onChange}
          withFormGroup
          labelClass="sr-only"
          params={params}
        />
        <div className="d-block mb-5">
          <InputButton disabled={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Reset</InputButton>
        </div>
      </Form>
    </>
  );
};
export default EditPassword;
