import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import ImageUpload from '../ImageUpload/Index';
import {
  ProfileImgCircular, TextAlignCenter, ImgLink, GrayText, NoClick,
} from '../../Components/Auth/Layout';
import uploadImage from '../../Images/dashboard/upload.png';
import SINK_ICON from '../../Images/sink.png';
import GenericAlert from '../../Components/GenericAlert';
import Permissions from '../../Constants/PermissionConstrants';
import { FETCH_USER_DETAIL, FETCH_EVENT } from '../../GraphQL/Queries';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const DividerLine = styled.div`
  line-height: 0.5;
  text-align: center;
  overflow: hidden;
  border-bottom: 2px solid #C6C5C5;
  span {
    display: inline-block;
    position: relative;
    border: 1px solid rgba(151, 151, 151, .9);
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    font-family: 'nunito_regular';
    ::before {
    right: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
  }
  ::after {
    left: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
    }
  }
`;
const MistID = styled.div`
  text-align:center;
  color:black;
  font-size:15px;
  padding-top:12px;
  font-weight:bold;
  font-family:Arial;
`;
const Text = styled.label`
  color:#C6C5C5;
  font-size:15px;
  font-weight:bold;
  display:inline;
`;
const Input = styled.input`
  border:none;
  display:block;
`;
const TextField = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:15px;
  margin-bottom:20px;
  margin-left:30px;
`;
const EmailField = styled.input`
  width=100%;
  background-color:#E7EBEE;
  border:none;
  border-radius:9px;
`;

const EventUserProfile = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { eventId } = useParams();
  const { userId } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [modal, setModal] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const teamMemeberPermission = useMemo(() => (
    checkPermission(Permissions.CAN_MANAGE_TEAM_MEMBERS)),
  [checkPermission]);
  const regionalAdminPermission = useMemo(() => (checkPermission(Permissions.CAN_MANAGE_REGION)),
    [checkPermission]);

  const [fetchUserDetail, { data: userData }] = useLazyQuery(
    FETCH_USER_DETAIL,
  );

  const user = getOr({}, 'fetchUserDetail', userData);

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useHeaderComponentsMutation({
    title: 'EVENT USER DETAIL',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  useEffect(() => {
    if (currentUser && (teamMemeberPermission || regionalAdminPermission)) {
      fetchUserDetail({
        variables: {
          id: parseInt(userId, 10),
        },
      });
    }
  }, [currentUser, fetchUserDetail, userId, teamMemeberPermission, regionalAdminPermission]);

  const onImagePreview = (url) => {
    setModal(true);
    setImagePreviewUrl(url);
  };

  const getFileName = (url) => url.split('/').pop();
  const toggle = () => setModal(!modal);

  return (
    <>
      {regionalAdminPermission || teamMemeberPermission
        ? (
          <>
            <MistID>
            MIST ID:
              {' '}
              {get('code', user)}
            </MistID>
            <div className="container-fluid">
              <div className="row">
                <div className="text-right col-md-2 offset-md-3 col-6 ">
                  <ProfileImgCircular>
                    <NoClick>
                      <ImageUpload
                        image={getOr(uploadImage, 'profileImageUrl', user)}
                        type="image"
                        visibleText="Profile Picture"
                      />
                    </NoClick>
                  </ProfileImgCircular>
                </div>
                <div className="col-md-5 offset-md-2  col-6 text-left">
                  <img src={get('qrCodeUrl', user)} alt="QR-Code" />
                  <p>your QR Code</p>
                </div>
              </div>
            </div>
            <DividerLine />
            <TextField>
              <div className="row">
                <div className="col-md-10 col-7 ">
                  <Text> First Name </Text>
                  <Input type="text" name="firstName" id="firstName" defaultValue={user.firstname} readonly />
                </div>
              </div>
            </TextField>
            <TextField>
              <Text> Last Name </Text>
              <Input type="text" name="lastName" id="lastname" defaultValue={user.lastname} readonly />
            </TextField>
            <TextField>
              <Text> Date of Birth </Text>
              <Input type="text" name="dateofbirth" id="dateofbirth" defaultValue={user.dateOfBirth} readonly />
            </TextField>
            <TextField>
              <Text> Gender </Text>
              <Input type="text" name="gender" id="gender" defaultValue={user.gender} readonly />
            </TextField>
            <TextField>
              <Text> phone </Text>
              <Input type="text" name="phone" id="phone" defaultValue={user.phone} readonly />
            </TextField>
            <TextField>
              <Text> Email: </Text>
              <EmailField type="text" name="email" id="email" defaultValue={user.email} readonly />
            </TextField>
            <TextField>
              <Text>Emergency Contact </Text>
              <Input type="text" name="emergencContact" id="emergencyContact" defaultValue={user.phone} readonly />
            </TextField>
            {get('fetchRole.title', user)
              ? (
                <TextField>
                  <Text>Default Role </Text>
                  <Input type="text" name="default-role" id="default-role" defaultValue={user.fetchRole.title} readonly />
                </TextField>
              )
              : null}
            {get('defaultRegion.name', user)
              ? (
                <TextField>
                  <Text>Default Region </Text>
                  <Input type="text" name="default-region" id="default-region" defaultValue={user.defaultRegion.name} readonly />
                </TextField>
              )
              : null}
            <TextField>
              <Text>Special Accomodations </Text>
              <Input type="text" name="accommodationNote" id="accommodationNote" defaultValue={user.accommodationNote} readonly />
            </TextField>
            <DividerLine />
            {getOr([], 'fetchSchoolsUsers', user).map((userSchool) => (
              <div key={userSchool.id}>
                <TextField>
                  <div className="row">
                    <div className="col-md-11 col-10">
                      <Text> Default School </Text>
                      <Input type="text" name="defaultschool" id="defaultschool" defaultValue={userSchool.fetchSchool.name} readonly />
                    </div>
                  </div>
                </TextField>
                <TextField>
                  <Text> School Verification </Text>
                  {userSchool.fetchUserAttachment.map((file) => (
                    <div className="d-flex" key={file.id}>
                      {file.verificationFileUrl
                        ? (
                          <ImgLink onClick={() => onImagePreview(file.verificationFileUrl)}>
                            <img className="img-fluid" src={SINK_ICON} alt="arrow" />
                            {'  '}
                            {getFileName(file.verificationFileUrl)}
                          </ImgLink>
                        )
                        : <GrayText>(None)</GrayText>}
                    </div>
                  ))}
                </TextField>
              </div>
            ))}
            <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
              <ModalHeader toggle={toggle}>File Preview </ModalHeader>
              <ModalBody>
                <TextAlignCenter>
                  <img src={imagePreviewUrl} alt="arrow" className="img-fluid" />
                </TextAlignCenter>
              </ModalBody>
            </Modal>
          </>
        )
        : (
          <GenericAlert>
You dont have required permission to access this page,
           Please contact system administrator.
          </GenericAlert>
        )}
    </>
  );
};
export default EventUserProfile;
