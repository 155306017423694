import PropTypes from 'prop-types';

export const ScheduleEventAttendeePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
    eventUsers: PropTypes.arrayOf(PropTypes.shape({
      fetchRole: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      fetchSchool: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      fetchTeam: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
    checkedInAt: PropTypes.string.isRequired,
  })).isRequired,
});
