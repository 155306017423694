import React from 'react';
import styled from 'styled-components';


const DodgerBlue = styled.div`
  background: #3E6796 !important;
  h5{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    font-family: 'nunito_bold';
    letter-spacing: .5px;
    color: rgba(255, 255, 255, 1);
  }
`;

const DodgerBlueInfo = () => (
  <DodgerBlue>
    <h5 className="py-2">Boston Regionals 2019</h5>
  </DodgerBlue>
);

export default DodgerBlueInfo;
