import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  isEmpty, getOr, get, last,
} from 'lodash/fp';
import EDIT from '../../Images/icons/edit.png';
import ADD from '../../Images/icons/add-small.png';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  MANAGE_MY_COMPETITIONS,
  FETCH_EVENT_DETAIL,
  FETCH_EVENTS_USERS_BY_USERS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import ManageCompetitionItem from './ManageCompetitionItem';
import {
  AlignCenter,
} from '../../Components/Auth/Layout';
import Loader from '../../Components/Loader';
import BackLink from '../../Components/BackLink';
import Permissions from '../../Constants/PermissionConstrants';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const ManageMyCompetitions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const { id } = useParams();
  const [manageMyCompetitions, { data, loading: myCompsLoading }] = useLazyQuery(
    MANAGE_MY_COMPETITIONS, {
      fetchPolicy: 'no-cache',
    },
  );

  const [fetchEventDetail, { data: dataEvent, loading: eventLoading }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', dataEvent);

  const myCompetitions = getOr([], 'manageMyCompetitions', data);

  const [fetchEventsUsersByUsers, { data: dataEventsUser, loading: eventsUsersLoading }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUser);

  useHeaderComponentsMutation({
    title: 'MANAGE EVENT',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (!isEmpty(get('fetchSchools', currentUser))) {
      manageMyCompetitions({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, manageMyCompetitions, id]);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id]);

  const submitFiles = () => {
  };

  if (myCompsLoading || eventLoading || eventsUsersLoading) {
    return <Loader />;
  }

  return (
    <>
      {myCompetitions.length === 0 && !get('teamFinalize', last(eventsUsers))
        ? (
          <>
            <div className="container px-0 pt-3 ml-0">
              <div className="row mt-3">
                <Link to={`/events/${id}/my-competitions/edit`}><img src={ADD} alt="ADD" /></Link>
                <span className="pl-4">
                  <div className="font-weight-bold">
                    Participate in your First Competition
                  </div>
                </span>
              </div>
            </div>
          </>
        )
        : (
          <div className="container px-0 pt-3 ml-0">
            <div className="row">
              <div className="col-md-8 col-8 font-weight-bold">
                 Your  Competitions
              </div>
              {checkPermission(Permissions.CAN_JOIN_COMPETITION)
                ? (
                  <>
                    {myCompetitions.length > 0 && !get('teamFinalize', last(eventsUsers))
                      ? (
                        <div className="col-md-4 col-4 text-right">
                          <Link to={`/events/${id}/my-competitions/edit`}><img src={EDIT} alt="EDIT" /></Link>
                        </div>
                      )
                      : null}
                  </>
                )
                : null}
            </div>
          </div>
        )}

      {myCompetitions.map((user, index) => (
        <ManageCompetitionItem
          key={user.id}
          competition={user}
          index={index}
          onClick={() => submitFiles()}
          backgroundColor={(user.status === 'joined') ? '#389446' : '#3E6796'}
        />
      ))}
      <AlignCenter>
        <BackLink to={`/event-manage/${id}`}>
       Manage Event
        </BackLink>
      </AlignCenter>
    </>
  );
};

export default ManageMyCompetitions;
