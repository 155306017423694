import styled from 'styled-components';

const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: rgba(41, 41, 41, 1);
  font-family: 'nunito_semibold';
`;

export default Title;
