import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { SET_BALLOT } from '../../GraphQL/Mutations';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';

const validateInput = (ballotTitle, ballotSections) => {
  const errors = new Set();
  if (ballotTitle === "") {
    errors.add("Ballot Title cannot be empty.");
  }
  ballotSections.forEach((ballotSection) => {
    if (ballotSection.title === "") {
      errors.add("Ballot Section Headers cannot be empty.")
    }
    if (ballotSection.totalPoints < 0) {
      errors.add("Ballot Sections cannot be worth negative points.")
    }
    if (ballotSection.description === "") {
      errors.add("Ballot Section Descriptions cannot be empty.")
    }
  });
  return errors;
}

const BallotNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [ballotSections, setBallotSections] = useState([{
        "title": "",
        "totalPoints": 0,
        "description": "",
      }]);
  const backLink = '/ballots';
  const [errorMessage, setErrorMessage] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [ballotTitle, setBallotTitle] = useState("");
  const [setBallot] = useMutation(SET_BALLOT);
  useHeaderComponentsMutation({
    backLink: backLink,
    title: 'CREATE BALLOT',
  });
  const extractError = useGraphQLErrorExtractor();


  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Competition') === undefined) {
        history.push('/ballots');
      }
    }
  }, [currentUser, checkPermission, history]);

  const onSubmit = (e) => {
    const errors = validateInput(ballotTitle, ballotSections);
    if (errors.size > 0) {
      const message = Array.from(errors).join(' ');
      setErrorMessage(message);
      window.scrollTo(0, 0);
    } else {
      setUnsavedChanges(false);
      setBallot({
        variables: {
          input: {
            title: ballotTitle,
            sections: ballotSections,
          },
        },
      }).then(() => {
        history.push(backLink);
      }).catch((err) => {
        setErrorMessage(extractError(err));
        window.scrollTo(0, 0);
      });
    }
  };

  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      {unsavedChanges ? <GenericAlert color="warning">You have unsaved changes! Please scroll down and press Save</GenericAlert> : null}
      <hr />
      <Form
        ballotSections={ballotSections}
        setBallotSections={setBallotSections}
        ballotTitle={ballotTitle}
        setBallotTitle={setBallotTitle}
        onSubmit={onSubmit}
        setUnsavedChanges={setUnsavedChanges}
        checkPermission={checkPermission}
      />
    </>
  );
};
export default BallotNew;
