import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FETCH_EVENT_USERS_BY_SCHOOL, FETCH_EVENT } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import ListItemDetail from '../../Components/ListItemDetail/Index';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useUpdateEventsUsersbyEventCache from '../../Hooks/useUpdateEventsUsersbyEventCache';

const TeamMemberRequest = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const [filteredEventUsers, setFilteredEventUsers] = useState([]);
  const { schoolId, id } = useParams();
  const updateCache = useUpdateEventsUsersbyEventCache(parseInt(id, 10),
    parseInt(currentUser.fetchSchools[0].id, 10));
  const [fetchEventUsersBySchool, { loading, error }] = useLazyQuery(
    FETCH_EVENT_USERS_BY_SCHOOL, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        updateCache(data.fetchEventUsersBySchool);
        setFilteredEventUsers(data.fetchEventUsersBySchool);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    fetchEventUsersBySchool({
      variables: {
        eventId: parseInt(id, 10),
        schoolId: parseInt(schoolId, 10),
      },
    });
  }, [schoolId, fetchEventUsersBySchool, id]);

  useHeaderComponentsMutation({
    backLink: `/event-manage/${id}`,
    title: 'PENDING TEAM REQUESTS',
    components: [{ key: EVENT_TITLE, value: event.title }],
  });


  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <ListItemDetail data={filteredEventUsers} imageAttached={userDefaultImage} redirectLink={`/event-manage/${id}/team-member-requests/${schoolId}/requests`} roleTitle="true" dataType="events" subLink={`team-member-requests/${schoolId}/requests/team-member-conversations`} />
    </>
  );
};
export default TeamMemberRequest;
