import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { get, getOr, isEmpty } from 'lodash/fp';
import { ADD_USER_TO_SUBTEAM, JOIN_EVENT_COMPETITION, REMOVE_MEMBER, REMOVE_USER_FROM_SUBTEAM } from '../../GraphQL/Mutations';
import { FETCH_EVENT_COMPETITIONS_BY_GENDER, GET_SCHOOL_SUBTEAMS, MANAGE_MY_COMPETITIONS, GET_USER_SUBTEAMS } from '../../GraphQL/Queries';
import useUpdateManageMyCompetitionsCache from '../../Hooks/useUpdateManageMyCompetitionsCache';
import Loader from '../../Components/Loader';
import BasicCategory from './BasicCategory';
import { RedirectButton } from '../../Components/Form';
import { AlignCenter } from '../../Components/Auth/Layout';
import BasicSubTeam from './BasicSubTeam';

const SPORTS_CATEGORY_ID = '6';

const buildCategoriesMap = (data, eventId) => {
  const availableCompetitions = data.fetchEventCompetitionsByGender.filter(
    (comp) => comp.eventId === eventId,
  );
  const categories = {
    // id to massive obj
  };
  availableCompetitions.forEach((c) => {
    const category = c.fetchCompetition.fetchCategory;
    if (!categories[category.id]) {
      categories[category.id] = {
        title: category.title,
        id: category.id,
        competitions: [],
      };
    }
    categories[category.id].competitions.push({
      title: c.title,
      id: c.id,
    });
  });
  const vals = Object.values(categories);
  vals.sort((c1, c2) => parseInt(c1.id, 10) - parseInt(c2.id, 10));
  return vals;
};

const changeComps = (oldSelectedComps, currentSelectedComps, deleteMember, addComp) => {
  const oldSelectedCompIds = Object.keys(oldSelectedComps);
  const newSelectedCompIds = Object.values(currentSelectedComps).flatMap(c => c);

  // if it's not currently selected, we need to delete
  const toDelete = oldSelectedCompIds.filter((id) => newSelectedCompIds.indexOf(id) < 0);

  // if it wasn't selected before, we need to add
  const toAdd = newSelectedCompIds.filter((id) => !oldSelectedComps[id]);

  const promises = [];
  toDelete.forEach((compId) => {
    promises.push(deleteMember(oldSelectedComps[compId].memberId));
  });
  toAdd.forEach((compId) => {
    promises.push(addComp(compId));
  });
  return promises;
};

const changeSubteams = (oldSelectedSubteams, currentSelectedSubteams, leaveSubteam, addSubteam) => {
  // both oldSelectedSubteams and currentSelectedSubteams are category id -> subteam id maps
  const oldSelectedSubteamIds = Object.values(oldSelectedSubteams);
  const newSelectedSubteamIds = Object.values(currentSelectedSubteams);

  // if it's not currently selected, we need to delete
  const toDelete = oldSelectedSubteamIds.filter((id) => newSelectedSubteamIds.indexOf(id) < 0);

  // if it wasn't selected before, we need to add
  const toAdd = newSelectedSubteamIds.filter((id) => oldSelectedSubteamIds.indexOf(id) < 0);

  const promises = [];
  toDelete.forEach((subteamId) => {
    promises.push(leaveSubteam(subteamId));
  });
  toAdd.forEach((subteamId) => {
    promises.push(addSubteam(subteamId));
  });

  return promises;
};

const initSelectedComps = (oldSelectedCompetitions, competitionsByCategory) => {
  const initialSelectedCompetitions = {};
  Object.keys(oldSelectedCompetitions).forEach((eventCompetitionId) => {
    const category = competitionsByCategory.find((cat) => cat.competitions.find(
      (comp) => comp.id === eventCompetitionId,
    ));
    if (category) {
      if (!initialSelectedCompetitions[category.id]) {
        initialSelectedCompetitions[category.id] = [];
      }
      initialSelectedCompetitions[category.id].push(eventCompetitionId);
    }
  });
  return initialSelectedCompetitions;
};

const BasicEventCompetitions = (props) => {
  const {
    order,
    addNewText,
    addNewLink,
    permissionName,
    eventAllowsMultipleSports,
  } = props;
  const eventId = parseInt(get('eventId', order), 10);
  const eventsUser = order.fetchEventsUser;
  const userId = getOr(null, 'userId', eventsUser);
  const history = useHistory();
  const schoolId = getOr(null, 'schoolId', eventsUser)

  const updateCache = useUpdateManageMyCompetitionsCache(
    eventId, userId,
  );

  const [competitionsByCategory, setCompetitionsByCategory] = useState([]);
  // looks like
  // const competitionsByCategory = [
  //   {
  //       title: 'Knowledge and Quran',
  //       id: 1,
  //       competitions: [
  //           {
  //               id: '123',
  //               title: 'Quran Memorization Level 1'
  //           }
  //       ]
  //   },
  //   {
  //       title: 'Arts',
  //       id: 2,
  //       competitions: [
  //           {
  //               id: '456',
  //               title: '2D Art'
  //           },
  //           {
  //               id: '457',
  //               title: '3D Art'
  //           }
  //       ]
  //   }
  // ];
  // "competition" objects are actually event_competitions!

  const [oldSelectedCompetitions, setOldSelectedCompetitions] = useState({});
  // keys are event_competition_id, value are objects with member_id
  // looks like
  // const oldSelectedCompetitions = {
  //   '952': {
  //     'memberId': '412',
  //   },
  //   '999': {
  //     'memberId': '413',
  //   }
  // }

  const [selectedCompetitions, setSelectedCompetitions] = useState({});
  // keys are category id, values are list of event_competition_id
  // looks like
  // const selectedCompetitions = {
  //   '1': ['123'],
  //   '2': ['456']
  // }

  const [oldSelectedSubteams, setOldSelectedSubteams] = useState({});
  // keys are event_competition_id, value is subteam id
  // looks like:
  // const oldSelectedSubteams = {
  //   '952': '123',
  //   '999': '456'
  // }

  const [selectedSubteams, setSelectedSubteams] = useState({});
  // keys are category id, value is subteam id
  // looks like:
  // const selectedSubteams = {
  //   '1': '123',
  //   '2': '456'
  // }

  const [eventMembers, setEventMembers] = useState({});

  const [schoolSubteamsByEventCompetition, setSchoolSubteamsByEventCompetition] = useState({});

  const [allUserSubteams, setAllUserSubteams] = useState([]);

  const [getUserSubteams, { loading: userSubTeamsLoading }] = useLazyQuery(
    GET_USER_SUBTEAMS,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ memberSubteams }) => {
        setAllUserSubteams(memberSubteams);
      },
    },
  );

  useEffect(() => {
    if (competitionsByCategory.length > 0 && allUserSubteams.length > 0) {
      const subteams = {};
      allUserSubteams.forEach((subteam) => {
        let categoryId;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < competitionsByCategory.length; i++) {
          const category = competitionsByCategory[i];
          const eventComps = category.competitions;

          // subteam.eventCompetitionId is a number, ec.id is a string
          // intentionally use ==
          // eslint-disable-next-line eqeqeq
          if (eventComps.find((ec) => ec.id == subteam.eventCompetitionId)) {
            categoryId = category.id;
            break;
          }
        }

        subteams[categoryId] = subteam.id;
      });
      setSelectedSubteams(subteams);
      setOldSelectedSubteams(subteams);
    }
  }, [competitionsByCategory, allUserSubteams]);

  useEffect(() => {
    getUserSubteams({
      variables: {
        userId,
      },
    });
  }, [getUserSubteams, userId]);

  const updateSubTeam = (selectedSubTeamId, categoryId) => {
    setSelectedSubteams({
      ...selectedSubteams,
      [categoryId]: selectedSubTeamId,
    });
  };

  const [getSchoolSubteams, { loading: schoolSubTeamsLoading }] = useLazyQuery(
    GET_SCHOOL_SUBTEAMS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setSchoolSubteamsByEventCompetition(buildSubTeamsMap(data))
      },
    },
  );

  useEffect(() => {
    if (eventId && schoolId) {
      getSchoolSubteams({
        variables: {
          eventId,
          schoolId,
        },
      });
    }
  }, [eventId, schoolId, getSchoolSubteams]);
  
  const buildSubTeamsMap = (data) => {
    const subTeamsByEventCompetition = {}
    const schoolSubteams = data.fetchSchoolSubteams.schoolSubteams
    schoolSubteams.forEach(eventCompetition => {
      subTeamsByEventCompetition[eventCompetition.eventCompetitionId] = eventCompetition.subteams
    })
    return subTeamsByEventCompetition
  }

  useEffect(() => {
    setSelectedCompetitions(initSelectedComps(oldSelectedCompetitions, competitionsByCategory));
  }, [oldSelectedCompetitions, competitionsByCategory]);


  const [isLoading, setLoading] = useState(false);
  const [joinEventCompetition] = useMutation(JOIN_EVENT_COMPETITION, {
    onCompleted: (data) => {
      updateCache(data.joinEventCompetition.members[0], 'add');
    },
  });
  const [deleteMember] = useMutation(REMOVE_MEMBER);
  const [addUserToCoalitionSubteam] = useMutation(ADD_USER_TO_SUBTEAM)
  const [removeUserFromCoalitionSubteam] = useMutation(REMOVE_USER_FROM_SUBTEAM)

  const joinComp = (compId) => {
    if (compId !== '-1') {
      return joinEventCompetition({
        variables: {
          eventsUserId: parseInt(eventsUser.id, 10),
          userId,
          status: 2,
          eventCompetitionId: parseInt(compId, 10),
          memberPriority: 2,
        },
      });
    }
    return Promise.resolve();
  };

  const joinSubteam = (coalitionId) => {
    if (coalitionId !== '-1') {
      return addUserToCoalitionSubteam({
        variables: {
          coalitionId: coalitionId,
          userId: userId,
        }
      });
    }
  }

  const unJoinSubteam = (oldCoalitionId) => {
    if (oldCoalitionId !== '-1') {
      return removeUserFromCoalitionSubteam({
        variables: {
          coalitionId: oldCoalitionId,
          userId: userId,
        }
      });
    }
  }

  const unjoinComp = (memberId) => deleteMember({
    variables: {
      memberId: parseInt(memberId, 10),
    },
  }).then(() => {
    const memberToDelete = eventMembers.find((member) => member.id === memberId);
    updateCache(memberToDelete, 'remove');
  });

  const onSave = () => {
    setLoading(true);

    const compsRequests = changeComps(
      oldSelectedCompetitions, selectedCompetitions, unjoinComp, joinComp,
    );

    // todo update subteam state to match comps state
    const subteamRequests = changeSubteams(
      oldSelectedSubteams, selectedSubteams, unJoinSubteam, joinSubteam,
    );

    Promise.all(compsRequests.concat(subteamRequests)).then(() => {
      setLoading(false);
      history.push(addNewLink);
    });
  };

  const updateCompetition = (categoryId, newSelectedCompetitions) => {
    setSelectedCompetitions({
      ...selectedCompetitions,
      [categoryId]: newSelectedCompetitions,
    });
    setSelectedSubteams({
      ...selectedSubteams,
      [categoryId]: '-1',
    });
  };

  const [fetchEventCompetitionsByGender, { loading: allEventCompsLoading }] = useLazyQuery(
    FETCH_EVENT_COMPETITIONS_BY_GENDER,
    {
      onCompleted: (data) => {
        const compsByCategory = buildCategoriesMap(data, eventId);
        setCompetitionsByCategory(compsByCategory);
      },
    },
  );

  useEffect(() => {
    if (!isEmpty(order) && eventsUser) {
      fetchEventCompetitionsByGender({
        variables: {
          eventId: parseInt(eventId, 10),
          gender: eventsUser.fetchUser.gender,
        },
      });
    }
  }, [fetchEventCompetitionsByGender, order, eventId, eventsUser]);

  const [manageMyCompetitions, { loading: myCompsLoading }] = useLazyQuery(
    MANAGE_MY_COMPETITIONS,
    {
      onCompleted: (data) => {
        const selectedComps = {};
        data.manageMyCompetitions.forEach((member) => {
          selectedComps[member.eventCompetitionId] = {
            memberId: member.id,
            competitionId: member.fetchEventCompetition.competitionId,
          };
        });
        setEventMembers(data.manageMyCompetitions);
        setOldSelectedCompetitions(selectedComps);
      },
    },
  );

  useEffect(() => {
    if (eventId && userId) {
      manageMyCompetitions({
        variables: {
          eventId,
          userId,
        },
      });
    }
  }, [eventId, userId, manageMyCompetitions]);

  if (myCompsLoading || allEventCompsLoading || userSubTeamsLoading || schoolSubTeamsLoading) {
    return <Loader />;
  }

  return (
    <div>
      {competitionsByCategory.map((cat) => (
        <div key={cat.id}>
          <BasicCategory
            key={cat.id}
            id={cat.id}
            title={cat.title}
            competitions={cat.competitions}
            onChange={updateCompetition}
            initialSelectedComps={selectedCompetitions[cat.id]}
            allowMultiple={eventAllowsMultipleSports && cat.id === SPORTS_CATEGORY_ID}
          />
      {(schoolSubteamsByEventCompetition[selectedCompetitions[cat.id]]?.length > 1)? 
        <BasicSubTeam
          key={selectedSubteams[cat.id]}
          id={selectedSubteams[cat.id]}
          title='Subteam'
          competitions={schoolSubteamsByEventCompetition[selectedCompetitions[cat.id]]}
          onChange={(subteamId) => updateSubTeam(subteamId, cat.id)}
          initialSubTeam={selectedSubteams[cat.id]}
        /> : null}
        </div>))}
      <AlignCenter>
        <RedirectButton
          backgroundcolor="#F4AB37"
          fontColor="#FFF"
          border="none"
          loading={isLoading}
          addNewText={addNewText}
          // addNewLink={addNewLink}
          permissionName={permissionName}
          onClick={onSave}
        />
      </AlignCenter>
    </div>
  );
};


BasicEventCompetitions.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  addNewText: PropTypes.string.isRequired,
  addNewLink: PropTypes.string.isRequired,
  permissionName: PropTypes.string,
  eventAllowsMultipleSports: PropTypes.bool.isRequired,
};

BasicEventCompetitions.defaultProps = {
  permissionName: null,
};

export default BasicEventCompetitions;
