import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_ROLE } from '../../GraphQL/Mutations';
import { FETCH_ROLE_DETAIL } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import Form from './Form';


const RoleEdit = () => {
  const { currentUser } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const history = useHistory();
  const { id } = useParams();
  const { params, onChange, setParams } = useFormParams({
    title: '',
    termsAndConditions: '',
    level: '',
    allowInvitation: '',
  });
  useHeaderComponentsMutation({ title: 'MANAGE ROLES' });
  useHeaderComponentsMutation({
    backLink: '/roles',
    title: 'MANAGE ROLES',
  });
  const extractError = useGraphQLErrorExtractor();
  const [updateRegion, { loading: updating, error: updateError }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: () => {
        history.push('/roles');
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update Role') === undefined) {
        history.push('/roles');
      }
    }
  }, [currentUser, checkPermission, history]);

  const { loading, error } = useQuery(
    FETCH_ROLE_DETAIL,
    {
      onCompleted: (data) => {
        setParams(data.fetchRoleDetail);
      },
      variables: { id: parseInt(id, 10) },
    },
  );
  const onSubmit = (e) => {
    e.preventDefault();
    params.level = parseInt(params.level, 10);
    updateRegion({
      variables: params,
    });
  };
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4 pt-3">
          {updateError ? <GenericAlert>{extractError(updateError)}</GenericAlert> : null}
          <Form
            className="pt-3"
            setParams={setParams}
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={updating}
            isEdit
            formType="edit"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default RoleEdit;
