import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import Input from '../../Components/Form/Input';
import Form from '../../Components/Form';
import useFormParams from '../../Hooks/useFormParams';
import {
  FontBold, Hr, PrimaryButton, LinkButton,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT,
  CHECK_TEAM_FINALIZE,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import ListItem from '../../Components/ListItem/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import {
  TEAM_FINALIZE, TEAM_UNFINALIZE,
} from '../../GraphQL/Mutations';
import useUpdateFinlizeTeamStatusCache from '../../Hooks/useUpdateFinlizeTeamStatusCache';

const SchoolTeamEdit = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const { schoolId } = useParams();
  const updateCache = useUpdateFinlizeTeamStatusCache(eventId, schoolId);
  const checkPermission = useUserPermissionChecker(currentUser);

  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );
  const teamFinalized = getOr([], 'checkTeamFinalize', dataCheckTeamFinalize);

  const { params, onChange } = useFormParams({
    status: '',
  });

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, event, checkTeamFinalize, eventId, schoolId]);

  useHeaderComponentsMutation({
    title: 'TEAM STATUS',
    backLink: `/event/${eventId}/teams/${schoolId}`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  const onCancel = () => {
    history.push(`/event/${eventId}/teams/${schoolId}`);
  };

  const [teamFinalize] = useMutation(
    TEAM_FINALIZE,
    {
      onCompleted: () => {
        updateCache(true);
        history.push(`/event/${eventId}/teams/${schoolId}`);
      },
    },
  );

  const [unfinalizeTeam, { loading }] = useMutation(
    TEAM_UNFINALIZE,
    {
      onCompleted: () => {
        updateCache(false);
        history.push(`/event/${eventId}/teams/${schoolId}`);
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (teamFinalized && Boolean(params.status)) {
      unfinalizeTeam({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
          status: 0,
        },
      });
    } else if (!teamFinalized && Boolean(params.status)) {
      teamFinalize({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
          status: 1,
        },
      });
    }
  };

  return (
    <>
      <div className="mt-4" />
      <div>
        {checkPermission(Permissions.CAN_MANAGE_REGION)
          ? (
            <>
              <FontBold className="pt-3">
                <p>Current Team Status</p>
              </FontBold>
              <ListItem title="Open" checkPermission={(!teamFinalized && checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <ListItem title="Closed" checkPermission={(teamFinalized && checkPermission(Permissions.CAN_MANAGE_REGION))} />
              <Form onSubmit={onSubmit}>
                <Input title="Update Status" name="status" type="select" params={params} id="status" required onChange={onChange}>
                  <option value="" disabled>Select Status</option>
                  {!teamFinalized
                    ? <option value={teamFinalized}>Closed</option>
                    : <option value={teamFinalized}>Open</option>}
                </Input>
                <div className="row align-items-center">
                  <div className="col-6 mx-auto">
                    <div className="pt-3">
                      <LinkButton className onClick={() => onCancel()}>Cancel</LinkButton>
                    </div>
                  </div>
                  <div className="col-6 mx-auto">
                    <PrimaryButton loading={loading} backgroundcolor="#343434" htmltype="submit">Save</PrimaryButton>
                  </div>
                </div>
              </Form>
            </>
          )
          : null}
      </div>
      <Hr />
    </>
  );
};
export default SchoolTeamEdit;
