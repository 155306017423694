import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_MY_EVENTS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateEventsByUserCache = () => {
  const client = useApolloClient();
  const updateCache = (event, action = 'add') => {
    try {
      const data = client.readQuery({ query: FETCH_MY_EVENTS });
      const { myEvents } = data;
      let response = [];
      if (action === 'add') {
        response = myEvents.unshift(event);
      } else {
        response = remove((e) => e.id === event.id)(myEvents);
      }
      client.writeQuery({
        query: FETCH_MY_EVENTS,
        data: { myEvents: response },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateEventsByUserCache;
