/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useCurrentUser from '../../Hooks/userCurrentUser';
import {
  MessageBox, SelfMessageBox,
} from '../Auth/Layout';

const Message = (props) => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const {
    data,
    displayAttribute,
    defaultMessage,
  } = props;

  return (
    <>
      <p>{defaultMessage}</p>
      {data.map((obj) => (
        <div key={obj.id}>
          <div className="mt-4" />
          { obj.userId === parseInt(currentUser.id, 10)
            ? (
              <div className="row">
                <div className="offset-2 col-10 px-4">
                  <SelfMessageBox>
                    <p>{obj[displayAttribute]}</p>
                    <span>
                      {moment(obj.createdAt, 'YYYY-MM-DD hh:mm:ss Z').format('MM/DD/YYYY hh:mma')}
                    </span>
                  </SelfMessageBox>
                </div>
              </div>
            )
            : (
              <div className="row">
                <div className="col-10 px-4">
                  <MessageBox>
                    <p>
                      {obj.authorFirstname}
                      {' '}
                      {obj.authorLastname}
                    </p>
                    <p>{obj[displayAttribute]}</p>
                    <span>
                      {moment(obj.createdAt, 'YYYY-MM-DD hh:mm:ss Z').format('MM/DD/YYYY hh:mma')}
                    </span>
                  </MessageBox>
                </div>
              </div>
            )}
        </div>
      ))}
    </>
  );
};

Message.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  displayAttribute: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
};

export default Message;
