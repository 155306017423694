import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash/fp';
import {
  TitleC, ListDetail, RIGHTARROW, Hr, ImgSqaure,
} from '../Auth/Layout';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';


const List = (props) => {
  const {
    data,
    displayAttribute,
    redirectLink,
    redirectOperation,
    imageAttached,
    imageNotRequired,
    arrowNotRequired,
    showAmount,
    paymentUser,
    paymentEvent,
    membersCount,
    school,
    couponUser,
    amount,

  } = props;

  return (
    <>
      {data && data.map((item) => (
        <div key={item.id}>
          <Link to={
                isEmpty(paymentUser)
                  ? `/${redirectLink}/${item.id}/${redirectOperation}`
                  : `/payments/${paymentEvent}/option/${item.id}`
            }
          >
            <div className="row align-items-center">
              <div className="col-11 mt-4 mx-auto px-4">
                <TitleC>
                  {isEmpty(imageNotRequired)
                    ? (
                      <ImgSqaure>
                        { item.profileImageUrl
                          ? <img src={item.profileImageUrl} alt="User-Icon" />
                          : (
                            <>
                              {imageAttached
                                ? <img src={imageAttached} alt="User-Icon" />
                                : null}
                            </>
                          )}
                      </ImgSqaure>
                    )
                    : null}


                  {!isEmpty(school)
                    ? (
                      <>
                        <span>
                          {item.user.full_name}
                          {' '}
                          {' '}
(
                          {item.user.code}
)
                        </span>
                        <p className="text-muted">
                          {item.user.accommodation_note}
                        </p>
                      </>
                    )
                    : (
                      <p>
                        {item[displayAttribute]}
                        {' '}
                        {item.lastname}
                        {' '}
                        {get('code', item)}
                      </p>
                    )}

                </TitleC>
                <ListDetail>
                  <div className="col-m-2 col-xs-3" style={{ float: 'left' }}>
                    {item.levelDetail && item.genderEnable === true
                      ? <p>Gender</p>
                      : null }
                  </div>
                  <div className="col-m-2 col-xs-3 px-4" style={{ float: 'left' }}>
                    {item.levelDetail
                      ? (
                        <p>
                          Level-
                          {item.levelDetail}
                        </p>
                      )
                      : null }
                  </div>
                </ListDetail>
                {!isEmpty(paymentUser)
                  ? (
                    <ListDetail>
                      <div className="col-m-2 col-xs-3" style={{ float: 'left' }}>
                        <p>
                          {item.paymentUser.unpaid_user_count}
                          {' '}
                          Unpaid
                        </p>
                      </div>
                      <div className="col-m-2 col-xs-3 px-4" style={{ float: 'left' }}>
                        <p>
                          {item.paymentUser.paid_user_count}
                          {' '}
                          Paid
                        </p>
                      </div>
                    </ListDetail>
                  )
                  : null}
                {!isEmpty(couponUser)
                  ? (
                    <ListDetail>
                      <div className="col-m-2 col-xs-3 float-left">
                        <p>
                          {item.couponUser.payment_amount / 100}
                          {' '}
                          {item.couponUser.default_currency }
                          {' '}
                        </p>
                      </div>
                    </ListDetail>
                  )
                  : null}
              </div>
              <div className="col-1 mt-4 mx-auto px-2">
                {
                  get('status', item) === 'pending'
                    ? <span>Pending</span>
                    : null
                }
                {
                  !isEmpty(showAmount)
                    ? (
                      <span>
                        {item.amount / 100}
                        {' '}
                        {item.defaultCurrency}
                      </span>
                    )
                    : null
                }
                {
                  !isEmpty(membersCount)
                    ? (
                      <p>
                        {item.joinedMemberCount}
                      </p>
                    )
                    : null
                }
                {
                  !isEmpty(amount)
                    ? (
                      <span>
                        {item.couponAmount / 100}
                        {item.defaultCurrency}
                      </span>
                    )
                    : null
                }
                {
                  !isEmpty(paymentUser)
                    ? <span>{item.paymentUser.total_user}</span>
                    : null
                }
                {
                  !isEmpty(couponUser)
                    ? <span>{item.couponUser.coupon_user_count}</span>
                    : null
                }
                {redirectLink && isEmpty(arrowNotRequired) && membersCount
                  ? (
                    <RIGHTARROW>
                      <img src={RIGHT_ARROW} alt="arrow" className="img-fluid mt-n5" />
                    </RIGHTARROW>
                  )
                  : (
                    <RIGHTARROW>
                      <img src={RIGHT_ARROW} alt="arrow" className="img-fluid mt-3" />
                    </RIGHTARROW>
                  )}
              </div>
            </div>
          </Link>
          <Hr />
        </div>
      ))}
    </>
  );
};

List.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  displayAttribute: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  redirectOperation: PropTypes.string,
  imageAttached: PropTypes.string,
  imageNotRequired: PropTypes.bool,
  arrowNotRequired: PropTypes.bool,
  showAmount: PropTypes.bool,
  paymentUser: PropTypes.bool,
  paymentEvent: PropTypes.string,
  membersCount: PropTypes.bool,
  school: PropTypes.bool,
  couponUser: PropTypes.bool,
  amount: PropTypes.bool,
};

List.defaultProps = {
  imageAttached: null,
  imageNotRequired: null,
  arrowNotRequired: null,
  redirectOperation: '',
  showAmount: null,
  paymentUser: null,
  paymentEvent: null,
  membersCount: null,
  school: null,
  couponUser: null,
  amount: null,
};

export default List;
