import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_REGISTRATION_OPTIONS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const RegistrationOptionsIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const { id } = useParams();
  const [fetchRegistrationOptions, { loading, error, data }] = useLazyQuery(
    FETCH_REGISTRATION_OPTIONS,
  );
  const registrationOptions = getOr([], 'fetchRegistrationOptions', data);
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'REGISTRATION OPTIONS',
    backLink: (`/event-manage/${id}`),
  });
  useEffect(() => {
    if (currentUser) {
      fetchRegistrationOptions({
        variables: {
          tenantId: currentUser.tenantId,
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptions, id]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">Registration Options</p>
          </FontBold>
        </div>
      </div>
      <List data={registrationOptions} displayAttribute="title" redirectLink="registration-options" redirectOperation="edit" dataType="registration-options" />
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Registration Option" addNewLink={`/events/${id}/registration-options/new`} imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('View Event'))} />
    </>
  );
};
export default RegistrationOptionsIndex;
