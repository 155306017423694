import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ProSidebar, SidebarHeader, Menu, MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { NavLink, useHistory } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { IoMdLogIn, IoMdLogOut, IoMdPeople } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';
import { BiCalendarEvent, BiDollarCircle } from 'react-icons/bi';
import { AiOutlineDashboard } from 'react-icons/ai';

import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import MistLightIcon from '../../Images/icons/MIST-Light.png';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserSession from '../../Hooks/useUserSession';
import { FETCH_EVENTS_USERS_BY_USER } from '../../GraphQL/Queries';


const SidebarWrapper = styled.div`
  .pro-sidebar > .pro-sidebar-inner {
    background-color: #F4AB37 !important;
  }


  .pro-sidebar .pro-menu a {
    color: #343a40;
  } 
  .pro-sidebar .pro-menu a:hover {
    color: white;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
      font-size: 20px;
      color: #343a40;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    color: white;
  }

`;

const MenuOptions = ({ closeSideBar }) => {
  const Link = (props) => <NavLink onClick={closeSideBar} {...props} />;
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { destroyUserSession } = useUserSession();

  const { data: dataEventsUser } = useQuery(
    FETCH_EVENTS_USERS_BY_USER,
    {
      variables: {
        userId: parseInt(get('id', currentUser), 10),
      },
      skip: !currentUser,
    },
  );

  const hasActiveTeam = useMemo(
    () => {
      if (!dataEventsUser?.eventsUsersByUser) {
        return false;
      }
      return dataEventsUser.eventsUsersByUser
        .filter((e) => e?.status === 'active')
        .filter((e) => e?.schoolId !== null)
        .length > 0;
    },
    [dataEventsUser],
  );

  const onLogout = (e) => {
    e.preventDefault();
    destroyUserSession();
    history.replace('/');
  };


  return currentUser && currentUser.status === 'active'
    ? (
      <>
        <MenuItem icon={<AiOutlineDashboard />}>
          {/* <Link to="/dashboard">My Dashboard</Link> */}
          <Link to="/dashboard">My Dashboard</Link>
        </MenuItem>
        {hasActiveTeam
          ? (
            <MenuItem icon={<IoMdPeople />}>
              <Link to="/events">My Team</Link>
            </MenuItem>
          )
          : (
            null
          )}

        <MenuItem icon={<BiCalendarEvent />}>
          <Link to="/">Browse Events</Link>
        </MenuItem>
        <MenuItem icon={<BiDollarCircle />}>
          <Link to="/payment-status">Manage Payments</Link>
        </MenuItem>
        <MenuItem icon={<FaRegQuestionCircle />}>
          <Link to="/help">Help</Link>
        </MenuItem>

        <MenuItem icon={<IoMdLogOut />}>
          <Link onClick={onLogout} to="/">Log Out</Link>
        </MenuItem>
      </>
    ) : (
      <>
        <MenuItem icon={<BiCalendarEvent />}>
          <Link to="/">Browse events</Link>
        </MenuItem>
        <MenuItem icon={<FaRegQuestionCircle />}>
          <Link to="/help">Help</Link>
        </MenuItem>

        <MenuItem icon={<IoMdLogIn />}>
          <Link to="/login">Log in</Link>
        </MenuItem>
        <MenuItem icon={<FiLogOut />}>
          <Link to="/sign_up">Sign up</Link>
        </MenuItem>

      </>
    );
};

MenuOptions.propTypes = {
  closeSideBar: PropTypes.func.isRequired,
};

const SideBar = ({ visible, changeSideBarVisibility }) => {
  // eslint-disable-next-line no-undef
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [collapsed, setCollapsed] = useState(!isMobile);
  const changeCollapsed = (val) => {
    if (!isMobile) {
      setCollapsed(val);
    }
  };
  const expandSidebar = () => changeCollapsed(false);
  const collapseSidebar = () => changeCollapsed(true);

  return (
    <SidebarWrapper onMouseEnter={expandSidebar} onMouseLeave={collapseSidebar}>
      <ProSidebar breakPoint="md" onToggle={changeSideBarVisibility} collapsed={collapsed} toggled={visible}>
        <SidebarHeader>
          <div className="d-flex">
            <img src={MistLightIcon} alt="logoLight" className="my-3 px-3 mx-auto img-fluid" />
          </div>
        </SidebarHeader>
        <Menu>
          <MenuOptions closeSideBar={changeSideBarVisibility} />
        </Menu>
      </ProSidebar>
    </SidebarWrapper>
  );
};

SideBar.propTypes = {
  visible: PropTypes.bool.isRequired,
  changeSideBarVisibility: PropTypes.func.isRequired,
};

export default SideBar;
