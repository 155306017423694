import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Spinner,
} from '@chakra-ui/react';

import { EVENT_TITLE } from '../../../Constants/AppConstants';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import { ExportButton } from './SpiritPointsReportExport';
import { SpiritPointsTable } from './SpiritPointsTable';

const SUMMARY_LIMIT = 10;
const SPIRIT_POINT_SUMMARY = gql`
query fetchEventDetail($id: Int!, $limit: Int!) {
  fetchEventDetail(id: $id) {
    id
    title
    spiritPointRankings(limit: $limit) {
      teams {
        code
        totalSpiritPoints
        school {
          id
          name
        }
      }
      students {
        id
        totalSpiritPoints
        fetchUser {
          id
          firstname
          lastname
          code
        }
        fetchSchool {
          id
          name
        }
      }
    }
  }
}
`;

const ToggleButton = ({ selected, children, onClick }) => {
  if (selected) {
    return (
      <Button
        backgroundColor="blue.100"
        disabled
        fontWeight={400}
        padding={4}
        borderRadius={16}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      onClick={onClick}
      variant="ghost"
      fontWeight={400}
      padding={4}
      borderRadius={16}
    >
      {children}
    </Button>
  );
};
ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SpiritPointsReport = () => {
  const [showStudents, setShowStudents] = useState(true);
  const { id } = useParams();
  const { data, loading } = useQuery(SPIRIT_POINT_SUMMARY, {
    variables: {
      id: parseInt(id, 10),
      limit: SUMMARY_LIMIT,
    },
  });

  useHeaderComponentsMutation({
    title: 'SPIRIT POINTS REPORT',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: data?.fetchEventDetail?.title }],
  });

  if (loading) {
    return (
      <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const { spiritPointRankings } = data.fetchEventDetail;

  return (
    <Box w="100%" h="100%" marginBlock={8}>
      <Flex justifyContent="space-between" w="100%">
        <Flex gap={2}>
          <ToggleButton selected={showStudents} onClick={() => setShowStudents(true)}>
            Students
          </ToggleButton>
          <ToggleButton selected={!showStudents} onClick={() => setShowStudents(false)}>
            Schools
          </ToggleButton>
        </Flex>
        <ExportButton eventId={parseInt(id, 10)} eventName={data.fetchEventDetail.title} />
      </Flex>
      <SpiritPointsTable spiritPointRankings={spiritPointRankings} showStudents={showStudents} />
    </Box>
  );
};
