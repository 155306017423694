import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_REGION } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const RegionIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [fetchRegions, { loading, error, data }] = useLazyQuery(FETCH_REGION);
  const regions = getOr([], 'fetchRegions', data);
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'REGIONS',
    backLink: '/dashboard',
  });
  useEffect(() => {
    if (currentUser) {
      fetchRegions({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRegions]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">Active Regions</p>
          </FontBold>
        </div>
      </div>
      <List data={regions} displayAttribute="name" redirectLink="regions" redirectOperation="edit" dataType="regions" />
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Region" addNewLink="regions/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Region'))} />
    </>
  );
};
export default RegionIndex;
