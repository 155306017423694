
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TitleC, Hr, RIGHTARROW, LinkTitle,
} from '../Auth/Layout';

const CancelItem = (props) => {
  const {
    title,
    onClick,
    imageAttached,
    checkPermission,
    color,
  } = props;

  return (
    <>
      {checkPermission
        ? (
          <>
            <div className="row align-items-center">
              <div className="col-10 mx-auto pt-3">
                <LinkTitle onClick={onClick}>
                  <TitleC style={{ color }}>
                    <p>
                      {title}
                    </p>
                  </TitleC>
                </LinkTitle>
              </div>
              <div className="col-2 mx-auto px-4 mt-4">
                <RIGHTARROW>
                  <img src={imageAttached} alt="arrow" className="img-fluid" />
                </RIGHTARROW>
              </div>
            </div>
            <Hr />
          </>
        )
        : null}
    </>
  );
};

CancelItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  imageAttached: PropTypes.string.isRequired,
  checkPermission: PropTypes.bool,
  color: PropTypes.string,
};

CancelItem.defaultProps = {
  onClick: null,
  title: null,
  checkPermission: null,
  color: '#000',
};

export default CancelItem;
