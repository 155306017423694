import { useApolloClient } from '@apollo/react-hooks';
// import { remove } from 'lodash/fp';
import { CURRENT_USER } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateUserDetailCache = () => {
  const client = useApolloClient();
  const updateCache = (updatedData) => {
    try {
      const data = client.readQuery({
        query: CURRENT_USER,
      });
      const { user } = data;
      client.writeQuery({
        query: CURRENT_USER,
        data: {
          user: {
            ...user,
            ...updatedData,
          },
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateUserDetailCache;
