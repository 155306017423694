import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { get } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENTS_USERS_BY_USERS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';

const ViewTermsAndConditions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const [eventUserDetails, setEventUserDetails] = useState([]);
  const [fetchEventDetails] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS, {
      onCompleted: (data) => {
        setEventUserDetails(data.fetchEventsUsersByUser);
      },
    },
  );
  useEffect(() => {
    if (id) {
      fetchEventDetails({
        variables: {
          eventId: parseInt(id, 10),
          userId: parseInt(get('id', currentUser), 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, fetchEventDetails, id]);

  useHeaderComponentsMutation({
    title: 'VIEW TERMS AND CONDITIONS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('title', get('fetchEvent', eventUserDetails[0])) }],
  });

  return (
    <>
      <div className="my-4 ">
        {get('termsAndConditions', get('fetchRole', get('fetchRegistrationOption', eventUserDetails[0])))}
        <div className="my-4 " />
        {get('termsAndConditions', get('fetchEvent', eventUserDetails[0]))}


      </div>

    </>
  );
};


export default ViewTermsAndConditions;
