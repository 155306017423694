import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  width: 100%;
`;

const GenericAlert = (props) => {
  const {
    color,
    children  
  } = props;

  return (
    <>
    <div class="mt-3">
      <StyledAlert color={color}>
        {children}
      </StyledAlert>
    </div>
    </>
  );
};

GenericAlert.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
};

GenericAlert.defaultProps = {
  color: 'danger',
};

export default GenericAlert;

