import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, getOr, isEmpty } from 'lodash/fp';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { FETCH_ACTIVE_COUNTIRES, FETCH_STATES_BY_COUNTRY } from '../../GraphQL/Queries';
import Form, { Input, PermissionButton } from '../../Components/Form';
import { LinkButton } from '../../Components/Auth/Layout';
import { DELETE_REGION_FROM_SCHOOL } from '../../GraphQL/Mutations';
import REMOVE_ICON from '../../Images/icons/remove.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import DeleteItem from '../../Components/DeleteItem/Index';
import useAdminUserChecker from '../../Hooks/useAdminUserChecker';

const SchoolForm = ({
  params,
  onSubmit,
  onChange,
  loading,
  data,
  regionLength,
  selectedRegions,
  onRemove,
  formType,
  currentUser,
  schoolObj,
  schoolsUsersCount,
}) => {
  const checkPermission = useUserPermissionChecker(currentUser);
  const adminCheck = useAdminUserChecker(currentUser);
  const history = useHistory();
  const [regionCount, setRegionCount] = useState(regionLength);
  const [states, setStates] = useState([]);
  const fieldsArray = [];
  for (let i = 0; i < regionCount; i += 1) {
    fieldsArray.push(
      <Input key={i} name={`regionUniqueId_${i}`} title="Regions" type="select" params={params} id={`regionUniqueId_${i}`} required onChange={onChange}>
        <option value="" disabled>Regions</option>
        {data.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.name}</option>
        ))}
      </Input>,
    );
  }
  const [deleteSchoolRegion] = useMutation(
    DELETE_REGION_FROM_SCHOOL,
    {
      onCompleted: (dataSchool) => {
        onRemove(dataSchool.deleteSchoolRegion.school.regionIds);
      },
    },
  );

  const deleteRegionSchool = (schoolId, regionId) => {
    deleteSchoolRegion({
      variables: {
        schoolId: parseInt(schoolId, 10),
        regionId,
      },
    });
  };

  const [fetchStatesByCountry] = useLazyQuery(
    FETCH_STATES_BY_COUNTRY, {
      onCompleted: (dataState) => {
        setStates(dataState.fetchStatesByCountry);
      },
    },
  );

  useEffect(() => {
    if (!isEmpty(params.country)) {
      fetchStatesByCountry({
        variables: {
          countryId: parseInt(params.country, 10),
        },
      });
    }
  }, [params.country, fetchStatesByCountry]);

  const [fetchActiveCountries, { data: dataCountries }] = useLazyQuery(
    FETCH_ACTIVE_COUNTIRES,
  );

  const countries = getOr([], 'fetchActiveCountries', dataCountries);

  useEffect(() => {
    fetchActiveCountries({
    });
  }, [fetchActiveCountries]);

  const redirect = () => {
    history.push(`/schools/${params.id}/students`);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="School Name"
        label="Name"
        name="name"
        id="name"
        placeholder="School Name"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <Input
        title="Address Line 1"
        label="Address Line 1"
        name="address1"
        id="address1"
        placeholder="Address Line 1"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <Input
        title="Address Line 2"
        label="Address Line 2"
        name="address2"
        id="address2"
        placeholder="Address Line 2"
        onChange={onChange}
        params={params}
      />
      <Input
        title="City"
        label="City"
        name="city"
        id="city"
        placeholder="City"
        onChange={onChange}
        params={params}
        required
      />
      <Input title="Country" name="country" type="select" params={params} id="country" required onChange={onChange}>
        <option value="" disabled>Country</option>
        {countries && countries.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.title}</option>
        ))}
      </Input>
      <div className="row">
        <div className="col-6">
          <Input title="State/Province" name="stateCode" type="select" params={params} id="stateCode" required onChange={onChange}>
            <option value="" disabled>State/Province</option>
            {states && states.map((state) => (
              <option key={state.id} value={state.code}>{state.title}</option>
            ))}
          </Input>
        </div>
        <div className="col-6">
          <Input
            title="Postal Code"
            label="Postal Code"
            name="postalCode"
            id="postalCode"
            placeholder="Postal Code"
            onChange={onChange}
            params={params}
            required
            pattern=".*\S+.*"
          />
        </div>
      </div>
      <Input
        title="School Website"
        label="School Website"
        name="website"
        id="website"
        placeholder="School Website"
        onChange={onChange}
        params={params}
      />

      <Input
        title="School Phone"
        label="School Phone"
        name="phone"
        id="phone"
        placeholder="School Phone"
        onChange={onChange}
        params={params}
      />
      {selectedRegions.map((id) => (
        <DeleteItem
          key={id}
          index={id}
          title={get('name', data[data.findIndex((x) => parseInt(x.id, 10) === id)])}
          onClick={() => deleteRegionSchool(params.id, id)}
          removeicon={REMOVE_ICON}
        />
      ))}
      {fieldsArray}
      {data.length > selectedRegions.length + regionCount && adminCheck === true
        ? (
          <LinkButton
            onClick={() => setRegionCount(regionCount + 1)}
          >
            ADD REGION +
          </LinkButton>
        )
        : null}

      {formType === 'new'
        ? <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Approve School')) || (checkPermission('Add School'))}>SUBMIT</PermissionButton>
        : <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Update School'))}>APPROVE</PermissionButton>}
      <div className="pt-3" />
      {formType === 'edit' && params.schoolsUsersCount && get('status', schoolObj) !== 'active' && schoolsUsersCount > 0
        ? <LinkButton onClick={() => redirect()}>REASSIGN STUDENTS</LinkButton>
        : null}
    </Form>
  );
};

SchoolForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array).isRequired,
  regionLength: PropTypes.number.isRequired,
  selectedRegions: PropTypes.instanceOf(Array),
  onRemove: PropTypes.func,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  schoolObj: PropTypes.instanceOf(Object),
  schoolsUsersCount: PropTypes.instanceOf(Object),
};

SchoolForm.defaultProps = {
  loading: false,
  selectedRegions: [],
  onRemove: null,
  schoolObj: null,
  schoolsUsersCount: 0,
};

export default SchoolForm;
