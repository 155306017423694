import React, { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_MY_EVENTS, SEARCH_EVENTS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const EventIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const { loading, error } = useQuery(
    FETCH_MY_EVENTS, {
      onCompleted: (data) => {
        setFilteredEvents(data.myEvents);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'EVENTS',
    backLink: '/dashboard',
  });

  const [eventsSearch] = useLazyQuery(
    SEARCH_EVENTS,
    {
      onCompleted: (data) => {
        setFilteredEvents(data.searchEvents.filter(({ status }) => status === 'active'));
      },
    },
  );

  const searchEvents = (e) => {
    eventsSearch({
      variables: { query: e.target.value },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={(e) => searchEvents(e)}
      />
      {isEmpty(filteredEvents)
        ? <p>No Events</p>
        : null}
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              Events(
              {filteredEvents.length}
              )
            </p>
          </FontBold>
        </div>
      </div>
      <List data={filteredEvents} displayAttribute="title" redirectLink="event-manage" redirectOperation="" dataType="events" />
      {/* TODO: change me once we have "import from past event" functionality
        *       this used to go to `/event-detail`
        */}
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Event" addNewLink="/events/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Event'))} />
    </>
  );
};
export default EventIndex;
