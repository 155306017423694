import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../Components/Loader';
import { EVENT_TITLE } from '../../../Constants/AppConstants';
import {
  FETCH_EVENT_APP_INFO,
} from '../../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../../Components/GenericAlert';
import AddLocationRow from './AddLocationRow';
import ExistingLocationMap from './ExistingLocationMap';

const Locations = () => {
  const { id } = useParams();
  const eventId = parseInt(id, 10);
  const [locations, setLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const queryResult = useQuery(FETCH_EVENT_APP_INFO, {
    variables: {
      id: eventId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const existingLocations = data.fetchEventAppDetail?.locations;
      existingLocations.sort((a, b) => (a.id < b.id ? 1 : -1));
      setLocations(existingLocations);
    },
  });
  const eventLoading = queryResult.loading;
  const event = queryResult.data?.fetchEventAppDetail;
  const backLink = `/event-manage/${id}`;
  useHeaderComponentsMutation({
    title: 'Locations',
    backLink,
    components: [{ key: EVENT_TITLE, value: event?.title }],
  });

  if (eventLoading) {
    return <Loader />;
  }

  const onLocationCreate = (newLocation) => {
    const newListOfLocations = [newLocation, ...locations];
    setLocations(newListOfLocations);
  };

  const onLocationRemoved = (deletedLocationId) => {
    const newListOfLocations = locations.filter((a) => a.id !== deletedLocationId);
    setLocations(newListOfLocations);
  };

  const setError = (msg) => {
    setErrorMessage(msg);
    window.scrollTo(0, 0);
  };

  // in other words, locations not allowed to be deleted
  const locationsUsedOnSchedule = event?.schedule?.scheduleEvents?.map(
      (scheduleEvent) => scheduleEvent?.fetchLocation?.id) ?? [];

  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="container">
        <div className="row my-2 justify-content-center">
          <AddLocationRow
            eventId={eventId}
            onCreate={onLocationCreate}
            onError={setError}
          />
        </div>
        <div className="row my-4 justify-content-center">
          <ExistingLocationMap
            locationsUsedOnSchedule={locationsUsedOnSchedule}
            onError={setError}
            locations={locations}
            onRemoveComplete={onLocationRemoved}
          />
        </div>
      </div>
    </>
  );
};

export default Locations;
