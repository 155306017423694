import useSortArray from './useSortArray';
import useUpdateTopMembersPriority from './useUpdateTopMembersPriority';
import useUpdateBottomMembersPriority from './useUpdateBottomMembersPriority';

const sortedArray = useSortArray();
const swapTopPriority = useUpdateTopMembersPriority();
const swapBottomPriority = useUpdateBottomMembersPriority();

const useChangePriority = () => {
  const changePriority = (array, firstElement, secondElement, sortAttribute, finalIndex, intialIndex) => {
    let swappedArray = array;
    if(finalIndex < intialIndex){
      swappedArray = swapTopPriority(
        swappedArray,
        firstElement,
        intialIndex,
        finalIndex,
        sortAttribute,
      );
    }
    else{
      swappedArray = swapBottomPriority(
        swappedArray,
        firstElement,
        intialIndex,
        finalIndex,
        sortAttribute,
      );
    }

    swappedArray = sortedArray(
      swappedArray,
      sortAttribute,
    );

    return swappedArray;
  };
  return changePriority;
};

export default useChangePriority;
