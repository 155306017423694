import { useState } from 'react';

const useFormParams = (defaultParams) => {
  const [params, setParams] = useState(defaultParams);

  const onChange = (name, value) => {
    setParams({
      ...params,
      [name]: value,
    });
  };
  return { params, onChange, setParams };
};

export default useFormParams;
