import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { BROWSE_EVENTS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateActiveEventsCache = () => {
  const updateResponse = (events, id, action) => {
    if (action === 'add') {
      return events.unshift(id);
    }
    if (action === 'remove') {
      return remove((e) => e.id === id)(events);
    }
    return events;
  };

  const client = useApolloClient();
  const updateEventCache = (eventId, action = 'add') => {
    try {
      const browseEvents = client.readQuery({ query: BROWSE_EVENTS });
      client.writeQuery({
        query: BROWSE_EVENTS,
        data: {
          fetchActiveEvents: updateResponse(browseEvents.fetchActiveEvents, eventId, action),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateEventCache;
};

export default useUpdateActiveEventsCache;
