import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import {
  GoogleMap, Autocomplete, Marker, useLoadScript,
} from '@react-google-maps/api';
import { CREATE_LOCATION } from '../../../GraphQL/Mutations';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';
import { PrimaryButton } from '../../../Components/Auth/Layout';

const containerStyle = {
  width: '700px',
  height: '400px',
};

const libraries = ['places'];

const extractError = useGraphQLErrorExtractor();
const AddLocationRow = ({
  eventId, onError, onCreate,
}) => {
  const [autocomplete, setAutocomplete] = useState();
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState(39.9506385);
  const [longitude, setLongitude] = useState(-75.1496731);

  const [centerMap, setCenterMap] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [zoom, setZoom] = useState(10);
  const [createLocation, { loading }] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted: () => {
        setName('');
      },
      onError: (err) => {
        onError(extractError(err));
      },
    },
  );

  const validateParams = (validateName, validateLatitude, validateLongitude) => !(validateName === '' || validateLatitude === '' || validateLongitude === '');
  const paramsInvalid = !validateParams(name, latitude, longitude);

  const onSave = () => {
    createLocation({
      variables: {
        input: {
          name,
          latitude,
          longitude,
          eventId,
        },
      },
    }).then((data) => {
      onCreate(data.data.createLocation.location);
    });
  };

  useEffect(() => {
    setCenterMap({
      lat: latitude,
      lng: longitude,
    });
  }, [latitude, longitude]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  return isLoaded ? (
    <div className="col-10 mx-2">
      <h2>Add new:</h2>
      <div className="row my-2 justify-content-center">
        <h4>Name:</h4>
        <div className="col-5 mx-2 my-2">
          <input style={{ width: '100%' }} value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={centerMap}
          zoom={zoom}
        >
          <Autocomplete
            onPlaceChanged={() => {
              if (autocomplete) {
                setLatitude(autocomplete.getPlace().geometry?.location.lat());
                setLongitude(autocomplete.getPlace().geometry?.location.lng());
                setZoom(16);
              }
            }}
            onLoad={(autocompleteLoad) => {
              setAutocomplete(autocompleteLoad);
            }}
          >
            <input
              type="text"
              placeholder="Location"
              style={{
                boxSizing: 'border-box',
                border: '1px solid transparent',
                width: '240px',
                height: '46px',
                padding: '0 12px',
                borderRadius: '3px',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                fontSize: '16px',
                outline: 'none',
                textOverflow: 'ellipses',
                position: 'absolute',
                left: '50%',
                marginLeft: '-120px',
                top: '2%',
              }}
            />
          </Autocomplete>
          <Marker
            position={centerMap}
            draggable
            onDragEnd={(e) => {
              setLatitude(e.latLng.lat);
              setLongitude(e.latLng.lng);
            }}
          />
          <></>
        </GoogleMap>
      </div>
      <div className="row m-2 justify-content-center">
        <div className="col-2 mx-2 my-2">
          <PrimaryButton
            backgroundcolor="#F4AB37"
            fontcolor="#FFF"
            border="none"
            onClick={onSave}
            disabled={loading || paramsInvalid}
          >
            {loading ? 'Submitting...' : 'Save'}
          </PrimaryButton>
        </div>
      </div>
    </div>
  ) : <></>;
};

AddLocationRow.propTypes = {
  eventId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AddLocationRow;
