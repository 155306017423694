import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_UNAPPROVE_SCHOOLS_BY_EVENT } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateUnapprovedSchoolsCache = (eventId) => {
  const client = useApolloClient();
  const updateCache = (school) => {
    try {
      const data = client.readQuery({
        query: FETCH_UNAPPROVE_SCHOOLS_BY_EVENT,
        variables: {
          eventId: parseInt(eventId, 10),
        },
      });
      client.writeQuery({
        query: FETCH_UNAPPROVE_SCHOOLS_BY_EVENT,
        data: {
          fetchUnapproveSchool: [
            ...data.fetchUnapproveSchool,
            { ...school },
          ],
        },
        variables: {
          eventId: parseInt(eventId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateUnapprovedSchoolsCache;
