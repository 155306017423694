import React from 'react';
import PropTypes from 'prop-types';
import Form, { Input, Button } from '../../Components/Form';

const DenySchoolForm = ({
  params, onSubmit, onChange, loading,
}) => (
  <Form onSubmit={onSubmit}>
    <Input
      title="Comments"
      label="Comments"
      name="denyComment"
      id="denyComment"
      placeholder="Comments"
      required
      onChange={onChange}
      params={params}
      type="textarea"
    />
    <Button loading={loading} htmltype="submit" marginBottom="10px" backgroundcolor="#FFF" fontColor="#000" border="1px solid">DENY</Button>
  </Form>
);

DenySchoolForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

DenySchoolForm.defaultProps = {
  loading: false,
};

export default DenySchoolForm;
