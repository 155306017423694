import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { compareAsc } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Button,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react';

import { Provider } from '../../../Chakra';
import Loader from '../../../Components/Loader';
import { EVENT_TITLE } from '../../../Constants/AppConstants';
import { FETCH_EVENT_APP_INFO } from '../../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';

import { ExistingScheduleCard } from './ExistingScheduleCard';
import { AddNewScheduleCard } from './AddNewScheduleEventCard';

const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DISPLAY_DATE_FORMAT = 'MMM d yyyy';

const scheduleEventsComparator = (e1, e2) => {
  if (e1.startTime !== e2.startTime) {
    return compareAsc(new Date(e1.startTime), new Date(e2.startTime));
  }
  if (e1.endTime !== e2.endTime) {
    return compareAsc(new Date(e1.endTime), new Date(e2.endTime));
  }
  return e1.title.localeCompare(e2.title);
};

const dayFromScheduleEvent = (scheduleEvent) => {
  const zonedTime = utcToZonedTime(scheduleEvent.startTime, USER_LONG_TZ);
  return format(zonedTime, DISPLAY_DATE_FORMAT);
};

// takes a sorted list of events
// returns list of objects:
// { day: 'DATE STRING', events: [ ... ]}
const groupScheduleEventsByDay = (scheduleEvents) => {
  if (scheduleEvents.length === 0) {
    return [];
  }

  const res = [];
  let currentDay = null;
  let currentSet = [];
  scheduleEvents.forEach((se) => {
    const eventDay = dayFromScheduleEvent(se);
    if (eventDay !== currentDay) {
      if (currentDay !== null) {
        res.push({
          day: currentDay,
          events: currentSet,
        });
        currentSet = [];
      }
      currentDay = eventDay;
    }
    currentSet.push(se);
  });
  res.push({
    day: currentDay,
    events: currentSet,
  });
  return res;
};

const Schedule = () => {
  const { id } = useParams();
  const [scheduleEvents, setScheduleEvents] = useState([]);
  const eventId = parseInt(id, 10);
  const backLink = `/event-manage/${id}`;
  const [isAddingNewEvent, setIsAddingNewEvent] = useState(false);

  const { data, loading } = useQuery(FETCH_EVENT_APP_INFO, {
    variables: { id: eventId },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      const dataScheduleEvents = [...d.fetchEventAppDetail.schedule.scheduleEvents];
      dataScheduleEvents.sort(scheduleEventsComparator);
      setScheduleEvents(dataScheduleEvents);
    },
  });
  const event = data?.fetchEventAppDetail;

  useHeaderComponentsMutation({
    title: 'Schedule',
    backLink,
    components: [{ key: EVENT_TITLE, value: event?.title }],
  });

  if (loading) {
    return <Loader />;
  }

  const onCreateNewScheduleEvent = (newScheduleEvent) => {
    setIsAddingNewEvent(false);
    setScheduleEvents((existingScheduleEvents) => {
      const newScheduleEvents = [...existingScheduleEvents, newScheduleEvent];
      newScheduleEvents.sort(scheduleEventsComparator);
      return newScheduleEvents;
    });
  };
  const onEditScheduleEvent = (updatedScheduleEvent) => {
    setScheduleEvents((existingScheduleEvents) => {
      const newScheduleEvents = existingScheduleEvents
        .filter((se) => se.id !== updatedScheduleEvent.id)
        .concat([updatedScheduleEvent]);
      newScheduleEvents.sort(scheduleEventsComparator);
      return newScheduleEvents;
    });
  };

  const onDeleteScheduleEvent = (deletedScheduleEventId) => {
    setScheduleEvents((existingScheduleEvents) => existingScheduleEvents
      .filter((s) => s.id !== deletedScheduleEventId));
  };

  const groupedEvents = groupScheduleEventsByDay(scheduleEvents);

  return (
    <>
      <Provider>
        <Flex direction="row" justifyContent="space-between" paddingBlock={2}>
          <Heading>Schedule</Heading>
          <Button variant="solid" colorScheme="orange" onClick={() => setIsAddingNewEvent(!isAddingNewEvent)}>
            {isAddingNewEvent ? 'Cancel' : 'Add New'}
          </Button>
        </Flex>
        {isAddingNewEvent && (
          <AddNewScheduleCard
            eventId={eventId}
            onSave={onCreateNewScheduleEvent}
            eventCompetitions={event.fetchEventCompetitions}
            locations={event.locations}
          />
        )}
        <VStack spacing={4} paddingBottom={4}>
          {groupedEvents.map((group) => (
            <React.Fragment key={group.day}>
              <Heading alignSelf="flex-start" size="md">{group.day}</Heading>
              {group.events.map((scheduleEvent) => (
                <ExistingScheduleCard
                  key={scheduleEvent.id}
                  schedule={scheduleEvent}
                  onEditScheduleEvent={onEditScheduleEvent}
                  onDeleteScheduleEvent={onDeleteScheduleEvent}
                  eventId={eventId}
                  eventCompetitions={event.fetchEventCompetitions}
                  locations={event.locations}
                />
              ))}
            </React.Fragment>
          ))}
        </VStack>
      </Provider>
    </>
  );
};

export default Schedule;
