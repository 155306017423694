import { getOr, first } from 'lodash/fp';

const useRegistrationOptionPermissionChecker = (registrationOption) => {
  const checkPermissionOfRegistrationOption = (permissionName) => {
    const permissions = getOr([], 'fetchRole.permissions', registrationOption);
    return first(permissions.filter((x) => x.title === permissionName));
  };

  return checkPermissionOfRegistrationOption;
};

export default useRegistrationOptionPermissionChecker;
