import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import Input from '../../Components/Form/Input';

const ButtonLink = styled.button`
  color: #3E6796;
  border: none;
  background: transparent;
`;

const BasicCategory = (props) => {
  const {
    id,
    title,
    competitions,
    onChange,
    initialSelectedComps,
    allowMultiple,
  } = props;

  const initialParams = {
    [id]: initialSelectedComps,
  };

  const [params, setParams] = useState(initialParams);

  if (!params[id] && initialSelectedComps) {
    // initial setup
    setParams({ [id]: initialSelectedComps });
  }

  const currentSelectedComps = params[id] ?? [-1];

  const wrappedOnChange = (selfId, idx, selection) => {
    const newSelectedCompIds = [...currentSelectedComps];
    newSelectedCompIds[idx] = selection;

    setParams({ [id]: newSelectedCompIds });
    onChange(selfId, newSelectedCompIds);
  };

  return (
    <div key={id}>
      {
        currentSelectedComps.map((selectedCompId, idx) => (
          <Input
            key={`${id}-${idx}`}
            title={idx === 0 ? title : null}
            name={id}
            type="select"
            id={id}
            onChange={(id, selection) => wrappedOnChange(id, idx, selection)}
            params={{ [id]: selectedCompId }}
          >
            <option value={-1} key={-1}>None</option>
            {competitions.map((c) => {
              const alreadySelectedIdx = currentSelectedComps.indexOf(c.id);
              const disabled = alreadySelectedIdx !== -1 && alreadySelectedIdx !== idx;
              return (
                <option key={c.id} value={c.id} disabled={disabled}>
                  {c.title}
                </option>
              );
            })}
          </Input>
        ))
      }
      {allowMultiple && currentSelectedComps.length < competitions.length && (
        <ButtonLink onClick={() => wrappedOnChange(id, currentSelectedComps.length, '-1')}>
          <img src={ADD_SMALL_ICON} className="img-fluid" alt="" style={{ paddingBlockEnd: '4px', paddingInlineEnd: '4px' }}/>
          Add Another Competition
        </ButtonLink>
      )}
    </div>
  );
};

BasicCategory.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  competitions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  initialSelectedComps: PropTypes.arrayOf(PropTypes.string),
};

BasicCategory.defaultProps = {
  initialSelectedComps: undefined,
};

export default BasicCategory;
