import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import {
  Box,
  Button,
  VStack,
} from '@chakra-ui/react';

import { CREATE_SCHEDULE_EVENT } from '../../../GraphQL/Mutations';

import { ScheduleEventForm } from './ScheduleEventForm';
import { EventCompetitionPropType, LocationPropType } from './propTypes';
import { EMPTY_SCHEDULE, isScheduleInvalid } from './utils';

export const AddNewScheduleCard = ({
  eventId,
  onSave,
  eventCompetitions,
  locations,
}) => {
  const [schedule, setSchedule] = useState(EMPTY_SCHEDULE);

  const [createScheduleEvent, { loading }] = useMutation(CREATE_SCHEDULE_EVENT);

  const wrappedOnSave = () => {
    createScheduleEvent({
      variables: {
        input: {
          eventId,
          ...schedule,
        },
      },
    }).then((res) => {
      const newScheduleEvent = res.data.createScheduleEvent.scheduleEvent;
      onSave(newScheduleEvent);
    });
  };

  return (
    <Box
      sx={{
        borderRadius: '8',
        borderColor: 'gray.200',
        borderWidth: '2px',
      }}
      width="100%"
      padding="16px"
    >
      <VStack width="full">
        <ScheduleEventForm
          schedule={schedule}
          setSchedule={setSchedule}
          onSave={onSave}
          eventCompetitions={eventCompetitions}
          locations={locations}
        />
        <Button
          variant="solid"
          colorScheme="orange"
          isLoading={loading}
          disabled={isScheduleInvalid(schedule)}
          onClick={wrappedOnSave}
        >
          Save
        </Button>
      </VStack>
    </Box>
  );
};
AddNewScheduleCard.propTypes = {
  eventId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  eventCompetitions: PropTypes.arrayOf(EventCompetitionPropType).isRequired,
  locations: PropTypes.arrayOf(LocationPropType).isRequired,
};
