import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  getOr, isEmpty,
} from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import {
  FETCH_EVENT,
  FETCH_SCHOOLS_BY_COACH,
  FETCH_ROLE_DETAIL,
  FETCH_EVENTS_USER_SCHOOLS,
  FETCH_EVENTS_USERS_COUNT_BY_COACH,
}
  from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { PermissionButton, Input, ButtonLink } from '../../Components/Form';
import useRolePermissionChecker from '../../Hooks/useRolePermissionChecker';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { COACH_ANOTHER_SCHOOL } from '../../GraphQL/Mutations';
import Permissions from '../../Constants/PermissionConstrants';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';

const CoachAnotherTeam = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const extractError = useGraphQLErrorExtractor();

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const [fetchSchoolsByCoach,
    { data: schoolsByCoach }] = useLazyQuery(FETCH_SCHOOLS_BY_COACH);
  const schoolUsers = getOr([], 'fetchSchoolsByCoach', schoolsByCoach);
  useHeaderComponentsMutation({
    title: 'COACH ANOTHER TEAM',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  const [fetchRoleDetail, { data: dataRoleDetail }] = useLazyQuery(
    FETCH_ROLE_DETAIL,
  );

  const role = getOr({}, 'fetchRoleDetail', dataRoleDetail);

  useEffect(() => {
    if (currentUser) {
      fetchRoleDetail({
        variables: {
          id: parseInt(currentUser.fetchRole.id, 10),
        },
      });
    }
  }, [currentUser, fetchRoleDetail]);

  const checkPermission = useRolePermissionChecker(role);

  const { params, onChange } = useFormParams({
    schoolId: '',
  });

  useEffect(() => {
    if (currentUser && eventId) {
      fetchSchoolsByCoach({
        variables: {
          eventId: parseInt(eventId, 10),
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchSchoolsByCoach, eventId]);

  const [coachAnotherSchool, { loading: Loading }] = useMutation(COACH_ANOTHER_SCHOOL, {
    refetchQueries: [{
      query: FETCH_EVENTS_USER_SCHOOLS,
      variables: {
        eventId: parseInt(eventId, 10),
        userId: parseInt(currentUser.id, 10),
        schoolId: null,
      },
    },
    {
      query: FETCH_EVENTS_USERS_COUNT_BY_COACH,
      variables: {
        eventId: parseInt(eventId, 10),
      },
    },
    {
      query: FETCH_SCHOOLS_BY_COACH,
      variables: {
        eventId: parseInt(eventId, 10),
        userId: parseInt(currentUser.id, 10),
      },
    }],
    onCompleted: () => {
      history.push(`/event-manage/${eventId}`);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    coachAnotherSchool({
      variables: {
        eventId: parseInt(eventId, 10),
        schoolId: parseInt(params.schoolId, 10),
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {!isEmpty(schoolUsers)
            ? (
              <Form onSubmit={onSubmit}>
                <Input type="select" params={params} onChange={onChange} name="schoolId" id="schoolId" required title="Schools">
                  <option value="" disabled>select schools</option>
                  { schoolUsers.map((obj) => (
                    <option key={obj.id} value={obj.fetchSchool.id}>{obj.fetchSchool.name}</option>
                  ))}
                </Input>
                <PermissionButton
                  loading={Loading}
                  backgroundcolor="#F4AB37"
                  fontColor="#FFF"
                  border="none"
                  htmltype="submit"
                  checkPermission={(checkPermission(Permissions.CAN_JOIN_MULTIPLE_SCHOOL))}
                >
                SUBMIT
                </PermissionButton>
                <div className="mt-4" />
              </Form>
            )
            : (
              <>
                <p>There is no more school associted with you.</p>
                <ButtonLink
                  htmltype="submit"
                  backgroundcolor="#F4AB37"
                  fontColor="#FFF"
                  border="none"
                  addNewText="Add School"
                  addNewLink="/new-school-verification"
                  imageAttached={ADD_SMALL_ICON}
                  checkPermission={
                  (checkPermission(Permissions.CAN_JOIN_MULTIPLE_SCHOOL))
                  || (checkPermission(Permissions.CAN_JOIN_SCHOOL_OPTIONAL))
                }
                />
              </>
            )}
        </div>
      </div>
    </>
  );
};
export default CoachAnotherTeam;
