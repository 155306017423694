import React, { useState } from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import Footer from '../../Components/Layouts/Footer';
import Header from '../../Components/Layouts/Header';
import PageTitle from '../../Components/PageTitle';
import Sidebar from '../../Components/Layouts/Sidebar';
import { HEADER_COMPONENTS } from '../../GraphQL/Queries';


const Wrapper = styled.div`
  border-width: 0;
  overflow: visible;
  overflow-x: hidden;
  flex: 1 0 auto;
`;

const AuthenticatedLayout = ({ children }) => {
  const { data } = useQuery(HEADER_COMPONENTS);
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const changeSideBarVisibility = () => {
    setSideBarVisible(!sideBarVisible);
  }

  return (
    <>
      <main className="d-flex align-items-stretch flex-grow-1">
        <Sidebar visible={sideBarVisible} changeSideBarVisibility={changeSideBarVisibility} />
        <div className="flex-grow-1" style={{width:"100%"}}>
          <Header toggleSidebar={changeSideBarVisibility} />
          <PageTitle data={data} />
          <Wrapper>
            <div className="container visible-xs" data-role="content">
              <div className="row">
                <div className="col-12 mx-auto px-4">
                  {children}
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </main>
      <Footer />
    </>
  );
};

AuthenticatedLayout.propTypes = {
  children: PropType.element.isRequired,
};

export default AuthenticatedLayout;
