import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { isAfter, subMinutes } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';

import { SchedulePropType } from '../EventSchedules/propTypes';
import { scheduleEventsComparator } from '../EventSchedules/utils';
import { ScheduleCard } from './ScheduleCard';

const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DISPLAY_DATE_FORMAT = 'MMM d yyyy';

const dayFromScheduleEvent = (scheduleEvent) => {
  const zonedTime = utcToZonedTime(scheduleEvent.startTime, USER_LONG_TZ);
  return format(zonedTime, DISPLAY_DATE_FORMAT);
};

// takes a sorted list of events
// returns list of objects:
// { day: 'DATE STRING', events: [ ... ]}
const groupScheduleEventsByDay = (scheduleEvents) => {
  if (scheduleEvents.length === 0) {
    return [];
  }

  const res = [];
  let currentDay = null;
  let currentSet = [];
  scheduleEvents.forEach((se) => {
    const eventDay = dayFromScheduleEvent(se);
    if (eventDay !== currentDay) {
      if (currentDay !== null) {
        res.push({
          day: currentDay,
          events: currentSet,
        });
        currentSet = [];
      }
      currentDay = eventDay;
    }
    currentSet.push(se);
  });
  res.push({
    day: currentDay,
    events: currentSet,
  });
  return res;
};

const useRerender = (interval) => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);

  useEffect(() => {
    const i = setInterval(() => setCount((c) => c + 1), interval);
    return () => {
      clearInterval(i);
    };
  }, [interval]);
};

export const PublicScheduleList = ({
  scheduleEvents,
  shouldScroll,
}) => {
  useRerender(30000);

  const sortedScheduleEvents = scheduleEvents
    // omit events that have already ended
    .filter((ev) => {
      // 1 min buffer for small UX niceness
      const now = subMinutes(new Date(), 1);
      const end = new Date(ev.endTime);
      return isAfter(end, now);
    })
    .sort(scheduleEventsComparator);

  const groupedEvents = groupScheduleEventsByDay(sortedScheduleEvents);

  return (
    <VStack spacing={4} paddingBottom={4} height={shouldScroll ? '100%' : '500px'}>
      {groupedEvents.map((group) => (
        <React.Fragment key={group.day}>
          <Heading alignSelf="flex-start" size="sm">{group.day}</Heading>
          {group.events.map((scheduleEvent) => (
            <ScheduleCard
              key={scheduleEvent.id}
              schedule={scheduleEvent}
            />
          ))}
        </React.Fragment>
      ))}
      {groupedEvents.length === 0 && (
        <Flex flexDirection="column" height="100%" justifyContent="center">
          <Text textAlign="center" paddingBottom={16}>
            No upcoming events scheduled!
          </Text>
        </Flex>
      )}
    </VStack>
  );
};
PublicScheduleList.propTypes = {
  scheduleEvents: PropTypes.arrayOf(SchedulePropType).isRequired,
  shouldScroll: PropTypes.bool.isRequired,
};
