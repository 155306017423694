import React, {
  useRef, useEffect, useState, useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  get, getOr, first, isEmpty, last,
} from 'lodash/fp';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import styled from 'styled-components';
import {
  FETCH_EVENT_DETAIL,
  FETCH_REGISTRATION_OPTION_DETAIL,
  FETCH_ROLE_DETAIL,
  FETCH_ORDER_DETAIL,
  FETCH_EVENTS_USERS_BY_USERS,
  FETCH_TEAM_MEMBERS,
} from '../../GraphQL/Queries';
import { UPDATE_JOIN_EVENT, CANCEL_REGISTRATION_OPTION } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useUpdateEventsUsersByUsersCache from '../../Hooks/useUpdateEventsUsersByUsersCache';
import useUpdateEventsByUserCache from '../../Hooks/useUpdateEventsByUserCache';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';
import Form, {
  Input, Button,
} from '../../Components/Form';
import {
  Signature,
  EmptySignature,
  CustomRadioButton,
  LinkButton,
}
  from '../../Components/Auth/Layout';
import CancelRegistrationOption from './CancelRegistrationOption';
import ReassignCaptainBeforeCancelRegistration from './ReassignCaptainBeforeCancelRegistration';
import Permissions from '../../Constants/PermissionConstrants';
import useFilterArray from '../../Hooks/useFilterArray';
import topScroll from '../../Hooks/useTopScroll';
import GenericAlert from '../../Components/GenericAlert';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import JoinEventSlider from './JoinEventSlider';

const SignatureHeading = styled.div`
  p{
    font-size: 16px;
    font-weight: 600;
    color: '#292929';
  }
`;
const SignatureSubHeading = styled.div`
  p{
    color: '#A5A5A5';
  }
`;

const JoinEventTermsAndConditions = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const canvasRef = useRef();
  const { orderId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [guardianAvailable, setGuardianAvailable] = useState('present');
  const [checkStatus, setCheckStatus] = useState('checked');
  const checkPermission = useUserPermissionChecker(currentUser);
  const [modalRegistration, setModalRegistration] = useState(false);
  const [modalReassignCaptain, setModalReassignCaptain] = useState(false);
  const filterArray = useFilterArray();
  const history = useHistory();

  const { params, onChange, setParams } = useFormParams({
    parentGuardianEmail: '',
  });


  const [fetchRoleDetail, { data: dataRoleDetail }] = useLazyQuery(
    FETCH_ROLE_DETAIL,
  );

  const role = getOr({}, 'fetchRoleDetail', dataRoleDetail);

  const [fetchRegistrationOptionDetail, { data: dataFetchRegistrationOptionDetail }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const registrationOption = getOr({}, 'fetchRegistrationOptionDetail', dataFetchRegistrationOptionDetail);

  const [fetchTeamMembers, { data: dataTeamMembers }] = useLazyQuery(
    FETCH_TEAM_MEMBERS,
  );

  const teamMembers = getOr([], 'viewMyTeam', dataTeamMembers);

  useEffect(() => {
    if (!isEmpty(registrationOption)) {
      fetchRoleDetail({
        variables: {
          id: parseInt(registrationOption.roleId, 10),
        },
      });
    }
  }, [registrationOption, fetchRoleDetail]);

  const [fetchEventDetail, { data: dataFetchEventDetail }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );

  const event = getOr({}, 'fetchEventDetail', dataFetchEventDetail);

  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));

  useEffect(() => {
    if (!isEmpty(get('fetchSchools', currentUser)) && !isEmpty(event)) {
      fetchTeamMembers({
        variables: {
          eventId: parseInt(event.id, 10),
          schoolId: parseInt(last(currentUser.fetchSchools).id, 10),
        },
      });
    }
  }, [currentUser, fetchTeamMembers, event]);


  const [fetchEventsUsersByUsers, { data: dataEventsUser }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUser = first(getOr([], 'fetchEventsUsersByUser', dataEventsUser));

  const captainEventsUser = useMemo(() => first(filterArray((getOr([], 'fetchEventsUsersByUser', dataEventsUser)), 'fetchRole.title', 'Captain')), [dataEventsUser, filterArray]);

  const [fetchOrderDetail, { data: dataFetchOrderDetail }] = useLazyQuery(
    FETCH_ORDER_DETAIL,
  );

  const order = getOr({}, 'fetchOrderDetail', dataFetchOrderDetail);

  const lineItem = useMemo(() => first(filterArray(getOr([], 'fetchLineItems', order), 'lineItemAbleType', 'RegistrationOption')), [filterArray, order]);

  useEffect(() => {
    if (!isEmpty(order) && !isEmpty(lineItem)) {
      fetchEventDetail({
        variables: {
          id: parseInt(order.eventId, 10),
        },
      });
      fetchRegistrationOptionDetail({
        variables: {
          id: lineItem.lineItemAbleId,
        },
      });
      fetchEventsUsersByUsers({
        variables: {
          eventId: parseInt(order.eventId, 10),
          userId: parseInt(currentUser.id, 10),
          schoolId: null,
        },
      });
    }
  }, [order,
    fetchEventDetail,
    fetchRegistrationOptionDetail,
    lineItem,
    fetchEventsUsersByUsers,
    currentUser,
  ]);

  const updateCache = useUpdateEventsByUserCache(getOr(0, 'id', currentUser));

  const reassignCaptainBeforeCancelRegistrationOption = () => {
    history.push(`/reassign-captain/${event.id}`);
  };

  const toggleRegistration = () => setModalRegistration(!toggleRegistration);

  const toggleReassignCaptain = () => setModalReassignCaptain(!toggleReassignCaptain);

  const userDob = get('dateOfBirth', currentUser);

  const ageInYears = moment().diff(userDob, 'years');

  const updateCacheEventsUsersByUsers = useUpdateEventsUsersByUsersCache(getOr(0, 'id', event), getOr(0, 'id', currentUser));

  const checkOrderStatus = (orderObj) => {
    if (get('status', orderObj) !== 'complete' && isEmpty(get('transactionId', orderObj), orderObj)) return true;
    return null;
  };

  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);

  const [registrationOptionCancel] = useMutation(
    CANCEL_REGISTRATION_OPTION,
    {
      onCompleted: () => {
        setModalRegistration(false);
        updateCacheEventsUsersByUsers(null, 'empty');
        updateOrdersCache(null, 'empty');
        updateCache(event, 'remove');
        history.push('/');
      },
    },
  );

  const cancelRegistrationOption = () => {
    registrationOptionCancel({
      variables: {
        eventsUserId: parseInt(get('id', get('fetchEventsUser', order)), 10),
      },
    });
  };

  const [updateJoinEvent, { loading }] = useMutation(UPDATE_JOIN_EVENT, {
    onCompleted: (data) => {
      updateCacheEventsUsersByUsers(data.updateJoinEvent.eventsUsers, 'update');
      if (checkOrderStatus(order)) {
        history.push(`/order/${orderId}/confirm-personal-details`);
      } else {
        history.push(`/event-manage/${event.id}`);
      }
    },
  });

  const isSignIn = () => {
    if (guardianAvailable === 'absent' && params.parentGuardianEmail) {
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (ageInYears < 18 && guardianAvailable === 'present' && canvasRef.current.getSignaturePad().isEmpty()) {
      setErrorMessage('Please sign signature before continue!');
      topScroll();
    } else if (ageInYears < 18 && guardianAvailable === 'absent' && !params.parentGuardianEmail) {
      setErrorMessage('Please enter email address before continue!');
      topScroll();
    } else {
      updateJoinEvent({
        variables: {
          parentGuardianSignature: canvasRef.current.toDataURL('image/png'),
          id: parseInt(eventsUser.id, 10),
          status: 1,
          signIn: isSignIn(),
          ...params,
        },
      });
    }
  };

  const checkGuardianPresence = (e) => {
    setGuardianAvailable(e.target.value);
    if (e.target.value === 'absent') {
      setCheckStatus('');
    } else {
      setCheckStatus('checked');
    }
  };

  const checkMembersLength = (members) => {
    if (!isEmpty(members) && members.length > 1) return 'true';
    return null;
  };

  useHeaderComponentsMutation({
    title: 'TERMS AND CONDITIONS',
    yes: '',
    no: '',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  return (
    <>
      {errorMessage
        ? <GenericAlert>{errorMessage}</GenericAlert>
        : null}
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={registrationOption}
          />
          {get('signIn', eventsUser) === false
            ? (
              <>
                <p>{get('termsAndConditions', role)}</p>
                <p>{event.termsAndConditions}</p>
                <Form onSubmit={onSubmit}>
                  {ageInYears < 18
                    ? (
                      <>
                        <p>We see you are under 18 years old. Is your parent/guardian present?</p>
                        <CustomRadioButton>
                          <label className="container" htmlFor="present">
                            <input type="radio" value="present" name="radio" id="present" checked={checkStatus} onChange={checkGuardianPresence} />
                            {' '}
                          Yes, my parent/guardian is present.
                            <span className="checkmark" />
                          </label>
                        </CustomRadioButton>
                        <CustomRadioButton>
                          <label className="container" htmlFor="absent">
                            <input type="radio" value="absent" name="radio" id="absent" onChange={checkGuardianPresence} />
                            {' '}
                          No, my parent/guardian is not present.
                            <span className="checkmark" />
                          </label>
                        </CustomRadioButton>
                        {guardianAvailable === 'present'
                          ? (
                            <>
                              <SignatureHeading>
                                <p>Parent/Guardian Signature</p>
                              </SignatureHeading>
                              <SignatureSubHeading>
                                <p>
                                  Please have your parent/guardian sign below
                                  to accept the terms and conditions for your participation.
                                </p>
                              </SignatureSubHeading>
                              <Signature>
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{ width: 1090, height: 200, className: 'sigCanvas' }}
                                  ref={canvasRef}
                                />
                              </Signature>
                            </>
                          )
                          : (
                            <>
                              <EmptySignature>
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{ className: 'sigCanvas' }}
                                  ref={canvasRef}
                                />
                              </EmptySignature>
                              <Input
                                title="Parent/Guardian Email Address"
                                params={params}
                                withFormGroup
                                id="parentGuardianEmail"
                                name="parentGuardianEmail"
                                placeholder="Parent/Guardian Email Address"
                                onChange={onChange}
                                setParams={setParams}
                                currentUser={currentUser}
                                type="email"
                              />
                              <SignatureSubHeading>
                                <p>
                                  Please provide a valid email address for your parent/guardian.
                                  We will email them a link to accept the terms and
                                  conditions for your participation.
                                </p>
                              </SignatureSubHeading>
                            </>
                          )}
                      </>
                    )
                    : (
                      <EmptySignature>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{ className: 'sigCanvas' }}
                          ref={canvasRef}
                        />
                      </EmptySignature>
                    )}
                  <Button
                    backgroundcolor="#F4AB37"
                    fontColor="#FFF"
                    border="2px solid #FFF"
                    marginbottom="-2rem"
                    addNewText="AGREE AND SUBMIT"
                    permissionName="View Event"
                    currentUser={currentUser}
                    htmltype="submit"
                    loading={loading}
                  >
                    AGREE AND SUBMIT
                  </Button>
                </Form>
                <div className="pt-3" />
                {eventsUser && !get('teamFinalize', last(eventsUser)) && !isEmpty(order) && get('status', order) !== 'complete' && isEmpty(get('transactionId', order), order)
                  ? (
                    <>
                      {isEmpty(captainEventsUser)
                        ? (
                          <LinkButton
                            onClick={() => setModalRegistration(true)}
                            checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                          >
                              DISAGREE
                          </LinkButton>
                        )
                        : (
                          <>
                            {!isEmpty(captainEventsUser) && checkMembersLength(teamMembers)
                              ? (
                                <LinkButton
                                  onClick={() => setModalReassignCaptain(true)}
                                  checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                                >
                                    DISAGREE
                                </LinkButton>
                              )
                              : (
                                <LinkButton
                                  onClick={() => setModalRegistration(true)}
                                  checkPermission={(checkPermission(Permissions.CAN_JOIN_EVENT))}
                                >
                                    DISAGREE
                                </LinkButton>
                              )}
                          </>
                        )}
                    </>
                  )
                  : null}
                <div className="pt-3" />
                <CancelRegistrationOption
                  modal={modalRegistration}
                  toggle={toggleRegistration}
                  onClick={() => cancelRegistrationOption()}
                  OnSelect={() => setModalRegistration(false)}
                />
                <ReassignCaptainBeforeCancelRegistration
                  modal={modalReassignCaptain}
                  toggle={toggleReassignCaptain}
                  onClick={() => reassignCaptainBeforeCancelRegistrationOption()}
                  onSelect={() => setModalReassignCaptain(false)}
                />
              </>
            )
            : (
              <p className="text-center">
                <>
                  {ageInYears < 18
                    ? (
                      <>
                        Your guardian has already agreed to terms and conditions of this event
                      </>
                    )
                    : (
                      <>
                        You have already agreed to terms and conditions
                      </>
                    )}
                </>
              </p>
            )}
        </div>
      </div>
    </>
  );
};

export default JoinEventTermsAndConditions;
