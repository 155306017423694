import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  IconButton,
  Input,
  List,
  ListItem,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { FaRegTimesCircle } from "react-icons/fa";

import { Provider } from '../../Chakra';

import { EVENT_TITLE } from '../../Constants/AppConstants';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';

const ADD_VOLUNTEER_MUTATION = gql`
  mutation inviteUserToEvent($email: String!, $roleId: Int!, $eventId: Int!) {
    inviteUserToEvent(input: {
      email: $email,
      roleId: $roleId,
      eventId: $eventId,
    }) {
      result
    }
  }
`;

const REMOVE_VOLUNTEER_MUTATION = gql`
  mutation removeVolunteer($email: String!, $eventId: Int!) {
    removeVolunteer(input: {
      email: $email,
      eventId: $eventId,
    }) {
      result
    }
  }
`;

export const GET_VOLUNTEERS = gql`
  query fetchEventVolunteers($eventId: ID!) {
    eventVolunteers(eventId: $eventId) {
      id
      fetchUser { firstname lastname email }
    }
    eventInvitedVolunteers(eventId: $eventId) {
      id
      email
    }
  }
`;

const NewVolunteerForm = ({ eventId, alreadyAdded }) => {
  const [volunteerEmail, setVolunteerEmail] = useState('');
  const [inviteVolunteer, { loading }] = useMutation(ADD_VOLUNTEER_MUTATION, {
    onCompleted: () => {
      setVolunteerEmail('');
    },
    refetchQueries: [
      'fetchEventVolunteers',
    ],
  });
  const onSave = (e) => {
    e.preventDefault();
    inviteVolunteer({
      variables: {
        email: volunteerEmail,
        roleId: 9,
        eventId,
      },
    });
  };

  const isInvalidEmail = volunteerEmail.trim().length === 0
    || alreadyAdded.includes(volunteerEmail.trim())
    || !volunteerEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);

  return (
    <Box
      sx={{
        borderRadius: '8',
        borderColor: 'gray.200',
        borderWidth: '2px',
      }}
      width="100%"
      padding="16px"
    >
      <form onSubmit={onSave}>
        <VStack width="full">
          <VStack width="full">
            <FormControl isRequired>
              <FormLabel htmlFor="title">Email Address</FormLabel>
              <Input backgroundColor="white" id="title" value={volunteerEmail} onChange={(e) => setVolunteerEmail(e.target.value)} />
            </FormControl>

          </VStack>
          <Button
            type="submit"
            variant="solid"
            colorScheme="orange"
            isLoading={loading}
            disabled={isInvalidEmail}
          >
            Add
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

const VolunteerItem = ({ volunteerEmail, eventId }) => {
  const [removeVolunteer, { loading }] = useMutation(REMOVE_VOLUNTEER_MUTATION, {
    variables: {
      eventId,
      email: volunteerEmail,
    },
    refetchQueries: [
      'fetchEventVolunteers',
    ],
  });

  return (
    <ListItem>
      {loading ? <Spinner /> : <IconButton onClick={removeVolunteer} aria-label="Remove Volunteer" icon={<FaRegTimesCircle />} variant="ghost" colorScheme="red" />}
      {volunteerEmail}
    </ListItem>
  );
};

const VolunteersList = ({ eventId, volunteers, invitedVolunteers }) => (
  <Flex justifyContent="center" alignItems="start" width="100%" flexDirection="column">
    {volunteers.length > 0 && (<Heading alignSelf="start" size="md" marginBottom={4}>Added Volunteers:</Heading>)}
    <List paddingBlockEnd={6}>
      {volunteers.map((v) => (
        <VolunteerItem
          key={v.fetchUser.email}
          volunteerEmail={v.fetchUser.email}
          eventId={eventId}
        />
      ))}
    </List>
    {invitedVolunteers.length > 0 && (<Heading alignSelf="start" size="md" marginBottom={4}>Invited Volunteers:</Heading>)}
    <List>
      {invitedVolunteers.map((iv) => (
        <VolunteerItem key={iv.email} volunteerEmail={iv.email} eventId={eventId} />
      ))}
    </List>
  </Flex>
);

const EVENT_DETAIL = gql`
query fetchEventDetail($id: Int!) {
  fetchEventDetail(id: $id) {
    id
    title
  }
}`;

export default () => {
  const { id } = useParams();
  const eventId = parseInt(id, 10);

  const { data: eventData } = useQuery(EVENT_DETAIL, {
    variables: {
      id,
    },
  });

  useHeaderComponentsMutation({
    title: 'Volunteers',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: eventData?.fetchEventDetail?.title }],
  });

  const { data, loading } = useQuery(GET_VOLUNTEERS, {
    variables: {
      eventId,
    },
  });

  const alreadyAdded = [];
  if (data) {
    alreadyAdded.push(...data.eventVolunteers.map((v) => v.fetchUser.email));
    alreadyAdded.push(...data.eventInvitedVolunteers.map((v) => v.email));
  }

  return (
    <Provider>
      <Heading>
        Volunteers
      </Heading>
      <Flex padding={4} gap={8} justifyContent="center" alignItems="center" flexDirection="column" width="100%">
        {loading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <NewVolunteerForm eventId={eventId} alreadyAdded={alreadyAdded} />
            <VolunteersList
              eventId={eventId}
              volunteers={data.eventVolunteers}
              invitedVolunteers={data.eventInvitedVolunteers}
            />
          </>
        )}
      </Flex>
    </Provider>
  );
};
