import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import './polyfills';
import Routes from './Routes';
import Client from './GraphQL/Client';
import bold from './Fonts/nunito_bold.ttf';
import extrabold from './Fonts/nunito_extrabold.ttf';
import regular from './Fonts/nunito_regular.ttf';
import semibold from './Fonts/nunito_semibold.ttf';
import Root from './Layouts';

const GlobalStyles = createGlobalStyle`
   @font-face {
     font-family: 'nunito_regular';
     src: url(${regular}) format('truetype');
   }

   /* Bold */
   @font-face {
     font-family: 'nunito_bold';
     src: url(${bold}) format('truetype');
   }

   /* semiBold */
   @font-face {
     font-family: 'nunito_semibold';
     src: url(${semibold}) format('truetype');
   }


   @font-face {
     font-family: 'nunito_extrabold';
     src: url(${extrabold}) format('truetype');
   }

   body {
     font-family: 'nunito_regular' !important;
   }
`;

const App = () => (
  <ApolloProvider client={Client}>
    <BrowserRouter>
      <GlobalStyles />
      <Root>
        <Routes />
      </Root>
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
