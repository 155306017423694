import { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { HEADER_COMPONENTS } from '../GraphQL/Queries';

const useHeaderComponentsMutation = (data) => {
  const client = useApolloClient();
  useEffect(() => {
    const components = getOr([], 'components', data);
    client.writeQuery({
      query: HEADER_COMPONENTS,
      data: {
        title: getOr('', 'title', data),
        components: components.map((c) => ({ ...c, __typename: 'headerComponent' })),
        backLink: getOr('', 'backLink', data),
      },
    });
  }, [client, data]);
};

export default useHeaderComponentsMutation;
