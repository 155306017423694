import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_COALITIONS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateCoalitionsCache = (eventId) => {
  const client = useApolloClient();
  const updateCache = (coalition, action = 'add') => {
    try {
      const data = client.readQuery({
        query: FETCH_COALITIONS,
        variables: {
          eventId,
        },
      });
      const { fetchCoalitions } = data;
      let response = [];
      if (action === 'add') {
        response = fetchCoalitions.unshift(coalition);
      } else {
        response = remove((c) => c.id === coalition.id)(fetchCoalitions);
      }
      client.writeQuery({
        query: FETCH_COALITIONS,
        data: {
          fetchCoalitions: response,
        },
        variables: {
          eventId,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateCoalitionsCache;
