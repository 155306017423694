import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../Auth/Layout';

const RedirectButton = (props) => {
  const {
    addNewText,
    addNewLink,
    loading,
    htmltype,
    backgroundcolor,
    fontColor,
    border,
    marginbottom,
    activebackgroundcolor,
    borderradius,
    activefontcolor,
    rightHandText,
    textalign,
    target,
    onClick,
    breakLine,
  } = props;

  return (
    <>
      <div className="w-100">
        {target
          ? (
            <a href={addNewLink} style={{ textDecoration: 'none' }} target={target}>
              <div className="row align-items-center">
                <div className="col-12 mx-auto mt-4">
                  <PrimaryButton
                    htmltype={htmltype}
                    disabled={loading}
                    backgroundcolor={backgroundcolor}
                    fontcolor={fontColor}
                    border={border}
                    marginbottom={marginbottom}
                    activebackgroundcolor={activebackgroundcolor}
                    activefontcolor={activefontcolor}
                    borderradius={borderradius}
                  >
                    {addNewText}
                  </PrimaryButton>
                </div>
              </div>
            </a>
          )
          : (
            <>
              {addNewLink
                ? (
                  <Link to={addNewLink} style={{ textDecoration: 'none' }}>
                    <div className="row align-items-center">
                      <div className="col-12 mx-auto mt-4">
                        <PrimaryButton
                          htmltype={htmltype}
                          disabled={loading}
                          backgroundcolor={backgroundcolor}
                          fontcolor={fontColor}
                          border={border}
                          marginbottom={marginbottom}
                          activebackgroundcolor={activebackgroundcolor}
                          activefontcolor={activefontcolor}
                          textalign={textalign}
                          borderradius={borderradius}
                        >
                          {addNewText}
                        </PrimaryButton>
                      </div>
                    </div>
                  </Link>
                )
                : (
                  <div className="row align-items-center">
                    <div className="col-12 mx-auto mt-4">
                      <PrimaryButton
                        htmltype={htmltype}
                        disabled={loading}
                        backgroundcolor={backgroundcolor}
                        fontcolor={fontColor}
                        border={border}
                        marginbottom={marginbottom}
                        activebackgroundcolor={activebackgroundcolor}
                        activefontcolor={activefontcolor}
                        textalign={textalign}
                        onClick={onClick}
                        borderradius={borderradius}
                      >
                        {addNewText}
                        {rightHandText
                          ? (
                            <span style={{ right: '2rem', position: 'absolute' }}>
                                $
                              {rightHandText}
                            </span>
                          )
                          : null}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
            </>
          )}
        {breakLine
          ? <hr />
          : null}
      </div>

    </>
  );
};

RedirectButton.propTypes = {
  loading: PropTypes.bool,
  htmltype: PropTypes.string,
  addNewText: PropTypes.string.isRequired,
  addNewLink: PropTypes.string,
  backgroundcolor: PropTypes.string,
  fontColor: PropTypes.string,
  marginbottom: PropTypes.string,
  border: PropTypes.string,
  target: PropTypes.string,
  activebackgroundcolor: PropTypes.string,
  activefontcolor: PropTypes.string,
  rightHandText: PropTypes.string,
  borderradius: PropTypes.string,
  textalign: PropTypes.string,
  onClick: PropTypes.func,
  breakLine: PropTypes.bool,
};

RedirectButton.defaultProps = {
  loading: false,
  htmltype: 'button',
  marginbottom: '0px',
  border: '0px',
  fontColor: '#FFF',
  backgroundcolor: 'F4AB37',
  activebackgroundcolor: null,
  activefontcolor: null,
  borderradius: '10px',
  target: null,
  rightHandText: null,
  textalign: null,
  addNewLink: null,
  onClick: null,
  breakLine: true,
};

export default RedirectButton;
