import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import {
  FontBold, DividerLine,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL,
  USER_COUNT_AND_AMOUNT_AGAINST_COUNPON,
  APPLIED_USERS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const CouponsByOption = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { couponId } = useParams();

  const [fetchEventDetail, { data }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const { data: userCountAmount } = useQuery(
    USER_COUNT_AND_AMOUNT_AGAINST_COUNPON,
    {
      variables: {
        eventId: parseInt(id, 10),
        couponId: parseInt(couponId, 10),
      },
    },
  );
  const countAmount = getOr({}, 'userCountAndAmountAgainstCoupon', userCountAmount);

  const { data: appliedUser } = useQuery(
    APPLIED_USERS,
    {
      variables: {
        eventId: parseInt(id, 10),
        couponId: parseInt(couponId, 10),
      },
    },
  );
  const users = getOr([], 'appliedUsers', appliedUser);

  useHeaderComponentsMutation({
    title: get('code', countAmount),
    backLink: `/events/${id}/coupons`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  {get('couponUser.coupon_user_count', countAmount)}
                </p>
              </FontBold>
              <h5 className="text-center">
                Total Uses
              </h5>
            </div>
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  {get('couponUser.payment_amount', countAmount) / 100}
                  {' '}
                  {get('couponUser.default_currency', countAmount)}
                </p>
              </FontBold>
              <h5 className="text-center">
               Total Amount
              </h5>
            </div>
          </div>
          <div className="pt-3" />

          {!isEmpty(users)
            ? (
              <>
                <FontBold>
                  <p>
Recipients (
                    {users.length}
)
                  </p>
                </FontBold>
                {users.map((item) => (
                  <>
                    <div className="row pl-2 my-2">
                      <div className="col-md-11 col-9 font-weight-bold">
                        {get('firstname', item)}
                        {' '}
                        {get('lastname', item)}
                      </div>
                      <div className="col-md-1 col-3 font-weight-bold float-right">
                        {get('couponAmount', item) / 100}
                        {' '}
                        {get('defaultCurrency', item) }
                      </div>
                    </div>
                    <DividerLine className="my-3" />
                  </>
                ))}
              </>
            )
            : null}
          <div className="mt-2" />
        </div>
      </div>
    </>
  );
};
export default CouponsByOption;
