import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_REGISTRATION_OPTIONS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateRegistrationOptionsCache = (tenantId, eventId) => {
  const client = useApolloClient();
  const updateCache = (registrationOption) => {
    try {
      const data = client.readQuery({
        query: FETCH_REGISTRATION_OPTIONS,
        variables: {
          tenantId,
          eventId,
        },
      });
      client.writeQuery({
        query: FETCH_REGISTRATION_OPTIONS,
        data: {
          fetchRegistrationOptions: [
            ...data.fetchRegistrationOptions,
            { ...registrationOption },
          ],
        },
        variables: {
          tenantId,
          eventId,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateRegistrationOptionsCache;
