import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  getOr, get, last, isEmpty,
} from 'lodash/fp';
import {
  Hr,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT, UNPAID_USERS,
  PENDING_TERM_AND_CONDITION_COUNT,
  PENDING_REQUEST_COUNT,
  USER_INVITATION_COUNT,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import { REMOVE_EVENT_USER } from '../../GraphQL/Mutations';
import useTeamMembersCache from '../../Hooks/useTeamMembersCache';
import PopUpModal from '../../Components/PopUpModal';
import ReassignCaptainPopUp from './ReassignCaptainPopUp';
import useUnpaidUserCountCache from '../../Hooks/useUnpaidUserCountCache';

const UnpaidUsers = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId } = useParams();
  const updateCache = useTeamMembersCache(get('id', last(get('fetchSchools', currentUser))), eventId);
  const updateUnpaidUserCountCache = useUnpaidUserCountCache(get('id', last(get('fetchSchools', currentUser))), eventId);
  const extractError = useGraphQLErrorExtractor();
  const [removeUser, setRemoveUser] = useState(false);
  const [unpaidMemberId, setUnpaidMemberId] = useState('');
  const [unpaidMemberName, setUnpaidMemberName] = useState('');
  const [modalReassignCaptain, setModalReassignCaptain] = useState(false);

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const [unpaidJoinCompetitions, { data: dataunpaidJoinCompetitions }] = useLazyQuery(
    UNPAID_USERS,
  );

  const unpaidUsers = getOr([], 'unpaidJoinCompetitions', dataunpaidJoinCompetitions);

  const [fetchPendingRequest] = useLazyQuery(
    PENDING_REQUEST_COUNT,
  );

  const [userInvitationCount] = useLazyQuery(
    USER_INVITATION_COUNT,
  );

  const [fetchPendingTermAndCondition] = useLazyQuery(
    PENDING_TERM_AND_CONDITION_COUNT,
  );

  const toggleRemoveUser = () => setRemoveUser(!toggleRemoveUser);
  const toggleReassignCaptain = () => setModalReassignCaptain(!toggleReassignCaptain);

  useHeaderComponentsMutation({
    title: 'UNPAID USER',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && eventId) {
      unpaidJoinCompetitions({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, unpaidJoinCompetitions, eventId]);

  const [removeEventUser] = useMutation(
    REMOVE_EVENT_USER,
    {
      onCompleted: (data1) => {
        setRemoveUser(false);
        updateCache(data1.removeEventUser.user, 'remove');
        updateUnpaidUserCountCache(data1.removeEventUser.user, 'remove');
      },
    },
  );

  const removeUnpaidMember = () => {
    removeEventUser({
      variables: {
        eventUserId: parseInt(unpaidMemberId, 10),
      },
      refetchQueries: ['fetchPendingTermAndCondition', 'fetchPendingRequest', 'userInvitationCount'],
    });
  };

  const setUnpaidMember = (eventUserId) => {
    setUnpaidMemberId(eventUserId);
  };

  const setPopUpUserName = (eventUserName) => {
    setUnpaidMemberName(eventUserName);
  };

  useEffect(() => {
    if (currentUser && eventId) {
      userInvitationCount({
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, eventId, userInvitationCount]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchPendingRequest({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, eventId, fetchPendingRequest]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchPendingTermAndCondition({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, eventId, fetchPendingTermAndCondition]);

  const ReassignCaptainPopUpOption = () => {
    history.push(`/reassign-captain/${eventId}`);
  };

  const makePayment = () => {
    history.push(`/payment-status/${eventId}`);
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {unpaidUsers.map((eventUser) => (
            <>
              <div key={eventUser.id}>
                {!isEmpty(eventUser) && eventUser.fetchUser.id === currentUser.id
                  ? (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => setModalReassignCaptain(true)}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        redirectLink="#"
                      />
                      <ReassignCaptainPopUp
                        modal={modalReassignCaptain}
                        toggle={toggleReassignCaptain}
                        onClick={() => ReassignCaptainPopUpOption()}
                        onSelect={() => makePayment()}
                        bodyText="Do you want to Finalize Team?
                                    You are a captain of this team and hence in order
                                    to finalize your team, you can either reassign
                                    captain or make your payment before continuing."
                      />
                    </>
                  )
                  : (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => {
                          setRemoveUser(true);
                          setUnpaidMember(eventUser.id);
                          setPopUpUserName(eventUser.fetchUser.firstname);
                         }}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        redirectLink="#"
                      />
                      <PopUpModal
                        isOpen={removeUser}
                        toggle={toggleRemoveUser}
                        onAccept={() => removeUnpaidMember()}
                        onCancel={() => setRemoveUser(false)}
                        headerText="UNPAID USER"
                        bodyText={"Are you sure you want to remove " + unpaidMemberName + "?"}
                      />
                    </>
                  )}
                <div className="mt-4" />
                <Hr />
              </div>
            </>
          ))}
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default UnpaidUsers;
