import { getOr, first } from 'lodash/fp';

const useRolePermissionChecker = (role) => {
  const checkRolePermission = (permissionName) => {
    const permissions = getOr([], 'permissions', role);
    return first(permissions.filter((x) => x.title === permissionName));
  };

  return checkRolePermission;
};

export default useRolePermissionChecker;
