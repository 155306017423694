import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  get, first,
} from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import { FETCH_SCHOOLS, SEARCH_SCHOOLS, FETCH_USER_DETAIL } from '../../GraphQL/Queries';
import { CREATE_SCHOOL_USER } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import ImageUpload from '../ImageUpload/Index';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import {
  EventImageUpload, SelectedSchool, LinkButton,
} from '../../Components/Auth/Layout';
import useSortArrayByAlphabets from '../../Hooks/useSortArrayByAlphabets';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Loader from '../../Components/Loader';
import Permissions from '../../Constants/PermissionConstrants';
import GenericAlert from '../../Components/GenericAlert';
import useUpdateCoachSchoolCache from '../../Hooks/useUpdateCoachSchoolCache';

const NewSchoolVerification = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const updateSchoolsCache = useUpdateCoachSchoolCache(get('fetchEventsUsers', currentUser), get('id', currentUser));

  useHeaderComponentsMutation({
    title: 'SCHOOL VERIFICATION',
    backLink: '/',
  });
  const { params } = useFormParams({
    verificationFileUrl: '',
  });
  const history = useHistory();
  const sortedArray = useSortArrayByAlphabets();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState({ id: '', name: '' });

  const [fetchSchools] = useLazyQuery(
    FETCH_SCHOOLS, {
      onCompleted: (data) => {
        setFilteredSchools(sortedArray(data.fetchSchools, 'name'));
      },
    },
  );

  const { loading } = useQuery(
    FETCH_USER_DETAIL,
    {
      variables: { id: parseInt(currentUser.id, 10) },
    },
  );

  const [createSchoolUser, { loading: addSchoolLoading }] = useMutation(
    CREATE_SCHOOL_USER,
    {
      onCompleted: (data3) => {
        setTimeout((() => {
          updateSchoolsCache(first(data3.createSchoolUser.schoolsUsers).id);
          history.goBack();
        }).bind(), 500);
      },
    },
  );
  const [clickStatus, setClickStatus] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState('');
  const onImageUpload = (d) => {
    setAvatarPreview(d.avatar);
    params.verificationFile = d.signed_id;
  };

  useEffect(() => {
    if (currentUser) {
      fetchSchools({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchSchools]);

  const [schoolsSearch] = useLazyQuery(
    SEARCH_SCHOOLS,
    {
      onCompleted: (data) => {
        setFilteredSchools(sortedArray(data.searchSchools, 'name'));
      },
    },
  );

  const searchSchools = (e) => {
    setSearchStringLength(e.target.value.length);
    schoolsSearch({
      variables: {
        query: e.target.value,
        userId: parseInt(currentUser.id, 10),
      },
    });
  };

  const getSelectedSchool = (e) => {
    if (e.target.id !== '') {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedSchool({ id: parseInt(e.target.id, 10), name: e.target.innerText });
    }
  };

  const createSchool = () => {
    createSchoolUser({
      variables: {
        ...params,
        schoolId: parseInt(selectedSchool.id, 10),
      },
      refetchQueries: ['fetchUserDetail'],
    });
  };

  if (loading || addSchoolLoading) {
    return <Loader />;
  }

  return (
    <>
      {checkPermission(Permissions.CAN_JOIN_MULTIPLE_SCHOOL)
        ? (
          <>
            <div className="row">
              <div className="col-12 mx-auto px-4">
                <p className="pt-3" style={{ fontWeight: '600' }}>School</p>
                <SearchDialog
                  placeholder="Search"
                  onChange={(e) => searchSchools(e)}
                  data={filteredSchools}
                  stringlength={searchStringLength}
                  displayattribute="name"
                  imageattatched={SEARCH}
                  emptySearch={clickStatus}
                  onClick={(e) => getSelectedSchool(e)}
                  addSchoolSearch
                  checkPermission
                />
                <SelectedSchool>
                  <h5>{selectedSchool.name}</h5>
                </SelectedSchool>
                <p>
                  Please provide proof of your affiliation with your selected high school.
                  Students may provide School ID.
                  Coaches and guests may provide any government-issued picture ID.
                </p>
                <p className="pt-3" style={{ fontWeight: '600' }}>Upload School Verification</p>
                <EventImageUpload>
                  <ImageUpload
                    onImageUpload={onImageUpload}
                    image={avatarPreview}
                    type="image"
                    visibleText="+ Add File"
                    additionalText="Accepted file formats: <br/> .jpg, .png"
                  />
                </EventImageUpload>
                <div className="pt-3" />
              </div>
            </div>
            {avatarPreview
              ? <LinkButton onClick={() => createSchool()} backgroundcolor="#F4AB37" fontcolor="#FFF" border="none">CONTINUE</LinkButton>
              : null}
            <div className="pt-3" />
          </>
        )
        : (
          <GenericAlert>
You dont have required permission to access this page,
           Please contact system administrator.
          </GenericAlert>
        )}
    </>
  );
};

export default NewSchoolVerification;
