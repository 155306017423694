import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import { HELP } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import GenericAlert from '../../Components/GenericAlert';
import Form, { Input } from '../../Components/Form';
import InputButton from '../../Components/Form/Button';

const Help = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(null);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [help, { loading }] = useMutation(
    HELP,
    {
      onCompleted: (data) => {
        if (data.help.result === 'Issue has been reported') {
          setModal(true);
        } else {
          setErrorMessage(data.help.result);
        }
      },
    },
  );
  useHeaderComponentsMutation({ title: 'HELP' });
  const toggle = () => setModal(!modal);

  const { params, onChange, setParams } = useFormParams({
    email: '',
    subject: '',
    message: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();

    help({
      variables: {
        ...params,
      },
    });
  };

  useEffect(() => {
    if (currentUser) {
      setParams({ email: currentUser.email });
    }
  }, [currentUser, setParams]);

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4 pt-3">
          {errorMessage
            ? <GenericAlert>{errorMessage}</GenericAlert>
            : null}
          <Form onSubmit={onSubmit}>
            <p className="mt-4">Fill out the form below and we&apos;ll get back to you as soon as we can!</p>
            <Input
              title="Email"
              label="Email"
              id="email"
              name="email"
              type="email"
              withFormGroup
              onChange={onChange}
              params={params}
              readOnly={Boolean(currentUser)}
            />
            <Input
              title="Subject"
              label="Subject"
              name="subject"
              id="subject"
              type="text"
              onChange={onChange}
              withFormGroup
              params={params}
            />
            <Input
              title="Message"
              name="message"
              id="message"
              type="textarea"
              onChange={onChange}
              withFormGroup
              params={params}
            />
            <InputButton loading={loading} htmltype="submit" backgroundcolor="#F4AB37">SEND</InputButton>
          </Form>
          <Modal isOpen={modal} toggle={toggle} className="forgot-passeord--bg">
            <ModalHeader toggle={toggle}>Success!</ModalHeader>
            <ModalBody>
              <h5>
                Issue has been Created
                <br />
                &lt;
                An email has been sent to
                {' '}
                {params.email}
                &gt;
              </h5>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => history.goBack()}>Done</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <hr />
    </>
  );
};
export default Help;
