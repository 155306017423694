import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_CATEGORIES } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateCategoriesCache = (tenantId) => {
  const client = useApolloClient();
  const updateCache = (category) => {
    try {
      const data = client.readQuery({
        query: FETCH_CATEGORIES,
        variables: {
          tenantId,
        },
      });
      client.writeQuery({
        query: FETCH_CATEGORIES,
        data: {
          fetchCategories: [
            ...data.fetchCategories,
            { ...category },
          ],
        },
        variables: {
          tenantId,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateCategoriesCache;
