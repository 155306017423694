import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import useRegistrationOptionPermissionChecker from '../../Hooks/useRegistrationOptionPermissionChecker';
import { SliderLikeStrip } from '../../Components/Auth/Layout';
import Permissions from '../../Constants/PermissionConstrants';

const SliderItem = ({ path, visible = true }) => {
  const location = useLocation();

  if (!visible) {
    return null;
  }

  if (!path) {
    return <li />;
  }
  if (location.pathname === path) {
    return <li className="active" />;
  }

  return (
    <li>
      <Link to={path} />
    </li>
  );
};
SliderItem.propTypes = {
  path: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};
SliderItem.defaultProps = {
  visible: true,
};

const JoinEventSlider = ({ order, event, selectedRegistrationOption }) => {
  const checkPermissionOfRegistrationOption = useRegistrationOptionPermissionChecker(
    selectedRegistrationOption,
  );

  return (
    <SliderLikeStrip>
      <ul>
        <SliderItem path={`/join-event/${event.id}`} />
        <SliderItem path={order && `/order/${order.id}/terms-and-conditions`} />
        <SliderItem path={order && `/order/${order.id}/confirm-personal-details`} />
        <SliderItem path={order && `/order/${order.id}/competitions`} visible={checkPermissionOfRegistrationOption(Permissions.CAN_JOIN_COMPETITION)} />
        <SliderItem path={order && `/order/${order.id}/merch`} visible={event.merchandise && event.merchandise.length > 0} />
        <SliderItem path={order && `/order/${order.id}/cart`} />
        <SliderItem path={order && `/order/${order.id}/billing`} />
      </ul>
    </SliderLikeStrip>
  );
};

JoinEventSlider.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    merchandise: PropTypes.arrayOf({}),
  }).isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectedRegistrationOption: PropTypes.shape({
    fetchRole: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default JoinEventSlider;
