import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import Form, { Input, PermissionButton, ButtonLink } from '../../Components/Form';
import { DELETE_ADMIN_FROM_REGION } from '../../GraphQL/Mutations';
import { FETCH_VALID_TIMEZONES } from '../../GraphQL/Queries';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import ADD_ICON from '../../Images/icons/add-small.png';
import userDefaultImage from '../../Images/team-dum.png';
import DeleteItem from '../../Components/DeleteItem/Index';
import REMOVE_ICON from '../../Images/icons/remove.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const RegionForm = ({
  params, onSubmit, onChange, loading, selectedAdmins, onRemove, formType, currentUser, region,
}) => {
  const checkPermission = useUserPermissionChecker(currentUser);
  const [deleteRegionsUser] = useMutation(
    DELETE_ADMIN_FROM_REGION,
    {
      onCompleted: (data) => {
        onRemove(data.deleteRegionsUser.region.regionsUsers);
      },
    },
  );

  const deleteUserRegion = (id) => {
    deleteRegionsUser({
      variables: {
        id: parseInt(id, 10),
      },
    });
  };

  const [fetchValidTimezones, { data: timezoneData }] = useLazyQuery(FETCH_VALID_TIMEZONES);
  const timezones = getOr([], 'fetchValidTimezones', timezoneData);
  useEffect(() => {
    if (currentUser) {
      fetchValidTimezones();
    }
  }, [currentUser, fetchValidTimezones]);

  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="Title"
        name="name"
        id="title"
        placeholder="Title"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <Input name="tierValue" title="Level" type="select" params={params} id="tierValue" required onChange={onChange}>
        <option value="" disabled>Select Level</option>
        <option value="0">international</option>
        <option value="1">national</option>
        <option value="2">regional</option>
        <option value="3">state</option>
        <option value="4">country</option>
        <option value="5">school</option>
      </Input>
      <hr />
      <Input
        title="Payment Account Info"
        label="Stripe Account"
        name="stripeAccount"
        id="stripeAccount"
        placeholder="Stripe Account"
        onChange={onChange}
        params={params}
        withFormGroup
      />
      <Input params={params} title="Default Currency" type="select" id="currency" name="currency" required onChange={onChange}>
        <option value="USD">USD</option>
        <option value="CAD">CAD</option>
      </Input>
      <Input params={params} title="Default Timezone" type="select" id="timezone" name="timezone" required onChange={onChange}>
        <option value="" disabled>Time Zone</option>
        {timezones && timezones.map((tz) => (
          <option key={tz} value={tz}>{tz.replace('_', ' ')}</option>
        ))}
      </Input>
      <FontBold>
        <p className="mt-4">Links</p>
      </FontBold>
      <Input
        params={params}
        title="Website"
        type="text"
        id="websiteUrl"
        name="websiteUrl"
        placeholder="e.g: https://www.website.com"
        onChange={onChange}
      />
      <Input
        params={params}
        title="Facebook"
        type="text"
        id="facebookUrl"
        name="facebookUrl"
        placeholder="e.g: https://www.facebook.com/ProfileName"
        pattern="https://facebook\.com\/(.+)|https://www\.facebook\.com\/(.+)|www\.facebook\.com\/(.+)|facebook\.com\/(.+)"
        onChange={onChange}
      />
      <Input
        params={params}
        title="Twitter"
        type="text"
        id="twitterUrl"
        name="twitterUrl"
        placeholder="e.g: https://twitter.com/ProfileName"
        pattern="https://twitter\.com\/(.+)|https://www\.twitter\.com\/(.+)|www\.twitter\.com\/(.+)|twitter\.com\/(.+)"
        onChange={onChange}
      />
      <Input
        params={params}
        title="Instagram"
        type="text"
        id="instagramUrl"
        name="instagramUrl"
        placeholder="e.g: https://www.instagram.com/ProfileName"
        pattern="https://instagram\.com\/(.+)|https://www\.instagram\.com\/(.+)|www\.instagram\.com\/(.+)|instagram\.com\/(.+)"
        onChange={onChange}
      />
      <Input params={params} title="Email" type="email" id="email" name="email" placeholder="e.g: test@test.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={onChange} />
      {formType === 'edit'
        ? (
          <>
            {selectedAdmins.map((admin) => (
              <DeleteItem
                key={admin.id}
                title={`${admin.fetchUser.firstname} ${admin.fetchUser.lastname}`}
                onClick={() => deleteUserRegion(admin.id)}
                userAdmin
                imageAttached={userDefaultImage}
                removeicon={REMOVE_ICON}
                profileImage={admin.fetchUser.profileImageUrl}
                roleTitle={admin.fetchRole.title}
              />
            ))}
            <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Admin" addNewLink={`/region/${region.id}/admins/`} imageAttached={ADD_ICON} checkPermission={(checkPermission('Add Region'))} />
          </>
        )
        : null}
      {formType === 'new'
        ? <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Add Region'))}>SUBMIT</PermissionButton>
        : <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Update Region'))}>SUBMIT</PermissionButton>}
    </Form>
  );
};

RegionForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectedAdmins: PropTypes.instanceOf(Array),
  onRemove: PropTypes.func,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  region: PropTypes.instanceOf(Object),
};

RegionForm.defaultProps = {
  loading: false,
  selectedAdmins: [],
  onRemove: null,
  region: null,
};

export default RegionForm;
