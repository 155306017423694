import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_SCHOOLS_BY_COACH } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateCoachSchoolCache = (eventUsers, userId) => {
  const client = useApolloClient();
  const updateSchoolsCache = (schoolUserId, action = 'add') => {
    for (let i = 0; i < eventUsers.length; i += 1) {
      try {
        const data = client.readQuery({
          query: FETCH_SCHOOLS_BY_COACH,
          variables: {
            eventId: parseInt(eventUsers[i].fetchEvent.id, 10),
            userId: parseInt(userId, 10),
          },
        });
        const { fetchSchoolsByCoach } = data;
        let response = [];
        if (action === 'add') {
          response = fetchSchoolsByCoach.unshift(schoolUserId);
        } else {
          response = remove((c) => c.id === schoolUserId)(fetchSchoolsByCoach);
        }
        client.writeQuery({
          query: FETCH_SCHOOLS_BY_COACH,
          data: {
            fetchSchoolsByCoach: response,
          },
          variables: {
            eventId: parseInt(eventUsers[i].fetchEvent.id, 10),
            userId: parseInt(userId, 10),
          },
        });
      } catch (e_) {
        logger('Update not needed!');
      }
    }
  };

  return updateSchoolsCache;
};

export default useUpdateCoachSchoolCache;
