import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  VStack,
} from '@chakra-ui/react';

import { Provider } from '../../../Chakra';
import Loader from '../../../Components/Loader';
import { EVENT_TITLE } from '../../../Constants/AppConstants';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';

import { AnnouncementCard } from '../EventAnnouncements/AnnouncementCard';
import { PublicScheduleList } from './PublicScheduleList';

const PUBLIC_EVENT_QUERY = gql`
query fetchEventDetail($id: Int!) {
  fetchEventDetail(id: $id) {
    id
    title
    announcements {
      id
      title
      body
      link
      targetType
      targetId
      isImportant
      createdAt
      fetchSchools {
        id
        name
      }
      fetchCompetitions {
        id
        title
      }
      fetchRoles {
        id
        title
      }
    }
    schedule {
      scheduleEvents {
        id
        title
        description
        type
        startTime
        endTime
        room
        fetchAssociatedEventCompetition {
          id
          title
        }
        fetchLocation {
          id
          name
        }
      }
    }
    fetchEventCompetitions {
      id
      title
    }
    locations {
      id
      name
    }
  }
}
`;

const LiveEventPage = () => {
  const handle = useFullScreenHandle();
  const { id } = useParams();
  const eventId = parseInt(id, 10);

  const { data, loading } = useQuery(PUBLIC_EVENT_QUERY, {
    variables: { id: eventId },
    fetchPolicy: 'network-only',
    pollInterval: 15000,
  });
  const event = data?.fetchEventDetail;

  useHeaderComponentsMutation({
    title: 'Live Dashboard',
    components: [{ key: EVENT_TITLE, value: event?.title }],
    backLink: `/event-manage/${eventId}`,
  });

  if (loading) {
    return <Loader />;
  }

  const announcements = [...event?.announcements]
    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    .filter((a) => a.targetType === 'all' || a.targetType === 'competition')
    ?? [];

  return (
    <>
      <Provider>
        <Box margin={2}>
          <Button onClick={handle.enter} size="lg">
            {/* // eslint-disable-next-line jsx-a11y/accessible-emoji */}
            Click here to fullscreen me 🎉
          </Button>
        </Box>
        <FullScreen handle={handle}>
          <Grid
            templateColumns="3fr 2fr"
            width="100%"
            height="100%"
            background="white"
            padding={handle.active ? 16 : 4}
            paddingBottom={4}
            columnGap={16}
            rowGap={4}
          >
            <GridItem gridArea="1 / 1 / 2 / 2">
              <Heading size="md">Latest Announcements</Heading>
            </GridItem>
            <GridItem gridArea="2 / 1 / 3 / 2" overflow="scroll">
              <VStack spacing={4} paddingBottom={4}>
                {announcements.map((announcement, idx) => (
                  <AnnouncementCard
                    announcement={announcement}
                    key={announcement.id}
                    highlight={idx === 0}
                  />
                ))}
              </VStack>
            </GridItem>
            <GridItem gridArea="1 / 2 / 2 / 3">
              <Heading size="md">Upcoming Schedule</Heading>
            </GridItem>
            <GridItem gridArea="2 / 2 / 3 / 3" overflow="scroll">
              <PublicScheduleList
                scheduleEvents={event.schedule.scheduleEvents}
                shouldScroll={handle.active}
              />
            </GridItem>
          </Grid>
        </FullScreen>
      </Provider>
    </>
  );
};

export default LiveEventPage;
