import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonToggle,
  ButtonToolbar,
} from 'reactstrap';
import { get, isEmpty } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT, EVENT_COMPETITION_WITH_SCORES,
} from '../../GraphQL/Queries';
import { UNFINALIZED_ROUND, FINALIZED_ROUND, CREATE_ROUND } from '../../GraphQL/Mutations';
import {
  ScoreButton, SecondaryScoreButton, CustomCheckBox, TitleC, DividerLine, FontBold,
} from '../../Components/Auth/Layout';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import { JudgableCodeCell } from './JudgeableCodeCell';

export const isRoundFinalized = (round) => round?.status === 'finalized';

const groupJudgablesByScore = (judgables) => {
  const scoreToJudgable = {};
  judgables.forEach((judgable) => {
    const { totalScore } = judgable;
    if (scoreToJudgable[totalScore]) {
      scoreToJudgable[totalScore].push(judgable);
    } else {
      scoreToJudgable[totalScore] = [judgable];
    }
  });
  return scoreToJudgable;
};

export const mapJudgablesByRank = (judgables) => {
  const judgablesByScore = groupJudgablesByScore(judgables);
  const sortedScores = Object.keys(judgablesByScore).sort((s1, s2) => s2 - s1);
  // eslint-disable-next-line arrow-body-style
  return sortedScores.flatMap((score, index) => {
    // eslint-disable-next-line arrow-body-style
    return judgablesByScore[score].map((judgable, _, group) => {
      return {
        rank: index + 1,
        isTied: group.length > 1 && judgable.totalScore > 0,
        judgable,
      };
    });
  });
};

// eslint-disable-next-line arrow-body-style
export const hasTiesInTop5 = (judgablesWithRank) => {
  return !!judgablesWithRank.slice(0, 5)
    .find((judgableWithRank) => judgableWithRank.isTied);
};

const RoundButton = ({ round, selectedRound, onClick }) => (
  <ButtonToggle type="radio" key={round.id} color="secondary" outline={selectedRound.id !== round.id} onClick={() => onClick(round)}>{round.name}</ButtonToggle>
);

RoundButton.propTypes = {
  round: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  selectedRound: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const CompetitionScores = () => {
  const [selectedRound, setSelectedRound] = useState();
  const [qualifiedForNextRound, setQualifiedForNextRound] = useState([]);
  const { id, eventCompetitionId } = useParams();
  const eventId = parseInt(id, 10);

  const [errorMessage, setErrorMessage] = useState();
  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();

  const { data: eventDetail } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: eventId },
    },
  );

  const {
    data,
    loading: eventCompLoading,
    refetch: refetchEventComp,
  } = useQuery(EVENT_COMPETITION_WITH_SCORES, {
    fetchPolicy: 'no-cache',
    variables: { id: eventCompetitionId },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setErrorMessage(extractError(error));
    },
    onCompleted: (res) => {
      const eventCompData = res.fetchEventCompetitionDetail;

      // TOOD: if we ever update this logic to chose a round that's not the last one
      // also make sure to update the qualifiedForNextRound array
      const lastIdx = eventCompData.rounds.length - 1;
      setSelectedRound(eventCompData.rounds[lastIdx]);
    },
  });
  const eventCompData = data?.fetchEventCompetitionDetail;
  const backLink = isRoundFinalized(selectedRound) ? `/events/${eventId}/complete-competition` : `/events/${eventId}/incomplete-competition`;

  useHeaderComponentsMutation({
    backLink,
    title: eventCompData?.title ?? '',
    components: [{ key: EVENT_TITLE, value: get('fetchEventDetail.title', eventDetail) }],
  });

  const [unfinalizeRound, { loading: unfinalizeLoading }] = useMutation(UNFINALIZED_ROUND, {
    variables: {
      roundId: selectedRound?.id,
    },
    onError: (error) => {
      setErrorMessage(extractError(error));
    },
    onCompleted: (res) => {
      setSelectedRound(res.unfinalizedRound.round);
      refetchEventComp();
    },
  });

  const [finalizeRoundMutation, { loading: finalizeLoading }] = useMutation(FINALIZED_ROUND, {
    variables: {
      input: {
        roundId: selectedRound?.id,
        roundScores: [],
        qualifiedForNextRound: qualifiedForNextRound.map((j) => ({ id: j.id, type: j.type })),
      },
    },
    onError: (error) => {
      setErrorMessage(extractError(error));
    },
    onCompleted: (res) => {
      setSelectedRound(res.finalizedRound.round);
      refetchEventComp();
    },
  });

  const [createRound, { loading: createRoundLoading }] = useMutation(CREATE_ROUND, {
    variables: {
      eventCompetitionId,
    },
    onError: (error) => {
      setErrorMessage(extractError(error));
    },
    onCompleted: () => refetchEventComp(),
  });

  if (eventCompLoading) {
    return <Loader />;
  }

  const editLinkTarget = `/events/${eventId}/scoring/${eventCompetitionId}/rounds/${selectedRound?.id}/edit`;
  const editScores = () => history.push(editLinkTarget);

  const currentRoundJudgables = selectedRound?.judgables ?? [];
  const judgablesWithRank = mapJudgablesByRank(currentRoundJudgables);
  const canNotFinalize = hasTiesInTop5(judgablesWithRank);
  const lastRoundIdx = eventCompData.rounds.length - 1;
  const isLastRound = selectedRound?.id === eventCompData.rounds[lastRoundIdx]?.id;

  const finalizeRound = () => {
    if (canNotFinalize) {
      setErrorMessage('Cannot Finalize Competition due to a tie in the top 5!');
      return;
    }
    finalizeRoundMutation();
  };

  const onClickRoundButton = (round) => {
    setSelectedRound(round);
    const nextRound = eventCompData.rounds.find((r) => r.level === round?.level + 1);

    const qualified = round.judgables
      .filter((judgable) => nextRound?.judgables
        .find((j) => j.id === judgable.id && j.type === judgable.type));

    setQualifiedForNextRound(qualified);
  };

  const setQualified = (judgable, val) => {
    if (val) {
      // we added them
      if (!qualifiedForNextRound.find((j) => j.id === judgable.id && j.type === judgable.type)) {
        setQualifiedForNextRound([...qualifiedForNextRound, judgable]);
      }
    } else {
      // we removed them
      setQualifiedForNextRound(qualifiedForNextRound
        .filter((j) => j.id !== judgable.id || j.type !== judgable.type));
    }
  };

  return (
    <div>
      <ButtonToolbar className="my-2">
        <ButtonGroup className="mr-2">
          {eventCompData.rounds.map((r) => (
            <RoundButton
              key={r.id}
              round={r}
              selectedRound={selectedRound ?? eventCompData.rounds[0]}
              onClick={onClickRoundButton}
            />
          ))}
        </ButtonGroup>
        <Button disabled={createRoundLoading} onClick={() => createRound(eventCompetitionId)}>
          + Add Round
        </Button>
      </ButtonToolbar>
      { errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="row">
        <div className="col-md-1 col-2 mt-1 mb-1 px-4" />
        <div className={`col-${isLastRound ? '10' : '8'} mt-1 pl-6`} />
        <div className="col-md-1 col-1 mt-1 pl-4">Total Score</div>
        {!isLastRound && <div className="col-md-1 col-1 mt-1 pl-4">Qualified For Next Round</div>}
      </div>
      {judgablesWithRank.map(({ judgable, rank, isTied }) => (
        <JudgableRow
          key={judgable.id}
          judgable={judgable}
          rank={rank}
          isValid={!isTied}
          hasNextRound={!isLastRound}
          isFinalized={isRoundFinalized(selectedRound)}
          isQualified={
            qualifiedForNextRound.find((j) => j.id === judgable.id && j.type === judgable.type)
          }
          setQualified={setQualified}
        />
      ))}
      <div className="my-4">
        {!isEmpty(judgablesWithRank) && (
          <Footer
            loading={unfinalizeLoading || finalizeLoading}
            finalized={isRoundFinalized(selectedRound)}
            unfinalizeRound={unfinalizeRound}
            finalizeRound={finalizeRound}
            editScores={editScores}
            isLastRound={isLastRound}
          />
        )}
      </div>
    </div>
  );
};

const JudgableRow = ({
  judgable,
  rank,
  isValid,
  hasNextRound,
  isFinalized,
  isQualified,
  setQualified,
}) => (
  <>
    <div className="row">
      <div className="col-md-1 col-2 mt-1 mb-1 px-4">
        <TitleC>
          {(judgable.penalty && judgable.penalty.attendance)
            ? <div className="rounded-circle text-warning border-top bg-light font-weight-bold h1 py-2 px-4">{rank}</div>
            : <div className="rounded-circle text-danger border-top bg-light font-weight-bold h1 py-2 px-4">-</div>}
        </TitleC>
      </div>
      <div className={`col-${hasNextRound ? '8' : '10'} mt-4 pl-4`}>
        <JudgableCodeCell judgable={judgable} />
      </div>
      <div className="col-md-1 col-1 mt-4 pl-4">
        <FontBold fontcolor={isValid ? '#3F3F3F' : 'red'}>
          {judgable.totalScore}
        </FontBold>
      </div>
      {hasNextRound && (
        <div className="col-md-1 col-1 mt-4 pl-4">
          <CustomCheckBox className="col">
            <label htmlFor={`${judgable.id} qualify`} className="container">
              <input
                type="checkbox"
                id={`${judgable.id} qualify`}
                name={`${judgable.id} qualify for next round`}
                onChange={(e) => setQualified(judgable, e.target.checked)}
                checked={isQualified}
                value={isQualified}
                disabled={isFinalized}
              />
              <span className="checkmark" />
            </label>
          </CustomCheckBox>
        </div>
      )}
    </div>
    <DividerLine className="mb-1 mt-3" />
  </>
);

/* eslint-disable react/prop-types */
const Footer = ({
  loading,
  finalized,
  unfinalizeRound,
  finalizeRound,
  editScores,
  isLastRound,
}) => {
  if (loading) {
    return <Loader />;
  }
  if (finalized) {
    return (
      <div className="row justify-content-center mb-3">
        <SecondaryScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={unfinalizeRound}>
          {isLastRound ? 'Unfinalize Competition' : 'Unfinalize Round'}
        </SecondaryScoreButton>
        <ScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={() => unfinalizeRound().then(() => editScores())}>Unfinalize and Edit Scores</ScoreButton>
      </div>
    );
  }
  return (
    <div className="row justify-content-center mb-3">
      <SecondaryScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={finalizeRound}>
        {isLastRound ? 'Finalize Competition': 'Finalize Round'}
      </SecondaryScoreButton>
      <ScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={editScores}>Edit Scores</ScoreButton>
    </div>
  );
};

export default CompetitionScores;
