import { getOr } from 'lodash/fp';
import Errors from '../Constants/Errors';

const useGraphQLErrorExtractor = () => {
  const extractError = (error) => {
    if (!error) {
      return null;
    }
    const { graphQLErrors, networkError } = error;
    if (networkError) {
      return networkError.message;
    }
    return getOr(Errors.generalNetworkError, '[0].message', graphQLErrors);
  };

  return extractError;
};

export default useGraphQLErrorExtractor;
