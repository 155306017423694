import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import {
  RIGHTARROW, TitleC,
  ListItemStatus, DividerLine,
} from '../Auth/Layout';
import { Checkbox, Title } from '../Form';
import useFormParams from '../../Hooks/useFormParams';
import Input from '../Form/Input';
import useCurrentUser from '../../Hooks/userCurrentUser';

const ScoresList = (props) => {
  const {
    user,
    redirectLink,
    arrowIcon,
    index,
    resultType,
    competitionName,
    roundStatus,
    groupCompetition,
    check,
  } = props;
  const { params, onChange, setParams } = useFormParams({
    attendance: user.attendance,
    score: user.score,
  });
  useCurrentUser(true);

  return (
    <>
      <Link to={redirectLink}>
        <div className="row" key={index}>
          {check
            ? (
              <>
                <div className="col-1 pt-2">
                  <Checkbox
                    id={index}
                    name="attendance"
                    params={params}
                    onChange={onChange}
                    setParams={setParams}
                    checked={params.attendance}
                    checkboxAlignment="left"
                  />
                </div>
                <div className="col-9 mt-4">
                  <Title>
                    {user.mist_code}
                  </Title>
                </div>
                <div className="col-2">

                  <Input
                    type="text"
                    id={index}
                    name="score"
                    placeholder="score"
                    onChange={onChange}
                    label="Last name"
                    params={params}
                    setParams={setParams}
                    className="float-right"
                  />
                </div>
              </>
            )
            : (
              <>
                {roundStatus
                  ? (
                    <>
                      <div className="col-md-1 col-2 mt-2 mb-4 px-4">
                        <TitleC>
                          <div className="rounded-circle text-warning border-top bg-light font-weight-bold h1 py-2 px-4">{index + 1}</div>
                        </TitleC>
                      </div>
                      <div className="col-md-10 col-9 mt-4 pl-4">
                        {groupCompetition === true
                          ? <span>Team</span>
                          : null}
                        {' '}
                        {get('mist_code', user)}
                      </div>
                    </>
                  )
                  : (
                    <>
                      {competitionName
                        ? (
                          <div className="col-md-11 col-9 mt-1 pl-4">
                            {competitionName}
                          </div>
                        )
                        : (
                          <>
                            {resultType === 'User'
                              ? (
                                <>
                                  <div className="col-md-1 col-2 mt-1 px-4">
                                    <TitleC>
                                      <div className="rounded-circle text-warning border-top bg-light font-weight-bold h1 py-2 px-4">{index + 1}</div>
                                    </TitleC>
                                  </div>
                                  <div className="col-md-10 col-7 mt-3 px-4 ">
                                    <TitleC>
                                      <p className="mb-1 pb-1">
                                        {get('scoreable_type.firstname', user)}
                                        {' '}
                                        {get('scoreable_type.lastname', user)}
                                      </p>
                                      <p className="text-muted mt-2 pb-2">
                                        {user.school}
                                      </p>

                                    </TitleC>
                                  </div>
                                </>
                              )
                              : (
                                <>
                                  <div className="col-md-1 col-2 pt-2 pb-">
                                    <TitleC>
                                      <div className="rounded-circle text-warning border-top bg-light font-weight-bold h1 py-2 px-4">
                                        {index + 1}
                                      </div>
                                    </TitleC>
                                  </div>
                                  <div className="col-md-10 col-7 mt-3 pb-4 ">
                                    <TitleC>
                                      <p className="mb-1 pb-4">
                                        {get('scoreable_type.name', user)}
                                      </p>
                                    </TitleC>
                                  </div>
                                </>
                              )}
                          </>
                        )}


                    </>
                  )}

                <div className="col-1 mt-3 mx-auto">
                  {roundStatus
                    ? <ListItemStatus color="#1fbe5e" className="pt-2 text-muted">{user.score}</ListItemStatus>
                    : <ListItemStatus color="#1fbe5e" className="pt-2 text-muted">{user.score}</ListItemStatus>}
                  <>
                    <RIGHTARROW className="pt-4 pb-1">
                      {arrowIcon
                        ? <img src={arrowIcon} alt="arrow" className="img-fluid" />
                        : null}
                    </RIGHTARROW>
                  </>
                </div>

              </>
            )}


        </div>
      </Link>
      <DividerLine className="mb-1 mt-n3" />
    </>
  );
};

ScoresList.propTypes = {
  user: PropTypes.instanceOf(Array).isRequired,
  redirectLink: PropTypes.string,
  arrowIcon: PropTypes.string,
  index: PropTypes.number,
  resultType: PropTypes.string,
  competitionName: PropTypes.string,
  roundStatus: PropTypes.string,
  groupCompetition: PropTypes.string,
  check: PropTypes.string,

};

ScoresList.defaultProps = {
  redirectLink: null,
  arrowIcon: null,
  index: 0,
  resultType: null,
  competitionName: null,
  roundStatus: null,
  groupCompetition: null,
  check: null,
};

export default ScoresList;
