import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  ScoreButton, DividerLine,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL,
  SPECIAL_NEED,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const SpecialNeedReport = () => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const [dataSet] = useState([]);


  const [fetchEventDetail, { data }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  useHeaderComponentsMutation({
    title: 'SPECIAL NEED REPORTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const { data: users, loading: loadingUsers} = useQuery(
    SPECIAL_NEED,
    {

      variables: { eventId: parseInt(id, 10) },
    },
  );
  const list = getOr({}, 'specialNeed', users);

  const exportList = () => {
    Object.values(list.special_need_user_list).map((item) => (
      dataSet.push({
        name: get('user.full_name', item),
        code: get('user.code', item),
        specialNeed: get('user.accommodation_note', item),
        schoolName: get('school.name', item),
        schoolId: get('school.id', item),
      })
    ));
  };

  if (loadingUsers) {
    return <Loader />
  }
  return (
    <>
      <div className="row mt-4">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <h5 className="text-center">
                {get('total_user_count', list)}
              </h5>
              <FontBold>
                <p className="mt-4 text-center">
                  Total Users
                </p>
              </FontBold>
            </div>
            <div className="col-6">
              <h5 className="text-center">
                {get('special_need_user_count', list)}
              </h5>
              <FontBold>
                <p className="mt-4 text-center">
                  Special Need Users
                </p>
              </FontBold>
            </div>
          </div>
          <div className="pt-3 mb-2">
            <ExcelFile filename={`${get('title', event)}_special_needs (${moment().format('YYYY-MM-DD')})`}
              element={<ScoreButton onClick={exportList}>EXPORT REPORT</ScoreButton>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="MIST CODE" value="code" />
                <ExcelColumn label="School Name" value="schoolName" />
                <ExcelColumn label="School Id" value="schoolId" />
                <ExcelColumn label="Special Need" value="specialNeed" />
              </ExcelSheet>
            </ExcelFile>
          </div>

          {!isEmpty(get('special_need_user_list', list))
            ? (
              <>
                <FontBold>
                  <p>
Users(
                    {get('special_need_user_count', list)}
)
                  </p>
                </FontBold>
                {get('special_need_user_list', list).map((item) => (
                  <>
                    <div className="row ml-2">
                      <div className="col-md-12">
                        <div className="row font-weight-bold">
                          {get('user.full_name', item)}
                          {' '}
                          (
                          {get('user.code', item)}
)
                        </div>
                        <div className="row text-muted font-weight-bold mt-n1">
                          {get('user.accommodation_note', item)}
                        </div>
                      </div>
                    </div>
                    <DividerLine className="my-2" />
                  </>
                ))}
              </>
            )
            : null}

        </div>
      </div>
    </>
  );
};
export default SpecialNeedReport;
