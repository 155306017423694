import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr, get, isEmpty } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  ScoreButton,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL,
  PAID_USER_COUNT_AND_AMOUNT,
  ALL_PAYMENTS_FOR_REGISTRATION_OPTION_AGAINST_EVENT,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';


const PaymentsByEvent = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const { id } = useParams();
  const extractError = useGraphQLErrorExtractor();

  const couponArray = [];
  const [dataSet, setDataSet] = useState(couponArray);

  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  const [paidUserCountAmount,
    { data: dataCountAmount, loading: countLoading, error: countError }] = useLazyQuery(
    PAID_USER_COUNT_AND_AMOUNT,
  );
  const userCountAmount = getOr({}, 'paidUserCountAndAmount', dataCountAmount);

  const [paymentAgainstRegistrationOption, { data: dataRegistration, loading: dataLoading }] = useLazyQuery(
    ALL_PAYMENTS_FOR_REGISTRATION_OPTION_AGAINST_EVENT, {
      onCompleted: (response) => {
        const paymentList = response.paymentAgainstRegistrationOption;
        for (let i = 0; i < paymentList.length; i += 1) {
          for (let j = 0; j < paymentList[i].paymentUser.paid_user.length; j += 1) {
            const temp = {};
            temp.title = paymentList[i].title;
            temp.amount = paymentList[i].paymentUser.paid_user[j].amount / 100;
            temp.amount = temp.amount.toFixed(2);
            temp.userName = get('full_name', paymentList[i].paymentUser.paid_user[j].user);
            temp.mistCode = get('code', paymentList[i].paymentUser.paid_user[j].user);
            temp.schoolName = get('name', paymentList[i].paymentUser.paid_user[j].school);
            temp.schoolId = get('id', paymentList[i].paymentUser.paid_user[j].school);
            temp.status = 'Paid';
            couponArray.push(temp);
          }

          for (let j = 0; j < paymentList[i].paymentUser.unpaid_user.length; j += 1) {
            const temp = {};
            temp.title = paymentList[i].title;
            temp.amount = paymentList[i].paymentUser.unpaid_user[j].amount / 100;
            temp.amount = temp.amount.toFixed(2);
            temp.userName = get('full_name', paymentList[i].paymentUser.unpaid_user[j].user);
            temp.mistCode = get('code', paymentList[i].paymentUser.unpaid_user[j].user);
            temp.schoolName = get('name', paymentList[i].paymentUser.unpaid_user[j].school);
            temp.schoolId = get('id', paymentList[i].paymentUser.unpaid_user[j].school);
            temp.status = 'Unpaid';
            couponArray.push(temp);
          }
        }
        setDataSet(couponArray);
      },
    },
  );
  const registrationOptions = getOr([], 'paymentAgainstRegistrationOption', dataRegistration);

  useHeaderComponentsMutation({
    title: 'PAYMENTS REPORTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (currentUser && id) {
      paidUserCountAmount({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, paidUserCountAmount, id]);

  useEffect(() => {
    if (currentUser && id) {
      paymentAgainstRegistrationOption({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, paymentAgainstRegistrationOption, id]);


  if (loading || userLoading || countLoading || dataLoading) {
    return <Loader />;
  }
  if (error || userError || countError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  Total Paid Users
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('paid_user_count', userCountAmount)}
              </h5>
            </div>
            <div className="col-6">
              <FontBold>
                <p className="mt-4 text-center">
                  Total Amount
                </p>
              </FontBold>
              <h5 className="text-center">
                {get('payment_amount', userCountAmount) / 100}
                {' '}
                {get('default_currency', userCountAmount)}

              </h5>
            </div>
          </div>
          <div className="pt-3 mb-2">
            <ExcelFile filename={`${get('title', event)}_payments (${moment().format('YYYY-MM-DD')})`}
              element={<ScoreButton>EXPORT REPORT</ScoreButton>}>
              <ExcelSheet data={dataSet} name="Coupons">
                <ExcelColumn label="Name" value="userName" />
                <ExcelColumn label="MIST code" value="mistCode" />
                <ExcelColumn label="School Name" value="schoolName" />
                <ExcelColumn label="School Id" value="schoolId" />
                <ExcelColumn label="Registration" value="title" />
                <ExcelColumn label="Amount" value="amount" />
                <ExcelColumn label="Status" value="status" />
              </ExcelSheet>
            </ExcelFile>
          </div>
          {!isEmpty(registrationOptions)
            ? (
              <>
                <FontBold>
                  <p>
Registration Options (
                    {registrationOptions.length}
)
                  </p>
                </FontBold>
                <List
                  data={registrationOptions}
                  displayAttribute="title"
                  redirectLink="registrationOption"
                  addNewLink=""
                  addNewText=""
                  addOperationRequired={false}
                  paymentUser="true"
                  paymentEvent={id}
                />
              </>
            )
            : null}
        </div>
      </div>
    </>
  );
};
export default PaymentsByEvent;
