import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_REGION } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateRegionsCache = (userId) => {
  const client = useApolloClient();
  const updateCache = (region) => {
    try {
      const data = client.readQuery({
        query: FETCH_REGION,
        variables: {
          userId: parseInt(userId, 10),
        },
      });
      client.writeQuery({
        query: FETCH_REGION,
        data: {
          fetchRegions: [
            { ...region },
            ...data.fetchRegions,
          ],
        },
        variables: {
          userId: parseInt(userId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateRegionsCache;
