import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, utcToZonedTime } from 'date-fns-tz';

import { GhostButton } from '../../Components/Button';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_SHORT_TZ = format(new Date(), 'z');
const stringifyDate = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const BoldCode = styled.span`
  font-family: nunito_extrabold;
  font-size: 1.05rem;
`;

const AttachmentPropType = PropTypes.arrayOf(PropTypes.shape({
  attachmentName: PropTypes.string,
  attachmentUrl: PropTypes.string,
}));
const MemberPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  attachments: AttachmentPropType,
});
const JudgablePropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  members: MemberPropType,
});

export const Code = ({ code }) => {
  const [schoolCode, userCode] = code.split('-');
  if (!userCode) {
    return <BoldCode>{schoolCode}</BoldCode>;
  }
  return (
    <>
      <span>{schoolCode}</span>
      -
      <BoldCode>{userCode}</BoldCode>
    </>
  );
};
Code.propTypes = {
  code: PropTypes.string.isRequired,
};

const ExpandButton = ({ expanded, onClick }) => (
  <GhostButton onClick={onClick}>
    <i className={expanded ? 'fa fa-minus' : 'fa fa-plus'} aria-hidden="true" />
  </GhostButton>
);
ExpandButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Attachment = ({ attachment, num }) => (
  <>
    <a
      href={attachment.attachmentUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {attachment.attachmentName === attachment.attachmentUrl ? `Untitled Submission #${num}` : attachment.attachmentName}
    </a>
    <br />
    {`(uploaded at ${stringifyDate(attachment.updatedAt)} ${USER_SHORT_TZ})`}
  </>
);
Attachment.propTypes = {
  attachment: AttachmentPropType.isRequired,
  num: PropTypes.number.isRequired,
};

const SingleMember = ({ member }) => (
  <div className="col">
    <div>
      <Code code={member.code} />
    </div>
    {member.attachments && member.attachments.length > 0 && (
      <>
        Student Submissions:
        <ol>
          {member.attachments.map((attachment, i) => (
            <li key={attachment.id}>
              <Attachment attachment={attachment} num={i + 1} />
            </li>
          ))}
        </ol>
      </>
    )}
  </div>
);
SingleMember.propTypes = {
  member: MemberPropType.isRequired,
};

const MultipleMembersList = ({ judgable, canCollapse }) => {
  const [expanded, setExpanded] = useState(canCollapse);
  return (
    <>
      {!canCollapse && (
        <ExpandButton
          expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        />
      )}
      <span className="mr-1">{judgable.type}</span>
      <Code code={judgable.code} />
      <div className="col-12" style={expanded ? {} : { display: 'none' }}>
        <div>{judgable.members.length > 0 ? 'Members:' : 'No Members in Group!'}</div>
        <ul className="list-group mt-2">
          {judgable.members.map((member) => (
            <li key={member.id} className="list-group-item"><SingleMember member={member} /></li>
          ))}
        </ul>
      </div>
    </>
  );
};
MultipleMembersList.propTypes = {
  judgable: JudgablePropType.isRequired,
  canCollapse: PropTypes.bool.isRequired,
};


export const JudgableCodeCell = ({ judgable, canCollapse }) => (
  <div className="row mx-auto">
    {judgable.members
      ? <MultipleMembersList judgable={judgable} canCollapse={canCollapse} />
      : <SingleMember member={judgable} />}
  </div>
);
JudgableCodeCell.propTypes = {
  judgable: JudgablePropType.isRequired,
  canCollapse: PropTypes.bool,
};
JudgableCodeCell.defaultProps = {
  canCollapse: false,
};
