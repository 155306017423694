import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import { CREATE_EVENT } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';
import {
  LinkButton,
} from '../../Components/Auth/Layout';

const REGION_QUERY = gql`
query fetchRegions($userId: Int!){
  fetchRegions(userId: $userId) {
    id
    name
  }
}
`;
const EventNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const [errorMessage, setErrorMessage] = useState('');
  const checkPermission = useUserPermissionChecker(currentUser);
  const [createEvent, { loading, error }] = useMutation(
    CREATE_EVENT,
    {
      onCompleted: (data) => {
        history.push(`/event-detail/${data.createEvent.event.id}`);
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: '/event-detail',
    title: 'ADD NEW EVENT',
  });
  const [fetchRegions, { data: regionData }] = useLazyQuery(REGION_QUERY);
  const regions = getOr([], 'fetchRegions', regionData);
  useEffect(() => {
    if (currentUser) {
      fetchRegions({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRegions]);

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Event') === undefined) {
        history.push('/events');
      }
    }
  }, [currentUser, checkPermission, history]);

  const { params, onChange, setParams } = useFormParams({
    title: '',
    label: '',
    tenantId: '',
    regionId: '',
    startDate: '',
    endDate: '',
    submissionDeadline: '',
    location: '',
    financialAidFormLink: '',
    discordLink: '',
    volunteerApplicationLink: '',
    judgeApplicationLink: '',
    sponsorApplicationLink: '',
    termsAndConditions: '',
    fee: 0,
    attachment: null,
  });
  const extractError = useGraphQLErrorExtractor();

  const onSubmit = (e) => {
    params.fee = parseFloat(params.fee, 10);
    if (params.submissionDeadline !== '' && moment(params.submissionDeadline).isBefore(moment(params.endDate)) === false) {
      e.preventDefault();
      setErrorMessage('Registration Deadline should be less than End Date.');
    } else if (params.startDate > params.endDate) {
      e.preventDefault();
      setErrorMessage('Start Date should be less than End Date.');
    } else {
      params.regionId = parseInt(params.regionId, 10);
      e.preventDefault();
      createEvent({
        variables: {
          ...params,
          tenantId: currentUser.tenantId,
        },
      });
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error
            ? <GenericAlert>{extractError(error)}</GenericAlert>
            : (
              <>
                {errorMessage
                  ? <GenericAlert>{errorMessage}</GenericAlert>
                  : null}
              </>
            )}
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            regionData={regions}
          />
        </div>
      </div>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <LinkButton href="/events">CANCEL</LinkButton>
        </div>
      </div>
      <hr />
    </>
  );
};
export default EventNew;
