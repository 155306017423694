/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { last } from 'lodash/fp';
import {
  RIGHTARROW, TitleC,
  LinkButton,
  ImgSqaure,
  ListItemStatus,
} from '../Auth/Layout';

const UserList = (props) => {
  const {
    user,
    imageAttached,
    onClick,
    removeicon,
    orderStatus,
    redirectLink,
    arrowIcon,
  } = props;

  return (
    <>
      <Link to={redirectLink}>
        <div className="row">
          <div className="col-md-1 col-3 mt-4 px-4">
            <TitleC>
              {user.profileImageUrl
                ? <ImgSqaure><img src={user.profileImageUrl} alt="User-Icon" /></ImgSqaure>
                : (
                  <>
                    {imageAttached
                      ? <ImgSqaure><img src={imageAttached} alt="User-Icon" /></ImgSqaure>
                      : null}
                  </>
                )}
            </TitleC>
          </div>
          <div className="col-md-10 col-6 mt-4 px-4">
            <TitleC>
              <p>
                {`${user.firstname} ${user.lastname} (${user.code})`}
              </p>
              {user.fetchSchools && user.fetchSchools.length > 0
                ? (
                  <p>
                    {last(user.fetchSchools).name}
                  </p>
                )
                : null}
            </TitleC>
          </div>
          <div className="col-md-1 col-3 mt-4 mx-auto">
            {orderStatus
              ? <ListItemStatus color="#1fbe5e"><span>Paid</span></ListItemStatus>
              : (
                <RIGHTARROW>
                  {arrowIcon
                    ? <img src={arrowIcon} alt="arrow" className="img-fluid" />
                    : (
                      <LinkButton onClick={onClick}>
                        <img src={removeicon} alt="arrow" className="img-fluid" />
                      </LinkButton>
                    )}
                </RIGHTARROW>
              )}
          </div>
        </div>
      </Link>
    </>
  );
};

UserList.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  imageAttached: PropTypes.string,
  onClick: PropTypes.func,
  removeicon: PropTypes.string,
  orderStatus: PropTypes.bool,
  redirectLink: PropTypes.string,
  arrowIcon: PropTypes.string,
};

UserList.defaultProps = {
  imageAttached: null,
  onClick: null,
  redirectLink: null,
  orderStatus: false,
  removeicon: null,
  arrowIcon: null,
};

export default UserList;
