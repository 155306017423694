import { useApolloClient } from '@apollo/react-hooks';
import { subtract } from 'lodash/fp';
import { USER_INVITATION_COUNT } from '../GraphQL/Queries';
import logger from '../logger';

const useInvitationCountCache = (userId, eventId) => {
  const client = useApolloClient();
  const updateInvitationCountCache = (user, action) => {
    try {
      const data = client.readQuery({
        query: USER_INVITATION_COUNT,
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(userId, 10),
        },
      });
      const { userInvitationCount } = data;
      let response = 0;
      if (action === 'remove') {
        response = subtract(userInvitationCount, 1);
      }
      client.writeQuery({
        query: USER_INVITATION_COUNT,
        data: {
          userInvitationCount: response,
        },
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(userId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateInvitationCountCache;
};

export default useInvitationCountCache;
