import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import {
  FontBold,
} from '../../Components/Auth/Layout';
import { FETCH_ALL_BALLOTS } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const BallotIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [fetchAllBallots, { loading, error, data }] = useLazyQuery(FETCH_ALL_BALLOTS, {
    fetchPolicy: 'network-only',
  });
  const ballots = getOr([], 'fetchAllBallots', data);
  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'MANAGE BALLOTS',
    backLink: '/dashboard',
  });
  useEffect(() => {
    if (currentUser) {
      fetchAllBallots();
    }
  }, [currentUser, fetchAllBallots]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">All Ballots</p>
          </FontBold>
        </div>
      </div>
      <List data={ballots.sort((a, b) => parseInt(a.id, 10) > parseInt(b.id, 10) ? 1 : -1)} displayAttribute="title" redirectLink="ballots" redirectOperation="edit" dataType="ballots" />
      <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Create New Ballot" addNewLink="ballots/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Competition'))} />
    </>
  );
};
export default BallotIndex;
