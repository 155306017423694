import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Button } from '@chakra-ui/react';
import { ScheduleEventAttendeePropType } from './propTypes';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const DISPLAY_DATE_FORMAT = 'yyyy-MM-dd hh:mm aa z';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CAPTAIN_ROLE_TITLE = 'Captain';

const formatTimestamp = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const buildUserCode = (code, team) => {
  if (!team) return code;
  return `${team.code}-${code}`;
};

export const ExportButton = ({ eventName, scheduleEvents }) => {
  const attendeeRows = scheduleEvents.flatMap((se) => {
    const {
      attendees,
      title,
      startTime,
      endTime,
    } = se;
    return attendees.flatMap((attendee) => {
      const { code, firstname, lastname } = attendee.user;
      return attendee.eventUsers
        .filter((eu) => eu.fetchRole.title !== CAPTAIN_ROLE_TITLE)
        .map((eu) => ({
          scheduleEventTitle: title,
          scheduleEventStartTime: formatTimestamp(startTime),
          scheduleEventEndTime: formatTimestamp(endTime),
          code: buildUserCode(code, eu.fetchTeam),
          name: `${firstname} ${lastname}`,
          school: eu.fetchSchool?.name,
          checkedInAt: formatTimestamp(attendee.checkedInAt),
        }));
    });
  });

  return (
    <ExcelFile
      filename={`${eventName}_spectator_attendance_(${format(new Date(), 'yyyy-MM-dd')})`}
      element={(
        <Button
          background="brand.orange.500"
          color="white"
          paddingBlock={4}
          paddingInline={8}
          borderRadius={16}
        >
          Export Attendance Report
        </Button>
      )}
    >
      <ExcelSheet data={attendeeRows} name="event attendance">
        <ExcelColumn label="Event Title" value="scheduleEventTitle" />
        <ExcelColumn label="Event Start Time" value="scheduleEventStartTime" />
        <ExcelColumn label="Event End Time" value="scheduleEventEndTime" />
        <ExcelColumn label="MIST ID" value="code" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="School" value="school" />
        <ExcelColumn label="Checked In At" value="checkedInAt" />
      </ExcelSheet>
    </ExcelFile>
  );
};
ExportButton.propTypes = {
  eventName: PropTypes.string.isRequired,
  scheduleEvents: PropTypes.arrayOf(ScheduleEventAttendeePropType).isRequired,
};
