import React, { useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import { CLONE_EVENT } from '../../GraphQL/Mutations';
import { FETCH_EVENT_DETAIL, FETCH_EXPIRED_EVENTS } from '../../GraphQL/Queries';

import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import ListItem from '../../Components/ListItem/Index';
import { Input } from '../../Components/Form';
import { LinkButton } from '../../Components/Auth/Layout';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const EventDetail = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const history = useHistory();
  const [fetchExpiredEvents, { data: expiredEventData }] = useLazyQuery(
    FETCH_EXPIRED_EVENTS,
  );
  const { id } = useParams();
  const expiredEvents = getOr([], 'fetchExpiredEvents', expiredEventData);
  const extractError = useGraphQLErrorExtractor();
  const { params, onChange } = useFormParams({
    eventId: '',
  });
  useHeaderComponentsMutation({
    title: 'EVENTS',
    backLink: '/events',
  });
  useEffect(() => {
    if (currentUser) {
      fetchExpiredEvents({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchExpiredEvents]);

  const [fetchEventDetail, { loading, error }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useHeaderComponentsMutation({ title: 'ADD NEW EVENT' });
  useHeaderComponentsMutation({
    backLink: '/events',
    title: 'ADD NEW EVENT',
  });
  const [cloneEvent] = useMutation(
    CLONE_EVENT,
    {
      onCompleted: (data) => {
        history.push(`event-detail/${data.cloneEvent.event.id}`);
      },
    },
  );

  const onSubmit = (e) => {
    e.preventDefault();
    cloneEvent({
      variables: {
        id: parseInt(params.eventId, 10),
      },
    });
  };


  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row py-5">
        <div className="col-12 mx-auto px-4">
          {id
            ? null
            : (
              <Input title="Import from previous event" name="eventId" type="select" id="eventId" params={params} onChange={onChange}>
                <option value="" disabled>Events</option>
                {expiredEvents && expiredEvents.map((obj) => (
                  <option key={obj.id} value={obj.id}>{obj.title}</option>
                ))}
              </Input>
            )}
          {id
            ? <ListItem title="Header*" redirectLink={`/events/${id}/edit`} checkPermission={(checkPermission('View Event'))} />
            : <ListItem title="Header*" redirectLink="/events/new" checkPermission={(checkPermission('View Event'))} />}
          {id
            ? (
              <>
                <ListItem title="Settings*" redirectLink={`/events/${id}/settings`} checkPermission={(checkPermission('View Event'))} />
                <ListItem title="Registration Options*" redirectLink={`/events/${id}/registration-options`} checkPermission={(checkPermission('View Event'))} />
                <ListItem title="Competitions*" redirectLink={`/events/${id}/event-competitions`} checkPermission={(checkPermission('View Event'))} />
              </>
            )
            : null}
          <div className="pt-3">
            <LinkButton backgroundcolor="#F4AB37" fontcolor="#FFF" border="none" onClick={(e) => onSubmit(e)}>SAVE</LinkButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventDetail;
