import React, { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import SignatureCanvas from 'react-signature-canvas';
import { FETCH_TERMS_AND_CONDITIONS } from '../../GraphQL/Queries';
import { PARENT_GUARDIAN_SIGNATURE } from '../../GraphQL/Mutations';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import Form, { Button, RedirectButton } from '../../Components/Form';
import { Signature } from '../../Components/Auth/Layout';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';

const ParentGuardianApproval = () => {
  const history = useHistory();
  const { id } = useParams();
  const extractError = useGraphQLErrorExtractor();

  const canvasRef = useRef();
  const { data, loading, error } = useQuery(
    FETCH_TERMS_AND_CONDITIONS,
    {
      variables: {
        eventUserId: parseInt(id, 10),
      },
    },
  );
  const [parentGuardianSignature] = useMutation(
    PARENT_GUARDIAN_SIGNATURE, {
      onCompleted: () => {
        history.push('/thank-you');
      },
    },
  );

  const terms = get('termsAndConditions', data);
  const onSubmit = (e) => {
    e.preventDefault();
    parentGuardianSignature({
      variables: {
        parentGuardianSignature: canvasRef.current.toDataURL('image/png'),
        id: parseInt(id, 10),
      },
    });
  };
  useHeaderComponentsMutation({
    title: 'TERMS AND CONDITIONS',
    components: [{ key: EVENT_TITLE, value: get('eventTitle', terms) }],
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="rounded mx-auto d-block">
            <b>Event Name: </b>
            {get('eventTitle', terms)}
          </div>
        </div>
      </div>
      <div className="ml-2 mt-5">
        {get('fullTermsAndConditions', terms)}
      </div>
      {terms.completed === true
        ? <p className="pl-1 mt-4 font-weight-bold">You have already submitted your signature.</p>
        : (
          <div className="row">
            <div className="col-12 mx-auto px-4 py-3">
              <Form onSubmit={onSubmit}>
                <p className="black font-weight-bold">Dear Parent.</p>
                <p className="text-muted">
                Please review terms and condition and sign it.
                </p>
                <Signature>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                    ref={canvasRef}
                  />
                </Signature>
                <div className="row font-weight-bold ml-1 my-2">
                Note: Once you submit your signature,you can&apos;t change your decision.
                </div>
                <Button
                  backgroundcolor="#F4AB37"
                  fontColor="#FFF"
                  border="2px solid #FFF"
                  marginbottom="-2rem"
                  addNewText="AGREE AND SUBMIT"
                  permissionName="View Event"
                  htmltype="submit"
                >
                AGREE AND SUBMIT
                </Button>
                <RedirectButton
                  backgroundcolor="#FFF"
                  fontColor="#F4AB37"
                  border="2px solid #F4AB37"
                  marginbottom="-2rem"
                  addNewText="DISAGREE"
                  addNewLink="/thank-you"
                />
              </Form>
            </div>
          </div>
        )}
    </>
  );
};

export default ParentGuardianApproval;
