import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Search from '../../Components/Search/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENT_SCHOOL_TEAMS, FETCH_EVENT } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFilterArray from '../../Hooks/useFilterArray';
import NestedList from '../../Components/NestedList/Index';

const Teams = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const filterArray = useFilterArray(currentUser);
  const [searchSchools, setSearchSchools] = useState([]);
  const [fetchCoaches, { data: teamsData }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_TEAMS, {
      onCompleted: () => {
        const getSchool = [];
        teamsData.eventSchoolTeams.forEach((team) => {
          getSchool.push(team.school);
        });
        setSearchSchools(getSchool);
      },
    },
  );
  const teams = getOr([], 'eventSchoolTeams', teamsData);

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser) {
      fetchCoaches({
        variables: {
          eventId: id,
        },
      });
    }
  }, [currentUser, fetchCoaches, id]);

  const searchTeam = (e) => {
    if (e.target.value === '') {
      setSearchSchools(teams.map(
        (obj) => (obj.school),
      ));
    } else {
      setSearchSchools(
        filterArray(searchSchools, 'searchSchools.name', ''),
      );
    }
  };

  useHeaderComponentsMutation({
    title: 'TEAMS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });

  return (
    <>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={searchTeam}
      />
      <NestedList data={teams} displayAttribute="name" redirectLink="event" redirectOperation="teams" dataType="schools" optionalId={id} />
    </>
  );
};
export default Teams;
