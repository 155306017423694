/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { UPDATE_ROLE_PERMISSIONS } from '../../GraphQL/Mutations';
import { FETCH_ROLE_PERMISSIONS } from '../../GraphQL/Queries';
import { PrimaryButton } from '../../Components/Auth/Layout';
import useFormParams from '../../Hooks/useFormParams';
import { Checkbox } from '../../Components/Form';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import ListItem from '../../Components/ListItem/Index';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Search from '../../Components/Search/Index';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useSortArrayByAlphabets from '../../Hooks/useSortArrayByAlphabets';

const RolePermissionIndex = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const sortedArray = useSortArrayByAlphabets();
  const checkPermission = useUserPermissionChecker(currentUser);
  useHeaderComponentsMutation({ title: 'Manage Roles', backLink: '/roles' });
  const extractError = useGraphQLErrorExtractor();
  const [filteredRolePermissions, setFilteredRolePermissions] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { params, onChange, setParams } = useFormParams({
  });

  const [updateRolePermissions, { loading }] = useMutation(
    UPDATE_ROLE_PERMISSIONS,
    {
      onCompleted: () => {
        history.push('/roles');
      },
    },
  );

  const [fetchRolePermissions, { data: dataRolePermissions, error }] = useLazyQuery(
    FETCH_ROLE_PERMISSIONS, {
      onCompleted(data) {
        setFilteredRolePermissions(sortedArray(data.fetchRolePermissions, 'fetchPermissions.displayName'));
      },
    },
  );

  const rolePermissions = getOr([], 'fetchRolePermissions', dataRolePermissions);

  useEffect(() => {
    if (currentUser) {
      fetchRolePermissions({
        variables: {
          roleId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchRolePermissions, id]);


  const onSubmit = (e) => {
    const rolePermissionsArray = [];
    e.preventDefault();
    Object.values(params).map((state, index) => (state === true
      ? rolePermissionsArray.push({ id: parseInt(Object.keys(params)[index], 10), status: 1 })
      : rolePermissionsArray.push({ id: parseInt(Object.keys(params)[index], 10), status: 0 })
    ));
    updateRolePermissions({ variables: { rolePermissions: rolePermissionsArray } });
  };


  const searchRolePermissions = (e) => {
    setFilteredRolePermissions(
      sortedArray(rolePermissions.filter(
        (x) => x.fetchPermissions.displayName.toLowerCase().includes(
          e.target.value,
        ),
      ), 'fetchPermissions.displayName'),
    );
  };

  return (
    <Form onSubmit={onSubmit}>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <div className="row py-5">
        <div className="col-12 mx-auto px-4">
          <ListItem
            title="Settings"
            redirectLink={`/roles/${parseInt(id, 10)}/edit`}
            checkPermission={(checkPermission('Update Role'))}
            underLineRequired={false}
          />
          <div className="pt-3" />
          <Search
            placeholder="Search"
            onChange={(e) => searchRolePermissions(e)}
          />
          {filteredRolePermissions.length === 0
            ? <p>No Role Permissions</p>
            : null}
          {filteredRolePermissions && filteredRolePermissions.map((rolePermission) => (
            <div key={`role-permission-${rolePermission.id}`}>
              <Checkbox
                id={`${rolePermission.id}`}
                name={`${rolePermission.id}`}
                params={params}
                onChange={onChange}
                setParams={setParams}
                text={rolePermission.fetchPermissions.displayName}
                checked={rolePermission.status === 'active'}
              />
            </div>
          ))}
          <div className="w-100">
            <PrimaryButton loading={loading} htmltype="submit">Save Permissions</PrimaryButton>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default RolePermissionIndex;
