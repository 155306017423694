import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_EVENTS_USERS_BY_USERS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateEventsUsersByUsersCache = (eventId, userId) => {
  const client = useApolloClient();
  const updateCacheEventsUsersByUsers = (eventsUser, action) => {
    try {
      const data = client.readQuery({
        query: FETCH_EVENTS_USERS_BY_USERS,
        variables: {
          eventId: parseInt(eventId, 10),
          userId: parseInt(userId, 10),
          schoolId: null,
        },
      });
      const { fetchEventsUsersByUser } = data;
      let response = [];
      if (action === 'remove') {
        response = remove((e) => e.id === eventsUser.id)(fetchEventsUsersByUser);
      } else if (action === 'update') {
        const index = fetchEventsUsersByUser.findIndex((eUser) => eUser.id === eventsUser.id);
        response = fetchEventsUsersByUser;
        response[index] = eventsUser;
      } else if (action === 'add') {
        response = fetchEventsUsersByUser;
        response.push(eventsUser);
      } else if (action === 'empty') {
        response = [];
      }
      client.writeQuery({
        query: FETCH_EVENTS_USERS_BY_USERS,
        data: {
          fetchEventsUsersByUser: response,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          userId: parseInt(userId, 10),
          schoolId: null,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCacheEventsUsersByUsers;
};

export default useUpdateEventsUsersByUsersCache;
