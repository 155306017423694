import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import ReactTooltip from 'react-tooltip';
import Question from '../../Images/icons/question.png';
import GROUP_ICON from '../../Images/alerts/group.png';
import {
  ImageButton,
  ParticipantCard,
  ParticipantWaitlist,
} from '../Auth/Layout';

const Participant = (props) => {
  const {
    user,
    color,
    index,
    imageAttatched,
    removeIcon,
    onRemove,
    dragHandle,
    reason,
  } = props;

  return (
    <ParticipantCard
      className="container p-3 mr-3 text-white"
      color={color}
      {...dragHandle}
    >

      <p>
        <span className="float-left">
          {dragHandle
            ? <img className="pr-2" src={GROUP_ICON} alt="group" />
            : null}
        </span>

        <span className="float-left">
          {index + 1}
    .
          {get('fetchUser.lastname', user)}
                &nbsp;
          {get('fetchUser.firstname', user)}
        </span>
        <span className="float-right">
          {removeIcon
            ? (
              <ImageButton float="right" onClick={onRemove}>
                <img src={removeIcon} alt="remove" />
              </ImageButton>
            )
            : null}
        </span>
        {imageAttatched
          ? (
            <ParticipantWaitlist>
              <button data-tip data-for={`reason_${user.id}`} type="button"><img src={Question} alt="question" /></button>
              <ReactTooltip id={`reason_${user.id}`}>
                <span>{reason}</span>
              </ReactTooltip>
            </ParticipantWaitlist>
          )
          : null}
      </p>

    </ParticipantCard>
  );
};

Participant.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  imageAttatched: PropTypes.string.isRequired,
  removeIcon: PropTypes.string,
  onRemove: PropTypes.func,
  dragHandle: PropTypes.instanceOf(Object),
  reason: PropTypes.string,
};

Participant.defaultProps = {
  removeIcon: null,
  onRemove: null,
  dragHandle: null,
  reason: null,
};

export default Participant;
