import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  getOr, get, last, isEmpty,
} from 'lodash/fp';
import {
  Hr,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL, PENDING_TERM_AND_CONDITION_USERS,
  PENDING_REQUEST_COUNT,
  USER_INVITATION_COUNT,
  UNPAID_COUNT,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import { REMOVE_EVENT_USER } from '../../GraphQL/Mutations';
import useTermAndConditionUserCache from '../../Hooks/useTermAndConditionUserCache';
import PopUpModal from '../../Components/PopUpModal';
import ReassignCaptainPopUp from './ReassignCaptainPopUp';
import usePendingTermConditionCountCache from '../../Hooks/usePendingTermConditionCountCache';

const PendingTermAndConditionUsers = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { eventId } = useParams();
  const history = useHistory();
  const updateCache = useTermAndConditionUserCache(get('id', last(get('fetchSchools', currentUser))), eventId);
  const updatePendingTermConditionCountCache = usePendingTermConditionCountCache(
    get('id', last(get('fetchSchools', currentUser))), eventId,
  );
  const extractError = useGraphQLErrorExtractor();
  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const [removeUser, setRemoveUser] = useState(false);
  const [pendingTermAndConditionId, setPendingTermAndConditionId] = useState('');
  const [modalReassignCaptain, setModalReassignCaptain] = useState(false);
  const event = getOr({}, 'fetchEventDetail', data);

  const [fetchUnpaidUserCount] = useLazyQuery(
    UNPAID_COUNT,
  );

  const [fetchPendingTermAndConditionUsers, { data: dataTermAndConditionUsers }] = useLazyQuery(
    PENDING_TERM_AND_CONDITION_USERS,
  );

  const pendingTermAndConditionUsers = getOr([], 'fetchPendingTermAndConditionUsers', dataTermAndConditionUsers);

  const [fetchPendingRequest] = useLazyQuery(
    PENDING_REQUEST_COUNT,
  );

  const [userInvitationCount] = useLazyQuery(
    USER_INVITATION_COUNT,
  );

  const toggleRemoveUser = () => setRemoveUser(!toggleRemoveUser);
  const toggleReassignCaptain = () => setModalReassignCaptain(!toggleReassignCaptain);

  useHeaderComponentsMutation({
    title: 'PENDING TERM & CONDITION USERS',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && eventId) {
      fetchEventDetail({
        variables: {
          id: parseInt(eventId, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, eventId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchPendingTermAndConditionUsers({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, fetchPendingTermAndConditionUsers, eventId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchUnpaidUserCount({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, eventId, fetchUnpaidUserCount]);


  useEffect(() => {
    if (currentUser && eventId) {
      userInvitationCount({
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, eventId, userInvitationCount]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchPendingRequest({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, eventId, fetchPendingRequest]);

  const [removeEventUser] = useMutation(
    REMOVE_EVENT_USER,
    {
      onCompleted: (data1) => {
        setRemoveUser(false);
        updateCache(data1.removeEventUser.user, 'remove');
        updatePendingTermConditionCountCache(data1.removeEventUser.user, 'remove');
      },
    },
  );

  const removePendingTermConditionUser = () => {
    removeEventUser({
      variables: {
        eventUserId: parseInt(pendingTermAndConditionId, 10),
      },
      refetchQueries: ['fetchUnpaidUserCount', 'fetchPendingRequest', 'userInvitationCount'],
    });
  };

  const ReassignCaptainPopUpOption = () => {
    history.push(`/reassign-captain/${eventId}`);
  };

  const acceptTermAndCondition = (eventUser) => {
    history.push(`/order/${eventUser.fetchOrder.id}/pending-terms-and-conditions`);
  };

  const setPendingTermAndConditionUserId = (eventUserId) => {
    setPendingTermAndConditionId(eventUserId);
  };


  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {pendingTermAndConditionUsers.map((eventUser) => (
            <>
              <div key={eventUser.id}>
                {!isEmpty(eventUser) && eventUser.fetchUser.id === currentUser.id
                  ? (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => setModalReassignCaptain(true)}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        redirectLink="#"
                      />
                      <ReassignCaptainPopUp
                        modal={modalReassignCaptain}
                        toggle={toggleReassignCaptain}
                        onClick={() => ReassignCaptainPopUpOption()}
                        onSelect={() => acceptTermAndCondition(eventUser)}
                        bodyText="Do you want to Finalize Team?
                                  You are a captain of this team and hence in order
                                  to finalize your team, you can either reassign
                                  captain or accept term and condition before continuing."
                      />
                    </>
                  )
                  : (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => {
                          setRemoveUser(true);
                          setPendingTermAndConditionUserId(eventUser.id);
                        }}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        orderStatus={get('status', get('fetchOrder', eventUser)) === 'complete'}
                        redirectLink={get('status', get('fetchOrder', eventUser)) === 'complete'
                          ? `/event-manage/${eventId}/event-user-detail/${get('id', get('fetchUser', eventUser))}`
                          : '#'}
                      />
                      <PopUpModal
                        isOpen={removeUser}
                        toggle={toggleRemoveUser}
                        onAccept={() => removePendingTermConditionUser()}
                        onCancel={() => setRemoveUser(false)}
                        headerText="PENDING TERM & CONDITION USERS"
                        bodyText="Are you sure to remove?"
                      />
                    </>
                  )}
                <div className="mt-4" />
                <Hr />
              </div>
            </>
          ))}
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default PendingTermAndConditionUsers;
