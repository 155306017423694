import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import useCurrentUser from '../Hooks/userCurrentUser';
import UnAuthenticatedLayout from './UnAuthenticated';
import AuthenticatedLayout from './Authenticated';

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Layout = ({ children }) => {
  const { currentUser, loading } = useCurrentUser(false);
  if (loading) {
    return <StyledSpinner />;
  }

  if (currentUser) {
    return (
      <AuthenticatedLayout>
        {children}
      </AuthenticatedLayout>
    );
  }
  return (
    <UnAuthenticatedLayout>
      {children}
    </UnAuthenticatedLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default Layout;
