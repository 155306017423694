import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  ScoreButton,
} from '../../Components/Auth/Layout';
import {
  CATEGORY_WITH_EVENT_COMPETITIONS,
  COMPETITION_STATS,
  FETCH_EVENT_HEADER,
  FETCH_COMPS_SPREADSHEET_STUDENTS,
  FETCH_REG_SPREADSHEET_TEAMS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import List from '../../Components/List/Index';
import Loader from '../../Components/Loader';
import { EVENT_TITLE } from '../../Constants/AppConstants';


const buildCategoryTitle = (categoryTitle, idx, maxCompsInCategory) => {
  if (maxCompsInCategory > 1) {
    return `${categoryTitle} ${idx}`;
  }
  return categoryTitle;
};

const CompetitionsByEvent = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const [competitorData, setCompetitorData] = useState([]);
  const [competitionData, setCompetitionData] = useState({});
  const [maximumCompetitionsByCategory, setMaximumCompetitionsByCategory] = useState({});
  const [categoryCompetitions, setCompetitionsCategory] = useState([]);
  const [teamData, setTeamData] = useState({});

  const [fetchEventDetail, { data, loading }] = useLazyQuery(FETCH_EVENT_HEADER);
  const event = getOr({}, 'fetchEventDetail', data);

  const { data: count } = useQuery(
    COMPETITION_STATS,
    {
      variables: { eventId: parseInt(id, 10) },
    },
  );
  const studentsAndCompetitionsCount = getOr({}, 'competitionStats', count);

  const [competitionsCategory, { loading : competitionsLoading}] = useLazyQuery(CATEGORY_WITH_EVENT_COMPETITIONS, {
    onCompleted: (response) => {
      const competitionsList = response.categoryWithEventCompetitions;
      const competitors = {};
      const maximumCompetitionsByCategory = {};
      for (let j = 0; j < competitionsList.length; j += 1) {
        for (let i = 0; i < competitionsList[j].eventCompetitions.length; i += 1) {
          for (let k = 0; k < competitionsList[j].eventCompetitions[i].users.length; k += 1) {
            const categoryTitle = competitionsList[j].title;
            const competitionTitle = get('title', competitionsList[j].eventCompetitions[i]);
            const mistCode = get('code', competitionsList[j].eventCompetitions[i].users[k].user);
            const schoolName = get('name', competitionsList[j].eventCompetitions[i].users[k].school) ?? '';
            const status = get('status', competitionsList[j].eventCompetitions[i].users[k].member);

            if (!(mistCode in competitors)) {
              const userDetails = {};
              userDetails.schoolName = schoolName;
              userDetails.mistCode = mistCode;
              competitors[mistCode] = userDetails;
            }
            const compTitle = status === 'waitlist' ? `${competitionTitle} (waitlisted)` : competitionTitle;
            if (competitors[mistCode][categoryTitle] === undefined) {
              competitors[mistCode][categoryTitle] = [];
            }
            competitors[mistCode][categoryTitle].push(compTitle);

            if (maximumCompetitionsByCategory[categoryTitle] === undefined) {
              maximumCompetitionsByCategory[categoryTitle] = 1;
            } else if (competitors[mistCode][categoryTitle].length > maximumCompetitionsByCategory[categoryTitle]) {
              maximumCompetitionsByCategory[categoryTitle] = competitors[mistCode][categoryTitle].length;
            }
          }
        }
      }

      setMaximumCompetitionsByCategory(maximumCompetitionsByCategory);
      setCompetitionsCategory(competitionsList);
      setCompetitionData(competitors);
    },
  });

  const [eventSchoolTeams, { loading: teamsLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_TEAMS, {
      onCompleted: (response) => {
        const teams = response.eventSchoolTeams;
        const numberOfTeams = teams.length;
        const teamDictionary = {};
        for (let i = 0; i < numberOfTeams; i++) {
          const team = teams[i];
          const teamId = team.code;
          const schoolName = team.school.name;
          teamDictionary[schoolName] = teamId;
        }
        setTeamData(teamDictionary);
      }
    }
  );

  const [eventStudents, { loading: studentsLoading }] = useLazyQuery(
    FETCH_COMPS_SPREADSHEET_STUDENTS, {
      onCompleted: (response) => {
        const eventStudents = response.eventStudents;
        const competitors = [];
        const numberOfCompetitors = eventStudents.length;
        for (let i = 0; i < numberOfCompetitors; i++) {
          const competitor = eventStudents[i].fetchUser;
          const competitorInfo = {};
          competitorInfo["firstName"] = competitor.firstname;
          competitorInfo["lastName"] = competitor.lastname;
          competitorInfo["email"] = competitor.email;
          competitorInfo["mistCode"] = competitor.code;
          competitorInfo["schoolName"] = eventStudents[i].fetchSchool?.name;
          competitorInfo["region"] = competitor.defaultRegion?.name;
          competitorInfo["registrationOption"] = eventStudents[i].fetchRegistrationOption?.title ?? '';
          competitors.push(competitorInfo);
        }
        setCompetitorData(competitors);
      }
    }
  );

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (currentUser && id) {
      competitionsCategory({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, competitionsCategory, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventSchoolTeams({
        variables: {
          eventId: parseInt(id, 10),
        }
      });
    }
  }, [currentUser, eventSchoolTeams, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventStudents({
        variables: {
          eventId: parseInt(id, 10),
        }
      });
    }
  }, [currentUser, eventStudents, id]);

  useHeaderComponentsMutation({
    title: 'Competitions REPORTS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });


  if (loading || competitionsLoading || teamsLoading || studentsLoading) {
    return <Loader />;
  }

  // need to define these up here because ExcelSheet doesn't work with null children
  // so we need to filter them out here before putting them underneath ExcelSheet
  const columns = [
    <ExcelColumn label="Registration Option" value="registrationOption" />,
    <ExcelColumn label="MIST ID" value="mistId" />,
    <ExcelColumn label="First Name" value="firstName" />,
    <ExcelColumn label="Last Name" value="lastName" />,
    <ExcelColumn label="Email" value="email" />,
    <ExcelColumn label="School" value="schoolName" />,
    event?.qualifiedAttendingPreviousEvent ? <ExcelColumn label="Region" value="region" /> : null,

    ...categoryCompetitions.flatMap((category) => {
      const maximumComps = maximumCompetitionsByCategory[category.title];

      const competitionColumns = [];
      for (let i = 0; i < maximumComps; i++) {
        const columnName = buildCategoryTitle(category.title, i + 1, maximumComps);
        competitionColumns.push(
          <ExcelColumn label={columnName} value={columnName} />,
        );
      }
      return competitionColumns;
    }),
  ].filter((c) => c !== null);

  const reportData = competitorData.map((user) => {
    if (competitionData[user["mistCode"]]) {
      const competitorInfo = competitionData[user["mistCode"]];
      user["schoolName"] = competitorInfo["schoolName"];

      categoryCompetitions.forEach((category) => {
        const maximumComps = maximumCompetitionsByCategory[category.title];
        const competitionsInCategory = competitorInfo[category.title];
        if (competitionsInCategory === undefined) {
          return;
        }

        competitionsInCategory.sort((a, b) => a.localeCompare(b));

        for (let i = 0; i < maximumComps; i++) {
          const columnName = buildCategoryTitle(category.title, i + 1, maximumComps);
          user[columnName] = competitionsInCategory[i];
        }
      });
    }
    if (teamData[user["schoolName"]]) {
      user["mistId"] = teamData[user["schoolName"]] + "-" + user["mistCode"];
    } else {
      user["mistId"] = user["mistCode"];
    }
    return user;
  });

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('student', studentsAndCompetitionsCount)}
              </p>
              <FontBold>
                <p className="mt-4 text-center">
                  Students
                </p>
              </FontBold>
            </div>
            <div className="col-6">
              <p className="mt-4 text-center">
                {get('competition', studentsAndCompetitionsCount)}
              </p>
              <FontBold>
                <p className="mt-4 text-center">
                  Competitions
                </p>
              </FontBold>
            </div>
          </div>
          <div className="pt-3 mb-2">
            <ExcelFile filename={`${get('title', event)}_competitions (${moment().format('YYYY-MM-DD')})`}
              element={<ScoreButton>EXPORT COMPETITIONS REPORT</ScoreButton>}>
              <ExcelSheet
                data={reportData}
                name="competitions"
              >
                {columns}
              </ExcelSheet>
            </ExcelFile>
          </div>

          {categoryCompetitions && categoryCompetitions.map((obj) => (
            <>
              <FontBold>
                {get('title', obj)}
              </FontBold>
              <List
                data={get('eventCompetitions', obj)}
                displayAttribute="title"
                redirectLink={`events/${id}/competitions`}
                addNewLink=""
                addNewText=""
                addOperationRequired={false}
                membersCount="true"
                paymentEvent={id}
              />

            </>
          ))}
        </div>
      </div>
    </>
  );
};
export default CompetitionsByEvent;
