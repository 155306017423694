import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { get, debounce } from 'lodash/fp';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Provider } from '../../Chakra';

import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENT, EVENT_COMPETITION_WITH_SCORE_DETAILS } from '../../GraphQL/Queries';
import { SUBMIT_PENALTY, SUBMIT_SCORE, DELETE_SCORE } from '../../GraphQL/Mutations';
import {
  ScoreButton, CustomCheckBox, SecondaryScoreButton,
} from '../../Components/Auth/Layout';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import { Title } from '../../Components/Form';
import { JudgableCodeCell } from './JudgeableCodeCell';

export const getAllJudges = (judgables) => {
  const judgesById = {};
  judgables.forEach((judgable) => {
    judgable.ballotScores.forEach((score) => {
      judgesById[score.judge.id] = score.judge;
    });
  });

  const sortedIds = Object.keys(judgesById).sort();
  return sortedIds.map((judgeId) => judgesById[judgeId]);
};

export const getColumnsForRow = (judgable, judges) => {
  const judgeIds = judges.map((j) => j.id);
  const scoresByJudgeId = {};
  judgable.ballotScores.forEach((score) => {
    const judgeId = score.judge.id;
    if (!scoresByJudgeId[judgeId]) {
      scoresByJudgeId[judgeId] = {};
    }
    scoresByJudgeId[judgeId] = score;
  });
  return judgeIds.map((id) => scoresByJudgeId[id]?.totalScore || '');
};

const EditCompetitionScores = () => {
  const [editingJudgable, setEditingJudgable] = useState();
  const { id, eventCompetitionId, roundId } = useParams();
  const eventId = parseInt(id, 10);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();

  const [penalties, setPenalties] = useState({});
  const [attendance, setAttendance] = useState({});

  const { data: eventDetail } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: eventId },
    },
  );

  const {
    data,
    loading: eventCompLoading,
    refetch,
  } = useQuery(EVENT_COMPETITION_WITH_SCORE_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: { id: eventCompetitionId },
    onError: (error) => {
      seterrorMessage(extractError(error));
    },
    onCompleted: ({ fetchEventCompetitionDetail }) => {
      const existingAttendance = {};
      const existingPenalties = {};
      const round = fetchEventCompetitionDetail.rounds.find((r) => r.id === roundId);
      round.judgables.forEach(({ id, penalty }) => {
        if (penalty) {
          existingPenalties[id] = penalty.penalty;
          existingAttendance[id] = !!penalty.attendance;
        }
      });
      setPenalties(existingPenalties);
      setAttendance(existingAttendance);
    },
  });
  const eventCompData = data?.fetchEventCompetitionDetail;

  useHeaderComponentsMutation({
    title: eventCompData?.title ?? '',
    backLink: `/events/${eventId}/scoring/${eventCompetitionId}`,
    components: [{ key: EVENT_TITLE, value: get('fetchEventDetail.title', eventDetail) }],
  });

  const [submitPenalty, { loading: submitPenaltyLoading }] = useMutation(
    SUBMIT_PENALTY,
    {
      onCompleted: () => {
        history.push(`/events/${eventId}/scoring/${eventCompetitionId}`);
      },
      onError: (error) => {
        seterrorMessage(extractError(error));
      },
      refetchQueries: [{
        query: EVENT_COMPETITION_WITH_SCORE_DETAILS,
        variables: {
          id: eventCompetitionId,
        },
      }],
      awaitRefetchQueries: true,
    },
  );

  const setAttendanceForAll = (value) => {
    setAttendance((existing) => Object.fromEntries(Object.keys(existing).map((k) => [k, value])));
  };

  const setAttendanceForJudgable = (id, value) => {
    setAttendance({
      ...attendance,
      [id]: value,
    });
    setUnsavedChanges(true);
  };

  const setPenaltyForJudgable = (id, value) => {
    setPenalties({
      ...penalties,
      [id]: parseInt(value, 10) || 0,
    });
    setUnsavedChanges(true);
  };

  if (eventCompLoading) {
    return (<Loader />);
  }

  const currentRound = eventCompData.rounds.find((r) => r.id === roundId);
  const { judgables } = currentRound;
  judgables.sort((j1, j2) => j1.id - j2.id); // sort by id
  const judges = getAllJudges(judgables);

  const submitPenaltyAction = (finalized) => {
    const penaltiesPayload = judgables.map((judgable) => ({
      judgableId: judgable.id,
      judgableType: judgable.type,
      attendance: attendance[judgable.id],
      penalty: penalties[judgable.id],
    }));
    submitPenalty({
      variables: {
        input: {
          penalties: penaltiesPayload,
          finalized,
          roundId,
        },
      },
    });
    setUnsavedChanges(false);
  };

  const saveAction = debounce(1000, () => submitPenaltyAction(false));
  const finalizeAction = debounce(1000, () => submitPenaltyAction(true));

  return (
    <>
      <Provider>
        {!!editingJudgable && (
          <EditScoresModal
            ballot={eventCompData.fetchCompetition.fetchBallot}
            editingJudgable={editingJudgable}
            onClose={() => setEditingJudgable()}
            eventCompetitionId={eventCompData.id}
            roundId={roundId}
            onSave={refetch}
          />
        )}
      </Provider>
      { errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      { unsavedChanges ? <GenericAlert color="warning">You have unsaved changes, don&apos;t forget to save!</GenericAlert> : null}
      <div className="row my-4">
        <div className="col-12">
          <Header
            judges={judges}
            setAttendanceForAll={setAttendanceForAll}
            allAttendance={Object.values(attendance).every((a) => a)}
          />
          {judgables.map((judgable) => (
            <JudgableRow
              key={judgable.id}
              judgable={judgable}
              judges={judges}
              attendance={attendance[judgable.id] ?? true}
              setAttendance={setAttendanceForJudgable}
              penalty={penalties[judgable.id] ?? 0}
              setPenalty={setPenaltyForJudgable}
              onClick={(j, judge) => setEditingJudgable({ judgable: j, judge })}
            />
          ))}

          { submitPenaltyLoading
            ? <Loader />
            : (
              <div className="row justify-content-center mb-3">
                <SecondaryScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={saveAction}>Save</SecondaryScoreButton>
                <ScoreButton className="col mx-4 font-weight-bold h6" value="open" onClick={finalizeAction}>Save and Finalize Competition</ScoreButton>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

const Header = ({ judges, setAttendanceForAll, allAttendance }) => (
  <div className="row mb-4">
    <div className="col">
      <CustomCheckBox className="col">
        <label htmlFor="all-attendance" className="container">
          <input
            type="checkbox"
            id="all-attendance"
            name="all-attendance"
            onChange={(e) => setAttendanceForAll(e.target.checked)}
            checked={allAttendance}
            value={allAttendance}
          />
          <span className="checkmark" />
          <Title>Attendance</Title>
        </label>
      </CustomCheckBox>
    </div>
    <div className="col-2"><Title>Penalty</Title></div>
    <div className="col"><Title>Code</Title></div>
    {judges.map((judge) => (
      <div key={judge.id} className="col">
        <Title>
          {`${judge.fetchUser.firstname} ${judge.fetchUser.lastname}`}
        </Title>
      </div>
    ))}
    <div className="col" style={{ textAlign: 'center' }}><Title>Total</Title></div>
  </div>
);

const addAllBallotScores = (judgable) => {
  let total = 0;
  judgable.ballotScores.forEach((ballot) => {
    total += ballot.totalScore;
  });
  return total;
};

// todo this should go in a table lol
const JudgableRow = ({
  judgable,
  judges,
  attendance,
  penalty,
  setAttendance,
  setPenalty,
  onClick,
}) => {
  const cols = getColumnsForRow(judgable, judges);

  const totalScore = addAllBallotScores(judgable) + penalty;


  return (
    <div className="row mb-4">
      <CustomCheckBox className="col">
        <label htmlFor={`${judgable.id} attendance`} className="container">
          <input
            type="checkbox"
            id={`${judgable.id} attendance`}
            name={`${judgable.id} attendance`}
            onChange={(e) => setAttendance(judgable.id, e.target.checked)}
            checked={attendance}
            value={attendance}
          />
          <span className="checkmark" />
        </label>
      </CustomCheckBox>
      <div className="col-2">
        <input
          style={{ border: '1px solid black' }}
          className="col"
          type="number"
          id={`${judgable.id} penalty`}
          name={`${judgable.id} penalty`}
          onChange={(e) => setPenalty(judgable.id, e.target.value)}
          value={penalty || ''}
        />
      </div>
      <div className="col">
        <JudgableCodeCell judgable={judgable} />
      </div>
      {cols.map((col, idx) => (
        <div
          key={judges[idx].id}
          className="col"
          style={{ textAlign: 'center' }}
        >
          {col}
          <button
            type="button"
            onClick={() => onClick(judgable, judges[idx])}
            style={{
              color: '#153e75',
              paddingLeft: '2px',
              background: 'transparent',
              border: 'none',
            }}
          >
            <i className={col ? "fa fa-pencil" : "fa fa-plus"}/>
          </button>
        </div>
      ))}
      <div className="col" style={{ textAlign: 'center' }}><b>{totalScore}</b></div>
    </div>
  );
};

const sum = (arr) => arr
  .map((v) => parseInt(v, 10))
  .filter((v) => !Number.isNaN(v))
  .reduce((total, val) => total + val, 0);

const parse = (num) => {
  const val = parseInt(num, 10);
  if (Number.isNaN(val)) {
    return 0;
  }
  return val;
};

const EditScoresModal = ({
  onClose,
  onSave,
  editingJudgable,
  ballot,

  // stuff for saving
  eventCompetitionId,
  roundId,
}) => {
  const containerRef = useRef();
  const extractError = useGraphQLErrorExtractor();
  const [errorMessage, setErrorMessage] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const canDelete = deleteConfirmationText === 'DELETE';

  const { judgable, judge } = editingJudgable;
  const ballotScore = judgable.ballotScores.find(bs => bs.judge.id === judge.id);
  const isExistingBallot = !!ballotScore;

  const [scores, setScores] = useState(() => {
    if (ballotScore) {
      return ballotScore.sections.reduce((scoresAcc, section) => ({
        ...scoresAcc,
        [section.ballotSection.id]: section.points || 0,
      }), {});
    }
    return ballot.fetchBallotSections.reduce((scoresAcc, section) => ({
      ...scoresAcc,
      [section.id]: '',
    }), {});
  });

  const setScoreForSection = (sectionId, points) => {
    setScores(oldScores => ({
      ...oldScores,
      [sectionId]: points,
    }));
  };

  const [submitScore] = useMutation(SUBMIT_SCORE);
  const onClickSave = () => {
    setIsSaving(true);
    const scoresArray = Object.entries(scores).map(([sectionId, points]) => ({
      sectionId,
      points: parse(points),
    })).sort((s1, s2) => s1.sectionId - s2.sectionId);
    submitScore({
      variables: {
        input: {
          eventCompetitionId,
          scores: scoresArray,
          roundId,
          judgableId: judgable.id,
          judgableType: judgable.type,
          judgeUserId: judge.fetchUser.id,
        },
      },
    }).then(() => {
      onSave().then(() => {
        onClose();
        setIsSaving(false);
      });
    }).catch((err) => {
      setIsSaving(false);
      setErrorMessage(extractError(err));
    });
  };
  const [deleteScore] = useMutation(DELETE_SCORE);
  const onClickDelete = () => {
    setIsDeleting(true);
    deleteScore({
      variables: {
        input: {
          ballotScoreId: ballotScore.id,
        },
      },
    }).then(() => {
      onSave().then(() => {
        onClose();
        setIsSaving(false);
      });
    });
  };

  const judgeName = `${judge.fetchUser.firstname} ${judge.fetchUser.lastname}`;
  const ballotTotal = sum(Object.values(ballot.fetchBallotSections)
    .map(section => section.totalPoints));

  return (
    <>
      <div ref={containerRef} />
      <Modal isOpen onClose={onClose} size="xl" portalProps={{ containerRef }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`Editing ${judgeName}'s ballot for ${judgable.code}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && <Alert status="error">{errorMessage}</Alert>}
            <Grid
              templateColumns="auto 50px 100px"
              rowGap={2}
            >
              {ballot.fetchBallotSections.map((section, idx) => (
                <React.Fragment key={section.id}>
                  <GridItem>
                    <Text>
                      {section.title}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Input
                      size="sm"
                      type="number"
                      value={scores[section.id] ?? ''}
                      min="0"
                      max={section.totalPoints}
                      onChange={(e) => setScoreForSection(section.id, e.target.value)}
                    />
                  </GridItem>
                  <GridItem>
                    <Text>
                      {`/${section.totalPoints} points`}
                    </Text>
                  </GridItem>
                </React.Fragment>
              ))}
              <GridItem>
                <Text>Total:</Text>
              </GridItem>
              <GridItem>
                <Text>
                  {sum(Object.values(scores))}
                </Text>
              </GridItem>
              <GridItem>
                <Text>
                  {`/${ballotTotal} points`}
                </Text>
              </GridItem>
            </Grid>
            {ballotScore && (
              <Container padding={0} paddingTop={2}>
                <Heading size="sm">Comments:</Heading>
                <Container borderColor="gray.400" padding={0}>
                  <Text paddingStart={0}>
                    {ballotScore.comments?.trim().length > 0 ? ballotScore.comments.trim() : '(no comments)'}
                  </Text>
                </Container>
                <Heading paddingBlockStart={1} size="sm" color="gray.600">Private Notes:</Heading>
                <Container borderColor="gray.400" padding={0}>
                  <Text paddingStart={0} color="gray.600">
                    {ballotScore.privateNotes?.trim().length > 0 ? ballotScore.privateNotes.trim() : '(no private comments)'}
                  </Text>
                </Container>
              </Container>
            )}
          </ModalBody>

          <ModalFooter>
            <Stack direction="column" width="full">
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {isExistingBallot && !showDelete && (
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() => {
                      setShowDelete(true);
                    }}
                    isDisabled={isSaving}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={onClickSave}
                  isLoading={isSaving}
                  isDisabled={isDeleting}
                >
                  Save
                </Button>
              </Stack>
              {isExistingBallot && showDelete && (
                <Stack direction="column">
                  <Alert status="warning" flexDirection='column'>
                    <AlertTitle>Are you sure you want to delete?</AlertTitle>
                    <AlertDescription>
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      This cannot be undone! Type <b>DELETE</b> below to confirm
                    </AlertDescription>
                  </Alert>
                  <Input width="full" value={deleteConfirmationText} onChange={(e) => setDeleteConfirmationText(e.target.value)} />
                  <Button colorScheme="red" variant="outline" onClick={onClickDelete} isLoading={isDeleting} isDisabled={!canDelete || isSaving}>
                    Definitely delete!
                  </Button>
                </Stack>
              )}
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditCompetitionScores;
