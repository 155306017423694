import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

export const PrimaryButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-align: ${(props) => props.textalign || 'center'} !important;
  text-transform: uppercase;
  min-height: 45px !important;
  border-radius: ${(props) => props.borderradius || '#10px'} !important;
  font-family: 'nunito_bold';
  display: flex !important;
  justify-content: ${(props) => props.textalign || 'center'} !important;;
  align-items: ${(props) => props.textalign || 'center'} !important;;
  box-shadow: none;
  background: ${(props) => props.backgroundcolor || '#F4AB37'} !important;
  color: ${(props) => props.fontcolor || '#FFF'} !important;
  border: ${(props) => props.border || 'none'} !important;
  margin-bottom: ${(props) => props.marginbottom || '0px'} !important;
  :hover{
    background: ${(props) => props.activebackgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :active{
    background: ${(props) => props.activebackgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :focus{
    background: ${(props) => props.activebackgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
`;

export const LightGray = styled.div`
  background: rgba(247, 247, 247, 1);
  h6{
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 800;
    line-height: 18px;
    text-align: center;
    letter-spacing: .2px;
    text-transform: uppercase;
    font-family: 'nunito_extrabold';
    color: rgba(52, 52, 52, 1);
  }
`;

export const FontBold = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  color: ${(props) => props.fontcolor || '#000'} !important;
  text-align: ${(props) => props.textalign || 'left'} !important;
`;

export const LinkText = styled.div`
  color: ${(props) => props.color || '#3E6796'} !important;
  font-weight: 600;
  display: inline-block;
  cursor: default;
`;

export const RIGHTARROW = styled.div`
  zoom: 70%;
  transform: translate(-50%,-50%);
  top: 50%;
  float: right;
  button{
    background: #fff !important;
    border: none !important;
    margin-top: 2rem;
  }
`;

export const AlignRight = styled.div`
  float: right;
`;
export const AlignLeft = styled.div`
  float:left;
`;
export const TextAlignCenter = styled.div`
  text-align:center;
`;

export const UISelect = styled.div`
  position: relative;
  border: 1px solid #343434;
  border-radius: .3125em;
  padding: .8em;
  transition: all .15s ease-in-out;
  margin: 0;
  opacity: 1;
`;

export const TitleC = styled.div`
  font-size: 16px;
  line-height: normal;
  color: rgba(41, 41, 41, 1);
  font-family: 'nunito_semibold';
  vertical-align: middle;
  display: inline-block;
  text-transform: capitalize;
`;

export const ListDetail = styled.div`
  p{
    color: #797878 !important;
  }
`;


export const CustomCheckBox = styled.div`
  float: right
  .container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 22px;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .container input:disabled ~ .checkmark {
    cursor: not-allowed;
    opacity: 80%;
  }
  .container input:disabled:not(:checked) ~ .checkmark {
    background-color: #EEEEEE;
  }
  .checkmark {
    position:absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FFFFFF;
    border: 1px solid #EAB214;
  }
  .container input:checked ~ .checkmark {
    background-color: #EAB214;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const LeftCheckBox = styled.div`
  margin-left: 1rem;
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FFFFFF;
    border: 1px solid #EAB214;
  }

  .container input:checked ~ .checkmark {
    background-color:#EAB214;;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;


export const LEFTARROW = styled.div`
  zoom: 70%;
  transform: translate(-50%,-50%);
  top: 50%;
  position: absolute;
`;

export const Hr = styled.div`
  margin: auto -25px;
  border-width: 2px;
  box-sizing: border-box;
  border-color: rgba(209, 209, 209, 1);
  background-color: white;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const LinkButton = styled(Button)`
  padding: 0.6rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px !important;
  font-weight: 600 !important;
  display: inline-block;
  width: 100%;
  text-align: ${(props) => props.textalign || 'center'} !important;
  margin-bottom: ${(props) => props.marginbottom || '10px'} !important;
  font-weight: 600;
  background: ${(props) => props.backgroundcolor || '#fff'} !important;
  color: ${(props) => props.fontcolor || '#000'} !important;
  border: ${(props) => props.border || 'border: 1px solid'} !important;
  min-height: 45px !important;
  :hover{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :active{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :focus{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
`;

export const ImageButton = styled(Button)`
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  float: ${(props) => props.float || 'none'} !important;
  :hover{
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  :active{
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  :focus{
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
`;

export const CustomButton = styled(Button)`
  background: ${(props) => props.backgroundcolor || '#fff'} !important;
  color: ${(props) => props.fontcolor || '#red'} !important;
  border: ${(props) => props.border || 'border: none'} !important;
  :hover{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :active{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :focus{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  padding: 0 12px;
  min-height: 42px;
  display: inline-block;
  box-sizing: border-box;
  transition: all .15s ease-in-out;
  border: 0;
  background: transparent none;
  border-radius: 8px !important;
  border-color: rgb(73, 80, 87) !important;
  border: 1px solid !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 20, 40, 0.2) !important;
  }
`;

export const FormTitle = styled.h4`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20px;
  color: rgba(244, 171, 55, 1);
  color: ${(props) => props.color || 'rgba(244, 171, 55, 1)'} !important;
`;

export const InnerFormTitle = styled.div`
  p{
    font-size: 12pt;
    font-weight: 600;
    line-height: 20px;
    color: ${(props) => props.color || '000'} !important;
  }
`;

export const SearchIcon = styled.div`
  span{
    float: right;
    margin-top: -50px;
    margin-right: 10px;
  }
`;

export const SearchSuggestions = styled(Button)`
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px !important;
  font-weight: 500 !important;
  text-align: left !important;
  display: inline-block;
  width: 100%;
  background: ${(props) => props.backgroundcolor || '#fff'} !important;
  color: ${(props) => props.fontcolor || '#000'} !important;
  border: ${(props) => props.border || 'none'} !important;
  :hover{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
    background: ${(props) => props.backgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
  }
  :active{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
    background: ${(props) => props.backgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
  }
  :focus{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
    background: ${(props) => props.backgroundcolor || '#F4AB37'} !important;
    color: ${(props) => props.activefontcolor || '#FFF'} !important;
  }
`;

export const EventBanner = styled.div`
  text-align: center;
  position: relative;
  img{
    height: 12rem;
    width: 100%;
  }
  span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export const EventCard = styled.div`
  border-left: 1px #E3E3E3 solid;
  border-right: 1px #E3E3E3 solid;
  border-top: 1px #E3E3E3 solid;
    span{
    font-weight: 500;
    position: relative;
    z-index: 1;
    line-height: 1.2;
    margin: 0;
    font-family: 'nunito_regular' !important;;
    letter-spacing: .3px;
    font-size: 14px;
  }
  p{
    span{
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
    font-weight: 500;
    position: relative;
    z-index: 1;
    line-height: 1.2;
    margin: 0;
    font-family: 'nunito_regular' !important;;
    letter-spacing: .3px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const EventCompetitionStrip = styled.div`
  background-color: #9A9A9A;
  height: 3rem;
  border-radius: 4px;
  h3{
    color: #fff;
    font-weight: 600;
    margin-left: 3rem;
    margin-top: -1.25rem;
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    h3{
      margin-top: -1rem;
      font-size: 1rem;
    }
  }
  @media (max-width: 480px) {
    h3{
      margin-top: -1rem;
      font-size: 0.75rem;
    }
  }
  img{
    margin-top: 1rem;
    margin-left: 1rem;
  }
`;

export const EventCompetitionCard = styled.div`
  height: auto;  
  width: 100%; 
  border: 1px solid #D1D1D1;  
  border-radius: 8px; 
  box-shadow: -2px 0 3px 0 rgba(0,0,0,0.16);
  margin: auto;
  button{
    height: 16px; 
    width: 38px;  
    color: #FF2F2F !important; 
    font-family: Nunito;  
    line-height: 16px;
    font-size: 1rem;
    float:right
    margin-top: -3rem;
    margin-right: 5rem !important;
    border: none !important;
  }
  p{
    padding: 1rem;
  }
  @media (max-width: 480px) {
    p{
      font-size: 0.75rem;
    }
  }
`;

export const OrderDescriptionCard = styled.div`
  height: auto;  
  width: 100%; 
  margin: auto;
`;


export const PaymentDescription = styled.div`
  div{
    margin-left: -6rem;
  }
  font-weight: 600;
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 100%;
  span{
    color: ${(props) => props.textColor || '#F4AB37'} !important;
    float: right;
    margin-right: ${(props) => props.marginRight || '0rem'} !important;
  }
`;

export const ImageWrapper = styled.div`
  border: 2px #F4AB37 solid;
  border-radius: 10px;
  img{
    margin: 0 auto;
    margin-top: 0.5rem;
  }
  p{
    color: #F4AB37;
    text-align: center;
    font-weight: 600;
  }
`;

export const DodgerBlue = styled.div`
  background: #3E6796 !important;
  h5{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    font-family: 'nunito_bold';
    letter-spacing: .5px;
    color: rgba(255, 255, 255, 1);
  }
`;

export const DarkGrey = styled.div`
  background: #A9A9A9 !important;
  h5{
    font-size: 18px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    font-family: 'nunito_bold';
    letter-spacing: .5px;
    color: rgba(255, 255, 255, 1);
  }
`;

export const CardBorder = styled.div`
border:1px solid lightgrey;
`;

export const AlignCenter = styled.div`
text-align:center;
font-size:22px;
font-weight:bold;
font-olor:Red;
`;
export const EventImageUpload = styled.div`
  border: 1px dotted #000;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background: #FAFAFA;
  img{
    height: 3rem;
    margin-top: 1.5rem;
  }
  h3{
    color: #A0A0A0;
    font-size: 20px;  
    font-weight: 600; 
    line-height: 27px;
  }
`;
export const Signature = styled.div`
    margin: auto;
    text-align: center;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    margin-bottom: 1rem;
`;
export const EmptySignature = styled.div`
    display: none;
`;
export const SelectedSchool = styled.div`
  h5{
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #F4AC49;
  }
`;

export const SliderLikeStrip = styled.div`
  text-align: center;
  li {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    background: #EEE;
    margin: 0 1em;
    display: inline-block;
    color: white;
    position: relative;
    a {
     text-decoration: none;
     display: block;
     width: 100%;
     height: 100%;
    }
  }

  li::before{
    content: '';
    position: absolute;
    top: .7em;
    left: -3em;
    width: 4em;
    height: .2em;
    background: #EEE;
    z-index: -1;
  }


  li:first-child::before {
    display: none;
  }

  .active {
    background: #F4AB37;
  }

  .active ~ li {
    background: #EEE;
  }

  .active ~ li::before {
    background: #EEE;
  }
`;

export const EventUploadedImage = styled.div`
  margin-top: 1rem;
  text-align: center;
  img{
    height: 12rem;
    width: 100%;
  }
`;

export const ImgCircular = styled.div`
  img{
   width: 50px;
   height: 50px;
   background-size: cover;
   display: block;
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
  }
`;

export const ProfileImgCircular = styled.div`
  img{
   width: 115px;
   height: 115px;
   background-size: cover;
   display: block;
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
  }
`;

export const ImgSqaure = styled.div`
  img{
   width: 70px;
   height: 70px;
   background-size: cover;
   display: block;
  }
`;

export const ImgSquareViewMember = styled.div`
  img{
   width: 60px;
   height: 65px; 
`;

export const CustomRadioButton = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #F4AB37;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

export const RoundImg = styled.div`
  img{
   width: 80px;
   height: 80px;
   background-size: cover;
   display: block;
   border-radius: 100px;
   -webkit-border-radius: 100px;
   -moz-border-radius: 100px;
  }
  .centerImg{
     display: block;
     margin: 0 auto;
   }
`;

export const EventTitle = styled.div`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'nunito_extrabold';
  font-weight: 600;
  padding: 1rem;
  background: #3E6796;
  color: #FFFFFF !important
`;

export const YellowText = styled.div`
  border-radius: 10px;
  p{
    color: #F4AB37;
    text-align: center;
    font-weight: 600;
  }
`;

export const GrayText = styled.div`
  font-size: 16px;
  line-height: normal;
  color: #949494;
  font-family: 'nunito_semibold';
  vertical-align: middle;
  display: inline-block;
`;

export const CounterText = styled.div`
  border-radius: 20px;
  float: right;
  width: 35px;
  text-align: center;
  color: #FFFFFF;
  background: red;
  font-family: 'nunito_semibold';
  vertical-align: middle;
  display: inline-block;
  margin-right: -100%;
  margin-bottom: 15%;
`;

export const CircleIcon = styled.div`
  span{
    float: right;
    margin-right: 0.5rem;
    margin-top: -3.25rem;
  }
`;

export const GoBack = styled.div`
  font-size: 14px;
  font-weight: 900;
  line-height: 0px;
  color: rgba(148, 148, 148, .9);
`;

export const MessageBox = styled.div`
  background: #3E6796;
  color: #FFFFFF !important;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
  span{
    text-align: right;
    font-size: 10px;
    float: right;
  }
  p{
    text-align: left;
    font-size: 14px;
  }
`;

export const ConversationDiv = styled.div`
  font-size: 16px;
  font-family: 'nunito_semibold';
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
  background: #EEEEEE;
  .center{
    text-align: center;
    color: #666666;
  }
  span{
    text-align: left;
    color: #FFFFFF;
  }
`;

export const SelfMessageBox = styled.div`
  background: #FFFFFF;
  color: #343434 !important;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
  span{
    text-align: right;
    font-size: 10px;
    color: #666666;
    float: right;
  }
`;

export const StripeCustomField = styled.div`
  label{
    width: 100%;
  }
  input, span{
    width: 100%;
    padding: 0 12px;
    min-height: 42px;
    display: inline-block;
    box-sizing: border-box;
    transition: all .15s ease-in-out;
    border: 0;
    background: transparent none;
    border-radius: 8px !important;
    border-color: rgb(73, 80, 87) !important;
    border: 1px solid !important;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 20, 40, 0.2) !important;
      border-radius: 8px !important;
    }
  }
`;

export const StripeBuiltInField = styled.div`
  font-size: 16px !important;
  width: 100%;
  padding: 0 12px;
  min-height: 42px;
  display: inline-block;
  box-sizing: border-box;
  transition: all .15s ease-in-out;
  border: 0;
  background: transparent none;
  border-radius: 8px !important;
  border-color: rgb(73, 80, 87) !important;
  border: 1px solid !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 20, 40, 0.2) !important;
    border-radius: 8px !important;
  }
  input{
    font-size: 16px !important;
    padding-top: 1rem !important;
  }
`;

export const CoverEntireWidth = styled.div`
  label{
    width: 100%;
  }
`;
export const CollapseBorder = styled.div`
  border: 1px solid lightgrey;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
`;
export const AddStudentButton = styled.button`
  border: 1px solid lightgrey;
  border-radius:10px;
  background:#F4AB37;
`;

export const OrderCard = styled.div`
  height: auto !important;
  background: ${(props) => props.backgroundColor || '#FFF'} !important;
  h5{
    font-weight: 500;
  }
  h4{
    margin-top: 1rem;
    font-size: 20px;
  }
  h3{
    margin-top: 1rem;
    font-size: 20px;
  }
`;

export const WrapperButton = styled(Button)`
  width: 100%;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: inherit !important;
`;

export const PaymentTransaction = styled.div`
  p{
    font-weight: 500;
    font-size: 14px;
    margin-right: 2rem;
  }
`;

export const ListItemStatus = styled.div`
  span{
    color: ${(props) => props.color || '#000'} !important;
    float: right;
    width: 35px;
    text-align: center;
    font-family: 'nunito_semibold';
    vertical-align: middle;
    display: inline-block;
    margin-right: -100%;
    margin-bottom: 1rem;
  }
`;

export const LinkTitle = styled(Button)`
  text-decoration: none;
  display: inline-block;
  width: 100%;
  text-align: left !important;
  margin-bottom: ${(props) => props.marginbottom || '10px'} !important;
  font-weight: 600;
  background: ${(props) => props.backgroundcolor || '#fff'} !important;
  color: ${(props) => props.fontcolor || '#000'} !important;
  border: none !important;
  min-height: 45px !important;
  :hover{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :active{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :focus{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
`;

export const ReassignCaptainBox = styled.div`
  text-align: center !important;
`;

export const UserEventCompetitionCard = styled.div`
  background-color: #F7F7F7;
  padding: 1rem;
  p{
    font-weight: 600;
  }
`;

export const UploadBtn = styled.div`
  button{
    color: #3E6796 !important;
    font-weight: 600;
    display: inline-block;
    float:left !important;
    text-align: left !important
  }
`;

export const CollapseHeight = styled.div`
  min-height:55px;
  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

export const ImgCentered = styled.div`
  img{
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

export const ZipCodeLabel = styled.div`
  label{
    width: 100%;
  }
`;
export const ParticipantCard = styled.div`
  background: ${(props) => props.color || '#389446'} !important;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ImgLink = styled.div`
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
`;

export const DottedBorder = styled.div`
  border-style: dotted;
  border-color:lightgrey;

`;

export const WaitListButton = styled.div`
  margin-top: 0.25rem;
  @media (max-width: 320px) {
    float: right !important;
  }
  button{
    background: transparent;
    border: 0px;
    border-radius: 20px;
    :focus{
      border: ${(props) => props.activeborder || 'none'} !important;
      box-shadow: ${(props) => props.activeshadow || 'none'} !important;
    }
  }
  float: ${(props) => props.float || 'none'} !important;
`;

export const ParticipantWaitlist = styled.div`
  float: left; 
  button{
    background: transparent;
    border: 0px;
    border-radius: 20px;
  }
`;

export const TeamWaitlist = styled.div`
  button{
    background: transparent;
    border: 0px;
    border-radius: 20px;
  }
`;
export const PaymentHelp = styled.div`
  button{
    background: transparent;
    border: 0px;
    border-radius: 20px;
    margin-top: 1.5rem;
  }
`;

export const HelpLabel = styled.label`
  color: #666666;  
  font-size: 10px;  
  line-height: 14px;
`;

export const LinkContainer = styled.p`
  color: #0091FF;   
  font-size: 14px;  
  ine-height: 17px;
`;

export const DisabledButton = styled.div`
  cursor: not-allowed;
  pointer-events: none;
  text-align: center;
  color: #fff;
  background-color: #c0c0c0;
  padding: 0.6rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px !important;
  font-weight: 600 !important;
  display: inline-block;
  width: 100%;
`;

export const ScoreButton = styled.button`
  width:100%;
  background:#F4AB37;

  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-align: center !important;
  text-transform: uppercase;
  min-height: 45px !important;
  border-radius: #10px !important;
  font-family: 'nunito_bold';
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none;
  color: #FFF !important;
  border: none !important;
  margin-bottom: 0px !important;
`;

export const SecondaryScoreButton = styled.button`
  width:100%;
  background:none;

  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-align: center !important;
  text-transform: uppercase;
  min-height: 45px !important;
  border-radius: #10px !important;
  font-family: 'nunito_bold';
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none;
  color: #F4AB37 !important;
  border: solid 2px #F4AB37 !important;
  margin-bottom: 0px !important;
`;

export const DividerLine = styled.div`
  line-height: 0.5;
  text-align: center;
  overflow: hidden;
  border-bottom: 2px solid #C6C5C5;
  span {
    display: inline-block;
    position: relative;
    border: 1px solid rgba(151, 151, 151, .9);
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    font-family: 'nunito_regular';
    ::before {
    right: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
  }
  ::after {
    left: 100%;
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(151, 151, 151, .5);
    width: 600px;
    top: 50%;
    transform: translateY(-50%);
    }
  }
`;

export const DatePickerInput = styled.div`
  .react-datepicker-wrapper{
    width: 100%;
  }
  input{
    width: 100%;
    padding: 0 12px;
    min-height: 42px;
    border-radius: 8px !important;
    border-color: rgb(73, 80, 87) !important;
    border: 1px solid !important;
  }
`;

export const NoClick = styled.div`
  pointer-events: none;  
`;

export const SmallLogo = styled.div`
  text-align: center;
  img{
    width: 15%;
  }
`;
