import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_COMPETITION_MEMBERS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateCompetitionMembersCache = (eventId, schoolId) => {
  const client = useApolloClient();
  const updateCache = (competitionMembers) => {
    try {
      client.writeQuery({
        query: FETCH_COMPETITION_MEMBERS,
        data: {
          competitionRoster: competitionMembers,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateCompetitionMembersCache;
