import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FETCH_CATEGORIES } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import { FontBold } from '../../Components/Auth/Layout';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const CategoryIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [fetchCategories, { loading, error, data }] = useLazyQuery(FETCH_CATEGORIES);
  const categories = getOr([], 'fetchCategories', data);
  useHeaderComponentsMutation({
    title: 'CATEGORIES',
    backLink: '/dashboard',
  });
  useEffect(() => {
    if (currentUser) {
      fetchCategories({
        variables: {
          tenantId: 1,
        },
      });
    }
  }, [currentUser, fetchCategories]);
  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <FontBold className="px-4">Active Categories</FontBold>
        <List data={categories} displayAttribute="title" redirectLink="competitions" addNewText="Add New Category" addNewLink="category/new" dataType="categories" redirectOperation="" />
        <ButtonLink loading={loading} htmltype="submit" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Add New Category" addNewLink="categories/new" imageAttached={ADD_SMALL_ICON} checkPermission={(checkPermission('Add Category'))} />
      </div>
    </div>
  );
};

export default CategoryIndex;
