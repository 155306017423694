import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontBold, PrimaryButton } from '../../Components/Auth/Layout';
import GenericAlert from '../../Components/GenericAlert';
import Loader from '../../Components/Loader';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT_JUDGES,
  FETCH_EVENT_INVITED_JUDGES,
  FETCH_COMPETITIONS_BY_EVENT_LIGHT,
  FETCH_EVENT_HEADER,
} from '../../GraphQL/Queries';
import { ASSIGN_JUDGES, RESEND_JUDGE_INVITE } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import REMOVE_ICON from '../../Images/icons/remove.png';

const IconButton = styled.button`
  background: transparent;
  height: 20px;
  border: none;
`;

const CompetitionBlock = ({
  eventId,
  competition,
  confirmedJudgeEmails,
  removeConfirmedJudge,
  invitedJudges,
  removeInvitedJudge,
  newJudgeEmails,
  updateNewJudges,
}) => {
  const addNewJudge = () => {
    const newJudges = [...newJudgeEmails, ''];
    updateNewJudges(newJudges);
  };

  const removeNewJudge = (idxToRemove) => {
    const newEmails = newJudgeEmails.filter((_, idx) => idx !== idxToRemove);
    updateNewJudges(newEmails);
  };

  const editNewJudge = (idxToEdit, newEmail) => {
    const newEmails = [...newJudgeEmails];
    newEmails[idxToEdit] = newEmail;
    updateNewJudges(newEmails);
  };

  const [resendJudgeInvite] = useMutation(RESEND_JUDGE_INVITE);
  const onResendJudgeInvite = (judgeEmail) => {
    resendJudgeInvite({
      variables: {
        input: {
          judgeEmail,
          eventId,
          eventCompetitionId: competition.id,
        },
      },
    }).then(() => {
      alert('Successfuly re-sent invitation email to judge');
    });
  };

  return (
    <>
      <div className="row my-2">
        <FontBold>
          <p className="mt-4">
            {competition.title}
          </p>
        </FontBold>
        {confirmedJudgeEmails.map((email, idx) => (
          <JudgeRow
            key={email}
            judgeEmail={email}
            onRemove={() => removeConfirmedJudge(idx)}
          />
        ))}
        {invitedJudges.map(({ email, inviteUrl }, idx) => (
          <JudgeRow
            key={email}
            judgeEmail={email}
            judgeInviteUrl={inviteUrl}
            onRemove={() => removeInvitedJudge(idx)}
            onResendJudgeInvite={() => onResendJudgeInvite(email)}
            invited
          />
        ))}
        {newJudgeEmails.map((email, idx) => (
          <NewJudgeRow
            // key is actually more stable here than email!
            // since we can edit the email, and the key will change
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            judgeEmail={email}
            onChange={(newEmail) => editNewJudge(idx, newEmail)}
            onRemove={() => removeNewJudge(idx)}
          />
        ))}
      </div>
      <div className="row my-2 justify-content-center">
        <div className="col-6 my-4">
          <PrimaryButton backgroundcolor="#F4AB37" fontcolor="#FFF" border="none" onClick={addNewJudge}>Add New Judge</PrimaryButton>
        </div>
      </div>
    </>
  );
};


const copy = async (linkToCopy, setErrorMessage) => {
  try {
    await navigator.clipboard.writeText(linkToCopy);
  } catch (err) {
    setErrorMessage(`unable to copy, here is the link: ${linkToCopy}`);
  }
};

const InvitedJudgeControls = ({ judgeInviteUrl, setErrorMessage, onClickResend }) => {
  const [toolTipMessage, setToolTipMessage] = useState('copy to clipboard');
  const randomID = String(Math.random());
  return (
    <>
      <IconButton
        onMouseDown={onClickResend}
        onTouchEnd={onClickResend}
        type="button"
        className="fa fa-paper-plane"
        style={{ paddingRight: '0.5em', minWidth: '24px' }}
        aria-label="resend invite email"
      />
      <IconButton
        onMouseDown={() => {
          copy(judgeInviteUrl, setErrorMessage)
            .then(() => setToolTipMessage('copied'));
        }}
        onTouchEnd={() => {
          copy(judgeInviteUrl, setErrorMessage)
            .then(() => setToolTipMessage('copied'));
        }}
        onMouseOut={() => setToolTipMessage('copy to clipboard')}
        onBlur={() => setToolTipMessage('copy to clipboard')}
        type="button"
        className="fa fa-clipboard"
        style={{ paddingRight: '0.5em', minWidth: '24px' }}
        aria-label="invited"
        data-tip
        data-for={randomID}
      />
      <ReactTooltip id={randomID} getContent={() => toolTipMessage} />
    </>
  );
};

const JudgeRow = ({
  judgeEmail, judgeInviteUrl, onRemove, invited, onResendJudgeInvite
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <div className="col-12">
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="row my-2">
        <div className="col-8 col-md-8 mx-auto px-2">
          { invited
            ? <i className="fa fa-envelope" style={{ paddingRight: '0.5em', minWidth: '24px' }} aria-label="invited" />
            : <i className="fa fa-check-square" style={{ color: 'green', paddingRight: '0.5em', minWidth: '24px' }} aria-label="confirmed" /> }
          <input style={{ width: '90%' }} type="email" value={judgeEmail} disabled />
        </div>
        { invited
          ? (
            <InvitedJudgeControls
              judgeInviteUrl={judgeInviteUrl}
              setErrorMessage={setErrorMessage}
              onClickResend={onResendJudgeInvite}
            />
          )
          : (
            <>
              <i className="fa fa-paper-plane" style={{ visibility: 'hidden', paddingRight: '0.5em', minWidth: '24px' }} aria-hidden="true" />
              <i className="fa fa-clipboard" style={{ visibility: 'hidden', paddingRight: '0.5em', minWidth: '24px' }} aria-hidden="true" />
            </>
          )}
        <div className="col-1 mx-auto px-2">
          <IconButton onMouseDown={onRemove} onTouchEnd={onRemove} type="button">
            <img src={REMOVE_ICON} alt="remove judge" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const NewJudgeRow = ({ judgeEmail, onRemove, onChange }) => (
  <div className="col-12">
    <div className="row my-2">
      <div className="col-8 col-md-8 mx-auto px-2">
        {/* get the width lined up! such an ugly hack lol */}
        <i style={{ visibility: 'hidden', paddingRight: '0.5em', minWidth: '24px' }} className="fa fa-envelope" aria-hidden="true" />
        <input style={{ width: '90%' }} type="email" value={judgeEmail} onChange={(evt) => { onChange(evt.target.value); }} />
      </div>
      <i className="fa fa-paper-plane" style={{ visibility: 'hidden', paddingRight: '0.5em', minWidth: '24px' }} aria-hidden="true" />
      <i className="fa fa-clipboard" style={{ visibility: 'hidden', paddingRight: '0.5em', minWidth: '24px' }} aria-hidden="true" />
      <div className="col-1 mx-auto px-2">
        <IconButton onMouseDown={onRemove} onTouchEnd={onRemove} type="button">
          <img src={REMOVE_ICON} alt="remove judge" />
        </IconButton>
      </div>
    </div>
  </div>
);

const computeJudgeEmailsByEventCompetitionId = (judges) => {
  const result = {};
  judges.forEach((j) => {
    j.competitionsToJudge.forEach((c) => {
      if (result[c.id] === undefined) {
        result[c.id] = [];
      }
      result[c.id].push(j.fetchUser.email);
    });
  });
  return result;
};

const computeEventCompetitionsById = (eventCompetitions) => {
  const result = {};
  eventCompetitions.forEach((ec) => {
    result[ec.id] = ec;
  });
  return result;
};

const buildAssignments = (judgeEmailsByEventCompetitionId) => {
  const body = [];
  Object.keys(judgeEmailsByEventCompetitionId).forEach((eventCompetitionId) => {
    const judgeEmails = judgeEmailsByEventCompetitionId[eventCompetitionId]
      .map((email) => email.trim())
      .filter((email) => email !== '');

    body.push({
      eventCompetitionId,
      judgeEmails,
    });
  });
  return body;
};

// go from eventCompId -> { email, url } obj
// to eventCompId -> email
const buildInvitedAssignments = (judgesByEventCompetitionId) => {
  const judgeEmailsByEventCompetitionId = {};
  Object.keys(judgesByEventCompetitionId).forEach((eventCompetitionId) => {
    const assignments = judgesByEventCompetitionId[eventCompetitionId].map(({ email }) => email);
    judgeEmailsByEventCompetitionId[eventCompetitionId] = assignments;
  });
  return buildAssignments(judgeEmailsByEventCompetitionId);
};

const Judges = () => {
  const { currentUser } = useCurrentUser(true);
  const { id } = useParams();
  const eventId = parseInt(id, 10);
  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();
  const [eventTitle, setEventTitle] = useState('');
  const [eventCompetitionsById, setEventCompetitionsById] = useState({});
  const [confirmedJudgeEmailsByEventCompetitionId, setConfirmedJudgeEmailsByEventCompetitionId] = useState({});
  const [invitedJudgesByEventCompetitionId, setInvitedJudgesByEventCompetitionId] = useState({});
  const [newJudgeEmailsByEventCompetitionId, setNewJudgeEmailsByEventCompetitionId] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const backLink = `/event-manage/${id}`;

  useHeaderComponentsMutation({
    title: 'judges',
    backLink,
    components: [{ key: EVENT_TITLE, value: eventTitle }],
  });

  const [fetchEventDetail, { loading: eventLoading }] = useLazyQuery(FETCH_EVENT_HEADER, {
    onCompleted: ({ fetchEventDetail: event }) => setEventTitle(event.title),
  });
  const [fetchEventJudges, { loading: judgesLoading }] = useLazyQuery(FETCH_EVENT_JUDGES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ eventJudges }) => {
      setConfirmedJudgeEmailsByEventCompetitionId(computeJudgeEmailsByEventCompetitionId(eventJudges));
    },
  });
  const [fetchEventInvitedJudges, { loading: invitedJudgesLoading }] = useLazyQuery(FETCH_EVENT_INVITED_JUDGES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ eventInvitedJudges }) => {
      const invitedJudges = {};
      eventInvitedJudges.forEach(({ email, inviteUrl, eventCompetitionId }) => {
        if (invitedJudges[eventCompetitionId] === undefined) {
          invitedJudges[eventCompetitionId] = [];
        }
        invitedJudges[eventCompetitionId].push({ email, inviteUrl });
      });
      setInvitedJudgesByEventCompetitionId(invitedJudges);
    },
  });
  const [fetchEventCompetitions, { loading: competitionsLoading }] = useLazyQuery(FETCH_COMPETITIONS_BY_EVENT_LIGHT, {
    onCompleted: ({ fetchCompetitionsByEvent }) => {
      setEventCompetitionsById(computeEventCompetitionsById(fetchCompetitionsByEvent));
    },
  });

  useEffect(() => {
    if (currentUser) {
      fetchEventDetail({
        variables: { id: eventId },
      });
      fetchEventJudges({
        variables: { eventId },
      });
      fetchEventInvitedJudges({
        variables: { eventId },
      });
      fetchEventCompetitions({
        variables: { eventId },
      });
    }
  }, [
    eventId,
    currentUser,
    fetchEventDetail,
    fetchEventJudges,
    fetchEventInvitedJudges,
    fetchEventCompetitions,
  ]);

  const [saveUpdatedJudges, { loading: judgesSaving }] = useMutation(ASSIGN_JUDGES);
  const onSave = () => {
    setUnsavedChanges(false);
    const confirmedAssignments = buildAssignments(confirmedJudgeEmailsByEventCompetitionId);
    const invitedAssignments = buildInvitedAssignments(invitedJudgesByEventCompetitionId);
    const newAssignments = buildAssignments(newJudgeEmailsByEventCompetitionId);
    const allAssignments = confirmedAssignments
      .concat(invitedAssignments)
      .concat(newAssignments);
    saveUpdatedJudges({
      variables: {
        input: {
          eventId: parseInt(id, 10),
          assignments: allAssignments,
        },
      },
    }).then(() => {
      history.push(backLink);
    }).catch((err) => {
      setErrorMessage(extractError(err));
      window.scrollTo(0, 0); // ew?
    });
  };

  const removeConfirmedJudge = (eventCompetitionId, idxToRemove) => {
    const confirmedJudgeEmails = confirmedJudgeEmailsByEventCompetitionId[eventCompetitionId];
    const newJudges = confirmedJudgeEmails.filter((_, idx) => idx !== idxToRemove);
    setConfirmedJudgeEmailsByEventCompetitionId({
      ...confirmedJudgeEmailsByEventCompetitionId,
      [eventCompetitionId]: newJudges,
    });
    setUnsavedChanges(true);
  };

  const removeInvitedJudge = (eventCompetitionId, idxToRemove) => {
    const invitedJudges = invitedJudgesByEventCompetitionId[eventCompetitionId];
    const newJudges = invitedJudges.filter((_, idx) => idx !== idxToRemove);
    setInvitedJudgesByEventCompetitionId({
      ...invitedJudgesByEventCompetitionId,
      [eventCompetitionId]: newJudges,
    });
    setUnsavedChanges(true);
  };

  const updateNewJudges = (eventCompetitionId, newJudges) => {
    setNewJudgeEmailsByEventCompetitionId({
      ...newJudgeEmailsByEventCompetitionId,
      [eventCompetitionId]: newJudges,
    });
    setUnsavedChanges(true);
  };

  if (eventLoading || judgesLoading || invitedJudgesLoading || competitionsLoading) {
    return <Loader />;
  }

  const orderedEventCompetitions = Object.values(eventCompetitionsById).sort((ec1, ec2) => {
    const title1 = ec1.fetchCompetition.fetchCategory.title;
    const title2 = ec2.fetchCompetition.fetchCategory.title;
    const titleSort = title1.localeCompare(title2);
    if (titleSort !== 0) return titleSort;
    return ec1.title.localeCompare(ec2.title);
  });

  // TODO: add a banner at the top that says "you have unsaved changes"
  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      {unsavedChanges ? <GenericAlert color="warning">You have unsaved changes! Please scroll down and press Save</GenericAlert> : null}
      <div className="mt-4" />
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto px-4">
            <FontBold>
              <p className="mt-4">
                Judges
              </p>
            </FontBold>
          </div>
        </div>
        {orderedEventCompetitions.map((eventCompetition) => (
          <CompetitionBlock
            key={eventCompetition.id}
            competition={eventCompetition}
            eventId={eventId}
            confirmedJudgeEmails={confirmedJudgeEmailsByEventCompetitionId[eventCompetition.id] || []}
            removeConfirmedJudge={(idx) => removeConfirmedJudge(eventCompetition.id, idx)}
            invitedJudges={invitedJudgesByEventCompetitionId[eventCompetition.id] || []}
            removeInvitedJudge={(idx) => removeInvitedJudge(eventCompetition.id, idx)}
            newJudgeEmails={newJudgeEmailsByEventCompetitionId[eventCompetition.id] || ['']}
            updateNewJudges={(newJudges) => updateNewJudges(eventCompetition.id, newJudges)}
          />
        ))}
        <div className="col-12 my-4">
          <PrimaryButton backgroundcolor="#F4AB37" fontcolor="#FFF" border="none" onClick={onSave}>
            {judgesSaving ? 'Saving...' : 'Save'}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default Judges;
