import styled from 'styled-components';

export default styled.div`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(52, 52, 52, 1);
  font-family: 'nunito_extrabold';
  font-weight: 600;
`;
