import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  get, getOr, last, isEmpty, first,
} from 'lodash/fp';
import { FontBold } from '../../Components/Auth/Layout';
import {
  FETCH_REGISTRATION_OPTIONS_BY_DEADLINE,
  FETCH_EVENTS_USERS_BY_USERS,
  FETCH_ORDERS_BY_EVENT,
  FETCH_INVITATION_DETAIL_BY_CODE,
  CHECK_TEAM_FINALIZE,
} from '../../GraphQL/Queries';
import {
  JOIN_EVENT,
  UPDATE_JOIN_EVENT,
  UPDATE_LINE_ITEM,
  CHANGE_REGISTRATION_OPTION,
} from '../../GraphQL/Mutations';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { Button, RedirectButton } from '../../Components/Form';
import BackLink from '../../Components/BackLink';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';
import useRegistrationOptionPermissionChecker from '../../Hooks/useRegistrationOptionPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import useUpdateActiveEventsCache from '../../Hooks/useUpdateActiveEventsCache';
import useUpdateEventsUsersByUsersCache from '../../Hooks/useUpdateEventsUsersByUsersCache';
import JoinEventSlider from './JoinEventSlider';

const JoinEventRegistration = ({
  event, onSelect, selectedRegistrationOption,
}) => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);

  const history = useHistory();
  const blueColor = '#3E6796';
  const whiteColor = '#FFF';

  const location = useLocation();
  const params = queryString.parse(location.search);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectedEventsUser, setSelectedEventsUser] = useState({});
  const [option, setOption] = useState({});
  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));
  const updateEventCache = useUpdateActiveEventsCache();
  const checkPermissionOfRegistrationOption = useRegistrationOptionPermissionChecker(
    selectedRegistrationOption,
  );
  const checkUserPermission = useUserPermissionChecker(currentUser);

  const [fetchEventsUsersByUsers] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS, {
      onCompleted: (data) => {
        if (data.fetchEventsUsersByUser) {
          setSelectedEventsUser(data.fetchEventsUsersByUser[0]);
          if (data.fetchEventsUsersByUser[0]) {
            setOption(data.fetchEventsUsersByUser[0].fetchRegistrationOption);
          }
        }
      },
    },
  );

  const [fetchOrdersByEvent, { data: dataOrdersByEvent }] = useLazyQuery(
    FETCH_ORDERS_BY_EVENT,
  );

  const order = first(getOr([], 'fetchOrdersByEvent', dataOrdersByEvent));

  const [fetchRegistrationOptions, { data: dataRegistrationOptions, error }] = useLazyQuery(
    FETCH_REGISTRATION_OPTIONS_BY_DEADLINE,
  );

  const registrationOptions = getOr(
    [], 'registrationOptionByDeadline', dataRegistrationOptions,
  );

  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );

  const teamFinalize = getOr(false, 'checkTeamFinalize', dataCheckTeamFinalize);

  useEffect(() => {
    if (currentUser && !isEmpty(event) && last(get('fetchSchools', currentUser))) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(get('id', event), 10),
          schoolId: parseInt(get('id', last(get('fetchSchools', currentUser))), 10),
        },
      });
    }
  }, [currentUser, event, checkTeamFinalize]);

  const [fetchInvitationDetail] = useLazyQuery(
    FETCH_INVITATION_DETAIL_BY_CODE, {
      onCompleted: (data) => {
        onSelect(data.fetchInvitationDetail.fetchRegistrationOption);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();

  useEffect(() => {
    if (currentUser && !isEmpty(event)) {
      fetchRegistrationOptions({
        variables: {
          tenantId: currentUser.tenantId,
          eventId: parseInt(event.id, 10),
        },
      });
    }
  }, [currentUser, event, fetchRegistrationOptions]);

  useEffect(() => {
    if (currentUser && !isEmpty(event)) {
      fetchOrdersByEvent({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(event.id, 10),
        },
      });
    }
  }, [currentUser, event, fetchOrdersByEvent]);


  useEffect(() => {
    if (currentUser && params.invitation_code) {
      fetchInvitationDetail({
        variables: {
          invitationCode: params.invitation_code,
        },
      });
    }
  }, [params.invitation_code, currentUser, fetchInvitationDetail]);

  useEffect(() => {
    if (currentUser) {
      if (checkUserPermission(Permissions.CAN_JOIN_SCHOOL) && isEmpty(get('fetchSchools', currentUser))) {
        history.replace('/school-verification');
      }
    }
  }, [checkUserPermission, currentUser, history]);

  useEffect(() => {
    if (currentUser && !isEmpty(event)) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(event.id, 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, event, event.id, fetchEventsUsersByUsers, fetchRegistrationOptions]);

  const updateCacheEventsUsersByUsers = useUpdateEventsUsersByUsersCache(getOr(0, 'id', event), getOr(0, 'id', currentUser));

  const [changeRegistrationOption,
    { loading: registrationOptionLoading }] = useMutation(CHANGE_REGISTRATION_OPTION,
    {
      onCompleted: () => {
        history.push(`/order/${order.id}/terms-and-conditions`);
      },
    });


  const [updateLineItem, { loading: lineItemLoading }] = useMutation(UPDATE_LINE_ITEM, {
    onCompleted: (data) => {
      const orderObj = data.updateLineItem.order;
      updateOrdersCache(orderObj, 'update');
      changeRegistrationOption({
        variables: {
          eventsUserId: parseInt(selectedEventsUser.id, 10),
        },
      });
    },
  });

  const [updateJoinEvent, { loading }] = useMutation(UPDATE_JOIN_EVENT, {
    onCompleted: (data) => {
      updateCacheEventsUsersByUsers(data.updateJoinEvent.eventsUsers, 'update');
      // TODO: why is this on the front-end?? Shouldn't it be handled on the server?
      updateLineItem({
        variables: {
          id: parseInt(first(order.fetchLineItems).id, 10),
          lineItemAbleId: parseInt(selectedRegistrationOption.id, 10),
          amount: selectedRegistrationOption.finalFee,
        },
      });
    },
  });

  const [joinEvent, { loading: eventLoading }] = useMutation(JOIN_EVENT, {
    onCompleted: (dataJoinEvent) => {
      if (dataJoinEvent.joinEvent.eventsUsers) {
        const orderObject = dataJoinEvent.joinEvent.eventsUsers.fetchOrder;
        updateCacheEventsUsersByUsers(dataJoinEvent.joinEvent.eventsUsers, 'add');
        updateOrdersCache(orderObject, 'add');
        updateEventCache(dataJoinEvent.joinEvent.eventsUsers.eventId);
        history.push(`/order/${orderObject.id}/terms-and-conditions`);
      } else {
        setErrorMessage(dataJoinEvent.joinEvent.result);
      }
    },
    onError: (errorObj) => {
      const msg = errorObj.graphQLErrors[0].message;

      // TODO: nuke this entire functionality in favour of tracking the EventUser status
      // setting them to waitlisted, not letting them pay, etc.
      //
      // for now, we'll just rewrite the error message with the URL
      if (selectedRegistrationOption.waitlistFormUrl && msg?.endsWith('Capacity has been full')) {
        setErrorMessage((
          <>
            Registration is now full! Please click
            {' '}
            <a target="_blank" rel="noopener noreferrer" href={selectedRegistrationOption.waitlistFormUrl}>here</a>
            {' '}
            to join the registration waitlist.
          </>
        ));
      } else {
        setErrorMessage(msg);
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (checkPermissionOfRegistrationOption(Permissions.CAN_JOIN_SCHOOL) && teamFinalize) {
      setErrorMessage("Your school's team for this event is already finalized. You may only register as a guest.");
    } else if (get('id', selectedEventsUser)) {
      updateJoinEvent({
        variables: {
          id: parseInt(selectedEventsUser.id, 10),
          registrationOptionId: parseInt(selectedRegistrationOption.id, 10),
        },
      });
    } else {
      joinEvent({
        variables: {
          eventId: parseInt(event.id, 10),
          tenantId: currentUser.tenantId,
          userId: parseInt(currentUser.id, 10),
          roleId: selectedRegistrationOption.roleId,
          schoolId: (last(currentUser.fetchSchools)
            ? parseInt(last(currentUser.fetchSchools).id, 10)
            : null
          ),
          registrationOptionId: parseInt(selectedRegistrationOption.id, 10),
          status: 1,
          invitationToken: params.invitation_code,
        },
      });
    }
  };


  if (loading || userLoading || eventLoading || lineItemLoading || registrationOptionLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          {error
            ? <GenericAlert>{extractError(error)}</GenericAlert>
            : (
              <>
                {errorMessage
                  ? <GenericAlert>{errorMessage}</GenericAlert>
                  : null}
              </>
            )}
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={selectedRegistrationOption}
          />
          <FontBold>
            <p className="mt-4">
              Select Registration Type
            </p>
          </FontBold>
          {event.id === '74' && (
            <p className="pt-3">
              <b>Note:</b>
              <ul>
                <li>With Accommodations - refers to attendees that will be staying in the dorms provided by nationals (additional $70 charge for lodging)</li>
                <li>No Accommodations - refers to attendees that will be self-arranging transportation to and from the venue each day or self-arranging overnight stays</li>
                <li>Virtual Student - for students who will NOT be travelling to nationals and only competed in virtual competitions</li>
              </ul>
            </p>
          )}
          {registrationOptions.map((registrationOption) => (
            <RedirectButton
              key={option.id}
              backgroundcolor={registrationOption.id === selectedRegistrationOption.id
                ? `${blueColor}`
                : `${whiteColor}`}
              fontColor={registrationOption.id === selectedRegistrationOption.id
                ? `${whiteColor}`
                : `${blueColor}`}
              border="2px solid #3E6796"
              marginbottom="-2rem"
              activebackgroundcolor="#3E6796"
              activefontcolor="#FFF"
              addNewText={registrationOption.title}
              rightHandText={`${registrationOption.finalFee}`}
              permissionName="View Event"
              textalign="left"
              onClick={() => onSelect(registrationOption)}
            />
          ))}
        </div>
      </div>
      <div className="pt-3" />
      {Object.keys(selectedRegistrationOption).length > 0
        ? (
          <Form onSubmit={onSubmit}>
            <Button
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="2px solid #FFF"
              marginbottom="-2rem"
              permissionName="View Event"
              currentUser={currentUser}
              htmltype="submit"
              loading={loading}
            >
              NEXT
            </Button>
          </Form>
        )
        : null}
      <BackLink to="/">
            Back
      </BackLink>
    </>
  );
};
JoinEventRegistration.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  selectedRegistrationOption: PropTypes.instanceOf(Object).isRequired,
};

JoinEventRegistration.defaultProps = {
  event: null,
  onSelect: null,
};


export default JoinEventRegistration;
