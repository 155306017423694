import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, Redirect } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { format, utcToZonedTime } from 'date-fns-tz';
import { getOr } from 'lodash/fp';

import Errors from '../../Constants/Errors';
import { FontBold } from '../../Components/Auth/Layout';
import { GhostButton } from '../../Components/Button';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import {
  FETCH_EVENT_COMPETITION_DETAIL_JUDGING,
  FETCH_EVENT_HEADER,
  FETCH_SCORE_FOR_JUDGABLE,
} from '../../GraphQL/Queries';
import { SUBMIT_SCORE } from '../../GraphQL/Mutations';
import GenericAlert from '../../Components/GenericAlert';
import Loader from '../../Components/Loader';
import { isRoundFinalized } from '../Scores/CompetitionScores';
import { Code, JudgableCodeCell } from '../Scores/JudgeableCodeCell';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_SHORT_TZ = format(new Date(), 'z');
const stringifyDate = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const POLLING_INTERVAL_MS = 15_000;

const scoresAreValid = (sections, scores) => sections.every((section) => {
  const score = scores[section.id] || 0;
  return score >= 0 && score <= section.totalPoints;
});

const totalScoreGiven = (scores) => Object.values(scores)
  .filter((score) => !Number.isNaN(score)).reduce((a, b) => a + b, 0);

const totalScorePossible = (sections) => sections.map((section) => section.totalPoints)
  .reduce((a, b) => a + b, 0);

const CHECKED_IN_JUDGABLES = gql`
query fetchRoundDetail($id: Int!) {
  fetchRoundDetail(id: $id) {
    id
    status
    judgables {
      id
      type
      code
      attachments {
        id
        attachmentName
        attachmentUrl
        createdAt
        updatedAt
      }
      penalty {
        id
        attendance
        checkedInTime
      }
      members {
        id
        code
        type
        attachments {
          id
          attachmentName
          attachmentUrl
          createdAt
          updatedAt
        }
      }
    }
  }
}
`;

const JudgablePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  penalty: PropTypes.shape({
    attendance: PropTypes.bool.isRequired,
    checkedInTime: PropTypes.string,
  }).isRequired,
});

const JudgableItem = ({ judgable, currentJudgableId, JudgableLink }) => (
  <div className="row pb-3" key={judgable.id}>
    <div className="col-1" style={judgable.id !== currentJudgableId ? { visibility: 'hidden' } : {}}>
      <i className="fa fa-arrow-right" aria-hidden="true" />
    </div>
    <div className="col">
      <div>
        {judgable.id === currentJudgableId
          ? <span><Code code={judgable.code} /></span>
          : <JudgableLink id={judgable.id} code={judgable.code} />}
      </div>
      {judgable.penalty.checkedInTime && (
        <div>
          <i>
            {`Checked in at ${stringifyDate(judgable.penalty.checkedInTime)} (${USER_SHORT_TZ})`}
          </i>
        </div>
      )}
    </div>
  </div>
);
JudgableItem.propTypes = {
  judgable: JudgablePropType.isRequired,
  currentJudgableId: PropTypes.string.isRequired,
  JudgableLink: PropTypes.func.isRequired,
};

const AvailableJudgablesList = ({
  checkedIn,
  notCheckedIn,
  currentJudgableId,
  JudgableLink,
}) => (
  <div className="col-4">
    {
      checkedIn
        .map((judgable) => (
          <JudgableItem
            key={judgable.id}
            judgable={judgable}
            currentJudgableId={currentJudgableId}
            JudgableLink={JudgableLink}
          />
        ))
    }
    {checkedIn.length > 0 && notCheckedIn.length > 0 && <hr />}
    {
      notCheckedIn
        .map((judgable) => (
          <JudgableItem
            key={judgable.id}
            judgable={judgable}
            currentJudgableId={currentJudgableId}
            JudgableLink={JudgableLink}
          />
        ))
    }
  </div>
);
AvailableJudgablesList.propTypes = {
  checkedIn: PropTypes.arrayOf(JudgablePropType).isRequired,
  notCheckedIn: PropTypes.arrayOf(JudgablePropType).isRequired,
  currentJudgableId: PropTypes.string.isRequired,
  JudgableLink: PropTypes.func.isRequired,
};

const extractError = (error) => {
  if (!error) {
    return null;
  }
  const { graphQLErrors, networkError } = error;
  if (networkError) {
    return networkError.message;
  }
  return getOr(Errors.generalNetworkError, '[0].message', graphQLErrors);
};

const Judging = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const params = useParams();
  const eventId = parseInt(params.id, 10);
  const eventCompetitionId = parseInt(params.eventCompetitionId, 10);
  const { roundId } = params;

  const { data: eventData, loading: eventLoading } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      id: eventId,
    },
    skip: !eventId,
    onError: (err) => setErrorMessage(extractError(err)),
  });

  const { data, loading } = useQuery(FETCH_EVENT_COMPETITION_DETAIL_JUDGING, {
    fetchPolicy: 'network-only',
    variables: {
      id: eventCompetitionId,
    },
    skip: !eventCompetitionId,
    onError: (err) => setErrorMessage(extractError(err)),
  });
  const eventCompetitionData = data?.fetchEventCompetitionDetail;
  const ballot = eventCompetitionData?.fetchCompetition.fetchBallot;

  const { data: roundData, judgablesLoading: roundLoading } = useQuery(CHECKED_IN_JUDGABLES, {
    variables: {
      id: roundId,
    },
    skip: !roundId,
    pollInterval: POLLING_INTERVAL_MS,
  });
  const currentRound = roundData?.fetchRoundDetail;

  useHeaderComponentsMutation({
    title: 'judges',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: eventData?.fetchEventDetail?.title }],
  });

  const [scoresByStudent, setScoresByStudent] = useState({});
  const [commentsByStudent, setCommentsByStudent] = useState({});
  const [privateNotesByStudent, setPrivateNotesByStudent] = useState({});
  const [nextJudgableId, setNextJudgableId] = useState();
  const [unsavedByJudgableId, setUnsavedByJudgableId] = useState({});

  const clearMessages = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const judgables = roundData?.fetchRoundDetail.judgables ?? [];
  const { checkedInUnsorted, notCheckedInUnsorted } = judgables.reduce((acc, current) => {
    if (current.penalty.attendance) {
      return {
        ...acc,
        checkedInUnsorted: acc.checkedInUnsorted.concat(current),
      };
    }
    return {
      ...acc,
      notCheckedInUnsorted: acc.notCheckedInUnsorted.concat(current),
    };
  }, { checkedInUnsorted: [], notCheckedInUnsorted: [] });

  const checkedIn = [...checkedInUnsorted]
    .sort((a, b) => a.penalty.checkedInTime.localeCompare(b.penalty.checkedInTime));
  const notCheckedIn = [...notCheckedInUnsorted]
    .sort((a, b) => a.code.localeCompare(b.code));

  const firstCheckedIn = checkedIn[0];
  const firstNotCheckedIn = notCheckedIn[0];

  // judgable id isn't in the url, redirect to include it after we've loaded the necessary data
  useEffect(() => {
    const firstJudgable = firstCheckedIn || firstNotCheckedIn;
    if (!params.judgableId && !nextJudgableId && (firstJudgable !== undefined)) {
      setNextJudgableId(firstJudgable.id);
    }
  }, [params.judgableId, nextJudgableId, firstCheckedIn, firstNotCheckedIn]);

  const currentJudgableId = params.judgableId;
  const scores = scoresByStudent[currentJudgableId] || {};
  const comments = commentsByStudent[currentJudgableId] || '';
  const privateNotes = privateNotesByStudent[currentJudgableId] || '';

  const currentJudgableIdx = judgables?.findIndex((j) => j.id === currentJudgableId);
  const currentJudgable = judgables?.[currentJudgableIdx];

  const [submitScore, { loading: submitScoreLoading }] = useMutation(SUBMIT_SCORE);

  const autoSaveTimerRef = useRef(null);
  useEffect(() => {
    const autoSaveTimer = autoSaveTimerRef.current;

    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }

    if (!currentJudgable || !unsavedByJudgableId[currentJudgable.id]) {
      return () => {};
    }

    const sectionIdToScores = scoresByStudent[currentJudgable.id] ?? {};
    if (ballot && !scoresAreValid(ballot.fetchBallotSections, sectionIdToScores)) {
      setErrorMessage('Could not save scores because point values are outside of allowed values.');
      return () => {};
    }

    const timer = setTimeout(() => {
      const scoresToSave = ballot?.fetchBallotSections.map((section) => ({
        sectionId: section.id,
        points: sectionIdToScores[section.id] || 0,
      }));
      submitScore({
        variables: {
          input: {
            eventCompetitionId,
            roundId,
            scores: scoresToSave,
            judgableId: currentJudgable.id,
            judgableType: currentJudgable.type,
            comments: commentsByStudent[currentJudgable.id] || '',
            privateNotes: privateNotesByStudent[currentJudgable.id] || '',
          },
        },
      }).then(() => {
        setSuccessMessage('Scores saved successfully!');
        setUnsavedByJudgableId({
          ...unsavedByJudgableId,
          [currentJudgable.id]: false,
        });
      }).catch((err) => setErrorMessage(extractError(err)));
    }, 1000);

    autoSaveTimerRef.current = timer;

    return () => clearTimeout(timer);
  }, [
    unsavedByJudgableId,
    scoresByStudent,
    commentsByStudent,
    privateNotesByStudent,
    currentJudgable,
    ballot,
    eventCompetitionId,
    roundId,
    submitScore,
  ]);

  const setScores = (newScores) => {
    setScoresByStudent({
      ...scoresByStudent,
      [currentJudgableId]: newScores,
    });
  };
  const updateScoreForSection = (sectionId, score) => {
    const newScores = {
      ...scoresByStudent[currentJudgableId],
      [sectionId]: score,
    };
    setScores(newScores);
    setUnsavedByJudgableId({
      ...unsavedByJudgableId,
      [currentJudgableId]: true,
    });
    clearMessages();
  };
  const updateComments = (newComments) => {
    setCommentsByStudent({
      ...commentsByStudent,
      [currentJudgableId]: newComments,
    });
    setUnsavedByJudgableId({
      ...unsavedByJudgableId,
      [currentJudgableId]: true,
    });
    clearMessages();
  };
  const updatePrivateNotes = (newPrivateNotes) => {
    setPrivateNotesByStudent({
      ...privateNotesByStudent,
      [currentJudgableId]: newPrivateNotes,
    });
    setUnsavedByJudgableId({
      ...unsavedByJudgableId,
      [currentJudgableId]: true,
    });
    clearMessages();
  };

  const { loading: currentScoresLoading } = useQuery(FETCH_SCORE_FOR_JUDGABLE, {
    fetchPolicy: 'network-only',
    variables: {
      eventCompetitionId,
      roundId,
      judgableId: currentJudgable?.id,
      judgableType: currentJudgable?.type,
    },
    skip: !currentJudgable,
    onError: (err) => setErrorMessage(extractError(err)),
    onCompleted: ({ fetchScoreForJudgable }) => {
      if (fetchScoreForJudgable) {
        setCommentsByStudent({
          ...commentsByStudent,
          [currentJudgableId]: fetchScoreForJudgable.comments,
        });
        setPrivateNotesByStudent({
          ...privateNotesByStudent,
          [currentJudgableId]: fetchScoreForJudgable.privateNotes,
        });
        const currentScores = {};
        fetchScoreForJudgable.sections.forEach((section) => {
          currentScores[section.ballotSection.id] = section.points;
        });
        setScores(currentScores);
      }
    },
  });

  if (eventCompetitionData && isRoundFinalized(currentRound)) {
    return (
      <div>
        Judging has now closed! Thank you for judging
        {' '}
        {eventCompetitionData.title}
!
      </div>
    );
  }

  if (nextJudgableId) {
    if (nextJudgableId === params.judgableId) {
      setNextJudgableId(undefined);
    } else if (nextJudgableId === -1) {
      // all done!
      return (
        <div>
          Thank you for judging
          {' '}
          {eventCompetitionData.title}
!
        </div>
      );
    } else {
      return (<Redirect to={`/events/${eventId}/judging/${eventCompetitionId}/rounds/${roundId}/competitors/${nextJudgableId}`} />);
    }
  }

  if (eventLoading || loading || currentScoresLoading || roundLoading || !roundData) {
    return <Loader />;
  }

  return (
    <>
      <AutoSaveMessage
        errorMessage={errorMessage}
        isDirty={unsavedByJudgableId[currentJudgableId]}
        isSaving={submitScoreLoading}
        isSaved={!!successMessage}
      />
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              {eventCompetitionData?.title}
            </p>
          </FontBold>
        </div>
      </div>
      {judgables && ballot && (
        <div className="row mb-4">
          <AvailableJudgablesList
            checkedIn={checkedIn}
            notCheckedIn={notCheckedIn}
            currentJudgableId={currentJudgableId}
            JudgableLink={({ id, code }) => (
              <Link to={`/events/${eventId}/judging/${eventCompetitionId}/rounds/${roundId}/competitors/${id}`} onClick={() => clearMessages()}>
                <Code code={code} />
              </Link>
            )}
          />
          <div className="col border p-4">
            {currentJudgable && <JudgableCodeCell judgable={currentJudgable} canCollapse />}
            {ballot.fetchBallotSections.map((section) => (
              <BallotSection
                key={`${section.id}_${currentJudgableId}`}
                section={section}
                onChange={updateScoreForSection}
                value={scores[section.id]}
              />
            ))}
            <div className="row mt-4 justify-content-between">
              <div className="col">
                Total Score:
              </div>
              <div className="col-3">
                <div className="row">
                  {`${totalScoreGiven(scores)}/${totalScorePossible(ballot.fetchBallotSections)} points`}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col my-4">
                Comments & Feedback for competitors:
                <textarea
                  className="col"
                  value={comments}
                  onChange={(evt) => updateComments(evt.target.value)}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col my-4">
                Private notes (will not be shared with the competitors):
                <textarea
                  className="col"
                  style={{ backgroundColor: '#e6efff' }}
                  value={privateNotes}
                  onChange={(evt) => updatePrivateNotes(evt.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Judging;

const BallotSection = ({ section, value, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [valid, setValid] = useState(true);

  const updateValue = (target) => {
    setValid(target.validity.valid);
    const num = Number.parseInt(target.value, 10);
    onChange(section.id, num);
  };

  return (
    <>
      <div className="row mt-4 justify-content-between">
        <div className="col">
          <GhostButton onClick={() => setExpanded(!expanded)}>
            <i className={expanded ? 'fa fa-minus' : 'fa fa-plus'} aria-hidden="true" />
          </GhostButton>
          {section.title}
        </div>
        <div className="col-3">
          <div className="row">
            <input
              type="number"
              style={valid ? {} : { border: '1px solid red' }}
              value={value}
              min="0"
              max={section.totalPoints}
              onChange={(evt) => updateValue(evt.target)}
            />
            <div className="mx-2">
              {`/${section.totalPoints} points`}
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center" style={expanded ? {} : { display: 'none' }}>
        <p className="col-auto justify-content-center" style={{ whiteSpace: 'pre-line' }}>{section.description}</p>
      </div>
    </>
  );
};


const AutoSaveMessage = ({
  errorMessage,
  isDirty,
  isSaving,
  isSaved,
}) => {
  if (errorMessage) {
    return <GenericAlert>{errorMessage}</GenericAlert>;
  }
  if (isSaving) {
    return (
      <GenericAlert color="warning">
        <i className="fa fa-circle-notch fa-spin" />
        {' Saving changes...'}
      </GenericAlert>
    );
  }
  if (isDirty) {
    return (
      <GenericAlert color="warning">
        <i className="fa fa-pencil" />
        {' You have unsaved changes'}
      </GenericAlert>
    );
  }
  if (isSaved) {
    return (
      <GenericAlert color="success">
        <i className="fa fa-check-circle" />
        {' All changes saved'}
      </GenericAlert>
    );
  }
  return null;
};

AutoSaveMessage.propTypes = {
  errorMessage: PropTypes.string,
  isDirty: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
};
AutoSaveMessage.defaultProps = {
  errorMessage: null,
};
