import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import useFormParams from '../../Hooks/useFormParams';
import {
  Hr, SearchSuggestions,
} from '../../Components/Auth/Layout';
import {
  FETCH_REGISTRATION_OPTIONS_BY_DEADLINE,
  FETCH_UNJOIN_USERS_BY_EVENT,
  FETCH_MY_EVENTS,
  FETCH_ROLES_BY_LEVEL,
  FETCH_ROLE_DETAIL,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Form, { PermissionButton, Input, RedirectButton } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import Search from '../../Components/Search/Index';
import { INVITE_EXITING_USER } from '../../GraphQL/Mutations';
import useRolePermissionChecker from '../../Hooks/useRolePermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';

const InviteExistingUsers = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const checkPermission = useUserPermissionChecker(currentUser);
  const extractError = useGraphQLErrorExtractor();
  const [fetchRegistrationOptions,
    { data: registrationOptions }] = useLazyQuery(FETCH_REGISTRATION_OPTIONS_BY_DEADLINE);
  const registrations = getOr([], 'registrationOptionByDeadline', registrationOptions);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [clickStatus, setClickStatus] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { params, onChange, setParams } = useFormParams({});

  const [fetchRolesByLevel, { data: roleData, loading, error }] = useLazyQuery(
    FETCH_ROLES_BY_LEVEL,
  );
  const roles = getOr([], 'fetchRolesByLevel', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchRolesByLevel({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRolesByLevel]);

  const { data: eventData } = useQuery(FETCH_MY_EVENTS);
  const events = getOr([], 'myEvents', eventData);

  const [fetchRoleDetail, { data: roleDetail }] = useLazyQuery(FETCH_ROLE_DETAIL);
  const role = getOr([], 'fetchRoleDetail', roleDetail);
  const checkRolePermission = useRolePermissionChecker(role);

  useEffect(() => {
    fetchRoleDetail({
      variables: {
        id: parseInt(params.roleId, 10),
      },
    });
  }, [params.roleId, fetchRoleDetail]);

  const [fetchUnregisteredUsersByEvent] = useLazyQuery(
    FETCH_UNJOIN_USERS_BY_EVENT,
    {
      onCompleted: (data1) => {
        setTotalUsers(
          data1.fetchUnregisteredUsersByEvent.filter(({ status }) => status === 'active'),
        );
      },
    },
  );
  useHeaderComponentsMutation({
    title: 'INVITE EXISITING USER(S)',
    backLink: '/users',
  });

  useEffect(() => {
    if (params.invitableId) {
      fetchUnregisteredUsersByEvent({
        variables: {
          eventId: parseInt(params.invitableId, 10),
        },
      });
    }
  }, [currentUser, fetchUnregisteredUsersByEvent, params.invitableId]);

  useEffect(() => {
    if (currentUser && params.invitableId) {
      fetchRegistrationOptions({
        variables: {
          tenantId: currentUser.tenantId,
          eventId: parseInt(params.invitableId, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptions, params.invitableId]);

  const getSelectedUser = (obj) => {
    setClickStatus(false);
    setSearchStringLength(0);
    selectedUsers.push(obj);
    params[`userId_${selectedUsers.length - 1}`] = obj.id;
  };

  const searchUsers = (e) => {
    setSearchStringLength(e.target.value.length);
    setFilteredUsers(
      totalUsers.filter(
        (user) => ((`${user.firstname} ${user.lastname}`).toLowerCase()).includes(e.target.value),
      ),
    );
  };

  const deleteUser = (user) => {
    const index = selectedUsers.indexOf(user);
    if (index !== -1) {
      selectedUsers.splice(index, 1);
    }
    setSelectedUsers([...selectedUsers]);
  };

  const [invitation, { loading: invitationLoading }] = useMutation(INVITE_EXITING_USER, {
    onCompleted: () => {
      history.push('/users');
    },
  });


  const onSubmit = (e) => {
    const invitations = [];
    e.preventDefault();
    if (checkRolePermission(Permissions.CAN_JOIN_EVENT)) {
      const len = Object.values(params).length;
      for (let i = 2; i < len; i += 2) {
        invitations.push({
          userId: parseInt(Object.values(params)[i], 10),
          registrationOptionId: parseInt(Object.values(params)[i + 1], 10),
        });
      }
      params.invitations = invitations;
    } else if (role) {
      const len = Object.values(params).length;
      for (let i = 2; i < len; i += 1) {
        invitations.push({
          userId: parseInt(Object.values(params)[i], 10),
          roleId: parseInt(get('id', role), 10),
        });
      }
      params.invitations = invitations;
    }
    invitation({
      variables: {
        ...params,
        invitableId: parseInt(params.invitableId, 10),
        invitableType: 'Event',
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <Input title="Events" name="invitableId" type="select" params={params} id="invitableId" required onChange={onChange}>
            <option value="" selected disabled>Select Event</option>
            {events && events.map((obj) => (
              <option key={obj.id} value={obj.id}>{obj.title}</option>
            ))}
          </Input>
          <Input title="Roles" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
            <option value="" disabled>Select Roles</option>
            {roles.map((obj) => (
              <option key={obj.id} value={obj.id}>{obj.title}</option>
            ))}
          </Input>
          <div className="mt-4" />
        </div>
        <div className="col-12 mx-auto px-4">
          <Search
            placeholder="Search"
            onChange={searchUsers}
          />
          {filteredUsers && (searchStringLength > 0 || clickStatus === true)
            ? (
              <>
                {filteredUsers.map((obj) => (
                  <SearchSuggestions
                    onClick={() => getSelectedUser(obj)}
                    onChange={searchUsers}
                  >
                    <span id={obj.id}>
                      {`${obj.firstname} ${obj.lastname}`}
                    </span>
                  </SearchSuggestions>
                ))}
              </>
            )
            : null}
          <div className="mt-4" />
          <div className="mt-4" />
          <Hr />
          <Form onSubmit={onSubmit}>
            {selectedUsers.map((user, index) => (
              <div key={user.id}>
                <UserList
                  user={user}
                  onClick={() => deleteUser(user)}
                  imageAttached={DefaultImage}
                  removeicon={REMOVE_ICON}
                  name={`userId_${index}`}
                  id={`userId_${index}`}
                  params={params}
                  setParams={setParams}
                />
                {checkRolePermission(Permissions.CAN_JOIN_EVENT)
                  ? (
                    <>
                      <Input type="select" params={params} onChange={onChange} name={`registrationOptionId_${index}`} id={`registrationOptionId_${index}`}>
                        <option value="" disabled>Select Registration Options</option>
                        {registrations && registrations.map((obj) => (
                          <option key={obj.id} value={obj.id}>{obj.title}</option>
                        ))}
                      </Input>
                      <div className="mt-4" />
                    </>
                  )
                  : null}
                <Hr />
              </div>
            ))}
            <div className="mt-4" />
            <PermissionButton
              loading={invitationLoading}
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="none"
              htmltype="submit"
              checkPermission={(checkPermission(Permissions.CAN_MANAGE_REGION))}
            >
INVITE USER
            </PermissionButton>
          </Form>
          <RedirectButton backgroundcolor="#FFF" fontColor="rgba(244, 171, 55, 1)" border="2px solid #f3AA44" addNewText="INVITE NEW USER" addNewLink="/invite-user" />
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default InviteExistingUsers;
