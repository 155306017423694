import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LinkText, AlignRight } from '../Auth/Layout';

const ButtonLink = (props) => {
  const {
    addNewText,
    addNewLink,
    imageAttached,
    color,
    checkPermission,
    onClick,
  } = props;

  return (
    <>
      {checkPermission
        ? (
          <div className="w-100">
            {addNewLink
              ? (
                <Link to={addNewLink}>
                  <div className="row align-items-center">
                    <div className="col-12 mx-auto mt-4 px-4">
                      <LinkText color={color}>
                        <p>
                          {addNewText}
                        </p>
                      </LinkText>
                      <AlignRight>
                        {imageAttached
                          ? <img src={imageAttached} alt="arrow" className="img-fluid" />
                          : null }
                      </AlignRight>
                    </div>
                  </div>
                </Link>
              )
              : (
                <div className="row align-items-center">
                  <div className="col-12 mx-auto mt-4 px-4">
                    <LinkText color={color} onClick={onClick}>
                      <p>
                        {addNewText}
                      </p>
                    </LinkText>
                    <AlignRight>
                      {imageAttached
                        ? <img src={imageAttached} alt="arrow" className="img-fluid" />
                        : null }
                    </AlignRight>
                  </div>
                </div>
              )}
            <hr />
          </div>
        )
        : null}
    </>
  );
};

ButtonLink.propTypes = {
  addNewText: PropTypes.string.isRequired,
  addNewLink: PropTypes.string,
  color: PropTypes.string,
  imageAttached: PropTypes.string,
  checkPermission: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonLink.defaultProps = {
  color: '#3E6796',
  imageAttached: null,
  checkPermission: null,
  onClick: null,
  addNewLink: null,
};

export default ButtonLink;
