import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { CREATE_COUPON } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUpdateCouponsCache from '../../Hooks/useUpdateCouponsCache';
import Form from './Form';
import { FETCH_ROLES, FETCH_EVENTS_FROM_USER } from '../../GraphQL/Queries';
import { RedirectButton } from '../../Components/Form';

const CouponNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const updateCache = useUpdateCouponsCache(getOr(0, 'tenantId', currentUser));
  const [error, setError] = useState('');
  const [createCoupon, { loading }] = useMutation(
    CREATE_COUPON,
    {
      onCompleted: (data) => {
        updateCache(data.createCoupon.coupon);
        history.goBack();
      },
      onError: (errorMessage) => {
        setError(errorMessage);
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: '/coupons',
    title: 'ADD NEW COUPON',
  });

  const [fetchRoles, { data: roleData }] = useLazyQuery(FETCH_ROLES);
  const roles = getOr([], 'fetchRoles', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchRoles({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRoles]);
  const [fetchEvents, { data: eventData }] = useLazyQuery(FETCH_EVENTS_FROM_USER);
  const events = getOr([], 'fetchEventsFromUser', eventData);
  useEffect(() => {
    if (currentUser) {
      fetchEvents({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchEvents]);
  const { params, onChange, setParams } = useFormParams({
    code: '',
    maxCodeUsage: '',
    expirationDate: '',
    value: '',
    valuePercentage: false,
  });
  const extractError = useGraphQLErrorExtractor();

  const onSubmit = (e) => {
    params.regionId = parseInt(params.regionId, 10);
    params.roleId = parseInt(params.roleId, 10);
    params.eventId = parseInt(params.eventId, 10);
    params.value = parseInt(params.value, 10);
    params.maxCodeUsage = parseInt(params.maxCodeUsage, 10);
    e.preventDefault();
    createCoupon({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
      },
    });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            roleData={roles}
            eventData={events}
            formType="new"
            currentUser={currentUser}
          />
        </div>
      </div>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <RedirectButton backgroundcolor="#FFF" fontColor="black" border="1px solid black" addNewText="CANCEL" addNewLink="/coupons" />
        </div>
      </div>
      <hr />
    </>
  );
};
export default CouponNew;
