import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { PrimaryButton } from '../Auth/Layout';

const StyledButton = styled(Spinner)`
  margin-left: 4px;
`;

const PermissionButton = (props) => {
  const {
    loading,
    htmltype,
    children,
    backgroundcolor,
    fontColor,
    border,
    marginBottom,
    checkPermission,
    onClick,
  } = props;

  return (
    <>
      {checkPermission
        ? (
          <PrimaryButton
            htmltype={htmltype}
            disabled={loading}
            backgroundcolor={backgroundcolor}
            fontcolor={fontColor}
            border={border}
            marginbottom={marginBottom}
            onClick={onClick}
          >
            {children}
            {loading ? <StyledButton size="sm" color="secondary" /> : null}
          </PrimaryButton>
        )
        : null}
    </>
  );
};

PermissionButton.propTypes = {
  loading: PropTypes.bool,
  htmltype: PropTypes.string,
  children: PropTypes.string.isRequired,
  backgroundcolor: PropTypes.string,
  fontColor: PropTypes.string,
  marginBottom: PropTypes.string,
  border: PropTypes.string,
  checkPermission: PropTypes.bool,
  onClick: PropTypes.func,
};

PermissionButton.defaultProps = {
  loading: false,
  htmltype: 'button',
  marginBottom: '0px',
  border: '0px',
  fontColor: '#FFF',
  backgroundcolor: 'F4AB37',
  checkPermission: null,
  onClick: null
};

export default PermissionButton;
