import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { FETCH_EVENT_USERS_BY_SCHOOL, FETCH_EVENT } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import ListItemDetail from '../../Components/ListItemDetail/Index';
import userDefaultImage from '../../Images/icons/userIcon.png';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import useUpdateEventsUsersbyEventCache from '../../Hooks/useUpdateEventsUsersbyEventCache';

const ApproveRequest = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const [pendingUsers, setPendingUsers] = useState([]);
  const { eventId, schoolId } = useParams();
  const updateCache = useUpdateEventsUsersbyEventCache(parseInt(eventId, 10),
    parseInt(schoolId, 10));
  const [fetchEventUsersBySchool, { loading, error }] = useLazyQuery(
    FETCH_EVENT_USERS_BY_SCHOOL, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        updateCache(data.fetchEventUsersBySchool);
        setPendingUsers(data.fetchEventUsersBySchool);
      },
    },
  );
  const extractError = useGraphQLErrorExtractor();

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    if (currentUser) {
      fetchEventUsersBySchool({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchEventUsersBySchool, eventId, schoolId]);

  useHeaderComponentsMutation({
    backLink: `/event/${eventId}/teams/${schoolId}`,
    title: 'PENDING TEAM REQUESTS',
    components: [{ key: EVENT_TITLE, value: event.title }],
  });


  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="mt-4" />
      { pendingUsers.length === 0 ?
        "There are no unapproved users left. Click the arrow on the top to return to the Team Manage page."
      : null}
      <ListItemDetail data={pendingUsers} imageAttached={userDefaultImage} redirectLink={`/event/${eventId}/teams/${schoolId}/approve-requests`} roleTitle="true" dataType="events" />
    </>
  );
};
export default ApproveRequest;
