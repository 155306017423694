import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import { FETCH_EVENT_HEADER } from '../../GraphQL/Queries';
import { UPDATE_EVENT } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';
import {
  LinkButton,
} from '../../Components/Auth/Layout';

const EventEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const { currentUser } = useCurrentUser(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [event, setEvent] = useState({});
  const checkPermission = useUserPermissionChecker(currentUser);

  const { params, onChange, setParams } = useFormParams({
    title: '',
    label: '',
    tenantId: '',
    regionId: '',
    startDate: '',
    endDate: '',
    submissionDeadline: '',
    location: '',
    financialAidFormLink: '',
    discordLink: '',
    volunteerApplicationLink: '',
    judgeApplicationLink: '',
    sponsorApplicationLink: '',
    termsAndConditions: '',
    fee: 0,
    attachment: null,
  });

  const [updateEvent, { loading, error }] = useMutation(
    UPDATE_EVENT,
    {
      onCompleted: () => {
        history.push(`/event-manage/${id}`);
      },
      onError: (errorObj) => {
        setErrorMessage(errorObj.graphQLErrors[0].message);
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: `/event-manage/${id}`,
    title: 'EVENT HEADER',
  });
  const [fetchRegions, { data: regionData }] = useLazyQuery(gql`
  query fetchRegions($userId: Int!){
    fetchRegions(userId: $userId) {
      id
      name
    }
  }
`);
  const regions = getOr([], 'fetchRegions', regionData);
  useEffect(() => {
    if (currentUser) {
      fetchRegions({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchRegions]);

  const [fetchEventDetail] = useLazyQuery(
    FETCH_EVENT_HEADER, {
      onCompleted: (eventData) => {
        setEvent(eventData.fetchEventDetail);
        const deadline = eventData.fetchEventDetail.submissionDeadline;
        setParams({
          ...eventData.fetchEventDetail,
          startDate: eventData.fetchEventDetail.startDate.split(' ')[0],
          endDate: eventData.fetchEventDetail.endDate.split(' ')[0],
          submissionDeadline: deadline ? deadline.split(' ')[0] : deadline,
        });
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update Event') === undefined) {
        history.push('/events');
      }
    }
  }, [currentUser, checkPermission, history]);


  const extractError = useGraphQLErrorExtractor();

  const onSubmit = (e) => {
    params.fee = parseFloat(params.fee, 10);
    if (params.submissionDeadline !== '' && moment(params.submissionDeadline).isAfter(moment(params.endDate))) {
      e.preventDefault();
      setErrorMessage('Submission Deadline cannot be after the End Date.');
    } else if (moment(params.startDate).isAfter(moment(params.endDate))) {
      e.preventDefault();
      setErrorMessage('Start Date cannot be after the End Date.');
    } else {
      e.preventDefault();
      params.regionId = parseInt(params.regionId, 10);
      updateEvent({
        variables: {
          ...params,
          id: parseInt(id, 10),
        },
      });
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error
            ? <GenericAlert>{extractError(error)}</GenericAlert>
            : (
              <>
                {errorMessage
                  ? <GenericAlert>{errorMessage}</GenericAlert>
                  : null}
              </>
            )}
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            regionData={regions}
            eventObj={event}

          />
        </div>
      </div>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <LinkButton href="/events">CANCEL</LinkButton>
        </div>
      </div>
      <hr />
    </>
  );
};
export default EventEdit;
