import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const ReassignCaptainBeforeCancelRegistration = (props) => {
  const {
    toggle,
    modal,
    onClick,
    onSelect,
  } = props;

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Cancel Registration Option</ModalHeader>
      <ModalBody>
        <div className="h6">
          Do you want to cancel Registration Option?
          You are a captain of this team and hence in order
          to cancel your registration, you need to reassign
          captain before continuing.
          Do you want want to reassign captain?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClick}>Yes</Button>
        <Button color="secondary" onClick={onSelect}>No</Button>
      </ModalFooter>
    </Modal>
  );
};

ReassignCaptainBeforeCancelRegistration.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};


export default ReassignCaptainBeforeCancelRegistration;
