import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { UPDATE_COUPON, EXPIRE_COUPON } from '../../GraphQL/Mutations';
import {
  FETCH_COUPON_DETAIL, FETCH_ROLES, FETCH_EVENTS_FROM_USER,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useUpdateCouponsCache from '../../Hooks/useUpdateCouponsCache';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import Form from './Form';
import { Button } from '../../Components/Form';
import DeleteItem from '../../Components/DeleteItem/Index';
import REMOVE_ICON from '../../Images/icons/remove.png';


const CouponEdit = () => {
  const { currentUser } = useCurrentUser(true);
  const history = useHistory();
  const { id } = useParams();
  const [coupon, setCoupon] = useState('');
  const updateCache = useUpdateCouponsCache(getOr(0, 'tenantId', currentUser));
  const checkPermission = useUserPermissionChecker(currentUser);
  const { params, onChange, setParams } = useFormParams({
    code: '',
    maxCodeUsage: '',
    expirationDate: '',
    value: '',
    valuePercentage: false,
  });
  useHeaderComponentsMutation({ title: 'EDIT COUPON' });
  useHeaderComponentsMutation({
    backLink: '/coupons',
    title: 'Edit COUPON',
  });


  const [fetchRoles, { data: roleData }] = useLazyQuery(FETCH_ROLES);
  const roles = getOr([], 'fetchRoles', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchRoles({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRoles]);

  const [fetchEvents, { data: eventData }] = useLazyQuery(FETCH_EVENTS_FROM_USER);
  const events = getOr([], 'fetchEventsFromUser', eventData);
  useEffect(() => {
    if (currentUser) {
      fetchEvents({
        variables: {
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, fetchEvents]);

  const [couponExpire] = useMutation(
    EXPIRE_COUPON,
    {
      onCompleted: () => {
        updateCache(coupon, 'remove');
        history.push('/coupons');
      },
    },
  );

  const expireCoupon = (couponId) => {
    couponExpire({
      variables: {
        id: couponId,
      },
    });
  };

  const extractError = useGraphQLErrorExtractor();
  const [updateCoupon, { loading: updating, error: updateError }] = useMutation(
    UPDATE_COUPON,
    {
      onCompleted: () => {
        history.push('/coupons');
      },
    },
  );
  const { loading, error } = useQuery(
    FETCH_COUPON_DETAIL,
    {
      onCompleted: (data) => {
        const expiryDate = data.fetchCouponDetail.expirationDate;
        setCoupon(data.fetchCouponDetail);
        setParams({
          ...data.fetchCouponDetail,
          expirationDate: expiryDate ? expiryDate.split(' ')[0] : expiryDate,
        });
      },
      variables: { id: parseInt(id, 10) },
    },
  );
  const onSubmit = (e) => {
    params.roleId = parseInt(params.roleId, 10);
    params.eventId = parseInt(params.eventId, 10);
    params.value = parseInt(params.value, 10);
    params.maxCodeUsage = parseInt(params.maxCodeUsage, 10);
    e.preventDefault();
    updateCoupon({
      variables: params,
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update Coupon') === undefined) {
        history.push('/coupons');
      }
    }
  }, [currentUser, checkPermission, history]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {updateError ? <GenericAlert>{extractError(updateError)}</GenericAlert> : null}
          <Form
            setParams={setParams}
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={updating}
            roleData={roles}
            eventData={events}
            formType="edit"
            currentUser={currentUser}
          />
        </div>
      </div>
      <div className="mt-4" />
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <Button htmltype="submit" backgroundcolor="#FFF" fontColor="black" border="1px solid black">VIEW ALL COUPON USES</Button>
        </div>
      </div>
      <hr />
      <DeleteItem
        index={id}
        title="Expire Coupon"
        onClick={() => expireCoupon(params.id)}
        removeicon={REMOVE_ICON}
      />
    </>
  );
};
export default CouponEdit;
