import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  getOr, isEmpty,
} from 'lodash/fp';
import {
  Hr,
} from '../../Components/Auth/Layout';
import {
  CHECK_TEAM_FINALIZE,
  FETCH_EVENT,
  FETCH_EVENT_SCHOOL_COACHES,
  FETCH_EVENT_SCHOOL_STUDENTS,
  SEARCH_EVENT_GUEST_BY_SCHOOL,
  TEAM_CAPTAIN,
  UNPAID_USERS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import { REMOVE_EVENT_USER } from '../../GraphQL/Mutations';
import useTeamMembersCache from '../../Hooks/useTeamMembersCache';
import PopUpModal from '../../Components/PopUpModal';
import ReassignCaptainPopUp from './ReassignCaptainPopUp';
import useUnpaidUserCountCache from '../../Hooks/useUnpaidUserCountCache';

const RemoveUsers = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const history = useHistory();
  const { eventId, schoolId } = useParams();
  const updateCache = useTeamMembersCache(schoolId, eventId);
  const updateUnpaidUserCountCache = useUnpaidUserCountCache(schoolId, eventId);
  const extractError = useGraphQLErrorExtractor();
  const [removeUser, setRemoveUser] = useState(false);
  const [removeMemberId, setRemoveMemberId] = useState('');
  const [popupUserName, setPopUpUserName] = useState('');
  const [modalReassignCaptain, setModalReassignCaptain] = useState(false);
  const [unpaidUsers, setUnpaidUsers] = useState([]);

  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  const [unpaidJoinCompetitions] = useLazyQuery(
    UNPAID_USERS, {
      onCompleted: (response) => {
        const users = response.unpaidJoinCompetitions;
        const numberOfUsers = users.length;
        let userList = [];
        for (let i = 0; i < numberOfUsers; i++) {
          userList.push(users[i].id);
        }
        setUnpaidUsers(userList);
      }
    }
  );

  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );
  const teamFinalize = getOr([], 'checkTeamFinalize', dataCheckTeamFinalize);

  const [fetchEventSchoolCoaches, { data: dataCoaches, loading: loadingCoaches }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_COACHES,
  );

  const teamCoaches = getOr([], 'fetchEventSchoolCoaches', dataCoaches);

  const [fetchEventSchoolStudents, { data: dataStudents, loading: loadingStudents }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_STUDENTS,
  );

  const teamStudents = getOr([], 'fetchEventSchoolStudents', dataStudents);

  const [searchEventGuestBySchool, { data: dataGuests, loading: loadingGuests }] = useLazyQuery(
    SEARCH_EVENT_GUEST_BY_SCHOOL,
  );

  const teamGuests = getOr([], 'searchEventGuestBySchool', dataGuests);

  const [fetchTeamCaptian, { data: dataCaptain, loading: loadingCaptain }] = useLazyQuery(
    TEAM_CAPTAIN,
  );

  // `fetchTeamCaptian` is intentionally misspelled, thanks legacy app
  const teamCaptain = getOr({}, 'fetchTeamCaptian', dataCaptain);

  const toggleRemoveUser = () => setRemoveUser(!toggleRemoveUser);
  const toggleReassignCaptain = () => setModalReassignCaptain(!toggleReassignCaptain);

  useHeaderComponentsMutation({
    title: 'REMOVE USERS',
    backLink: `/event/${eventId}/teams/${schoolId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && eventId) {
      unpaidJoinCompetitions({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, unpaidJoinCompetitions, eventId, schoolId]);

  const [removeEventUser] = useMutation(
    REMOVE_EVENT_USER,
    {
      onCompleted: (data1) => {
        setRemoveUser(false);
        updateCache(data1.removeEventUser.user, 'remove');
        updateUnpaidUserCountCache(data1.removeEventUser.user, 'remove');
      },
    },
  );

  const removeSelectedUser = () => {
    if ([...teamStudents, ...teamCoaches].length > 1) {
      removeEventUser({
        variables: {
          eventUserId: parseInt(removeMemberId, 10),
        },
        refetchQueries: ['fetchEventSchoolCoaches', 'fetchEventSchoolStudents', 'fetchTeamCaptian'],
      });
    }
  };

  const setPopUpUser = (eventUser) => {
    setRemoveMemberId(eventUser.id);
    setPopUpUserName(eventUser.fetchUser.firstname);
  }

  useEffect(() => {
    if (currentUser && eventId) {
      fetchEventSchoolStudents({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, eventId, fetchEventSchoolStudents, schoolId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchEventSchoolCoaches({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, eventId, fetchEventSchoolCoaches, schoolId]);

  useEffect(() => {
    if (currentUser && eventId) {
      searchEventGuestBySchool({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, eventId, searchEventGuestBySchool, schoolId]);

  useEffect(() => {
    if (currentUser && eventId) {
      fetchTeamCaptian({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, eventId, fetchTeamCaptian, schoolId]);

  useEffect(() => {
    if (currentUser) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, event, checkTeamFinalize, eventId, schoolId]);

  const ReassignCaptainPopUpOption = () => {
    history.push(`/event/${eventId}/teams/${schoolId}/reassign-captain`);
  };

  if (loading || userLoading || loadingStudents || loadingCaptain || loadingCoaches || loadingGuests) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }
  if (teamFinalize) {
    return <h3>This team has already been finalized. Please unfinalize before attempting to remove team members.</h3>
  }

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {[...teamStudents, ...teamCoaches, ...teamGuests].map((eventUser) => (
            <>
              <div key={eventUser.id}>
                {!isEmpty(eventUser) &&
                  [...teamStudents, ...teamCoaches].length > 1 &&
                  eventUser.fetchUser.id === teamCaptain.fetchUser.id
                  ? (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => setModalReassignCaptain(true)}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        redirectLink="#"
                      />
                      <ReassignCaptainPopUp
                        modal={modalReassignCaptain}
                        toggle={toggleReassignCaptain}
                        onClick={() => ReassignCaptainPopUpOption()}
                        bodyText="This user is the team captain!
                                    You cannot remove the team captain while there
                                    are other active members of this team. Please
                                    either remove the remaining members first or
                                    reassign captain to another team member and
                                    then come back to remove this user."
                      />
                    </>
                  )
                  : (
                    <>
                      <UserList
                        user={eventUser.fetchUser}
                        onClick={() => { setRemoveUser(true); setPopUpUser(eventUser); }}
                        imageAttached={DefaultImage}
                        removeicon={REMOVE_ICON}
                        redirectLink="#"
                      />
                      <PopUpModal
                        isOpen={removeUser}
                        toggle={toggleRemoveUser}
                        onAccept={() => removeSelectedUser()}
                        onCancel={() => setRemoveUser(false)}
                        headerText="REMOVE USER"
                        bodyText={"Are you sure you want to remove " + popupUserName + "?"}
                        finalTeamMember={[...teamStudents, ...teamCoaches].length === 1}
                        isPaidUser={unpaidUsers.indexOf(removeMemberId) === -1}
                      />
                    </>
                  )}
                <div className="mt-4" />
                <Hr />
              </div>
            </>
          ))}
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default RemoveUsers;
