import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import { FontBold } from '../../Components/Auth/Layout';
import { FETCH_EVENT_DETAIL } from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import { RedirectButton } from '../../Components/Form';
import BackLink from '../../Components/BackLink';


const VolunteerEvent = () => {
  const {
    currentUser,
  } = useCurrentUser(null);
  const { id } = useParams();

  const extractError = useGraphQLErrorExtractor();
  useHeaderComponentsMutation({
    title: 'VOLUNTEER',
    backLink: '/dashboard',
  });

  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  useEffect(() => {
    fetchEventDetail({
      variables: {
        id: parseInt(id, 10),
      },
    });
  }, [fetchEventDetail, id]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <p style={{ display: 'none' }}>{get('firstname', currentUser)}</p>
          <FontBold>
            <p className="mt-4">
              Select Volunteer Type
            </p>
          </FontBold>
        </div>
      </div>
      {event.judgeApplicationLink !== ''
        ? (
          <RedirectButton
            backgroundcolor="#FFF"
            fontColor="#3E6796"
            border="2px solid #3E6796"
            marginbottom="-2rem"
            addNewText="Judge"
            addNewLink={event.judgeApplicationLink}
            permissionName="View Event"
            target="_blank"
          />
        )
        : null}
      {event.volunteerApplicationLink !== ''
        ? (
          <RedirectButton
            backgroundcolor="#FFF"
            fontColor="#3E6796"
            border="2px solid #3E6796"
            marginbottom="-2rem"
            addNewText="Volunteer"
            addNewLink={event.volunteerApplicationLink}
            permissionName="View Event"
            target="_blank"
          />
        )
        : null}
      {event.sponsorApplicationLink !== ''
        ? (
          <RedirectButton
            backgroundcolor="#FFF"
            fontColor="#3E6796"
            border="2px solid #3E6796"
            marginbottom="-2rem"
            addNewText="Sponsor"
            addNewLink={event.sponsorApplicationLink}
            permissionName="View Event"
            target="_blank"
          />
        )
        : null}
      <BackLink to="/">
            Back
      </BackLink>
    </>
  );
};
export default VolunteerEvent;
