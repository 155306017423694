import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import GenericAlert from '../../../Components/GenericAlert';
import Loader from '../../../Components/Loader';
import { EVENT_TITLE } from '../../../Constants/AppConstants';
import {
  FETCH_EVENT_APP_INFO,
} from '../../../GraphQL/Queries';
import useCurrentUser from '../../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../../Hooks/useHeaderComponentsMutation';
import AddResourceRow from './AddResourceRow';
import ExistingResourceRow from './ExistingResourceRow';

const Resources = () => {
  const { currentUser } = useCurrentUser(true);
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const backLink = `/event-manage/${id}`;

  useHeaderComponentsMutation({
    title: 'Resources',
    backLink,
    components: [{ key: EVENT_TITLE, value: event?.title ?? '' }],
  });

  const [fetchEventDetail, { loading: eventLoading }] = useLazyQuery(FETCH_EVENT_APP_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchEventAppDetail: e }) => setEvent(e),
  });

  useEffect(() => {
    if (currentUser) {
      const eventId = parseInt(id, 10);
      fetchEventDetail({
        variables: { id: eventId },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const onResourceCreated = (newResource) => {
    setEvent((existingEvent) => ({
      ...existingEvent,
      resources: [
        ...existingEvent.resources,
        newResource,
      ],
    }));
  };

  const onResourceRemoved = (deletedResource) => {
    setEvent((existingEvent) => ({
      ...existingEvent,
      resources: existingEvent.resources.filter((r) => r.id !== deletedResource.id),
    }));
  };

  const setError = (msg) => {
    setErrorMessage(msg);
    window.scrollTo(0, 0);
  };

  if (eventLoading) {
    return <Loader />;
  }

  const existingResourceTitles = event?.resources?.map((r) => r.title) ?? [];

  return (
    <>
      {errorMessage ? <GenericAlert>{errorMessage}</GenericAlert> : null}
      <div className="row my-2 justify-content-center">
        <AddResourceRow
          eventId={id}
          existingResourceTitles={existingResourceTitles}
          onError={setError}
          onCreate={onResourceCreated}
        />
        {event?.resources?.map((existingResource) => (
          <ExistingResourceRow
            key={existingResource.id}
            resource={existingResource}
            onError={setError}
            onRemoveComplete={onResourceRemoved}
          />
        ))}
      </div>
    </>
  );
};

export default Resources;
