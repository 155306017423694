import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { add, isFuture } from 'date-fns';
import { get, getOr } from 'lodash/fp';
import {
  FETCH_EVENT_HEADER,
  FETCH_REGISTRATION_OPTION_DETAIL,
  FETCH_ORDER_DETAIL,
} from '../../GraphQL/Queries';
import { UPDATE_USER } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFormParams from '../../Hooks/useFormParams';
import Form, { Button } from '../../Components/Form';
import Input from '../../Components/Form/Input';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useRegistrationOptionPermissionChecker from '../../Hooks/useRegistrationOptionPermissionChecker';
import GenericAlert from '../../Components/GenericAlert';
import BackLink from '../../Components/BackLink';
import Loader from '../../Components/Loader';
import Permissions from '../../Constants/PermissionConstrants';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import JoinEventSlider from './JoinEventSlider';
import { raceChoices } from '../../Constants/RaceConstants';

const JoinEventPersonalDetails = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  // they turn 18 sometime in the future
  const isUnder18 = isFuture(add(new Date(currentUser?.dateOfBirth), { years: 18 }));

  const extractError = useGraphQLErrorExtractor();
  const history = useHistory();

  const { orderId } = useParams();
  const [order, setOrder] = useState({});

  const [fetchEventHeader, {
    data: dataFetchEventHeader,
    loading: eventLoading,
  }] = useLazyQuery(FETCH_EVENT_HEADER);

  const event = getOr({}, 'fetchEventDetail', dataFetchEventHeader);

  const [fetchRegistrationOptionDetail, { data: dataRegistrationOption }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const option = getOr({}, 'fetchRegistrationOptionDetail', dataRegistrationOption);
  const checkPermissionOfEventsUser = useRegistrationOptionPermissionChecker(option);

  const [fetchOrderDetail, {
    data: dataFetchOrderDetail,
    loading: orderLoading,
  }] = useLazyQuery(FETCH_ORDER_DETAIL);

  useEffect(() => {
    if (dataFetchOrderDetail?.fetchOrderDetail && order !== {}) {
      const orderData = dataFetchOrderDetail.fetchOrderDetail;
      setOrder(orderData);
      fetchEventHeader({
        variables: {
          id: parseInt(orderData.eventId, 10),
        },
      });
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(orderData.fetchLineItems[0].lineItemAbleId, 10),
        },
      });
    }
  }, [dataFetchOrderDetail, fetchEventHeader, fetchRegistrationOptionDetail, order]);

  const [updateUser, { error, loading }] = useMutation(
    UPDATE_USER,
    {
      onCompleted: () => {
        let path;
        if (checkPermissionOfEventsUser(Permissions.CAN_JOIN_COMPETITION)) {
          path = `/order/${orderId}/competitions`;
        } else if (event?.merchandise?.length > 0) {
          path = `/order/${orderId}/merch`;
        } else {
          path = `/order/${orderId}/cart`;
        }
        history.push(path);
      },
    },
  );


  useHeaderComponentsMutation({
    title: 'PERSONAL DETAILS',
    components: [{ key: EVENT_TITLE, value: get('title', event) }],
  });


  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);

  const { params, onChange, setParams } = useFormParams({
    emergencyContactName: '',
    emergencyContactNumber: '',
    race: '',
  });
  useEffect(() => {
    if (currentUser) {
      setParams({
        ...currentUser,
        gender: parseInt(currentUser.genderValue, 10),
        roleId: parseInt(currentUser.fetchRole.id, 10),
        regionId: parseInt(currentUser.regionId, 10),
        status: 'active',
      });
    }
  }, [currentUser, setParams]);

  const onSubmit = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        ...params,
        tenantId: 1,
      },
    });
  };

  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  if (loading || eventLoading || orderLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={option}
          />
          <p style={{ fontWeight: '600' }}>Please Confirm the Following Details</p>
          <Form onSubmit={onSubmit}>
            <div style={{ margin: 'auto', textAlign: 'center' }} />
            <Input
              type="text"
              title="Emergency Contact Name"
              id="emergencyContactName"
              name="emergencyContactName"
              placeholder="Emergency Contact Name"
              required={isUnder18}
              onChange={onChange}
              withFormGroup
              label="Emergency Contact Name"
              labelClass="sr-only"
              params={params}
            />
            <Input
              title="Emergency Contact Phone Number"
              id="emergencyContactNumber"
              name="emergencyContactNumber"
              placeholder="Emergency Contact Phone Number"
              required={isUnder18}
              onChange={onChange}
              withFormGroup
              label="Emergency Contact Phone Number"
              labelClass="sr-only"
              params={params}
            />
            <Input
              title="Race/Ethnicity"
              name="race"
              type="select"
              params={params}
              id="race"
              required
              onChange={onChange}
              info="For demographics research purposes only"
            >
              <option value="" selected disabled>Select Race/Ethnicity</option>
              {Object.keys(raceChoices).map((key) => (
                <option value={key}>{raceChoices[key]}</option>
              ))}
            </Input>
            <Button
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="2px solid #FFF"
              marginbottom="-2rem"
              addNewText="CONTINUE"
              permissionName="View Event"
              currentUser={currentUser}
              htmltype="submit"
            >
            CONTINUE
            </Button>
            <div className="pt-3" />
          </Form>
          <BackLink to={`/event-manage/${order.eventId}`} imageNotRequired>
                Skip For Now
          </BackLink>
        </div>
      </div>
    </>
  );
};

export default JoinEventPersonalDetails;
