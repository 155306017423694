import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { FETCH_ORDERS_BY_EVENT } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateOrdersCache = (userId, eventId) => {
  const client = useApolloClient();
  const updateOrdersCache = (order, action) => {
    try {
      const data = client.readQuery({
        query: FETCH_ORDERS_BY_EVENT,
        variables: {
          userId: parseInt(userId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
      const { fetchOrdersByEvent } = data;
      let response = [];
      if (action === 'remove') {
        response = remove((o) => o.id === order.id)(fetchOrdersByEvent);
      } else if (action === 'update') {
        const index = fetchOrdersByEvent.findIndex((o) => o.id === order.id);
        response = fetchOrdersByEvent;
        response[index] = order;
      } else if (action === 'add') {
        response = fetchOrdersByEvent;
        response.push(order);
      } else if (action === 'empty') {
        response = [];
      }
      client.writeQuery({
        query: FETCH_ORDERS_BY_EVENT,
        data: {
          fetchOrdersByEvent: response,
        },
        variables: {
          userId: parseInt(userId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateOrdersCache;
};

export default useUpdateOrdersCache;
