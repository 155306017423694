/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { omit } from 'lodash/fp';
import { StyledInput, SearchIcon } from '../Auth/Layout';
import SEARCH from '../../Images/search.png';


const Search = (props) => {
  const {
    withFormGroup,
    children,
  } = props;
  const inputProps = omit(['withFormGroup', 'children'])(props);
  const field = <StyledInput {...inputProps}>{children}</StyledInput>;

  return (
    <>
      {
        withFormGroup
          ? (
            <>
              <FormGroup>{field}</FormGroup>
              <SearchIcon>
                <span><img src={SEARCH} alt="search" /></span>
              </SearchIcon>
            </>
          )
          : (
            <>
              {field}
              <SearchIcon>
                <span><img src={SEARCH} alt="search" /></span>
              </SearchIcon>
            </>
          )
      }
    </>
  );
};

Search.propTypes = {
  withFormGroup: PropTypes.bool,
  children: PropTypes.node,
};

Search.defaultProps = {
  withFormGroup: true,
  children: null,
};

export default Search;
