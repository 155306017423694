/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { omit, getOr } from 'lodash/fp';
import { Input, FormGroup } from 'reactstrap';
import {
  CustomCheckBox, TitleC, Hr, LeftCheckBox,
} from '../Auth/Layout';


const Checkbox = (props) => {
  const {
    withFormGroup,
    name,
    params,
    text,
    subtext,
    onChange,
    checked,
    checkboxAlignment,
  } = props;
  const onValueChange = (e) => {
    onChange(e.target.name, e.target.checked);
  };
  const inputProps = omit(['withFormGroup', 'params'])(props);
  const inputField = (
    <>
      <div className="row align-items-center" style={{ paddingTop: '1rem' }}>
        {checkboxAlignment === 'right' ? (
          <>
            <div className="col-10 mx-auto px-4">
              <div>
                <TitleC>
                  <p>{text}</p>
                </TitleC>
              </div>
              {subtext && (
                <div>
                  <i>
                    {subtext}
                  </i>
                </div>
              )}
            </div>
            <div className="col-2 mx-auto">
              <CustomCheckBox>
                <label className="container">
                  <Input type="checkbox" {...inputProps} onChange={onValueChange} checked={getOr(checked, name, params)} />
                  <span className="checkmark" />
                </label>
              </CustomCheckBox>
            </div>
          </>
        )
          : (
            <>
              <LeftCheckBox>
                <label className="container">
                  <TitleC>
                    <p>{text}</p>
                  </TitleC>
                  <Input type="checkbox" {...inputProps} onChange={onValueChange} checked={getOr(checked, name, params)} />
                  <span className="checkmark" />
                </label>
              </LeftCheckBox>
            </>
          )}
      </div>
      <Hr />
    </>
  );
  return (
    <>
      {
        withFormGroup
          ? (<FormGroup>{inputField}</FormGroup>)
          : inputField
      }
    </>
  );
};

Checkbox.propTypes = {
  withFormGroup: PropTypes.bool,
  name: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  checkboxAlignment: 'left',
};

Checkbox.defaultProps = {
  withFormGroup: true,
  checked: false,
  checkboxAlignment: 'right',
  subtext: null,
};

export default Checkbox;
