import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import triangle from '../Images/icons/triangle.png';

const StyledLink = styled(Link)`
  font-size: 13pt;
  font-weight: 900;
  line-height: 19px;
  color: rgba(148, 148, 148, .9);
`;

const StyledImg = styled.img`
  margin-right: 4px;
`;

const BackLink = ({ to, children, imageNotRequired }) => (
  <div className="text-center mb-5 mt-5">
    <StyledLink to={to}>
      {imageNotRequired
        ? null
        : <StyledImg src={triangle} alt="arrow" className="img-fluid" />}
      {children}
    </StyledLink>
  </div>
);

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  imageNotRequired: PropTypes.bool,
};

BackLink.defaultProps = {
  imageNotRequired: null,
};


export default BackLink;
