import { useApolloClient } from '@apollo/react-hooks';
import { TEAM_CAPTAIN } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateTeamCaptianCache = (eventId, schoolId) => {
  const client = useApolloClient();
  const updateCache = (eventUser) => {
    try {
      client.writeQuery({
        query: TEAM_CAPTAIN,
        data: {
          fetchTeamCaptian: eventUser,
        },
        variables: {
          schoolId: parseInt(schoolId, 10),
          eventId: parseInt(eventId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateTeamCaptianCache;
