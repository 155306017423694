import { useApolloClient } from '@apollo/react-hooks';
import { FETCH_EVENT_COMPETITIONS } from '../GraphQL/Queries';
import logger from '../logger';

const useUpdateEventCompetitionsCache = (tenantId) => {
  const client = useApolloClient();
  const updateCache = (eventCompetitions) => {
    try {
      const data = client.readQuery({
        query: FETCH_EVENT_COMPETITIONS,
        variables: {
          tenantId,
        },
      });
      client.writeQuery({
        query: FETCH_EVENT_COMPETITIONS,
        data: {
          fetchEventCompetitions: [
            { ...eventCompetitions },
            ...data.fetchEventCompetitions,
          ],
        },
        variables: {
          tenantId,
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useUpdateEventCompetitionsCache;
