import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { CREATE_REGION } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUpdateRegionsCache from '../../Hooks/useUpdateRegionsCache';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';


const RegionNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateRegionsCache(getOr(0, 'id', currentUser));
  const [createRegion, { loading, error }] = useMutation(
    CREATE_REGION,
    {
      onCompleted: (data) => {
        updateCache(data.createRegion.region);
        history.goBack();
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: '/regions',
    title: 'ADD NEW REGION',
  });
  const { params, onChange, setParams } = useFormParams({
    name: '',
    tier: '',
    stripeAccount: '',
    currency: 'USD',
    websiteUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    email: '',
  });
  const extractError = useGraphQLErrorExtractor();

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Region') === undefined) {
        history.push('/regions');
      }
    }
  }, [currentUser, checkPermission, history]);

  const onSubmit = (e) => {
    e.preventDefault();
    params.tier = parseInt(params.tier, 10);
    params.tier = parseInt(params.tierValue, 10);
    createRegion({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
        userIds: [1],
      },
    });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            className="pt-3"
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            setParams={setParams}
            formType="new"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default RegionNew;
