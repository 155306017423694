import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { FETCH_CATEGORY_DETAIL } from '../../GraphQL/Queries';
import { UPDATE_CATEGORY } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Form from './Form';

const CategoryEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const { currentUser } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const [updateCategory, { loading, error }] = useMutation(
    UPDATE_CATEGORY,
    {
      onCompleted: () => {
        history.goBack();
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: '/categories',
    title: 'categories',
  });
  const { params, onChange, setParams } = useFormParams({
    title: '',
  });
  const extractError = useGraphQLErrorExtractor();

  const [fetchCategoryDetail] = useLazyQuery(
    FETCH_CATEGORY_DETAIL, {
      onCompleted: (data) => {
        setParams(data.fetchCategoryDetail);
      },
    },
  );


  const onSubmit = (e) => {
    e.preventDefault();
    updateCategory({
      variables: {
        ...params,
        id: parseInt(id, 10),
      },
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Update Category') === undefined) {
        history.push('/categories');
      }
    }
  }, [currentUser, checkPermission, history]);

  useEffect(() => {
    if (currentUser) {
      fetchCategoryDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchCategoryDetail, id]);

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default CategoryEdit;
