import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_FROM_REGION, SEARCH_REGION_USERS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import GenericAlert from '../../Components/GenericAlert';
import List from '../../Components/List/Index';
import InputButton from '../../Components/Form/Button';
import userDefaultImage from '../../Images/team-dum.png';
import Search from '../../Components/Search/Index';

const UserIndex = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const extractError = useGraphQLErrorExtractor();
  const [filteredUsers, setFilteredUsers] = useState([]);
  useHeaderComponentsMutation({
    title: 'USERS',
    backLink: '/dashboard',
  });

  const [fetchUsers, { loading, error }] = useLazyQuery(
    USER_FROM_REGION, {
      onCompleted: (data) => {
        setFilteredUsers(data.userFromRegion);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchUsers({
        variables: {
          regionIds: currentUser.fetchRegionsUsers.map((obj) => obj.regionId),
        },
      });
    }
  }, [currentUser, fetchUsers]);

  const [usersSearch] = useLazyQuery(
    SEARCH_REGION_USERS,
    {
      onCompleted: (data) => {
        setFilteredUsers(data.searchRegionUsers);
      },
    },
  );

  const searchUsers = (e) => {
    usersSearch({
      variables: {
        query: e.target.value,
        regionIds: currentUser.fetchRegionsUsers.map((obj) => obj.regionId),
      },
    });
  };


  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }
  return (
    <div className="row py-5">
      <div className="col-12 mx-auto px-4">
        <Link to="/invite-existing-user">
          <InputButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none">Add New User</InputButton>
        </Link>
        <div className="pt-3">
          <Search placeholder="Search" onChange={(e) => searchUsers(e)} />
        </div>
        <div className="pt-3" />
        <List data={filteredUsers} displayAttribute="firstname" redirectLink="users" addNewLink="" redirectOperation="view" addNewText="" addOperationRequired={false} imageAttached={userDefaultImage} />
      </div>
    </div>
  );
};

export default UserIndex;
