import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { UNPAID_USERS } from '../GraphQL/Queries';
import logger from '../logger';

const useTeamMembersCache = (schoolId, eventId) => {
  const client = useApolloClient();
  const updateCache = (user, action = 'add') => {
    try {
      const data = client.readQuery({
        query: UNPAID_USERS,
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
      const { unpaidJoinCompetitions } = data;
      let response = [];
      if (action === 'add') {
        response = unpaidJoinCompetitions.unshift(user);
      } else {
        response = remove((u) => parseInt(u.userId, 10)
          === parseInt(user.id, 10))(unpaidJoinCompetitions);
      }
      client.writeQuery({
        query: UNPAID_USERS,
        data: {
          unpaidJoinCompetitions: response,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useTeamMembersCache;
