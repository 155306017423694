import React from 'react';
import PropTypes from 'prop-types';
import Form, { Input, PermissionButton, Checkbox } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const RolesForm = ({
  params, onSubmit, onChange, loading, isEdit, formType, currentUser,
}) => {
  const checkPermission = useUserPermissionChecker(currentUser);
  return (
    <Form onSubmit={onSubmit}>
      {!isEdit
        ? (
          <Input
            title="Title"
            label="Title"
            name="title"
            id="title"
            placeholder="Title"
            required
            onChange={onChange}
            params={params}
            maxLength="30"
            pattern="[a-zA-Z\s]+"
          />
        )
        : null}
      <Input
        params={params}
        title="Level"
        type="number"
        id="level"
        name="level"
        placeholder="Enter Level"
        onChange={onChange}
      />
      <Checkbox
        id="allowInvitation"
        name="allowInvitation"
        params={params}
        onChange={onChange}
        text="Allow Invitation"
        checked={params.allowInvitation === 'true'}
      />
      <Input
        title="Terms and Conditions"
        label="Terms and Conditions"
        name="termsAndConditions"
        id="termsAndConditions"
        placeholder="Terms and Conditions"
        required
        onChange={onChange}
        params={params}
        type="textarea"
        maxLength="100000"
        pattern=".*\S+.*"
        rows="10"
      />
      {formType === 'new'
        ? <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Add Role'))}>SUBMIT</PermissionButton>
        : <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Update Role'))}>SUBMIT</PermissionButton>}
    </Form>
  );
};

RolesForm.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

RolesForm.defaultProps = {
  loading: false,
  isEdit: false,
};

export default RolesForm;
