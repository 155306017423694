import { useApolloClient } from '@apollo/react-hooks';
import { remove } from 'lodash/fp';
import { INVITED_USERS } from '../GraphQL/Queries';
import logger from '../logger';

const useInviteUsersCache = (id, eventId) => {
  const client = useApolloClient();
  const updateCache = (user, action = 'add') => {
    try {
      const data = client.readQuery({
        query: INVITED_USERS,
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(id, 10),
        },
      });
      const { userInvitationList } = data;
      let response = [];
      if (action === 'add') {
        response = userInvitationList.unshift(user);
      } else {
        response = remove((u) => parseInt(u.id, 10) === parseInt(user.id, 10))(userInvitationList);
      }
      client.writeQuery({
        query: INVITED_USERS,
        data: {
          userInvitationList: response,
        },
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(id, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateCache;
};

export default useInviteUsersCache;
