import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { format, utcToZonedTime } from 'date-fns-tz';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';
import { DELETE_ANNOUNCEMENT } from '../../../GraphQL/Mutations';
import REMOVE_ICON from '../../../Images/icons/remove.png';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const IconButton = styled.button`
background: transparent;
border: none;
`;

const getNameFromAnnouncement = (announcement) => {
  switch (announcement.targetType) {
    case 'school': return announcement?.fetchSchools[0]?.name;
    case 'competition': return announcement?.fetchCompetitions[0]?.title;
    case 'role': return announcement?.fetchRoles[0]?.title;
    default: return '';
  }
};

const ExistingAnnouncementRow = ({ announcement, onRemoveComplete, onError }) => {
  const extractError = useGraphQLErrorExtractor();
  const [deleteAnnouncement, { loading }] = useMutation(
    DELETE_ANNOUNCEMENT,
    {
      onCompleted: () => {
        onRemoveComplete(announcement.id);
      },
      onError: (err) => {
        onError(extractError(err));
      },
    },
  );

  const onRemove = () => {
    deleteAnnouncement({
      variables: {
        announcementId: announcement.id,
      },
    });
  };

  const createdAtStr = format(utcToZonedTime(announcement.createdAt, USER_LONG_TZ),
    DISPLAY_DATE_FORMAT);

  return (
    <div className="col-10 mx-2 mt-2" style={{ border: '1px solid #F4AB37' }}>
      <div className="row my-2 justify-content-center align-items-center">
        <div className="col m-4">
          {announcement.title}
        </div>
        <div className="col m-4">
          {announcement.targetType}
        </div>
        <div className="col m-4">
          {getNameFromAnnouncement(announcement)}
        </div>
        <div className="col m-4">
          {announcement?.link && (<a href={announcement?.link} target="_blank" rel="noopener noreferrer">{announcement.link}</a>)}
        </div>
        <div className="col m-4">
          {createdAtStr}
        </div>
        {announcement.body && (
        <div className="col-8 m-4">
          <p
            style={{
              width: '100%',
              color: 'rgb(84, 84, 84)',
              background: 'rgba(239, 239, 239, 0.3)',
              border: '1px solid rgba(118, 118, 118, 0.3)',
              padding: '4px',
            }}
          >
            {announcement.body.split('\n').map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </p>
        </div>
        )}
        <div className="col-1 px-2">
          <div className="row justify-content-center align-items-center">
            {loading ? (
              <i className="fa fa-spinner" aria-label="loading" />
            ) : (
              <IconButton onMouseDown={onRemove} onTouchEnd={onRemove} type="button">
                <img src={REMOVE_ICON} alt="remove resource" />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExistingAnnouncementRow.propTypes = {
  announcement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    link: PropTypes.string,
    targetType: PropTypes.string.isRequired,
    fetchSchools: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onRemoveComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ExistingAnnouncementRow;
