import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Form, { Input, PermissionButton, Checkbox } from '../../Components/Form';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerInput, TitleC } from '../../Components/Auth/Layout';

const CouponForm = ({
  params,
  onSubmit,
  onChange,
  loading,
  setParams,
  roleData,
  formType,
  currentUser,
  eventData,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const checkPermission = useUserPermissionChecker(currentUser);

  const updateDate = (date) => {
    if (currentUser) {
      setSelectedDate(date);
      setParams({
        ...params,
        expirationDate: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input title="Event" name="eventId" type="select" params={params} id="eventId" required onChange={onChange}>
        <option value="" disabled>Select</option>
        {eventData && eventData.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.title}</option>
        ))}
      </Input>
      <Input title="Role" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
        <option value="" disabled>Roles</option>
        {roleData && roleData.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.title}</option>
        ))}
      </Input>
      <Input
        title="Code"
        name="code"
        id="code"
        placeholder="Code"
        required
        onChange={onChange}
        params={params}
        pattern=".*\S+.*"
      />
      <Input
        title="Max Code Users"
        label="Max Code Users"
        name="maxCodeUsage"
        id="maxCodeUsage"
        placeholder="Max Code Users"
        type="number"
        required
        onChange={onChange}
        params={params}
        withFormGroup
        pattern=".*\S+.*"
      />
      <p>
        <TitleC>
Expiration Date
          {' '}
          <span className="text-danger">*</span>
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          value={params.expirationDate}
          selected={selectedDate}
          onChange={(date) => updateDate(date)}
          dateFormat="MM/dd/yyyy"
          required
          placeholderText="mm/dd/yyyy"
        />
      </DatePickerInput>
      <hr />
      <Input
        title="Value"
        label="Value"
        name="value"
        id="value"
        type="number"
        placeholder="Value"
        required
        onChange={onChange}
        params={params}
        withFormGroup
        pattern=".*\S+.*"
      />
      <Checkbox
        title="Value is a percentage (%)"
        id="valuePercentage"
        name="valuePercentage"
        params={params}
        onChange={onChange}
        text="Value is a percentage (%)"
        setParams={setParams}
        checkboxAlignment="left"
      />
      {formType === 'new'
        ? <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Add Coupon'))}>SUBMIT</PermissionButton>
        : <PermissionButton loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('Update Coupon'))}>SUBMIT</PermissionButton>}
    </Form>
  );
};

CouponForm.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setParams: PropTypes.func.isRequired,
  roleData: PropTypes.instanceOf(Array).isRequired,
  eventData: PropTypes.instanceOf(Array).isRequired,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

CouponForm.defaultProps = {
  loading: false,
};

export default CouponForm;
