import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import {
  GoogleMap, Marker, useLoadScript,
} from '@react-google-maps/api';
import useGraphQLErrorExtractor from '../../../Hooks/useGraphQLErrorExtractor';
import { DELETE_LOCATION } from '../../../GraphQL/Mutations';
import REMOVE_ICON from '../../../Images/icons/remove.png';

const IconButton = styled.button`
background: transparent;
border: none;
`;

const containerStyle = {
  width: '700px',
  height: '400px',
};

const libraries = ['places'];

const DEFAULT_LATITUDE = 39.9506385;
const DEFAULT_LONGITUDE = -75.1496731;

const ExistingLocationMap = ({
  locations, locationsUsedOnSchedule, onRemoveComplete, onError,
}) => {
  const [locationToDelete, setLocationToDelete] = useState('');
  const latitude = locations[0]?.latitude ?? DEFAULT_LATITUDE;
  const longitude = locations[0]?.longitude ?? -DEFAULT_LONGITUDE;
  const centerMap = {
    lat: latitude,
    lng: longitude,
  };

  const extractError = useGraphQLErrorExtractor();
  const [deleteLocation] = useMutation(
    DELETE_LOCATION,
    {
      onCompleted: () => {
        onRemoveComplete(locationToDelete.id);
        setLocationToDelete('');
      },
      onError: (err) => {
        setLocationToDelete('');
        onError(extractError(err));
      },
    },
  );

  const onRemove = (location) => {
    if (locationsUsedOnSchedule.includes(location.id)) {
      onError('Location could not be deleted because a scheduled event is currently using it.'
          + ' Please delete the location from the event first and then try again.');
    } else {
      setLocationToDelete(location);
      deleteLocation({
        variables: {
          locationId: location.id,
        },
      });
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  return isLoaded ? (
    <div className="col-10 mx-2">
      <h2>Existing Locations:</h2>
      <div className="row my-4 justify-content-center">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={centerMap}
          zoom={10}
        >
          <div>
            {locations.map((location) => (
              <Marker position={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              />
            ))}
          </div>
          <></>
        </GoogleMap>
      </div>
      <div className="row overflow-auto h-25">
        {locations.map((location) => (
          <div className="col-12">
            <div className="row my-1">
              <div className="col">
                {location.name}
              </div>
              <div className="col">
                <IconButton onMouseDown={() => onRemove(location)} onTouchEnd={() => onRemove(location)} type="button">
                  <img src={REMOVE_ICON} alt="remove resource" />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : <></>;
};

ExistingLocationMap.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  })).isRequired,
  locationsUsedOnSchedule: PropTypes.arrayOf(PropTypes.number).isRequired,
  onRemoveComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ExistingLocationMap;
