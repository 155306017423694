import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { utcToZonedTime } from 'date-fns-tz';
import { UPDATE_REGISTRATION_OPTION, DELETE_REGISTRATION_OPTION } from '../../GraphQL/Mutations';
import { FETCH_ROLES, FETCH_REGISTRATION_OPTION_DETAIL } from '../../GraphQL/Queries';
import {
  FormTitle,
  LinkButton,
} from '../../Components/Auth/Layout';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useFormParams from '../../Hooks/useFormParams';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUpdateRegistrationOptionsCache from '../../Hooks/useUpdateRegistrationOptionsCache';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Form from './RegistrationOptionsForm';
import DeleteItem from '../../Components/DeleteItem/Index';
import REMOVE_ICON from '../../Images/icons/remove.png';

const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const stringifyDate = (d) => utcToZonedTime(d, USER_LONG_TZ).toISOString();

const EVENT_INFO = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      feePercentage
    }
  }
`;

const RegistrationOptionsEdit = () => {
  const history = useHistory();
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const extractError = useGraphQLErrorExtractor();
  const [fetchRoles, { data: roleData }] = useLazyQuery(FETCH_ROLES);

  const { params, onChange, setParams } = useFormParams({
    id: '',
    title: '',
    fee: '',
    lateFee: '',
    roleId: '',
    capacity: '',
    earlyDeadline: '',
    finalDeadline: '',
    eventId: '',
    minAge: '',
    maxAge: '',
    waitlistFormUrl: '',
  });

  const updateCache = useUpdateRegistrationOptionsCache(getOr(0, 'tenantId', currentUser), parseInt(params.eventId, 10));

  const { data: eventData, loading: eventLoading } = useQuery(EVENT_INFO, {
    variables: {
      id: params.eventId,
    },
    skip: !params.eventId,
  });

  const roles = getOr([], 'fetchRoles', roleData);
  useEffect(() => {
    if (currentUser) {
      fetchRoles({
        variables: {
          tenantId: currentUser.tenantId,
        },
      });
    }
  }, [currentUser, fetchRoles]);

  useHeaderComponentsMutation({ title: 'Registration Options', backLink: `/events/${params.eventId}/registration-options` });

  const [inviteUser, { loading, error }] = useMutation(UPDATE_REGISTRATION_OPTION, {
    onCompleted: () => {
      history.push(`/events/${params.eventId}/registration-options`);
    },
  });


  const [fetchRegistrationOptionDetail] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL, {
      onCompleted: (data) => {
        setParams({
          ...data.fetchRegistrationOptionDetail,
          // need to pass these through a round of stringifying them in case
          // the user doesn't touch them
          // we don't want to send badly-formatted dates to the backend
          earlyDeadline: stringifyDate(data.fetchRegistrationOptionDetail.earlyDeadline),
          finalDeadline: stringifyDate(data.fetchRegistrationOptionDetail.finalDeadline),
        });
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      fetchRegistrationOptionDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchRegistrationOptionDetail, id]);


  const onSubmit = (e) => {
    e.preventDefault();
    params.roleId = parseInt(params.roleId, 10);
    params.lateFee = parseFloat(params.lateFee);
    params.fee = parseFloat(params.fee);
    params.capacity = params.capacity ? parseInt(params.capacity, 10) : 0;
    params.minAge = parseInt(params.minAge, 10);
    params.maxAge = parseInt(params.maxAge, 10);
    inviteUser({
      variables: {
        ...params,
      },
    });
  };

  const [deleteRegistrationOption] = useMutation(
    DELETE_REGISTRATION_OPTION,
    {
      onCompleted: (data) => {
        updateCache(data.deleteRegistrationOption.event.fetchRegistrationOptions);
        history.push(`/events/${params.eventId}/registration-options`);
      },
    },
  );

  const removeRegistrationOption = (registrationOptionId) => {
    deleteRegistrationOption({
      variables: {
        id: parseInt(registrationOptionId, 10),
      },
    });
  };

  const onCancel = () => {
    history.goBack();
  };

  if (eventLoading || !eventData) {
    return null;
  }

  const event = eventData?.fetchEventDetail;

  return (
    <>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <div className="column pt-3">
        <FormTitle color="#000">Registration Options</FormTitle>
      </div>
      <Form
        className="pt-3"
        onSubmit={onSubmit}
        onChange={onChange}
        params={params}
        loading={loading}
        setParams={setParams}
        roles={roles}
        event={event}
      />
      <DeleteItem
        index={id}
        title="DELETE REGISTRATION OPTION"
        onClick={() => removeRegistrationOption(params.id)}
        removeicon={REMOVE_ICON}
      />
      <div className="row align-items-center pt-3">
        <div className="col-12 mx-auto">
          <LinkButton onClick={() => onCancel()}>Back</LinkButton>
        </div>
      </div>
    </>
  );
};
export default RegistrationOptionsEdit;
