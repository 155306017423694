import React from 'react';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@chakra-ui/react';

import { SchedulePropType } from '../EventSchedules/propTypes';
import { SCHEDULE_BORDER_COLOR } from '../EventSchedules/utils';

const DISPLAY_TIME_FORMAT = 'hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ScheduleInfo = ({ schedule, canEdit, onEdit }) => {
  const startTime = utcToZonedTime(schedule.startTime, USER_LONG_TZ);
  const endTime = utcToZonedTime(schedule.endTime, USER_LONG_TZ);

  return (
    <>
      <Flex>
        <Heading size="sm">
          {schedule.title}
        </Heading>
        <Spacer />
        {canEdit && <Button variant="link" onClick={onEdit}>edit</Button>}
      </Flex>
      <Text size="sm">
        {schedule.description.trim()}
      </Text>
      <Flex gap="64px">
        <Text>
          {format(startTime, DISPLAY_TIME_FORMAT)}
          {' - '}
          {format(endTime, DISPLAY_TIME_FORMAT)}
          {' | '}
          {formatDistance(endTime, startTime)}
        </Text>
        <Flex direction="column">
          <Text>
            {schedule.fetchLocation.name}
            {schedule.room.trim() !== '' ? ` - ${schedule.room}` : null}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
ScheduleInfo.propTypes = {
  schedule: SchedulePropType.isRequired,
  onEdit: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export const ScheduleCard = React.forwardRef(({
  schedule,
}, ref) => (
  <Box
    sx={{
      borderLeftStyle: 'solid',
      borderLeftColor: SCHEDULE_BORDER_COLOR[schedule.type],
      borderLeftWidth: '8px',
      borderRadius: '8',
      background: '#f2f2f2',
    }}
    width="100%"
    padding="16px"
    ref={ref}
  >
    <ScheduleInfo schedule={schedule} />
  </Box>
));
ScheduleCard.propTypes = {
  schedule: SchedulePropType.isRequired,
};
