import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import { FETCH_EVENT, INCOMPLETE_COMPETITION } from '../../GraphQL/Queries';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';
import {
  FontBold,
} from '../../Components/Auth/Layout';

const CompetitionIncompleted = () => {
  const { id } = useParams();
  const { data: event } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const { data: comp } = useQuery(
    INCOMPLETE_COMPETITION,
    {
      variables: { eventId: parseInt(id, 10) },
      fetchPolicy: 'network-only',
    },
  );
  const competetions = getOr([], 'inprogressCompetitions', comp);
  useHeaderComponentsMutation({
    title: 'SCORING PROGRESS',
    backLink: `/events/${id}/score-progress`,
    components: [{ key: EVENT_TITLE, value: get('fetchEventDetail.title', event) }],
  });

  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-12 mx-auto px-4">
          <p className="font-weight-bold pt-3 ml-n3 mt-n2 h5"> Incomplete Competitions</p>
          {competetions && competetions.map((item) => (
            <>
              <FontBold>
                {get('title', item)}
              </FontBold>
              {getOr([], 'inprogress', item).map((list) => (
                <>
                  <Link to={`/events/${id}/scoring/${list.id}`}>
                    <div className="row px-2">
                      <div className="col-md-11 col-10 text-dark pt-2">
                        {get('title', list)}
                      </div>
                      <div className="col-md-1 col-2 mt-4">
                        <img src={RIGHT_ARROW} alt="arrow" className="img-fluid mt-n5" />
                      </div>
                    </div>
                  </Link>
                  <hr className="border mt-n2" />
                </>
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CompetitionIncompleted;
