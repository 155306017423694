import { getOr, first } from 'lodash/fp';

const useEventsUserPermissionChecker = (eventsUser) => {
  const checkPermissionofEventsUser = (permissionName) => {
    const permissions = getOr([], 'fetchRole.permissions', eventsUser);
    return Boolean(first(permissions.filter((permission) => permission.title === permissionName)));
  };

  return checkPermissionofEventsUser;
};

export default useEventsUserPermissionChecker;
