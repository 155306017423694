/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { getOr, get } from 'lodash/fp';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {
  FontBold,
  ScoreButton,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_DETAIL,
  FETCH_REG_SPREADSHEET_CAPTAINS,
  FETCH_REG_SPREADSHEET_COACHES,
  FETCH_REG_SPREADSHEET_GUESTS,
  FETCH_REG_SPREADSHEET_STUDENTS,
  FETCH_REG_SPREADSHEET_TEAMS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import GenericAlert from '../../Components/GenericAlert';
import ListItem from '../../Components/ListItem/Index';
import Loader from '../../Components/Loader';
import Permissions from '../../Constants/PermissionConstrants';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const formatResponse = (registrationQuery) => {
  const numberOfUsers = registrationQuery.length;
  const formattedResponse = [];
  for (let i = 0; i < numberOfUsers; i++) {
    const user = registrationQuery[i];
    const userDetails = {};
    const { fetchUser, fetchSchool, fetchOrder } = user;

    userDetails.mistId = fetchUser.code;
    userDetails.firstName = fetchUser.firstname;
    userDetails.lastName = fetchUser.lastname;
    userDetails.gender = fetchUser.gender;
    userDetails.email = fetchUser.email;
    userDetails.phone = fetchUser.phone;
    userDetails.school = fetchSchool?.name;
    userDetails.registrationOption = user.fetchRegistrationOption?.title;
    userDetails.status = user.status;
    userDetails.fee = fetchOrder?.amount / 100;
    userDetails.paymentStatus = fetchOrder?.status;
    userDetails.region = fetchUser.defaultRegion?.name;
    userDetails.emergencyContactName = fetchUser.emergencyContactName ?? '';
    userDetails.emergencyContactNumber = fetchUser.emergencyContactNumber ?? '';
    userDetails.shirtSize = fetchUser.shirtSize ?? '';
    userDetails.dateOfBirth = fetchUser.dateOfBirth;
    userDetails.registrationDate = user.createdAt;

    let registrationStatus = user.status;
    // paymentStatus uses "complete" and "pending" while registrationStatus
    // uses "active" and "pending". Changing for consistency.
    if (registrationStatus === 'active') {
      registrationStatus = 'complete';
    }
    userDetails.status = registrationStatus;

    formattedResponse.push(userDetails);
  }
  return formattedResponse;
};

const RegistrationReport = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { ExcelFile } = ReactExport;
  const { ExcelColumn, ExcelSheet } = ReactExport.ExcelFile;
  const { id } = useParams();
  const extractError = useGraphQLErrorExtractor();

  const [captainData, setCaptainData] = useState([]);
  const [coachData, setCoachData] = useState([]);
  const [competitorData, setCompetitorData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [teamData, setTeamData] = useState({});

  const checkPermission = useUserPermissionChecker(currentUser);

  const [fetchEventDetail, { data, loading, error }] = useLazyQuery(FETCH_EVENT_DETAIL);
  const event = getOr({}, 'fetchEventDetail', data);

  const [eventCaptains, { loading: captainsLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_CAPTAINS, {
      onCompleted: (response) => {
        const captains = response.eventCaptains;
        const numberOfCaptains = captains.length;
        const captainList = [];
        for (let i = 0; i < numberOfCaptains; i++) {
          const captain = captains[i];
          const mistId = captain.fetchUser.code;
          captainList.push(mistId);
        }
        setCaptainData(captainList);
      },
    },
  );

  const [eventCoaches, { loading: coachesLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_COACHES, {
      onCompleted: (response) => {
        const formattedResponse = formatResponse(response.eventCoaches);
        setCoachData(formattedResponse);
      },
    },
  );

  const [eventGuests, { loading: guestsLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_GUESTS, {
      onCompleted: (response) => {
        const formattedResponse = formatResponse(response.eventGuests);
        setGuestData(formattedResponse);
      },
    },
  );

  const [eventStudents, { loading: studentsLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_STUDENTS, {
      onCompleted: (response) => {
        const formattedResponse = formatResponse(response.eventStudents);
        setCompetitorData(formattedResponse);
      },
    },
  );

  const [eventSchoolTeams, { loading: teamsLoading }] = useLazyQuery(
    FETCH_REG_SPREADSHEET_TEAMS, {
      onCompleted: (response) => {
        const teams = response.eventSchoolTeams;
        const numberOfTeams = teams.length;
        const teamDictionary = {};
        for (let i = 0; i < numberOfTeams; i++) {
          const team = teams[i];
          const teamId = team.code;
          const schoolName = team.school.name;
          teamDictionary[schoolName] = teamId;
        }
        setTeamData(teamDictionary);
      },
    },
  );

  useHeaderComponentsMutation({
    title: 'REGISTRATION REPORT',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && id) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventCaptains({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, eventCaptains, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventCoaches({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, eventCoaches, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventGuests({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, eventGuests, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventStudents({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, eventStudents, id]);

  useEffect(() => {
    if (currentUser && id) {
      eventSchoolTeams({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, eventSchoolTeams, id]);

  // eslint-disable-next-line max-len
  if (loading || userLoading || captainsLoading || coachesLoading || guestsLoading || studentsLoading || teamsLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  // need to define these up here because ExcelSheet doesn't work with null children
  // so we need to filter them out here before putting them underneath ExcelSheet
  const columns = [
    <ExcelColumn label="Registration Date" value="registrationDate" />,
    <ExcelColumn label="MIST ID" value="mistId" />,
    <ExcelColumn label="First Name" value="firstName" />,
    <ExcelColumn label="Last Name" value="lastName" />,
    <ExcelColumn label="Gender" value="gender" />,
    <ExcelColumn label="Email" value="email" />,
    <ExcelColumn label="Phone" value="phone" />,
    <ExcelColumn label="Emergency Contact Name" value="emergencyContactName" />,
    <ExcelColumn label="Emergency Contact Number" value="emergencyContactNumber" />,
    <ExcelColumn label="School" value="school" />,
    <ExcelColumn label="Date of Birth" value="dateOfBirth" />,
    <ExcelColumn label="T-Shirt Size" value="shirtSize" />,
    event?.qualifiedAttendingPreviousEvent ? <ExcelColumn label="Region" value="region" /> : null,
    <ExcelColumn label="Registration Option" value="registrationOption" />,
    <ExcelColumn label="Captain" value="captain" />,
    <ExcelColumn label="Registration Status" value="status" />,
    <ExcelColumn label="Registration Fee" value="fee" />,
    <ExcelColumn label="Payment Status" value="paymentStatus" />,
  ].filter((c) => c !== null);

  const sheetData = [...competitorData, ...guestData, ...coachData]
    .sort((user1, user2) => user1.registrationDate.localeCompare(user2.registrationDate))
    .map((user) => ({
      ...user,
      captain: captainData.indexOf(user.mistId) >= 0,
      mistId: teamData[user.school] ? `${teamData[user.school]}-${user.mistId}` : user.mistId,
    }));

  return (
    <>
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <div className="row">
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Total Users
                </p>
              </FontBold>
              <h5 className="text-center">
                {[...competitorData, ...guestData, ...coachData].length}
              </h5>
            </div>
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Approved Users
                </p>
              </FontBold>
              <h5 className="text-center">
                {[...competitorData, ...guestData, ...coachData].filter((user) => user.status === 'complete').length}
              </h5>
            </div>
            <div className="col-4">
              <FontBold>
                <p className="mt-4 text-center">
                  Paid Users
                </p>
              </FontBold>
              <h5 className="text-center">
                {[...competitorData, ...guestData, ...coachData].filter((user) => user.paymentStatus === 'complete').length}
              </h5>
            </div>
          </div>
          <div className="pt-3 mb-2">
            <ExcelFile
              filename={`${get('title', event)}_registration (${moment().format('YYYY-MM-DD')})`}
              element={<ScoreButton>EXPORT REPORT</ScoreButton>}
            >
              <ExcelSheet
                data={sheetData}
                name="Registration Data"
              >
                {columns}
              </ExcelSheet>
            </ExcelFile>
          </div>
          <div>
            <FontBold className="pt-3">
              <p>Users</p>
            </FontBold>
            <ListItem title="Students" redirectLink={`/event/${id}/students`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} isCount userCount={competitorData.length} />
            <ListItem title="Coaches" redirectLink={`/event/${id}/coaches`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} isCount userCount={coachData.length} />
            <ListItem title="Guests" redirectLink={`/event/${id}/guests`} checkPermission={(checkPermission(Permissions.CAN_VIEW_EVENT))} isCount userCount={guestData.length} />
          </div>
        </div>
      </div>
    </>
  );
};
export default RegistrationReport;
