import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { get, getOr } from 'lodash/fp';
import {
  FontBold, AddStudentButton,
} from '../../Components/Auth/Layout';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Search from '../../Components/Search/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import { FETCH_EVENT_ADMINS } from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserSearch from '../../Hooks/useUserSearch';
import userDefaultImage from '../../Images/icons/userIcon.png';
import UserList from '../../Components/UserList/Index';
import RIGHT_ARROW from '../../Images/icons/right-color-arrow.png';

const Admins = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const searchList = useUserSearch();
  const [searchAdmins, setSearchAdmins] = useState([]);
  const [fetchStudents, { data: adminsData }] = useLazyQuery(
    FETCH_EVENT_ADMINS, {
      onCompleted: (data) => {
        setSearchAdmins(data.eventAdmins);
      },
    },
  );
  const admins = getOr([], 'eventAdmins', adminsData);
  useEffect(() => {
    if (currentUser) {
      fetchStudents({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchStudents, id]);

  const searchAdmin = (e) => {
    if (e.target.value === '') {
      setSearchAdmins(admins);
    } else {
      setSearchAdmins(
        searchList(searchAdmins, e.target.value),
      );
    }
  };
  useHeaderComponentsMutation({
    title: 'ADMINS',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: get('[0].fetchEvent.title', admins) }],
  });

  return (
    <>
      <div className="mt-4" />
      {/* This block is hidden beacuse this functionality will be added in the next sprint */}
      <div className="container d-none">
        <div className="row">
          <AddStudentButton className="col-md-6 col-6">
            <button className="btn text-white text-small" type="button">
              <small className="font-weight-bold">ADD EXISTING USER</small>
            </button>
          </AddStudentButton>
          <AddStudentButton className="col-md-6 col-6">
            <button className="btn text-white text-small" type="button">
              <small className="font-weight-bold">ADD NEW USER</small>
            </button>
          </AddStudentButton>
        </div>
      </div>
      <div className="mt-4" />
      <Search
        placeholder="Search"
        onChange={searchAdmin}
      />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          <FontBold>
            <p className="mt-4">
              Admins(
              {searchAdmins.length}
)
            </p>
          </FontBold>
        </div>
      </div>
      {searchAdmins.map((eventUser) => (
        <>
          <div key={eventUser.id}>
            <UserList
              user={get('fetchUser', eventUser)}
              imageAttached={userDefaultImage}
              arrowIcon={RIGHT_ARROW}
              redirectLink={`/event-manage/${id}/event-user-profile/${get('id', get('fetchUser', eventUser))}`}
            />
          </div>
        </>
      ))}
    </>
  );
};
export default Admins;
