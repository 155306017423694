import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { get } from 'lodash/fp';
import moment from 'moment';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  FontBold,
  EventBanner,
  EventCard,
  LinkButton,
} from '../../Components/Auth/Layout';
import {
  BROWSE_EVENTS,
  FETCH_COMPETITIONS_BY_EVENT,
  FETCH_EVENTS_USERS_BY_USER,
}
  from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { RedirectButton } from '../../Components/Form';
import FILTER_ICON from '../../Images/icons/filter.png';
import EVENT_BANNER_STATIC from '../../Images/atlanta.png';
import LOCATION_ICON from '../../Images/icons/Location.png';
import DATE_ICON from '../../Images/icons/Date.png';

const finalRegistrationDeadline = (event) => event.fetchRegistrationOptions
  .filter((option) => option.fetchRole.title === 'Student')
  .map((option) => option.finalDeadline)
  .sort((a, b) => new Date(a) - new Date(b))
  .pop();

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const stringifyDate = (date) => format(utcToZonedTime(date, USER_LONG_TZ), DISPLAY_DATE_FORMAT);

const BrowseEvents = () => {
  const {
    currentUser,
  } = useCurrentUser(null);
  const currentUserId = parseInt(get('id', currentUser), 10);
  const [showEventCompetitions, setShowEventCompetitions] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [eventCompetitions, setEventCompetitions] = useState([]);
  const [fetchActiveEvents, { loading, error }] = useLazyQuery(
    BROWSE_EVENTS, {
      onCompleted: (data) => {
        const regionId = get('regionId', currentUser);
        const activeEvents = data.fetchActiveEvents;
        const eventsInMyRegion = activeEvents.filter((event) => event.regionId === regionId);
        const eventsNotInMyRegion = activeEvents.filter((event) => event.regionId !== regionId);
        setFilteredEvents(eventsInMyRegion.concat(eventsNotInMyRegion));
      },
    },
  );
  const { data: dataEventsUser } = useQuery(
    FETCH_EVENTS_USERS_BY_USER,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: currentUserId,
      },
      skip: !currentUser,
    },
  );
  const registeredEvents = dataEventsUser?.eventsUsersByUser?.map((eu) => eu.fetchEvent.id) ?? [];

  const extractError = useGraphQLErrorExtractor();

  useHeaderComponentsMutation({
    title: 'BROWSE EVENTS',
  });

  useEffect(() => {
    fetchActiveEvents({
    });
  }, [fetchActiveEvents]);


  const [fetchEventDetail] = useLazyQuery(
    FETCH_COMPETITIONS_BY_EVENT,
    {
      onCompleted: (data) => {
        setEventCompetitions(
          data.fetchCompetitionsByEvent,
        );
      },
    },
  );

  const fetchDetailEvent = (id) => {
    setShowEventCompetitions(!showEventCompetitions);
    fetchEventDetail({
      variables: {
        eventId: parseInt(id, 10),
      },
    });
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <GenericAlert>{extractError(error)}</GenericAlert>;
  }

  return (
    <>
      <div className="row d-none">
        <div className="col-12 mx-auto px-4 pt-3">
          <FontBold fontcolor="#F4AB37" textalign="center">
            <img src={FILTER_ICON} alt="Filter-Icon" />
            <span>Filter Events</span>
          </FontBold>
        </div>
      </div>
      <p style={{ display: 'none' }}>{get('firstname', currentUser)}</p>
      <div className="pt-3">
        {filteredEvents.map((event) => (
          <div key={event.id} className="pt-3">
            <EventBanner>
              {event.coverImageUrl
                ? <img src={event.coverImageUrl} alt="event-banner" style={{ objectFit: 'cover' }} />
                : <img src={EVENT_BANNER_STATIC} alt="event-banner" style={{ objectFit: 'cover' }} />}

              <span>{event.title}</span>
            </EventBanner>
            <EventCard>
              <div className="pt-3">
                {event.registrationEarlyDeadline
                  ? (
                    <p className="px-4">
                      <img src={DATE_ICON} alt="date" />
                      <span className="px-4">
                        <>
                          {moment(event.startDate, 'YYYY-MM-DD').format('MMMM')}
                          {' '}
                          {moment(event.startDate, 'YYYY-MM-DD').format('DD')}
                          {', '}
                          {moment(event.startDate, 'YYYY-MM-DD').format('YYYY')}
                          {' - '}
                          {moment(event.endDate, 'YYYY-MM-DD').format('MMMM')}
                          {' '}
                          {moment(event.endDate, 'YYYY-MM-DD').format('DD')}
                          {', '}
                          {moment(event.endDate, 'YYYY-MM-DD').format('YYYY')}
                        </>
                      </span>
                    </p>
                  )
                  : null}
                <p className="px-4 pt-3">
                  <img src={LOCATION_ICON} alt="location" />
                  <span className="px-4">{event.location}</span>
                </p>
                {event.fee
                  ? (
                    <p className="px-4 pt-3">
                      <span>Registration Fee:</span>
                      {' '}
                      $
                      {event.fee}
                    </p>
                  )
                  : null}
                <p className="px-4 pt-3">
                  <span>Registration Deadline:</span>
                  {' '}
                  {stringifyDate(finalRegistrationDeadline(event))}
                </p>
              </div>
              <div className="pt-3 px-4" />
              <LinkButton onClick={() => fetchDetailEvent(event.id)} className="px-4" textalign="left" marginbottom="none" fontcolor="#F4AB37" border="none">
                View Competition Offerings
                {' >'}
              </LinkButton>
              {get('eventId', eventCompetitions[0]) === parseInt(event.id, 10) && showEventCompetitions === true
                ? (
                  <div className="px-4">
                    {eventCompetitions
                      .map((competition) => (
                        <span key={competition.id}>
                          {competition.title}
                          ,
                          {' '}
                        </span>
                      ))}
                  </div>
                )
                : null}
              <div className="row">
                <div className="col-6" style={{ paddingRight: '0' }}>
                  {registeredEvents.includes(event.id)
                    ? <RedirectButton borderradius="0" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="View >" activebackgroundcolor="#404141" addNewLink={`/event-manage/${event.id}`} />
                    : <RedirectButton borderradius="0" backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="Register >" activebackgroundcolor="#404141" addNewLink={`/join-event/${event.id}`} />}
                </div>
                <div className="col-6" style={{ paddingLeft: '0' }}>
                  <RedirectButton borderradius="0" backgroundcolor="#404141" fontColor="#FFF" border="none" addNewText="Volunteer >" activebackgroundcolor="#F4AB37" addNewLink={`/volunteer-event/${event.id}`} />
                </div>
              </div>
            </EventCard>
          </div>
        ))}
      </div>
    </>
  );
};
export default BrowseEvents;
