import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { getOr } from 'lodash/fp';
import { CREATE_ROLE } from '../../GraphQL/Mutations';
import useFormParams from '../../Hooks/useFormParams';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useUpdateRegionsCache from '../../Hooks/useUpdateRolesCache';
import Form from './Form';

const RoleNew = () => {
  const history = useHistory();
  const { currentUser } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);
  const updateCache = useUpdateRegionsCache(getOr(0, 'tenantId', currentUser));
  const [createRole, { loading, error }] = useMutation(
    CREATE_ROLE,
    {
      onCompleted: (data) => {
        updateCache(data.createRole.role);
        history.goBack();
      },
    },
  );
  useHeaderComponentsMutation({
    backLink: '/roles',
    title: 'MANAGE ROLES',
  });
  const { params, onChange } = useFormParams({
    title: '',
    termsAndConditions: '',
    level: '',
    allowInvitation: '',
  });
  const extractError = useGraphQLErrorExtractor();

  useEffect(() => {
    if (currentUser) {
      if (checkPermission('Add Role') === undefined) {
        history.push('/roles');
      }
    }
  }, [currentUser, checkPermission, history]);

  const onSubmit = (e) => {
    e.preventDefault();
    params.level = parseInt(params.level, 10);
    createRole({
      variables: {
        ...params,
        tenantId: currentUser.tenantId,
      },
    });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4 pt-3">
          {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
          <Form
            className="pt-3"
            onSubmit={onSubmit}
            onChange={onChange}
            params={params}
            loading={loading}
            formType="new"
            currentUser={currentUser}
          />
        </div>
      </div>
      <hr />
    </>
  );
};
export default RoleNew;
