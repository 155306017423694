/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  RIGHTARROW, TitleC, Hr, ListDetail,
  LinkButton,
  ImgSqaure,
} from '../Auth/Layout';

const DeleteItem = (props) => {
  const {
    title,
    userAdmin,
    imageAttached,
    onClick,
    removeicon,
    profileImage,
    roleTitle,
  } = props;

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-10 col-9 mx-auto px-4">
          <TitleC>
            {profileImage
              ? <ImgSqaure><img src={profileImage} alt="User-Icon" /></ImgSqaure>
              : (
                <>
                  {imageAttached
                    ? <ImgSqaure><img src={imageAttached} alt="User-Icon" /></ImgSqaure>
                    : null}
                </>
              )}
            <p>
              {title}
            </p>
          </TitleC>
          <ListDetail>
            <div className="col-m-2 col-xs-3" style={{ float: 'left' }}>
              {userAdmin
                ? <p>{roleTitle}</p>
                : null }
            </div>
          </ListDetail>
        </div>
        <div className="col-md-2 col-3 mx-auto pr-0 mt-4">
          <RIGHTARROW>
            <LinkButton onClick={onClick}>
              {userAdmin && roleTitle === 'Super Admin'
                ? null
                : <img src={removeicon} alt="arrow" className="img-fluid" />}
            </LinkButton>
          </RIGHTARROW>
        </div>
      </div>
      <Hr />
    </>
  );
};

DeleteItem.propTypes = {
  title: PropTypes.string,
  userAdmin: PropTypes.bool,
  imageAttached: PropTypes.string,
  onClick: PropTypes.func,
  removeicon: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
  roleTitle: PropTypes.string,
};

DeleteItem.defaultProps = {
  userAdmin: false,
  imageAttached: null,
  onClick: null,
  title: '',
  profileImage: null,
  roleTitle: null,
};

export default DeleteItem;
