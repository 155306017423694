const useSearchThroughMultiArray = () => {
  const filterMultiArray = (array, key, value, innerKey) => {
    for (let i = 0; i < array.length; i += 1) {
      for (let j = 0; j < array[i][key].length; j += 1) {
        if (array[i][key][j][innerKey] === value) { return array[i]; }
      }
    }
    return true;
  };
  return filterMultiArray;
};

export default useSearchThroughMultiArray;
