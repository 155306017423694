import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_ADMIN_USERS, FETCH_REGION_DETAIL } from '../../GraphQL/Queries';
import { UPDATE_REGION } from '../../GraphQL/Mutations';
import { PrimaryButton } from '../../Components/Auth/Layout';
import useFormParams from '../../Hooks/useFormParams';
import { Checkbox } from '../../Components/Form';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import Search from '../../Components/Search/Index';


const AddAdmin = () => {
  const {
    currentUser,
  } = useCurrentUser(true);
  const extractError = useGraphQLErrorExtractor();
  const { id } = useParams();
  const history = useHistory();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [regionsUsers, setRegionsUsers] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const { params, onChange, setParams } = useFormParams({
    name: '',
    tier: '',
    stripeAccount: '',
    currency: 'USD',
    websiteUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    email: '',
  });
  useHeaderComponentsMutation({ title: 'Add Admin To Region', backLink: '/dashboard' });

  const [updateRegion] = useMutation(
    UPDATE_REGION,
    {
      onCompleted: () => {
        history.push(`/regions/${id}/edit`);
      },
    },
  );

  const { loading } = useQuery(
    FETCH_REGION_DETAIL,
    {
      onCompleted: (data) => {
        setParams({
          ...data.fetchRegionDetail,
        });
        setUsersArray(data.fetchRegionDetail.regionsUsers.map((a) => a.userId));
        setRegionsUsers(data.fetchRegionDetail.regionsUsers);
      },
      variables: { id: parseInt(id, 10) },
    },
  );

  const [searchAdminUsers, { error }] = useLazyQuery(
    SEARCH_ADMIN_USERS,
    {
      onCompleted: (dataAdminUsers) => {
        setFilteredUsers(dataAdminUsers.searchAdminUsers);
      },
    },
  );

  useEffect(() => {
    if (currentUser) {
      searchAdminUsers({
        variables: {
          query: '',
        },
      });
    }
  }, [currentUser, searchAdminUsers]);

  const searchUsers = (e) => {
    searchAdminUsers({
      variables: {
        query: e.target.value,
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const tempArray = usersArray;
    Object.values(params).map((state, index) => (state === true
      ? tempArray.push(parseInt(Object.keys(params)[index], 10))
      : tempArray.filter((Id) => Id !== parseInt(Object.keys(params)[index], 10))
    ));
    params.id = parseInt(id, 10);
    params.tier = parseInt(params.tierValue, 10);
    updateRegion({
      variables: {
        ...params,
        userIds: tempArray,
      },
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      <div className="row py-5">
        <div className="col-12 mx-auto px-4">
          <div className="pt-3">
            <Search placeholder="Search" onChange={(e) => searchUsers(e)} />
          </div>
          {filteredUsers && filteredUsers.map((user) => (
            <div key={`${user.id}`}>
              <Checkbox
                id={`${user.id}`}
                name={`${user.id}`}
                params={params}
                onChange={onChange}
                text={`${user.firstname} ${user.lastname}`}
                setParams={setParams}
                checked={regionsUsers.map((a) => a.userId).includes(parseInt(user.id, 10))}
              />
            </div>
          ))}
          <div className="w-100">
            <PrimaryButton loading={loading} htmltype="submit">Save</PrimaryButton>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddAdmin;
