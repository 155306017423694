import React from 'react';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';

import customTheme from './theme';

const theme = extendTheme(customTheme);

export const Provider = ({ children }) => (
  <ChakraProvider theme={theme}>
    {children}
  </ChakraProvider>
);
