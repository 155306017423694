import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { formatDistance } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DELETE_SCHEDULE_EVENT, UPDATE_SCHEDULE_EVENT } from '../../../GraphQL/Mutations';

import { ScheduleEventForm } from './ScheduleEventForm';
import { EventCompetitionPropType, LocationPropType } from './propTypes';
import { EMPTY_SCHEDULE, SCHEDULE_BORDER_COLOR, isScheduleInvalid } from './utils';

const DISPLAY_TIME_FORMAT = 'hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

const SchedulePropType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  fetchLocation: LocationPropType,
  room: PropTypes.string.isRequired,
  fetchAssociatedEventCompetition: EventCompetitionPropType,
});

const ScheduleInfo = ({ schedule, onEdit }) => {
  const startTime = utcToZonedTime(schedule.startTime, USER_LONG_TZ);
  const endTime = utcToZonedTime(schedule.endTime, USER_LONG_TZ);

  return (
    <>
      <Flex>
        <Heading size="sm">{schedule.title}</Heading>
        <Spacer />
        <Button variant="link" onClick={onEdit}>edit</Button>
      </Flex>
      <Text size="sm">{schedule.description}</Text>
      <Text size="sm" color="#666666">
        {schedule.type === 'competition' ? schedule.fetchAssociatedEventCompetition.title : schedule.type}
      </Text>
      <Flex gap="64px">
        <Text>
          {format(startTime, DISPLAY_TIME_FORMAT)}
          {' - '}
          {format(endTime, DISPLAY_TIME_FORMAT)}
          {' | '}
          {formatDistance(endTime, startTime)}
        </Text>
        <Flex direction="column">
          <Text>
            {schedule.fetchLocation.name}
          </Text>
          <Text>
            {schedule.room}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
ScheduleInfo.propTypes = {
  schedule: SchedulePropType.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const EditExistingSchedule = ({
  schedule,
  onEditScheduleEvent,
  onDeleteScheduleEvent,
  onCancel,
  eventId,
  eventCompetitions,
  locations,
}) => {
  const [updatedSchedule, setUpdatedSchedule] = useState(schedule);
  const [updateScheduleEvent, { loading: editLoading }] = useMutation(UPDATE_SCHEDULE_EVENT);
  const onEdit = () => {
    const input = Object.keys(EMPTY_SCHEDULE).reduce((obj, key) => ({
      ...obj,
      [key]: updatedSchedule[key],
    }), {});
    updateScheduleEvent({
      variables: {
        input: {
          ...input,
          eventId,
          scheduleEventId: updatedSchedule.id,
          startTime: utcToZonedTime(updatedSchedule.startTime, USER_LONG_TZ).toISOString(),
          endTime: utcToZonedTime(updatedSchedule.endTime, USER_LONG_TZ).toISOString(),
        },
      },
    }).then((res) => {
      const updatedScheduleEvent = res.data.updateScheduleEvent.scheduleEvent;
      onEditScheduleEvent(updatedScheduleEvent);
      onCancel();
    });
  };
  const [deleteScheduleEvent, { loading: deleteLoading }] = useMutation(DELETE_SCHEDULE_EVENT);
  const onClickDelete = () => {
    deleteScheduleEvent({
      variables: {
        input: {
          scheduleEventId: schedule.id,
        },
      },
    }).then(() => {
      onDeleteScheduleEvent(schedule.id);
    });
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Flex alignItems="flex-start">
        <ScheduleEventForm
          schedule={updatedSchedule}
          setSchedule={setUpdatedSchedule}
          eventCompetitions={eventCompetitions}
          locations={locations}
        />
        <Button variant="link" onClick={onCancel}>Cancel</Button>
      </Flex>
      <HStack justifyContent="flex-end">
        <Button
          variant="solid"
          colorScheme="red"
          isLoading={deleteLoading}
          disabled={editLoading}
          onClick={onClickDelete}
        >
          Remove Event
        </Button>
        <Button
          variant="solid"
          colorScheme="orange"
          isLoading={editLoading}
          disabled={deleteLoading || isScheduleInvalid(schedule)}
          onClick={onEdit}
        >
          Save Event
        </Button>
      </HStack>
    </VStack>
  );
};
EditExistingSchedule.propTypes = {
  schedule: SchedulePropType.isRequired,
  onEditScheduleEvent: PropTypes.func.isRequired,
  onDeleteScheduleEvent: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  eventCompetitions: PropTypes.arrayOf(EventCompetitionPropType).isRequired,
  locations: PropTypes.arrayOf(LocationPropType).isRequired,
};

export const ExistingScheduleCard = ({
  schedule,
  onEditScheduleEvent,
  onDeleteScheduleEvent,
  eventId,
  eventCompetitions,
  locations,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box
      sx={{
        borderLeftStyle: 'solid',
        borderLeftColor: SCHEDULE_BORDER_COLOR[schedule.type],
        borderLeftWidth: '8px',
        borderRadius: '8',
        background: '#f2f2f2',
      }}
      width="100%"
      padding="16px"
    >
      {isEditing ? (
        <EditExistingSchedule
          schedule={schedule}
          onEditScheduleEvent={onEditScheduleEvent}
          onDeleteScheduleEvent={onDeleteScheduleEvent}
          onCancel={() => setIsEditing(false)}
          eventId={eventId}
          eventCompetitions={eventCompetitions}
          locations={locations}
        />
      ) : <ScheduleInfo schedule={schedule} onEdit={() => setIsEditing(true)} />}
    </Box>
  );
};
ExistingScheduleCard.propTypes = {
  schedule: SchedulePropType.isRequired,
  onEditScheduleEvent: PropTypes.func.isRequired,
  onDeleteScheduleEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  eventCompetitions: PropTypes.arrayOf(EventCompetitionPropType).isRequired,
  locations: PropTypes.arrayOf(LocationPropType).isRequired,
};
