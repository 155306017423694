import React, { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { getOr, get } from 'lodash/fp';
import {
  Hr,
} from '../../Components/Auth/Layout';
import {
  FETCH_EVENT, INVITED_USERS,
} from '../../GraphQL/Queries';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import Loader from '../../Components/Loader';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useCurrentUser from '../../Hooks/userCurrentUser';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import UserList from '../../Components/UserList/Index';
import DefaultImage from '../../Images/team-dum.png';
import REMOVE_ICON from '../../Images/cross.png';
import { REMOVE_INVITED_USER } from '../../GraphQL/Mutations';
import useInviteUsersCache from '../../Hooks/useInviteUsersCache';
import useInvitationCountCache from '../../Hooks/useInvitationCountCache';

const ReviewInvite = () => {
  const {
    currentUser,
    loading: userLoading,
    error: userError,
  } = useCurrentUser(true);
  const { eventId } = useParams();
  const updateCache = useInviteUsersCache(get('id', currentUser), eventId);
  const extractError = useGraphQLErrorExtractor();

  const [userInvitationList, { data: dataUserInvitationList }] = useLazyQuery(
    INVITED_USERS,
  );

  const invitedUsers = getOr([], 'userInvitationList', dataUserInvitationList);

  const updateInvitationCountCache = useInvitationCountCache(
    get('id', currentUser), eventId,
  );


  const { data, loading, error } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(eventId, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useHeaderComponentsMutation({
    title: 'REVIEW INVITES',
    backLink: `/event-manage/${eventId}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  useEffect(() => {
    if (currentUser && eventId) {
      userInvitationList({
        variables: {
          invitableId: parseInt(eventId, 10),
          invitableType: 'Event',
          userId: parseInt(currentUser.id, 10),
        },
      });
    }
  }, [currentUser, userInvitationList, eventId]);

  const [removeInvitation] = useMutation(
    REMOVE_INVITED_USER,
    {
      onCompleted: (data1) => {
        updateCache(data1.removeInvitation.user, 'remove');
        updateInvitationCountCache(data1.removeInvitation.user, 'remove');
      },
    },
  );

  const removeInvitationUser = (userId) => {
    removeInvitation({
      variables: {
        invitableId: parseInt(eventId, 10),
        invitableType: 'Event',
        userId: parseInt(userId, 10),
      },
    });
  };

  if (loading || userLoading) {
    return <Loader />;
  }
  if (error || userError) {
    return <GenericAlert>{extractError(error || userError)}</GenericAlert>;
  }

  return (
    <>
      Please contact your regional admins to cancel invites and finalize your team.
      <div className="mt-4" />
      <div className="row">
        <div className="col-12 mx-auto px-4">
          {invitedUsers.map((user) => (
            <div key={user.id}>
              <UserList
                user={user}
                onClick={() => removeInvitationUser(user.id)}
                imageAttached={DefaultImage}
                removeicon={REMOVE_ICON}
                redirectLink="#"
              />
              <div className="mt-4" />
              <Hr />
            </div>
          ))}
          <div className="mt-4" />
        </div>
      </div>
    </>
  );
};
export default ReviewInvite;
