import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../Components/Form/Input';

const BasicSubTeam = (props) => {
  const {
    id,
    title,
    competitions,
    onChange,
    initialSubTeam,
  } = props;

  const initialParams = {
    [id]: initialSubTeam,
  };


  const [params, setParams] = useState(initialParams);

  if (!params && initialSubTeam) {
    // initial setup
    setParams({ [id]: initialSubTeam });
  }

  const wrappedOnChange = (selfId, selection) => {
    setParams({ [id]: selection });
    onChange(selection);
  };

  return (
    <div key={id}>
      <Input
        title={title}
        name={id}
        type="select"
        id={id}
        onChange={wrappedOnChange}
        params={params}
      >
        <option value={-1} key={-1}>None</option>
        {competitions.map((c) => <option key={c.id} value={c.id} disabled={(c.maxSize === c.size)} >SubTeam {c.code} - {c.size} Registered - {c.maxSize - c.size} Remaining</option>)} 
      </Input>
    </div>
  );
};

BasicSubTeam.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  competitions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  initialSubTeam: PropTypes.string,
};

BasicSubTeam.defaultProps = {
  initialSubTeam: undefined,
};

export default BasicSubTeam;
