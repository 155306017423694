import { useApolloClient } from '@apollo/react-hooks';
import { subtract } from 'lodash/fp';
import { UNPAID_COUNT } from '../GraphQL/Queries';
import logger from '../logger';

const useUnpaidUserCountCache = (schoolId, eventId) => {
  const client = useApolloClient();
  const updateUnpaidUserCountCache = (user, action) => {
    try {
      const data = client.readQuery({
        query: UNPAID_COUNT,
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
      const { fetchUnpaidUserCount } = data;
      let response = 0;
      if (action === 'remove') {
        response = subtract(fetchUnpaidUserCount, 1);
      }
      client.writeQuery({
        query: UNPAID_COUNT,
        data: {
          fetchUnpaidUserCount: response,
        },
        variables: {
          eventId: parseInt(eventId, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } catch (e_) {
      logger('Update not needed!');
    }
  };

  return updateUnpaidUserCountCache;
};

export default useUnpaidUserCountCache;
