import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import styled from 'styled-components';
import Form, { Input, Button } from '../../Components/Form';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerInput, TitleC } from '../../Components/Auth/Layout';
import { createCostBreakdown } from './paymentUtils';

const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy hh:mm aa';
const USER_LONG_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const USER_TZ = format(new Date(), 'z');

const FeeDetails = styled.details`
  font-size: 14px;
  summary {
    font-style: italic;
  }
`;

const Cost = styled.span`
  font-style: normal;
  font-weight: bold;
`;

const CostBreakdown = ({ cost, fee }) => {
  const {
    regionAmount = 0,
    natsAmount = 0,
    stripeFee = 0,
  } = cost > 0 ? createCostBreakdown(cost * 100, fee) : {};

  return (
    <FeeDetails>
      <summary>Cost breakdown:</summary>
      <div>
        Your Region: $
        <Cost>{regionAmount}</Cost>
      </div>
      <div>
        {`MIST Nationals: ${fee}% = $`}
        <Cost>{natsAmount}</Cost>
      </div>
      <div>
        Payment Processing Fee (non-refundable): $
        <Cost>{stripeFee}</Cost>
      </div>
    </FeeDetails>
  );
};
CostBreakdown.propTypes = {
  cost: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
};

const RegistrationOptionsForm = ({
  params, onSubmit, onChange, loading, setParams, roles, event,
}) => {
  const earlyDeadline = params.earlyDeadline ? utcToZonedTime(params.earlyDeadline, USER_LONG_TZ) : '';
  const finalDeadline = params.finalDeadline ? utcToZonedTime(params.finalDeadline, USER_LONG_TZ) : '';
  const setEarlyDeadline = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_LONG_TZ);
    setParams({
      ...params,
      earlyDeadline: utcDate.toISOString(),
    });
  };
  const setFinalDeadline = (date) => {
    const utcDate = zonedTimeToUtc(date, USER_LONG_TZ);
    setParams({
      ...params,
      finalDeadline: utcDate.toISOString(),
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        title="Option Name"
        params={params}
        withFormGroup
        id="title"
        name="title"
        placeholder="Option Name"
        required
        onChange={onChange}
        label="First Name"
        labelClass="sr-only"
        setParams={setParams}
      />
      <Input title="Roles" name="roleId" type="select" params={params} id="roleId" required onChange={onChange}>
        <option value="" disabled>Roles</option>
        {roles && roles.map((obj) => (
          <option key={obj.id} value={obj.id}>{obj.title}</option>
        ))}
      </Input>
      <Input
        title="Fee"
        params={params}
        withFormGroup
        id="fee"
        name="fee"
        placeholder="Fee"
        required
        onChange={onChange}
        setParams={setParams}
        type="number"
        pattern="\d+(\.\d*)?"
      />
      <CostBreakdown cost={parseInt(params.fee, 10)} fee={event.feePercentage} />
      <Input
        title="Total Capacity (Optional)"
        params={params}
        withFormGroup
        id="capacity"
        name="capacity"
        placeholder="Total Capacity (Optional)"
        onChange={onChange}
        setParams={setParams}
        type="number"
      />
      <p className="mt-3">
        <TitleC>
          {`Early Registration Deadline (in ${USER_TZ}) `}
          <span className="text-danger">*</span>
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          selected={earlyDeadline}
          onChange={(date) => setEarlyDeadline(date)}
          dateFormat={DISPLAY_DATE_FORMAT}
          placeholderText="mm/dd/yyyy"
          timeIntervals={30}
          showTimeSelect
        />
      </DatePickerInput>
      <p className="mt-3">
        <TitleC>
          {`Final Registration Deadline (in ${USER_TZ}) `}
          <span className="text-danger">*</span>
        </TitleC>
      </p>
      <DatePickerInput>
        <DatePicker
          selected={finalDeadline}
          onChange={(date) => setFinalDeadline(date)}
          dateFormat={DISPLAY_DATE_FORMAT}
          placeholderText="mm/dd/yyyy"
          timeIntervals={30}
          showTimeSelect
        />
      </DatePickerInput>
      <Input
        title="Minimum Age"
        params={params}
        withFormGroup
        id="minAge"
        name="minAge"
        placeholder="Minimum Age"
        onChange={onChange}
        required
        type="number"
        setParams={setParams}
      />
      <Input
        title="Maximum Age"
        params={params}
        withFormGroup
        id="maxAge"
        name="maxAge"
        placeholder="Maximum Age"
        onChange={onChange}
        required
        type="number"
        setParams={setParams}
      />
      <Input
        title="Late Fee"
        params={params}
        withFormGroup
        id="lateFee"
        name="lateFee"
        placeholder="Late Fee"
        onChange={onChange}
        required
        setParams={setParams}
        type="number"
        pattern="\d+(\.\d*)?"
      />
      <CostBreakdown
        cost={parseInt(params.fee, 10) + parseInt(params.lateFee, 10)}
        fee={event.feePercentage}
      />

      <Input
        title="Waitlist Form URL"
        params={params}
        withFormGroup
        id="waitlistFormUrl"
        name="waitlistFormUrl"
        onChange={onChange}
        setParams={setParams}
        type="url"
      />

      <hr />
      <Button loading={loading} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit">SUBMIT</Button>
    </Form>
  );
};

RegistrationOptionsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setParams: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  event: PropTypes.shape({
    feePercentage: PropTypes.number.isRequired,
  }).isRequired,
};

RegistrationOptionsForm.defaultProps = {
  loading: false,
};

export default RegistrationOptionsForm;
