import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { getOr, get, first } from 'lodash/fp';
import {
  FETCH_EVENT_SCHOOL_STUDENTS,
  FETCH_EVENT_SCHOOL_COACHES,
  FETCH_EVENT_USERS_IN_EVENT,
  FETCH_EVENT,
  FETCH_EVENTS_USERS_BY_USERS,
} from '../../GraphQL/Queries';
import { REASSIGN_CAPTAIN } from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useNotEqualFilterArray from '../../Hooks/useNotEqualFilterArray';
import SearchDialog from '../../Components/SearchDialog/Index';
import { EVENT_TITLE } from '../../Constants/AppConstants';
import BackLink from '../../Components/BackLink';
import SEARCH from '../../Images/search.png';
import {
  SelectedSchool, LinkButton, ReassignCaptainBox,
} from '../../Components/Auth/Layout';
import NOT_ICON from '../../Images/icons/not.png';
import useUpdateEventsUsersByUsersCache from '../../Hooks/useUpdateEventsUsersByUsersCache';

const ReassignCaptain = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const history = useHistory();
  const { id } = useParams();
  const notEqualFilterArray = useNotEqualFilterArray();

  const [clickStatus, setClickStatus] = useState(false);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [filteredEventsUser, setFilteredEventsUser] = useState([]);
  const [selectedEventsUserId, setSelectedEventsUserId] = useState('');
  const [selectedUserName, setSelectedUserName] = useState(null);

  const updateCacheEventsUsersByUsers = useUpdateEventsUsersByUsersCache(id, getOr(0, 'id', currentUser));

  const [fetchEventSchoolStudents, { data: dataEventsUsers }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_STUDENTS, {
    },
  );
  const [fetchEventSchoolCoaches, { data: dataTeamCoaches }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_COACHES,
  );

  const [fetchEventsUsersByUsers, { data: dataEventsUser }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const allEventsUsers = [
    ...getOr([], 'fetchEventSchoolStudents', dataEventsUsers),
    ...getOr([], 'fetchEventSchoolCoaches', dataTeamCoaches),
  ];
  const eventsUsers = notEqualFilterArray(allEventsUsers, 'fetchUser.id', get('id', currentUser));

  const eventsCurrentUser = useMemo(() => first(notEqualFilterArray((getOr([], 'fetchEventsUsersByUser', dataEventsUser)), 'fetchRole.title', 'Student')), [dataEventsUser, notEqualFilterArray]);

  const [fetchEventUsersInEvent, { data: dataEventsUsersInEvent }] = useLazyQuery(
    FETCH_EVENT_USERS_IN_EVENT,
  );

  const eventsUser = useMemo(() => first(getOr([], 'fetchEventUsersInEvent', dataEventsUsersInEvent)), [dataEventsUsersInEvent]);

  useEffect(() => {
    if (eventsUser) {
      fetchEventSchoolStudents({
        variables: {
          schoolId: eventsUser.schoolId,
          eventId: parseInt(id, 10),
        },
      });
      fetchEventSchoolCoaches({
        variables: {
          schoolId: eventsUser.schoolId,
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [fetchEventSchoolStudents, fetchEventSchoolCoaches, eventsUser, id]);


  const searchUsers = (e) => {
    setSearchStringLength(e.target.value.length);
    setFilteredEventsUser(
      eventsUsers.filter(
        (x) => ((`${x.fetchUser.firstname} ${x.fetchUser.lastname}`).toLowerCase()).includes(e.target.value),
      ),
    );
  };

  const assignSelectedEventsUserId = (e) => {
    if (e.target.id !== '') {
      setClickStatus(false);
      setSearchStringLength(0);
      setSelectedEventsUserId(parseInt(e.target.id, 10));
      setSelectedUserName(e.target.innerText);
    }
  };

  const [captainAssign] = useMutation(
    REASSIGN_CAPTAIN,
    {
      onCompleted: () => {
        updateCacheEventsUsersByUsers(eventsCurrentUser, 'remove');
        history.push(`/event-manage/${id}`);
      },
    },
  );

  const assignCaptain = () => {
    captainAssign({
      variables: {
        schoolId: parseInt(eventsUser.schoolId, 10),
        eventsUserId: parseInt(selectedEventsUserId, 10),
        eventId: parseInt(id, 10),
      },
    });
  };


  useEffect(() => {
    if (currentUser && id) {
      fetchEventUsersInEvent({
        variables: {
          eventId: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventUsersInEvent, id]);

  useEffect(() => {
    if (currentUser && id) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: null,
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id]);

  const { data } = useQuery(
    FETCH_EVENT,
    {
      variables: { id: parseInt(id, 10) },
    },
  );
  const event = getOr({}, 'fetchEventDetail', data);

  useHeaderComponentsMutation({
    title: 'REASSIGN CAPTAIN',
    backLink: `/event-manage/${id}`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          <SearchDialog
            placeholder="Search"
            onChange={(e) => searchUsers(e)}
            data={filteredEventsUser}
            stringlength={searchStringLength}
            displayattribute="name"
            displayObject="fetchUser"
            imageattatched={SEARCH}
            emptySearch={clickStatus}
            checkPermission
            onClick={assignSelectedEventsUserId}
          />
          {selectedUserName
            ? (
              <SelectedSchool>
                <h5>{selectedUserName}</h5>
              </SelectedSchool>
            )
            : null}
          <ReassignCaptainBox>
            <div className="pt-3" />
            <img src={NOT_ICON} alt="up" />
            <div className="pt-3" />
            <p>
              After you reassign, the current Captain will lose all
              the captain privileges with
              MIST TEAM.
            </p>
          </ReassignCaptainBox>
        </div>
      </div>
      <LinkButton
        onClick={assignCaptain}
        backgroundcolor="#F4AB37"
        fontcolor="#FFF"
        border="none"
      >
        CONTINUE
      </LinkButton>
      <div className="pt-3" />
      <BackLink to={`/event-manage/${event.id}`}>
            Manage Event
      </BackLink>
      <div className="pt-3" />
    </>
  );
};

export default ReassignCaptain;
