import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Link, useParams } from 'react-router-dom';
import {
  isEmpty, getOr, first, get,
} from 'lodash/fp';
import groupBy from 'lodash/groupBy';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter,

  InnerFormTitle,
} from '../../Components/Auth/Layout';
import Search from '../../Components/Search/Index';
import useEventsUserPermissionChecker from '../../Hooks/useEventsUserPermissionChecker';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import {
  FETCH_COMPETITION_MEMBERS,
  SEARCH_COMPETITION_MEMBERS,
  FETCH_EVENTS_USERS_BY_USERS,
  CHECK_TEAM_FINALIZE,
} from '../../GraphQL/Queries';

import useCurrentUser from '../../Hooks/userCurrentUser';
import Participant from '../../Components/Participant/Index';
import BackLink from '../../Components/BackLink';
import Edit from '../../Images/icons/edit.png';
import useFilterArray from '../../Hooks/useFilterArray';
import Permissions from '../../Constants/PermissionConstrants';
import { RedirectButton } from '../../Components/Form';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';


const CompetitionRoster = () => {
  useHeaderComponentsMutation({ title: 'COMPETITION ROSTER' });
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { schoolId } = useParams();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [totalcompetitionMembers, setTotalcompetitionMembers] = useState([]);
  const [searchCompetitionMembers, setSearchCompetitionMembers] = useState([]);
  const extractError = useGraphQLErrorExtractor();
  const filterArray = useFilterArray();
  const [fetchEventsUsersByUsers, { data: dataEventsUsers }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUsers);

  const eventsUser = useMemo(() => first(filterArray(eventsUsers, 'fetchRole.title', 'Captain')), [eventsUsers, filterArray]);

  const checkPermissionofEventsUser = useEventsUserPermissionChecker(eventsUser);

  const [fetchCompetitionMembers, { error }] = useLazyQuery(
    FETCH_COMPETITION_MEMBERS, {
      onCompleted: (data) => {
        setTotalcompetitionMembers(data.competitionRoster);
        setSearchCompetitionMembers(data.competitionRoster);
      },
    },
  );

  useEffect(() => {
    fetchCompetitionMembers({
      variables: {
        eventId: parseInt(id, 10),
        schoolId: parseInt(schoolId, 10),
      },
    });
  }, [currentUser, fetchCompetitionMembers, id, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id, schoolId]);


  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );

  const teamFinalize = getOr([], 'checkTeamFinalize', dataCheckTeamFinalize);

  useEffect(() => {
    if (currentUser && !isEmpty(schoolId)) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [checkTeamFinalize, currentUser, id, schoolId]);

  const [competitionRosterSearch] = useLazyQuery(
    SEARCH_COMPETITION_MEMBERS,
    {
      onCompleted: (data) => {
        setSearchCompetitionMembers(data.searchCompetitionRoster);
      },
    },
  );
  const searchCompetitionRoster = (e) => {
    competitionRosterSearch({
      variables: {
        eventId: parseInt(id, 10),
        schoolId: parseInt(schoolId, 10),
        query: e.target.value,
      },
    });
  };

  const competitionsByCategory = groupBy(
    searchCompetitionMembers, (counter) => counter.fetchCategory.id,
  );

  return (
    <>
      <div className="mt-4" />
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      {totalcompetitionMembers.length && !teamFinalize
        ? (
          <Search
            placeholder="Search by Name or Competition"
            onChange={searchCompetitionRoster}
          />
        )
        : null}
      {totalcompetitionMembers.length === 0 && !teamFinalize
        ? (
          <>
            <div className="alert alert-warning mt-3" role="alert">
                No body join your team yet!. Invite your school mates to join your team
            </div>
            <RedirectButton backgroundcolor="#F4AB37" fontColor="#FFF" border="none" addNewText="INVITE USERS" addNewLink={`/event-manage/${id}/invite-existing-user`} />
          </>
        )
        : null}
      <div className="container  pl-0 pr-0">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {searchCompetitionMembers.length === 0
                ? null
                : (
                  <div className="col-md-6 col-6 font-weight-bold">
                  Competitions
                  </div>
                )}
              {searchCompetitionMembers.length > 0
                ? (
                  <>
                    {(checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS)
                     || checkPermission(Permissions.CAN_MANAGE_REGION))
                     && !teamFinalize
                      ? (
                        <div className="col-md-6 col-5 text-right">
                          <Link to={`/events/${id}/competition-roster/${schoolId}/edit`}>
                            <img src={Edit} alt="Edit" />
                          </Link>
                        </div>
                      )
                      : null}
                  </>
                )
                : null}
            </div>
            <div className="pt-3" />
            {Object.keys(competitionsByCategory).map((categoryId, index) => (
              <InnerFormTitle key={categoryId}>
                <p>
                  {get('title', get('fetchCategory', competitionsByCategory[categoryId][0]))}
                </p>
                {Object.values(competitionsByCategory)[index].map((competition) => (
                  <>
                    {get('fetchEventCompetition', competition)
                      ? (
                        <div className="row mt-4 mb-2">
                          <div className="col-md-12 col-12 h5  font-weight-bold">
                            {get('fetchEventCompetition.title', competition)}
                          </div>
                        </div>
                      )
                      : (
                        <div className="row mt-4 mb-2">
                          <div className="col-md-12 col-12 h5  font-weight-bold">
                            {get('title', competition)}
                          </div>
                        </div>
                      )}
                    {competition.fetchMembers.map((member, Index) => (
                      <div className="row pl-3">
                        <Participant
                          user={member}
                          index={Index}
                          color={member.status === 'joined'
                            ? '#389446'
                            : '#3E6796'}
                          imageAttatched={member.status === 'joined'
                            ? null
                            : 'true'}
                          reason={member.reason}
                        />
                      </div>
                    ))}
                  </>
                ))}
              </InnerFormTitle>
            ))}
          </div>
        </div>
      </div>
      <AlignCenter>
        <BackLink to={`/event-manage/${id}`}>
       Manage Event
        </BackLink>
      </AlignCenter>
    </>
  );
};

export default CompetitionRoster;
