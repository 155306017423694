import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  LinkText, PaymentDescription,
  StyledInput,
  StripeCustomField,
  StripeBuiltInField,
  CoverEntireWidth,
  ZipCodeLabel,
  PaymentHelp,
  DisabledButton,
} from '../../Components/Auth/Layout';
import Form, { Button, Title } from '../../Components/Form';
import HELP_ICON from '../../Images/icons/question.png';
import { LineItem } from './JoinEventCart';


function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

// generateId :: Integer -> String
function generateId(len) {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

class PaymentForm extends React.Component {
  state = {
    userFullName: '',
    userEmail: '',
    randomKey: generateId(10),
  };

  componentDidMount() {
    const {
      currentUser,
    } = this.props;
    this.setState({ userFullName: (`${currentUser.firstname} ${currentUser.lastname}`) });
    this.setState({ userEmail: (currentUser.email) });
  }

  handleChange = ({ error }) => {
    const { onSelect } = this.props;
    const { onRemove } = this.props;
    if (error) {
      onSelect(error.message);
      onRemove(false);
    } else {
      onSelect(null);
      onRemove(false);
    }
  };


  handleChangeName = (e) => {
    this.setState({ userFullName: e.target.value });
  };


  handleSubmit = (ev) => {
    ev.preventDefault();
    const {
      order,
      stripe,
      onClick,
      onRemove,
      onSelect,
    } = this.props;

    const {
      userFullName,
      userEmail,
      randomKey,
    } = this.state;

    onRemove(true);

    stripe.createToken({
      name: userFullName,
      email: userEmail,
    }).then(({ token, error }) => {
      if (token) {
        onClick({
          variables: {
            orderId: parseInt(order.id, 10),
            email: userEmail,
            token: token.id,
            clientIp: randomKey,
            userFullName,
          },
        });
      } else {
        if (error && error.message) {
          onSelect(error.message);
        }
        onRemove(false);
      }
    });
  }

  render() {
    const {
      order,
      event,
      currentUser,
      totalAmount,
      loading,
      disabled,
    } = this.props;

    const {
      userFullName,
      userEmail,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <div style={{ margin: 'auto', textAlign: 'center' }} />
        {order.status !== 'complete'
          ? (
            <>
              <StripeCustomField>
                <label htmlFor="userFullName">
                  <Title>
                      Full Name (as it appears on card)*
                  </Title>
                  <input
                    type="text"
                    id="userFullName"
                    className="StripeElement"
                    required
                    onChange={this.handleChangeName}
                    defaultValue={userFullName}
                  />
                </label>
              </StripeCustomField>
              <StripeCustomField>
                <label htmlFor="userEmail">
                  <Title>
                      Email*
                  </Title>
                  <input
                    type="email"
                    id="userEmail"
                    className="StripeElement"
                    required
                    defaultValue={userEmail}
                    readOnly
                  />
                </label>
              </StripeCustomField>
              <CoverEntireWidth>
                <Title>
                  Card number*
                </Title>
                <StripeBuiltInField>
                  <CardNumberElement
                    onChange={this.handleChange}
                  />
                </StripeBuiltInField>
              </CoverEntireWidth>
              <CoverEntireWidth>
                <Title>
                    Expiration date*
                </Title>
                <StripeBuiltInField>
                  <CardExpiryElement
                    onChange={this.handleChange}
                    id="cardExpiry"
                  />
                </StripeBuiltInField>
              </CoverEntireWidth>
              <div className="row">
                <div className="col-5">
                  <CoverEntireWidth>
                    <Title>
                        CARD SECURITY CODE*
                    </Title>
                    <StripeBuiltInField>
                      <CardCVCElement
                        onChange={this.handleChange}
                        id="cvcCode"
                      />
                    </StripeBuiltInField>
                  </CoverEntireWidth>
                </div>
                <div className="col-2">
                  <PaymentHelp>
                    <button data-tip data-for="reason" type="button"><img src={HELP_ICON} alt="question" /></button>
                    <ReactTooltip id="reason">
                      <span>
                        The CVV/CVC code (Card Security Value/Code) is
                        located on the back of your credit/debit card on the
                        right side of the white signature strip
                      </span>
                    </ReactTooltip>
                  </PaymentHelp>
                </div>
                <div className="col-5">
                  <ZipCodeLabel>
                    <label htmlFor="zipCode">
                      <Title>
                          Postal code
                      </Title>
                      <StyledInput>
                        <input
                          type="text"
                          id="postalCode"
                          className="StripeElement"
                          required
                        />
                      </StyledInput>
                    </label>
                  </ZipCodeLabel>
                </div>
              </div>
            </>
          )
          : null}
        <hr />
        <LinkText color="#000" style={{ display: 'block' }}>
          <p>{event.title}</p>
          <p>
            {get('firstname', currentUser)}
            {' '}
            {get('lastname', currentUser)}
          </p>
          {order && order.fetchLineItems
            && order.fetchLineItems.sort((li1, li2) => li1.id - li2.id).map((lineItem) => (
              <LineItem key={lineItem.id} lineItem={lineItem} event={event} />
            ))}
        </LinkText>
        <PaymentDescription>
          <p style={{ textAlign: 'center' }}>
            Total
            <span style={{ textAlign: 'center', float: 'inherit' }}>
              {' '}
              $
              {parseFloat(totalAmount / 100, 10).toFixed(2)}
            </span>
            {` (${event.currency})`}
          </p>
        </PaymentDescription>

        <hr />
        {order.status !== 'complete' && disabled === false
          ? (
            <Button
              backgroundcolor="#F4AB37"
              fontColor="#FFF"
              border="2px solid #FFF"
              marginbottom="-2rem"
              addNewText="PAY NOW"
              permissionName="View Event"
              currentUser={currentUser}
              htmltype="submit"
              loading={loading}
            >
                Make Payment
            </Button>
          )
          : (
            <DisabledButton>
                Make Payment
            </DisabledButton>
          )}
        <div className="pt-3" />
      </Form>
    );
  }
}

PaymentForm.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  event: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  totalAmount: PropTypes.number.isRequired,
  stripe: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

PaymentForm.defaultProps = {
  loading: false,
  disabled: false,
};


export default injectStripe(PaymentForm);
