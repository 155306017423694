import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  get, getOr, first, isEmpty, debounce,
} from 'lodash/fp';
import { StripeProvider, Elements } from 'react-stripe-elements';
import StripeScriptLoader from 'react-stripe-script-loader';
import GenericAlert from '../../Components/GenericAlert';
import { CREATE_CHARGE } from '../../GraphQL/Mutations';
import { SmallLogo } from '../../Components/Auth/Layout';
import {
  FETCH_EVENT_HEADER,
  FETCH_ORDER_DETAIL,
  FETCH_REGISTRATION_OPTION_DETAIL,
} from '../../GraphQL/Queries';
import useCurrentUser from '../../Hooks/userCurrentUser';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import PaymentForm from './PaymentForm';
import useFilterArray from '../../Hooks/useFilterArray';
import BackLink from '../../Components/BackLink';
import useUpdateOrdersCache from '../../Hooks/useUpdateOrdersCache';

import ADD_SMALL_ICON from '../../Images/stripe_secure.png';
import Loader from '../../Components/Loader';

import JoinEventSlider from './JoinEventSlider';

const JoinEventBilling = () => {
  const {
    currentUser,
  } = useCurrentUser(true);

  const history = useHistory();
  const { orderId } = useParams();
  const filterArray = useFilterArray();
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useHeaderComponentsMutation({
    title: 'PAYMENT',
  });

  const [fetchRegistrationOptionDetail, { data: dataFetchRegistrationOptionDetail }] = useLazyQuery(
    FETCH_REGISTRATION_OPTION_DETAIL,
  );

  const option = getOr({}, 'fetchRegistrationOptionDetail', dataFetchRegistrationOptionDetail);

  const [fetchEventDetail, { data: dataFetchEventDetail, loading: eventLoading }] = useLazyQuery(
    FETCH_EVENT_HEADER,
  );

  const event = getOr({}, 'fetchEventDetail', dataFetchEventDetail);

  const [fetchOrderDetail, { data: dataFetchOrderDetail, loading: orderLoading }] = useLazyQuery(
    FETCH_ORDER_DETAIL,
  );

  const order = getOr({}, 'fetchOrderDetail', dataFetchOrderDetail);

  const lineItem = useMemo(() => first(filterArray(getOr([], 'fetchLineItems', order), 'lineItemAbleType', 'RegistrationOption')), [filterArray, order]);

  const updateOrdersCache = useUpdateOrdersCache(getOr(0, 'id', currentUser), getOr(0, 'id', event));

  useEffect(() => {
    if (!isEmpty(order)) {
      fetchEventDetail({
        variables: {
          id: parseInt(order.eventId, 10),
        },
      });
    }
  }, [order, fetchEventDetail]);

  useEffect(() => {
    if (!isEmpty(lineItem)) {
      fetchRegistrationOptionDetail({
        variables: {
          id: lineItem.lineItemAbleId,
        },
      });
    }
  }, [fetchRegistrationOptionDetail, lineItem]);

  useEffect(() => {
    if (currentUser && orderId) {
      fetchOrderDetail({
        variables: {
          id: parseInt(orderId, 10),
        },
      });
    }
  }, [currentUser, fetchOrderDetail, orderId]);

  const [createCharge, { loading }] = useMutation(
    CREATE_CHARGE,
    {
      onCompleted: (data) => {
        const response = data.createCharge.message;
        const orderObj = data.createCharge.order;
        if (!isEmpty(orderObj)) {
          updateOrdersCache(orderObj, 'update');
          setErrorMessage(null);
          setDisabled(false);
          history.push(`/payment-confirmation/${orderId}`);
        } else {
          setErrorMessage(response);
          setDisabled(false);
        }
      },
      onError: (errorObj) => {
        setErrorMessage(errorObj.message);
        setDisabled(false);
      },
    },
  );
  const debouncedCreateCharge = useCallback(
    debounce(100, createCharge),
    [createCharge],
  );

  if (orderLoading || eventLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mx-auto px-4 pt-3">
          {errorMessage
            ? <GenericAlert>{errorMessage}</GenericAlert>
            : null}
          <p style={{ display: 'none' }}>{get('firstname', currentUser)}</p>
          <JoinEventSlider
            event={event}
            order={order}
            selectedRegistrationOption={option}
          />
          <p style={{ fontWeight: '600' }}>Billing Information.</p>
          <StripeScriptLoader
            uniqueId="stripe-js"
            script="https://js.stripe.com/v3/"
            async="true"
            loader="Loading..."
          >
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
              <Elements>
                <PaymentForm
                  currentUser={currentUser}
                  order={order}
                  event={event}
                  totalAmount={order.amount}
                  onClick={debouncedCreateCharge}
                  loading={loading}
                  onSelect={setErrorMessage}
                  disabled={disabled}
                  onRemove={setDisabled}
                />
              </Elements>
            </StripeProvider>
          </StripeScriptLoader>
          <SmallLogo>
            <img src={ADD_SMALL_ICON} alt="arrow" className="SmallLogo" />
          </SmallLogo>
          <BackLink to={`/event-manage/${order.eventId}`} imageNotRequired>
                Manage Event
          </BackLink>
        </div>
      </div>
    </>
  );
};


export default JoinEventBilling;
